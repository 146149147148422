import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreatePaymentMethodInput,
  PaymentMethodChannelEnabledInput,
  UpdatePaymentMethodInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreatePaymentMethodGQL,
  PaymentMethodByIdGQL,
  PaymentMethodDetailGQL,
  PaymentMethodItemGQL,
  PaymentMethodItemsGQL,
  PaymentMethodItemsQueryVariables,
  PaymentMethodRowGQL,
  PaymentMethodRowsGQL,
  PaymentMethodRowsQueryVariables,
  UpdateChannelEnabledGQL,
  UpdatePaymentMethodGQL,
} from './gql/payment-method.gql.generated';
import {
  IPaymentMethodItemObject,
  IPaymentMethodRowObject,
  PaymentMethodDetailObject,
  PaymentMethodRowObject,
} from './types/payment-method';

@Injectable()
export class PaymentMethodService {
  constructor(
    private paymentMethodItemGQL: PaymentMethodItemGQL,
    private paymentMethodItemsGQL: PaymentMethodItemsGQL,
    private paymentMethodRowGQL: PaymentMethodRowGQL,
    private rowsGQL: PaymentMethodRowsGQL,
    private createGQL: CreatePaymentMethodGQL,
    private updateGQL: UpdatePaymentMethodGQL,
    // private updateManyGQL: UpdateManyChannelsGQL,
    private updateChannelEnabledGQL: UpdateChannelEnabledGQL,
    // private searchGQL: ChannelSearchGQL,
    private byIdGQL: PaymentMethodByIdGQL,
    private detailGQL: PaymentMethodDetailGQL,
  ) {}

  fetchItem(id: number): Observable<IPaymentMethodItemObject> {
    return this.paymentMethodItemGQL.fetch({ id }).pipe(map((r) => r.data.paymentMethod));
  }

  fetchItems(
    query: PaymentMethodItemsQueryVariables,
  ): Observable<{ items: IPaymentMethodItemObject[]; totalItemsCount: number }> {
    return this.paymentMethodItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number): Observable<IPaymentMethodRowObject> {
    return this.paymentMethodRowGQL.fetch({ id }).pipe(map((r) => r.data.paymentMethod));
  }

  fetchRows(
    query: PaymentMethodRowsQueryVariables,
  ): Observable<{ items: PaymentMethodRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<PaymentMethodDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((result) => result.data.paymentMethod));
  }

  fetchById(id: number) {
    return this.byIdGQL.fetch({ id }).pipe(map((r) => r.data.paymentMethod));
  }

  fetchSearch(searchText?: string | null, results = 10) {
    return this.fetchRows({
      filter: {
        search: searchText,
      },
      page: {},
      sort: { column: 'name' },
    }).pipe(map((r) => r.items));
  }

  fetchForChannels(enabledChannelIds: number[]): Observable<PaymentMethodRowObject[]> {
    return this.fetchRows({
      filter: {
        enabledChannelIds,
      },
      page: {},
      sort: { column: 'id' },
    }).pipe(map((r) => r.items));
  }

  create(input: CreatePaymentMethodInput) {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createPaymentMethod));
  }

  update(input: UpdatePaymentMethodInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updatePaymentMethod));
  }

  updateMany() {
    //TODO
  }

  updateChannelEnabled(input: PaymentMethodChannelEnabledInput) {
    return this.updateChannelEnabledGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updatePaymentMethodChannelEnabled));
  }
}
