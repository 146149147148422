import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { AddressService, IAddressItem } from '@x/ecommerce/domain-client';
import { AddressFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AddressItemCollectionProvider
  implements IDataCollectionProvider<IAddressItem, AddressFilterInput>
{
  constructor(private addressService: AddressService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<AddressFilterInput, any>>): Observable<IDataCollection<IAddressItem>> {
    return this.addressService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IAddressItem | Observable<IAddressItem> {
    return this.addressService.fetchItem(Number(id));
  }

  toId(model: IAddressItem): number {
    return model.id;
  }

  toString(model: IAddressItem): string {
    return [model.street, model.suburb, model.city, model.province, model.country]
      .filter((p) => !!p)
      .join(', ');
  }
}
