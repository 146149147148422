import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductOptionItemFragment = { __typename: 'ProductOptionObject', id: number, name: string, code: string };

export type ProductOptionRowFragment = { __typename: 'ProductOptionObject', id: number, name: string, createdAt: any, updatedAt: any, values: Array<{ __typename: 'ProductOptionValueObject', id: number, code: string, name: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', locale: string, name: string }> };

export type ProductOptionDetailFragment = { __typename: 'ProductOptionObject', id: number, code: string, name: string, values: Array<{ __typename: 'ProductOptionValueObject', id: number, name: string, code: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', id: number, locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', id: number, locale: string, name: string }> };

export type ProductOptionItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductOptionItemQuery = { __typename: 'Query', productOption: { __typename: 'ProductOptionObject', id: number, name: string, code: string } };

export type ProductOptionItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ProductOptionFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductOptionItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductOptionObject', id: number, name: string, code: string }> };

export type ProductOptionRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductOptionRowQuery = { __typename: 'Query', productOption: { __typename: 'ProductOptionObject', id: number, name: string, createdAt: any, updatedAt: any, values: Array<{ __typename: 'ProductOptionValueObject', id: number, code: string, name: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', locale: string, name: string }> } };

export type ProductOptionRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ProductOptionFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductOptionRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductOptionObject', id: number, name: string, createdAt: any, updatedAt: any, values: Array<{ __typename: 'ProductOptionValueObject', id: number, code: string, name: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', locale: string, name: string }> }> };

export type ProductOptionDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductOptionDetailQuery = { __typename: 'Query', productOption: { __typename: 'ProductOptionObject', id: number, code: string, name: string, values: Array<{ __typename: 'ProductOptionValueObject', id: number, name: string, code: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', id: number, locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', id: number, locale: string, name: string }> } };

export type ProductOptionSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type ProductOptionSearchQuery = { __typename: 'Query', productOptions: Array<{ __typename: 'ProductOptionObject', id: number, name: string, values: Array<{ __typename: 'ProductOptionValueObject', id: number, code: string, name: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', locale: string, name: string }> }> };

export type CreateProductOptionMutationVariables = Types.Exact<{
  data: Types.CreateProductOptionInput;
}>;


export type CreateProductOptionMutation = { __typename: 'Mutation', createProductOption: { __typename: 'ProductOptionObject', id: number, code: string, name: string, values: Array<{ __typename: 'ProductOptionValueObject', id: number, name: string, code: string, position: number, option: { __typename: 'ProductOptionObject', id: number }, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', id: number, locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', id: number, locale: string, name: string }> } };

export type UpdateProductOptionMutationVariables = Types.Exact<{
  data: Types.UpdateProductOptionInput;
}>;


export type UpdateProductOptionMutation = { __typename: 'Mutation', updateProductOption: { __typename: 'ProductOptionObject', id: number, code: string, name: string, values: Array<{ __typename: 'ProductOptionValueObject', id: number, name: string, code: string, option: { __typename: 'ProductOptionObject', id: number }, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', id: number, locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', id: number, locale: string, name: string }> } };

export type ArchiveProductOptionMutationVariables = Types.Exact<{
  productOptionId: Types.Scalars['Int']['input'];
}>;


export type ArchiveProductOptionMutation = { __typename: 'Mutation', archiveProductOption: { __typename: 'ProductOptionObject', id: number, code: string, name: string, values: Array<{ __typename: 'ProductOptionValueObject', id: number, name: string, code: string, position: number, translations: Array<{ __typename: 'ProductOptionValueTranslationObject', id: number, locale: string, name: string }> }>, translations: Array<{ __typename: 'ProductOptionTranslationObject', id: number, locale: string, name: string }> } };

export const ProductOptionItemFragmentDoc = gql`
    fragment ProductOptionItem on ProductOptionObject {
  id
  name
  code
}
    `;
export const ProductOptionRowFragmentDoc = gql`
    fragment ProductOptionRow on ProductOptionObject {
  id
  name
  createdAt
  updatedAt
  values {
    id
    code
    name
    position
    translations {
      locale
      name
    }
  }
  translations {
    locale
    name
  }
}
    `;
export const ProductOptionDetailFragmentDoc = gql`
    fragment ProductOptionDetail on ProductOptionObject {
  id
  code
  name
  values {
    id
    name
    code
    position
    translations {
      id
      locale
      name
    }
  }
  translations {
    id
    locale
    name
  }
}
    `;
export const ProductOptionItemDocument = gql`
    query ProductOptionItem($id: Int!) {
  productOption(id: $id) {
    ...ProductOptionItem
  }
}
    ${ProductOptionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductOptionItemGQL extends Apollo.Query<ProductOptionItemQuery, ProductOptionItemQueryVariables> {
    document = ProductOptionItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductOptionItemsDocument = gql`
    query ProductOptionItems($page: PageInput, $filter: ProductOptionFilterInput, $sort: SortInput) {
  items: productOptions(page: $page, filter: $filter, sort: $sort) {
    ...ProductOptionItem
  }
  totalItemsCount: productOptionsCount(filter: $filter)
}
    ${ProductOptionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductOptionItemsGQL extends Apollo.Query<ProductOptionItemsQuery, ProductOptionItemsQueryVariables> {
    document = ProductOptionItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductOptionRowDocument = gql`
    query ProductOptionRow($id: Int!) {
  productOption(id: $id) {
    ...ProductOptionRow
  }
}
    ${ProductOptionRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductOptionRowGQL extends Apollo.Query<ProductOptionRowQuery, ProductOptionRowQueryVariables> {
    document = ProductOptionRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductOptionRowsDocument = gql`
    query ProductOptionRows($page: PageInput, $filter: ProductOptionFilterInput, $sort: SortInput) {
  items: productOptions(filter: $filter, page: $page, sort: $sort) {
    id
    name
    createdAt
    updatedAt
    values {
      id
      code
      name
      position
      translations {
        locale
        name
      }
    }
    translations {
      locale
      name
    }
  }
  totalItemsCount: productOptionsCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductOptionRowsGQL extends Apollo.Query<ProductOptionRowsQuery, ProductOptionRowsQueryVariables> {
    document = ProductOptionRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductOptionDetailDocument = gql`
    query ProductOptionDetail($id: Int!) {
  productOption(id: $id) {
    ...ProductOptionDetail
  }
}
    ${ProductOptionDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductOptionDetailGQL extends Apollo.Query<ProductOptionDetailQuery, ProductOptionDetailQueryVariables> {
    document = ProductOptionDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductOptionSearchDocument = gql`
    query ProductOptionSearch($searchText: String, $results: Int!) {
  productOptions(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    id
    name
    values {
      id
      code
      name
      position
      translations {
        locale
        name
      }
    }
    translations {
      locale
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductOptionSearchGQL extends Apollo.Query<ProductOptionSearchQuery, ProductOptionSearchQueryVariables> {
    document = ProductOptionSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductOptionDocument = gql`
    mutation CreateProductOption($data: CreateProductOptionInput!) {
  createProductOption(data: $data) {
    id
    code
    name
    values {
      id
      name
      code
      position
      option {
        id
      }
      translations {
        id
        locale
        name
      }
    }
    translations {
      id
      locale
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductOptionGQL extends Apollo.Mutation<CreateProductOptionMutation, CreateProductOptionMutationVariables> {
    document = CreateProductOptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductOptionDocument = gql`
    mutation UpdateProductOption($data: UpdateProductOptionInput!) {
  updateProductOption(data: $data) {
    id
    code
    name
    values {
      id
      name
      code
      option {
        id
      }
      translations {
        id
        locale
        name
      }
    }
    translations {
      id
      locale
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductOptionGQL extends Apollo.Mutation<UpdateProductOptionMutation, UpdateProductOptionMutationVariables> {
    document = UpdateProductOptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveProductOptionDocument = gql`
    mutation ArchiveProductOption($productOptionId: Int!) {
  archiveProductOption(id: $productOptionId) {
    ...ProductOptionDetail
  }
}
    ${ProductOptionDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveProductOptionGQL extends Apollo.Mutation<ArchiveProductOptionMutation, ArchiveProductOptionMutationVariables> {
    document = ArchiveProductOptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }