import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { SubscriptionEntryFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { ISubscriptionEntryRowObject, SubscriptionEntryService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class SubscriptionEntryRowCollectionProvider
  implements
    IDataCollectionProvider<ISubscriptionEntryRowObject, SubscriptionEntryFilterInput, any, number>
{
  constructor(private entryService: SubscriptionEntryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriptionEntryRowObject>> {
    return this.entryService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: ISubscriptionEntryRowObject): number {
    return model.id;
  }

  toString(model: ISubscriptionEntryRowObject): string {
    return String(model.id);
  }
}
