import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { ParcelOptionFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { ParcelOptionService } from '../../domain-client/services/parcel-option.service';
import { IParcelOptionRowObject } from '../../domain-client/services/types/parcel-option';

@Injectable({ providedIn: 'root' })
export class ParcelOptionRowCollectionProvider
  implements
    IDataCollectionProvider<IParcelOptionRowObject, ParcelOptionFilterInput>,
    IDataProvider<IParcelOptionRowObject>
{
  constructor(private parcelOptionService: ParcelOptionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IParcelOptionRowObject>> {
    return this.parcelOptionService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IParcelOptionRowObject> {
    return this.parcelOptionService.fetchRow(Number(id));
  }

  toId(model: IParcelOptionRowObject): string | number {
    return model.id;
  }

  toString(model: IParcelOptionRowObject): string {
    return model.name;
  }
}
