import { ModuleWithProviders, NgModule } from '@angular/core';
import { STORAGE_VERSION } from '@x/common/storage/storage-version';
import { LocalStorage, LOCAL_STORAGE_PROVIDER } from './local-storage.service';
import { NoopStorage } from './noop-storage';
import { SessionStorage, SESSION_STORAGE_PROVIDER } from './session-storage.service';
import { StorageProviderInterface } from './storage';
import { STORAGE_PREFIX } from './storage-prefix';

export interface StorageModuleConfig {
  prefix: string;
  version: number;
}

@NgModule({
  providers: [LocalStorage, SessionStorage],
})
export class StorageModule {
  static forRoot(config: StorageModuleConfig): ModuleWithProviders<StorageModule> {
    return {
      ngModule: StorageModule,
      providers: [
        {
          provide: LOCAL_STORAGE_PROVIDER,
          useValue: (typeof localStorage !== 'undefined'
            ? localStorage
            : new NoopStorage()) as StorageProviderInterface,
        },
        {
          provide: SESSION_STORAGE_PROVIDER,
          useValue: (typeof sessionStorage !== 'undefined'
            ? sessionStorage
            : new NoopStorage()) as StorageProviderInterface,
        },
        {
          provide: STORAGE_PREFIX,
          useValue: config.prefix,
        },
        {
          provide: STORAGE_VERSION,
          useValue: config.version,
        },
      ],
    };
  }
}
