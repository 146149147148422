import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { Observable } from 'rxjs';
import {
  UserAddressesGQL,
  UserAddressRowsGQL,
  UserAddressRowsQueryVariables,
} from './gql/user-address.gql.generated';
import { IUserRowAddressObject } from './types/user';

@Injectable()
export class UserAddressService {
  constructor(
    private userAddressRowsGQL: UserAddressRowsGQL,
    private userAddressesGQL: UserAddressesGQL,
  ) {}

  fetchRows(
    query: UserAddressRowsQueryVariables,
  ): Observable<{ items: IUserRowAddressObject[]; totalItemsCount: number }> {
    return this.userAddressRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchAddresses(
    search?: string | null,
    userIds?: number[] | null,
    limit = 10,
  ): Observable<IUserRowAddressObject[]> {
    return this.userAddressesGQL
      .fetch({
        filter: { search, userIds },
        page: { index: 0, size: limit },
        sort: { column: 'street', order: 'asc' },
      })
      .pipe(mapFetchResultData((r) => r.addresses));
  }
}
