import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateTaxonInput, UpdateTaxonInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ArchiveTaxonGQL,
  CreateTaxonGQL,
  TaxonByIdGQL,
  TaxonDetailGQL,
  TaxonFormGQL,
  TaxonItemGQL,
  TaxonItemsGQL,
  TaxonItemsQueryVariables,
  TaxonRowsGQL,
  TaxonRowsQueryVariables,
  TaxonSearchGQL,
  UpdateTaxonGQL,
} from './gql/taxon.gql.generated';
import {
  ITaxonItemObject,
  TaxonDetailObject,
  TaxonRowObject,
  TaxonSearchObject,
} from './types/taxon';

@Injectable()
export class TaxonService {
  constructor(
    private taxonItemGQL: TaxonItemGQL,
    private taxonItemsGQL: TaxonItemsGQL,
    private updateTaxonGQL: UpdateTaxonGQL,
    private createTaxonGQL: CreateTaxonGQL,
    private archiveGQL: ArchiveTaxonGQL,
    private searchGQL: TaxonSearchGQL,
    private byIdGQL: TaxonByIdGQL,
    private rowsGQL: TaxonRowsGQL,
    private detailGQL: TaxonDetailGQL,
    private formGQL: TaxonFormGQL,
  ) {}

  fetchItem(id: number): Observable<ITaxonItemObject> {
    return this.taxonItemGQL.fetch({ id }).pipe(map((r) => r.data.taxon));
  }

  fetchItems(
    query: TaxonItemsQueryVariables,
  ): Observable<{ items: ITaxonItemObject[]; totalItemsCount: number }> {
    return this.taxonItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<TaxonDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((result) => result.data.taxon));
  }

  fetchRows(
    query: TaxonRowsQueryVariables,
  ): Observable<{ items: TaxonRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<TaxonSearchObject[]> {
    return this.searchGQL.fetch({ searchText, results }).pipe(map((r) => r.data.taxons));
  }

  fetchById(id: number): Observable<TaxonSearchObject> {
    return this.byIdGQL.fetch({ id }).pipe(map((r) => r.data.taxon));
  }

  fetchForm(id: number) {
    return this.formGQL.fetch({ id }).pipe(map((r) => r.data.taxon));
  }

  update(id: number, data: UpdateTaxonInput) {
    return this.updateTaxonGQL
      .mutate({ id, data })
      .pipe(mapFetchResultData((data) => data.updateTaxon));
  }

  create(data: CreateTaxonInput) {
    return this.createTaxonGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.createTaxon));
  }

  archive(id: number) {
    return this.archiveGQL.mutate({ id }).pipe(mapFetchResultData((data) => data.archiveTaxon));
  }
}
