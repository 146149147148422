import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DeleteGlobalVariableGQL,
  DeleteUserVariableGQL,
  GlobalVariableArrayGQL,
  GlobalVariableGQL,
  SetGlobalVariableGQL,
  SetUserVariableGQL,
  UserVariableArrayGQL,
  UserVariableGQL,
} from './gql/variable.gql.generated';
import { IVariableObject } from './types/variable';

@Injectable()
export class VariableService {
  constructor(
    private readonly globalVariableGQL: GlobalVariableGQL,
    private readonly globalVariableArrayGQL: GlobalVariableArrayGQL,
    private readonly setGlobalVariableGQL: SetGlobalVariableGQL,
    private readonly deleteGlobalVariableGQL: DeleteGlobalVariableGQL,
    private readonly userVariableGQL: UserVariableGQL,
    private readonly userVariableArrayGQL: UserVariableArrayGQL,
    private readonly setUserVariableGQL: SetUserVariableGQL,
    private readonly deleteUserVariableGQL: DeleteUserVariableGQL,
  ) {}

  fetchGlobalVariable(key: string): Observable<IVariableObject> {
    return this.globalVariableGQL.fetch({ key }).pipe(map((r) => r.data.globalVariable));
  }

  fetchGlobalVariableArray(prefix: string): Observable<IVariableObject[]> {
    return this.globalVariableArrayGQL.fetch({ prefix }).pipe(map((r) => r.data.globalVariables));
  }

  setGlobalVariable(key: string, value: any): Observable<IVariableObject> {
    return this.setGlobalVariableGQL
      .mutate({ input: { key, value } })
      .pipe(mapFetchResultData((r) => r.setGlobalVariable));
  }

  deleteGlobalVariable(key: string): Observable<{ key: string }> {
    return this.deleteGlobalVariableGQL
      .mutate({ input: { key } })
      .pipe(mapFetchResultData((r) => r.deleteGlobalVariable));
  }

  fetchUserVariable(key: string, userId: number): Observable<IVariableObject> {
    return this.userVariableGQL.fetch({ key, userId }).pipe(map((r) => r.data.userVariable));
  }

  fetchUserVariableArray(prefix: string, userId: number): Observable<IVariableObject[]> {
    return this.userVariableArrayGQL
      .fetch({ prefix, userId })
      .pipe(map((r) => r.data.userVariables));
  }

  setUserVariable(key: string, value: any, userId: number): Observable<IVariableObject> {
    return this.setUserVariableGQL
      .mutate({ input: { key, value, userId } })
      .pipe(mapFetchResultData((r) => r.setUserVariable));
  }

  deleteUserVariable(key: string, userId: number): Observable<{ key: string }> {
    return this.deleteUserVariableGQL
      .mutate({ input: { key, userId } })
      .pipe(mapFetchResultData((r) => r.deleteUserVariable));
  }
}
