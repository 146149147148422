import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { StockItemService } from '@x/ecommerce/domain-client';
import { StockItemObject } from '@x/schemas/ecommerce';
import { tap } from 'rxjs/operators';

@Injectable()
export class StockItemIdTransformer implements ModelTransformer<StockItemObject, number> {
  private _cache = new Map<number, StockItemObject>();

  constructor(private service: StockItemService) {}

  modelToValue(model: StockItemObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<any> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as StockItemObject);
    }

    return this.service
      .fetchById(id)
      .pipe(tap((r) => this._cache.set(id, r as StockItemObject)))
      .toPromise();
  }
}
