import { animate, style, transition, trigger } from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataTableView, IColumnDefinition } from '@x/common/data';
import { DragBoundaryDirective, DropPredicateFn, DropZoneDragEvent } from '@x/common/drag';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataActionDefinitionDirective } from './data-action.directive';
import { DataBulkActionDefinitionDirective } from './data-bulk-action.directive';
import { DataColumnDefinitionDirective } from './data-column.directive';

export class DataRowActionEvent<T> {
  constructor(
    public actionId: string,
    public row: T,
  ) {}
}

export class DataBulkActionEvent<I = string | number> {
  constructor(
    public actionId: string,
    public selection: Array<I>,
  ) {}
}

export class DataColumnDropEvent {
  constructor(
    public columnId: string,
    public dragEvent: DropZoneDragEvent,
  ) {}
}

export class DataRowReorderEvent<T> {
  constructor(
    public oldPosition: number | null,
    public newPosition: number | null,
    public dragEvent: DropZoneDragEvent,
  ) {}
}

interface IColumnDefinitionTemplate extends IColumnDefinition {
  template: DataColumnDefinitionDirective;
  droppable?: boolean;
}

@Component({
  selector: 'x-data-table',
  templateUrl: 'data-table.component.html',
  styleUrls: ['data-table.component.scss'],
  host: {
    class: 'x-data-table',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [
    trigger('fadeInOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DataTableComponent<T = any, F = any, A = any, I = string | number>
  implements AfterContentInit, OnDestroy
{
  @ViewChild('rowActionOverflowOverlay', { static: false })
  rowActionOverflowOverlay: CdkConnectedOverlay;

  @Input()
  view?: DataTableView<T, F, A, I>;

  @Input()
  set selectable(selectable: any) {
    this._selectable = coerceBooleanProperty(selectable);
  }
  get selectable() {
    return this._selectable;
  }
  _selectable = true;

  @Input()
  set activatable(activatable: any) {
    this._activatable = coerceBooleanProperty(activatable);
  }
  get activatable() {
    return this._activatable;
  }
  _activatable = true;

  @Input('draggableRows')
  set draggable(draggable: any) {
    this._draggable = coerceBooleanProperty(draggable);
    this.updateDisplayColumns();
  }
  get draggable() {
    return this._draggable;
  }
  _draggable = false;

  @Input('reorderableRows')
  set reorderable(reorderable: any) {
    this._reorderable = coerceBooleanProperty(reorderable);
    this.updateDisplayColumns();
  }
  get reorderable() {
    return this._reorderable;
  }

  @HostBinding('class.x-reorderable')
  _reorderable = false;

  _scrollStateRestored = false;

  @Output('rowAction')
  rowActionEmitter = new EventEmitter<DataRowActionEvent<T>>();

  @Output('bulkAction')
  bulkActionEmitter = new EventEmitter<DataBulkActionEvent<I>>();

  @Output('columnDrop')
  columnDropEmitter = new EventEmitter<DataColumnDropEvent>();
  onColumnDrop(event: DropZoneDragEvent, columnId: string) {
    this.columnDropEmitter.emit(new DataColumnDropEvent(columnId, event));
  }

  @Output('rowReorder')
  rowReorderEmitter = new EventEmitter<DataRowReorderEvent<T>>();
  onRowReorder(event: DropZoneDragEvent) {
    const oldPosition = event.draggable?.dragData
      ? this.getRowPosition(event.draggable?.dragData)
      : null;

    let newPosition = event.dropzone ? this.getRowPosition(event.dropzone.dropZoneData as T) : null;
    if (newPosition !== null) {
      newPosition++;
      // because our dropzone is _below_ the row
    } else {
      newPosition = 0; // FIXME, position must be first in this page
    }

    this.rowReorderEmitter.emit(new DataRowReorderEvent(oldPosition, newPosition, event));
  }

  @Input()
  canReorder: DropPredicateFn = () => true;

  @ContentChildren(DataColumnDefinitionDirective, { descendants: true })
  columnTemplates: QueryList<DataColumnDefinitionDirective>;

  @ContentChildren(DataActionDefinitionDirective, { descendants: true })
  rowActionTemplates: QueryList<DataActionDefinitionDirective>;
  primaryRowActionTemplates: DataActionDefinitionDirective[];
  secondaryRowActionTemplates: DataActionDefinitionDirective[];

  @ContentChildren(DataBulkActionDefinitionDirective, { descendants: true })
  bulkActionTemplates: QueryList<DataBulkActionDefinitionDirective>;

  @ViewChild('tableContainer')
  tableContainerRef: ElementRef<HTMLDivElement>;

  displayColumns: string[] = [];
  columnDefs: IColumnDefinitionTemplate[] = [];

  trackById = (i: number, o: T) => this.view?.getId(o) ?? o;
  trackActionById = (
    i: number,
    o: DataActionDefinitionDirective | DataBulkActionDefinitionDirective,
  ) => o.id;

  private _scroll$ = new Subject<any>();
  private _destroy$ = new Subject<void>();

  rowActionOverlayOpenId: I | null = null;

  constructor(
    private readonly changeRef: ChangeDetectorRef,
    @Optional()
    public readonly dragBoundary?: DragBoundaryDirective,
  ) {}

  ngAfterContentInit() {
    const validColumnsDefs: IColumnDefinitionTemplate[] = [];

    if (!this.view) {
      console.warn('No view specified for DataTableComponent');
      return;
    }

    for (let template of this.columnTemplates) {
      const def = this.view?.columns.find((c) => c.id === template.id);
      if (def) {
        validColumnsDefs.push({
          ...def,
          template,
        });
      } else {
        console.warn(`Definition of column '${template.id}' not found.`);
      }
    }
    for (let def of this.view.columns) {
      const exist = validColumnsDefs.find((d) => d.id === def.id);
      if (!exist) {
        console.warn(`Template for column '${def.id}' not found.`);
      }
    }

    this.columnDefs = validColumnsDefs;

    this.updateActionTemplates();

    this.view
      .stateChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.rowActionOverlayOpenId = null;
        this.updateDisplayColumns();
        this.changeRef.markForCheck();
      });

    this.updateDisplayOptions();
    this.updateDisplayColumns();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onSortChange(event: Sort) {
    if (this.view) {
      this.view.setSort(event.active, event.direction === '' ? null : event.direction);
    }
  }

  onContentChange() {
    this.restoreScrollState();
  }

  onScroll($event: Event) {
    this._scroll$.next($event);
  }

  onOverflowActionClick(row: T) {
    const id = this.getId(row);

    if (this.rowActionOverlayOpenId === id) {
      this.rowActionOverlayOpenId = null;
    } else {
      this.rowActionOverlayOpenId = id;
    }
  }

  getId(row: T): I | null {
    return this.view?.getId(row) ?? null;
  }

  onRowClick(event: MouseEvent, row: T) {
    const id = this.getId(row);
    if (this.view && id && this._activatable) {
      this.view.setActive(id);
    }
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  isRowActive(row: T) {
    return this._activatable && this.view?.getActive() === this.getId(row);
  }

  getRowPosition(row: T): number | null {
    if (!row) {
      return null;
    }

    if (this.view?.options.rowPositionField) {
      const position = row[this.view?.options.rowPositionField as keyof T];
      if (typeof position === 'number' || position === null) {
        return position as number | null;
      }

      console.warn(
        `Invalid value for options.rowPositionField field ${String(
          this.view?.options.rowPositionField,
        )}`,
        {
          row,
        },
      );
    }

    if (this.view?.options.rowPositionFn) {
      const position = this.view?.options.rowPositionFn(row);
      if (typeof position === 'number' || position === null) {
        return position as number | null;
      }
    }

    return null;
  }

  private updateDisplayOptions() {
    if (this.view) {
      const { draggableRows, reorderableRows } = this.view.options;
      if (draggableRows !== undefined) {
        this.draggable = draggableRows;
      }
      if (reorderableRows !== undefined) {
        this.reorderable = reorderableRows;
      }
    }
  }

  private updateDisplayColumns() {
    if (this.view) {
      const displayable: string[] = [];

      for (let id of this.view.selectedColumns) {
        const def = this.columnDefs.find((d) => d.id === id);

        if (def) {
          displayable.push(id);
        } else {
          console.warn(`Selected column '${id}' not found.`);
        }
      }

      this.displayColumns = [
        ...(this._reorderable ? ['_reorderDropZone'] : []),
        ...(this._draggable ? ['_drag'] : []),
        ...(this._selectable ? ['_select'] : []),
        ...(this.view.options.rowPositionField || this.view.options.rowPositionFn
          ? ['_position']
          : []),
        ...displayable,
        ...(this.rowActionTemplates?.length > 0 ? ['_action'] : []),
      ];
    } else {
      this.displayColumns = [];
    }
  }

  private updateActionTemplates() {
    let i = 0;
    this.primaryRowActionTemplates = [];
    this.secondaryRowActionTemplates = [];
    for (let template of this.rowActionTemplates) {
      if (template.primary) {
        this.primaryRowActionTemplates.push(template);
      } else {
        this.secondaryRowActionTemplates.push(template);
      }

      i++;
    }
  }

  private restoreScrollState() {
    if (this.view && !this._scrollStateRestored) {
      // this._scrollStateRestored = true;
      // const scrollState = this.view.getScrollState();
      // if (scrollState.top !== 0 || scrollState.left !== 0) {
      //   setTimeout(() => {
      //     this.tableContainerRef.nativeElement.scrollTop = scrollState.top;
      //     this.tableContainerRef.nativeElement.scrollLeft = scrollState.left;
      //   }, 50);
      // }
    }
  }
}
