import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { ProductAttributeSearchObject, ProductAttributeService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProductAttributeIdTransformer
  implements ModelTransformer<ProductAttributeSearchObject, number>
{
  private _cache = new Map<number, ProductAttributeSearchObject>();

  constructor(private service: ProductAttributeService) {}

  modelToValue(model: ProductAttributeSearchObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<ProductAttributeSearchObject> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as ProductAttributeSearchObject);
    }

    return firstValueFrom(this.service.fetchById(id).pipe(tap((r) => this._cache.set(id, r))));
  }
}
