import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubscriptionVariantProductQuantityDetailFragment = { __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string }, subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string } };

export type SubscriptionVariantProductQuantityRowFragment = { __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', name: string }, subscriptionVariant: { __typename: 'SubscriptionVariantObject', name: string } };

export type SubscriptionVariantProductQuantityItemFragment = { __typename: 'SubscriptionVariantProductQuantityObject', id: number, productVariant: { __typename: 'ProductVariantObject', name: string } };

export type SubscriptionVariantProductQuantityRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.SubscriptionVariantProductQuantityFilterInput>;
}>;


export type SubscriptionVariantProductQuantityRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', name: string }, subscriptionVariant: { __typename: 'SubscriptionVariantObject', name: string } }> };

export type SubscriptionVariantProductQuantityItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionVariantProductQuantityItemQuery = { __typename: 'Query', subscriptionVariantProductQuantity: { __typename: 'SubscriptionVariantProductQuantityObject', id: number, productVariant: { __typename: 'ProductVariantObject', name: string } } };

export type SubscriptionVariantProductQuantityDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionVariantProductQuantityDetailQuery = { __typename: 'Query', subscriptionVariantProductQuantity: { __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string }, subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string } } };

export type SubscriptionVariantProductQuantitySearchQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.SubscriptionVariantProductQuantityFilterInput>;
}>;


export type SubscriptionVariantProductQuantitySearchQuery = { __typename: 'Query', subscriptionVariantProductQuantities: Array<{ __typename: 'SubscriptionVariantProductQuantityObject', id: number, productVariant: { __typename: 'ProductVariantObject', name: string } }> };

export type SubscriptionVariantProductQuantityUpdateMutationVariables = Types.Exact<{
  data: Types.SubscriptionVariantProductQuantityInput;
}>;


export type SubscriptionVariantProductQuantityUpdateMutation = { __typename: 'Mutation', updateSubscriptionVariantProductQuantity: { __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string }, subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string } } };

export type SubscriptionVariantProductQuantityCreateMutationVariables = Types.Exact<{
  data: Types.SubscriptionVariantProductQuantityInput;
}>;


export type SubscriptionVariantProductQuantityCreateMutation = { __typename: 'Mutation', createSubscriptionVariantProductQuantity: { __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string }, subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string } } };

export const SubscriptionVariantProductQuantityDetailFragmentDoc = gql`
    fragment SubscriptionVariantProductQuantityDetail on SubscriptionVariantProductQuantityObject {
  id
  quantity
  productVariant {
    id
    name
  }
  subscriptionVariant {
    id
    name
  }
}
    `;
export const SubscriptionVariantProductQuantityRowFragmentDoc = gql`
    fragment SubscriptionVariantProductQuantityRow on SubscriptionVariantProductQuantityObject {
  id
  quantity
  productVariant {
    name
  }
  subscriptionVariant {
    name
  }
}
    `;
export const SubscriptionVariantProductQuantityItemFragmentDoc = gql`
    fragment SubscriptionVariantProductQuantityItem on SubscriptionVariantProductQuantityObject {
  id
  productVariant {
    name
  }
}
    `;
export const SubscriptionVariantProductQuantityRowsDocument = gql`
    query SubscriptionVariantProductQuantityRows($page: PageInput, $sort: SortInput, $filter: SubscriptionVariantProductQuantityFilterInput) {
  items: subscriptionVariantProductQuantities(
    page: $page
    sort: $sort
    filter: $filter
  ) {
    ...SubscriptionVariantProductQuantityRow
  }
  totalItemsCount: subscriptionVariantProductQuantitiesCount(filter: $filter)
}
    ${SubscriptionVariantProductQuantityRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantProductQuantityRowsGQL extends Apollo.Query<SubscriptionVariantProductQuantityRowsQuery, SubscriptionVariantProductQuantityRowsQueryVariables> {
    document = SubscriptionVariantProductQuantityRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantProductQuantityItemDocument = gql`
    query SubscriptionVariantProductQuantityItem($id: Int!) {
  subscriptionVariantProductQuantity(id: $id) {
    ...SubscriptionVariantProductQuantityItem
  }
}
    ${SubscriptionVariantProductQuantityItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantProductQuantityItemGQL extends Apollo.Query<SubscriptionVariantProductQuantityItemQuery, SubscriptionVariantProductQuantityItemQueryVariables> {
    document = SubscriptionVariantProductQuantityItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantProductQuantityDetailDocument = gql`
    query SubscriptionVariantProductQuantityDetail($id: Int!) {
  subscriptionVariantProductQuantity(id: $id) {
    ...SubscriptionVariantProductQuantityDetail
  }
}
    ${SubscriptionVariantProductQuantityDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantProductQuantityDetailGQL extends Apollo.Query<SubscriptionVariantProductQuantityDetailQuery, SubscriptionVariantProductQuantityDetailQueryVariables> {
    document = SubscriptionVariantProductQuantityDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantProductQuantitySearchDocument = gql`
    query SubscriptionVariantProductQuantitySearch($page: PageInput, $sort: SortInput, $filter: SubscriptionVariantProductQuantityFilterInput) {
  subscriptionVariantProductQuantities(page: $page, sort: $sort, filter: $filter) {
    ...SubscriptionVariantProductQuantityItem
  }
}
    ${SubscriptionVariantProductQuantityItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantProductQuantitySearchGQL extends Apollo.Query<SubscriptionVariantProductQuantitySearchQuery, SubscriptionVariantProductQuantitySearchQueryVariables> {
    document = SubscriptionVariantProductQuantitySearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantProductQuantityUpdateDocument = gql`
    mutation SubscriptionVariantProductQuantityUpdate($data: SubscriptionVariantProductQuantityInput!) {
  updateSubscriptionVariantProductQuantity(data: $data) {
    ...SubscriptionVariantProductQuantityDetail
  }
}
    ${SubscriptionVariantProductQuantityDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantProductQuantityUpdateGQL extends Apollo.Mutation<SubscriptionVariantProductQuantityUpdateMutation, SubscriptionVariantProductQuantityUpdateMutationVariables> {
    document = SubscriptionVariantProductQuantityUpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantProductQuantityCreateDocument = gql`
    mutation SubscriptionVariantProductQuantityCreate($data: SubscriptionVariantProductQuantityInput!) {
  createSubscriptionVariantProductQuantity(data: $data) {
    ...SubscriptionVariantProductQuantityDetail
  }
}
    ${SubscriptionVariantProductQuantityDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantProductQuantityCreateGQL extends Apollo.Mutation<SubscriptionVariantProductQuantityCreateMutation, SubscriptionVariantProductQuantityCreateMutationVariables> {
    document = SubscriptionVariantProductQuantityCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }