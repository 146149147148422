import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { LocaleItem } from '@x/common/locale';
import { ChannelService, IChannelItemObject, IChannelRowObject } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChannelContext, ICurrencyItem } from '../services/channel-context.service';

@Injectable({ providedIn: 'root' })
export class ChannelItemCollectionProvider implements IDataCollectionProvider<IChannelItemObject> {
  constructor(private channelService: ChannelService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IChannelItemObject>> {
    return this.channelService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IChannelItemObject | Observable<IChannelItemObject> {
    return this.channelService.fetchItem(Number(id));
  }

  toId(model: IChannelItemObject): string | number {
    return model.id;
  }

  toString(model: IChannelItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ChannelRowCollectionProvider implements IDataCollectionProvider<IChannelRowObject> {
  constructor(private channelService: ChannelService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IChannelRowObject>> {
    return this.channelService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IChannelRowObject | Observable<IChannelRowObject> {
    return this.channelService.fetchRow(Number(id));
  }

  toId(model: IChannelRowObject): string | number {
    return model.id;
  }

  toString(model: IChannelRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ChannelLocaleItemCollectionProvider
  implements IDataCollectionProvider<LocaleItem, any, any, string>
{
  constructor(private channelService: ChannelContext) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<LocaleItem>> {
    return this.channelService
      .getLocaleOptionsInAllChannels()
      .pipe(map((items) => ({ items, totalItemsCount: items.length })));
  }

  fetchSingle(id: string): LocaleItem | Observable<LocaleItem> {
    return this.channelService.getLocaleOption(id);
  }

  toId(model: LocaleItem): string {
    return model.id;
  }

  toString(model: LocaleItem): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ChannelCurrencyItemCollectionProvider
  implements IDataCollectionProvider<ICurrencyItem, any, any, string>
{
  constructor(private channelService: ChannelContext) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ICurrencyItem>> {
    return this.channelService
      .getCurrencyOptionsInAllChannels()
      .pipe(map((items) => ({ items, totalItemsCount: items.length })));
  }

  fetchSingle(id: string): Observable<ICurrencyItem | undefined> {
    return this.channelService
      .getCurrencyOptionsInAllChannels()
      .pipe(map((currencies) => currencies.find((currency) => currency.id === id)));
  }

  toId(model: ICurrencyItem): string {
    return model.id;
  }

  toString(model: ICurrencyItem): string {
    return model.id;
  }
}
