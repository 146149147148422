import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  CollectionPointService,
  ICollectionPointItemObject,
  ICollectionPointRowObject,
} from '@x/ecommerce/domain-client';
import { CollectionPointFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CollectionPointItemCollectionProvider
  implements
    IDataCollectionProvider<ICollectionPointItemObject, CollectionPointFilterInput, any, number>
{
  constructor(private collectionPointService: CollectionPointService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ICollectionPointItemObject>> {
    return this.collectionPointService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): ICollectionPointItemObject | Observable<ICollectionPointItemObject> {
    return this.collectionPointService.fetchItem(Number(id));
  }

  toId(model: ICollectionPointItemObject): number {
    return model.id;
  }

  toString(model: ICollectionPointItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class CollectionPointRowCollectionProvider
  implements
    IDataCollectionProvider<ICollectionPointRowObject, CollectionPointFilterInput, any, number>
{
  constructor(private collectionPointService: CollectionPointService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ICollectionPointRowObject>> {
    return this.collectionPointService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): ICollectionPointRowObject | Observable<ICollectionPointRowObject> {
    return this.collectionPointService.fetchRow(Number(id));
  }

  toId(model: ICollectionPointRowObject): number {
    return model.id;
  }

  toString(model: ICollectionPointRowObject): string {
    return model.name;
  }
}
