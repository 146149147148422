import { Injectable } from '@angular/core';
import { ShippingMethodOrderIntervalFilterInput } from '@x/schemas/ecommerce';
import { map } from 'rxjs/operators';
import { ShippingMethodOrderIntervalGQL } from './gql/shipping-method-order.gql.generated';

@Injectable()
export class ShippingMethodOrderService {
  constructor(private intervalGQL: ShippingMethodOrderIntervalGQL) {}

  fetchIntervals(filter: ShippingMethodOrderIntervalFilterInput) {
    return this.intervalGQL.fetch({ filter }).pipe(map((r) => r.data.shippingMethodOrderInterval));
  }
}
