import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockAllocationIntervalItemFragment = { __typename: 'StockAllocationIntervalObject', interval: { __typename: 'IntervalObject', start: any, end: any }, orderQuantities: { __typename: 'StockAllocationOrderQuantitiesObject', unallocated: number, reserved: number, allocated: number, packed: number, cancelled: number }, lotQuantities: { __typename: 'StockAllocationLotQuantitiesObject', quantity?: number | null | undefined, reserved: number, allocated: number }, item: { __typename: 'StockItemObject', id: number, sku: string }, warehouse: { __typename: 'WarehouseObject', id: number, name: string, color?: string | null | undefined } };

export type StockAllocationIntervalQueryVariables = Types.Exact<{
  filter: Types.StockAllocationIntervalFilterInput;
}>;


export type StockAllocationIntervalQuery = { __typename: 'Query', stockAllocationIntervals: Array<{ __typename: 'StockAllocationIntervalObject', interval: { __typename: 'IntervalObject', start: any, end: any }, orderQuantities: { __typename: 'StockAllocationOrderQuantitiesObject', unallocated: number, reserved: number, allocated: number, packed: number, cancelled: number }, lotQuantities: { __typename: 'StockAllocationLotQuantitiesObject', quantity?: number | null | undefined, reserved: number, allocated: number }, item: { __typename: 'StockItemObject', id: number, sku: string }, warehouse: { __typename: 'WarehouseObject', id: number, name: string, color?: string | null | undefined } }> };

export const StockAllocationIntervalItemFragmentDoc = gql`
    fragment StockAllocationIntervalItem on StockAllocationIntervalObject {
  interval {
    start
    end
  }
  orderQuantities {
    unallocated
    reserved
    allocated
    packed
    cancelled
  }
  lotQuantities {
    quantity
    reserved
    allocated
  }
  item {
    id
    sku
  }
  warehouse {
    id
    name
    color
  }
}
    `;
export const StockAllocationIntervalDocument = gql`
    query StockAllocationInterval($filter: StockAllocationIntervalFilterInput!) {
  stockAllocationIntervals(filter: $filter) {
    ...StockAllocationIntervalItem
  }
}
    ${StockAllocationIntervalItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockAllocationIntervalGQL extends Apollo.Query<StockAllocationIntervalQuery, StockAllocationIntervalQueryVariables> {
    document = StockAllocationIntervalDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }