import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { MemberFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { IMemberRowObject, MemberService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class MemberRowCollectionProvider
  implements
    IDataCollectionProvider<IMemberRowObject, MemberFilterInput, any, number>,
    IDataProvider<IMemberRowObject>
{
  constructor(private memberService: MemberService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IMemberRowObject>> {
    return this.memberService.fetchRows({
      filter: { ...filter, search: searchText },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IMemberRowObject | Observable<IMemberRowObject> {
    return this.memberService.fetchRow({ id: Number(id) });
  }

  toId(model: IMemberRowObject): number {
    return model.id;
  }

  toString(model: IMemberRowObject): string {
    return `Member ID${model.id}`;
  }
}
