import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IPaymentMethodItemObject,
  IPaymentMethodRowObject,
  PaymentMethodService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentMethodItemCollectionProvider
  implements IDataCollectionProvider<IPaymentMethodItemObject>
{
  constructor(private paymentMethodService: PaymentMethodService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IPaymentMethodItemObject>> {
    return this.paymentMethodService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IPaymentMethodItemObject | Observable<IPaymentMethodItemObject> {
    return this.paymentMethodService.fetchItem(Number(id));
  }

  toId(model: IPaymentMethodItemObject): number {
    return model.id;
  }

  toString(model: IPaymentMethodItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class PaymentMethodRowCollectionProvider
  implements IDataCollectionProvider<IPaymentMethodRowObject>
{
  constructor(private paymentMethodService: PaymentMethodService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IPaymentMethodRowObject>> {
    return this.paymentMethodService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IPaymentMethodRowObject | Observable<IPaymentMethodRowObject> {
    return this.paymentMethodService.fetchRow(Number(id));
  }

  toId(model: IPaymentMethodRowObject): string | number {
    return model.id;
  }

  toString(model: IPaymentMethodRowObject): string {
    return model.name;
  }
}
