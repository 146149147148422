import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { OrderIncidentCategoryFilterInput } from '@x/schemas/ecommerce';
import { Observable, tap } from 'rxjs';
import {
  IOrderIncidentCategoryDetailObject,
  IOrderIncidentCategoryItemObject,
  OrderIncidentCategoryService,
} from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class OrderIncidentCategoryItemCollectionProvider
  implements
    IDataCollectionProvider<
      IOrderIncidentCategoryItemObject,
      OrderIncidentCategoryFilterInput,
      any,
      number
    >,
    IDataProvider<IOrderIncidentCategoryItemObject>
{
  constructor(private readonly service: OrderIncidentCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<OrderIncidentCategoryFilterInput, any>>): Observable<
    IDataCollection<IOrderIncidentCategoryItemObject>
  > {
    return this.service.fetchRows({
      filter: { ...filter, search: searchText },
      page,
      sort,
    });
  }

  fetchSingle(id: number): Observable<IOrderIncidentCategoryItemObject> {
    return this.service.fetchItem(id);
  }

  toId(model: IOrderIncidentCategoryItemObject): number {
    return model.id;
  }

  toString(model: IOrderIncidentCategoryItemObject): string {
    return model.name;
  }
}
@Injectable({ providedIn: 'root' })
export class OrderIncidentCategoryRowCollectionProvider
  implements
    IDataCollectionProvider<
      IOrderIncidentCategoryItemObject,
      OrderIncidentCategoryFilterInput,
      any,
      number
    >
{
  constructor(private service: OrderIncidentCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<
    IDataCollection<IOrderIncidentCategoryItemObject>
  > {
    return this.service.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IOrderIncidentCategoryItemObject): number {
    return model.id;
  }

  toString(model: IOrderIncidentCategoryItemObject): string {
    return `Order Incident ID${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class OrderIncidentCategoryDetailProvider
  implements IDataProvider<IOrderIncidentCategoryDetailObject>
{
  constructor(private readonly service: OrderIncidentCategoryService) {}

  fetchSingle(id: number): Observable<IOrderIncidentCategoryDetailObject> {
    return this.service.fetchDetail(id).pipe(
      tap(() => {
        console.info('fetchSingle', id);
      }),
    );
  }

  toId(model: IOrderIncidentCategoryDetailObject): number {
    return model.id;
  }

  toString(model: IOrderIncidentCategoryDetailObject): string {
    return model.name;
  }
}
