import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  AddProductTaxonInput,
  CloneProductInput,
  CreateProductInput,
  RemoveProductTaxonInput,
  SetProductPositionInput,
  UpdateProductInput,
  UpdateProductKeywordInput,
  UpdateProductRegionsInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AddProductRegionsGQL,
  AddProductTaxonGQL,
  ArchiveProductGQL,
  CloneProductGQL,
  CreateProductGQL,
  PositionProductGQL,
  ProductDetailGQL,
  ProductFormGQL,
  ProductItemGQL,
  ProductItemsGQL,
  ProductItemsQueryVariables,
  ProductRowGQL,
  ProductRowsGQL,
  ProductRowsQueryVariables,
  RemoveProductRegionsGQL,
  RemoveProductTaxonGQL,
  TaxonProductRowsGQL,
  TaxonProductRowsQueryVariables,
  UpdateProductGQL,
  UpdateProductKeywordsGQL,
} from './gql/product.gql.generated';
import {
  IProductFilter,
  IProductItemObject,
  ProductDetailObject,
  ProductRowObject,
  TaxonProductRowObject,
} from './types/product';

@Injectable()
export class ProductService {
  constructor(
    private itemGQL: ProductItemGQL,
    private itemsGQL: ProductItemsGQL,
    private rowGQL: ProductRowGQL,
    private rowsGQL: ProductRowsGQL,
    private detailGQL: ProductDetailGQL,
    private createProductGQL: CreateProductGQL,
    private cloneProductGQL: CloneProductGQL,
    private updateProductGQL: UpdateProductGQL,
    private archiveProductGQL: ArchiveProductGQL,
    private formGQL: ProductFormGQL,
    private addProductTaxonGQL: AddProductTaxonGQL,
    private removeProductTaxonGQL: RemoveProductTaxonGQL,
    private positionProductGQL: PositionProductGQL,
    private taxonRowsGQL: TaxonProductRowsGQL,
    private updateProductKeywordsGQL: UpdateProductKeywordsGQL,
    private addProductRegionsGQL: AddProductRegionsGQL,
    private removeProductRegionsGQL: RemoveProductRegionsGQL,
  ) {}

  fetchItem(id: number) {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.product));
  }

  fetchItems({
    args,
    ...query
  }: IProductFilter): Observable<{ items: IProductItemObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch({ ...query, ...args }).pipe(map((r) => r.data));
  }

  fetchRow(id: number) {
    return this.rowGQL.fetch({ id }).pipe(map((r) => r.data.product));
  }

  fetchRows({
    args,
    ...query
  }: IProductFilter): Observable<{ items: ProductRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }
  fetchRowsT(
    query: ProductRowsQueryVariables,
  ): Observable<{ items: ProductRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRowsForTaxon(
    query: TaxonProductRowsQueryVariables,
  ): Observable<{ items: TaxonProductRowObject[]; totalItemsCount: number }> {
    return this.taxonRowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number, locale?: string | null): Observable<ProductDetailObject> {
    return this.detailGQL.fetch({ id, locale }).pipe(map((r) => r.data.product));
  }

  fetchById(id: string | number): Observable<IProductItemObject> {
    return this.itemGQL.fetch({ id: Number(id) }).pipe(map((r) => r.data.product));
  }

  fetchSearch(
    search: string,
    query?: ProductItemsQueryVariables,
  ): Observable<{ items: IProductItemObject[]; totalItemsCount: number }> {
    return this.itemsGQL
      .fetch({
        filter: { search, ...query?.filter },
        page: query?.page,
        sort: query?.sort,
      })
      .pipe(map((r) => r.data));
  }

  fetchForm(id: number) {
    return this.formGQL.fetch({ id }).pipe(map((r) => r.data.product));
  }

  fetchRowsDepr(
    query: ProductRowsQueryVariables,
  ): Observable<{ items: ProductRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  create(data: CreateProductInput) {
    return this.createProductGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.createProduct));
  }

  clone(data: CloneProductInput) {
    return this.cloneProductGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.cloneProduct));
  }

  update(data: UpdateProductInput) {
    return this.updateProductGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.updateProduct));
  }

  archive(id: number) {
    return this.archiveProductGQL
      .mutate({ id })
      .pipe(mapFetchResultData((data) => data.archiveProduct));
  }

  addTaxon(data: AddProductTaxonInput) {
    return this.addProductTaxonGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.addProductTaxon));
  }

  removeTaxon(data: RemoveProductTaxonInput) {
    return this.removeProductTaxonGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.removeProductTaxon));
  }

  positionProduct(input: SetProductPositionInput) {
    return this.positionProductGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.setProductPosition));
  }

  updateKeywords(data: UpdateProductKeywordInput) {
    return this.updateProductKeywordsGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.updateProductKeywords));
  }

  addRegions(data: UpdateProductRegionsInput) {
    return this.addProductRegionsGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.addProductRegions));
  }

  removeRegions(data: UpdateProductRegionsInput) {
    return this.removeProductRegionsGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.removeProductRegions));
  }
}
