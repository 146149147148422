import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { MediaFolderObject, MediaObject } from '@x/schemas/ecommerce';
import { Observable, map } from 'rxjs';
import { MediaService } from '../../domain-client/services/media.service';

export interface IMediaDataCollection extends IDataCollection<MediaObject> {
  nextToken?: string;
}

@Injectable({ providedIn: 'root' })
export class MediaFolderCollectionProvider
  implements
    IDataCollectionProvider<
      MediaFolderObject,
      {
        path: string;
      },
      any,
      string
    >
{
  constructor(private mediaService: MediaService) {}

  fetchCollection({
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<MediaFolderObject>> {
    return this.mediaService.browseMediaFolder(filter.path).pipe(
      map((items) => ({
        items,
        totalItemsCount: items.length,
      })),
    );
  }

  toId(model: MediaFolderObject): string {
    return model.path;
  }

  toString(model: MediaFolderObject): string {
    return model.name;
  }
}
