import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateShippingMethodInput,
  ShipmentLabelGeneratorObject,
  ShippingCostCalculatorObject,
  ShippingMethodChannelEnabledInput,
  ShippingProviderObject,
  UpdateShippingMethodInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateShippingMethodGQL,
  ShipmentLabelGeneratorsGQL,
  ShippingCostCalculatorsGQL,
  ShippingMethodByIdGQL,
  ShippingMethodDetailGQL,
  ShippingMethodItemGQL,
  ShippingMethodItemsGQL,
  ShippingMethodItemsQueryVariables,
  ShippingMethodRowsGQL,
  ShippingMethodRowsQueryVariables,
  ShippingProvidersGQL,
  UpdateChannelEnabledGQL,
  UpdateShippingMethodGQL,
} from './gql/shipping-method.gql.generated';
import {
  IShippingMethodItemObject,
  ShippingMethodDetailObject,
  ShippingMethodRowObject,
} from './types/shipping-method';

@Injectable()
export class ShippingMethodService {
  constructor(
    private shippingMethodItemGQL: ShippingMethodItemGQL,
    private shippingMethodItemsGQL: ShippingMethodItemsGQL,
    private createGQL: CreateShippingMethodGQL,
    private updateGQL: UpdateShippingMethodGQL,
    private updateChannelEnabledGQL: UpdateChannelEnabledGQL,
    private rowsGQL: ShippingMethodRowsGQL,
    private byIdGQL: ShippingMethodByIdGQL,
    private detailGQL: ShippingMethodDetailGQL,
    private shippingProvidersGQL: ShippingProvidersGQL,
    private shipmentLabelGeneratorsGQL: ShipmentLabelGeneratorsGQL,
    private shippingCostCalculatorsGQL: ShippingCostCalculatorsGQL,
  ) {}

  fetchItem(id: number): Observable<IShippingMethodItemObject> {
    return this.shippingMethodItemGQL.fetch({ id }).pipe(map((r) => r.data.shippingMethod));
  }

  fetchItems(
    query: ShippingMethodItemsQueryVariables,
  ): Observable<{ items: IShippingMethodItemObject[]; totalItemsCount: number }> {
    return this.shippingMethodItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<ShippingMethodDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((result) => result.data.shippingMethod));
  }

  fetchById(id: number) {
    return this.byIdGQL.fetch({ id }).pipe(map((r) => r.data.shippingMethod));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<ShippingMethodRowObject[]> {
    const query: ShippingMethodRowsQueryVariables = {
      filter: { search: searchText },
      page: {},
      sort: { column: 'name' },
    };

    return this.fetchRows(query).pipe(map((r) => r.items));
  }

  fetchRows(
    query: ShippingMethodRowsQueryVariables,
  ): Observable<{ items: ShippingMethodRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchForChannels(enabledChannelIds: number[]): Observable<ShippingMethodRowObject[]> {
    return this.fetchRows({
      filter: {
        enabledChannelIds,
      },
      page: {},
      sort: { column: 'id' },
    }).pipe(map((r) => r.items));
  }

  fetchShippingProviders(): Observable<ShippingProviderObject[]> {
    return this.shippingProvidersGQL.fetch().pipe(mapFetchResultData((r) => r.shippingProviders));
  }

  fetchShipmentLabelGenerators(): Observable<ShipmentLabelGeneratorObject[]> {
    return this.shipmentLabelGeneratorsGQL
      .fetch()
      .pipe(mapFetchResultData((r) => r.shipmentLabelGenerators));
  }

  fetchShippingCostCalculators(): Observable<ShippingCostCalculatorObject[]> {
    return this.shippingCostCalculatorsGQL
      .fetch()
      .pipe(mapFetchResultData((r) => r.shippingCostCalculators));
  }

  create(input: CreateShippingMethodInput) {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShippingMethod));
  }

  update(input: UpdateShippingMethodInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShippingMethod));
  }

  updateMany() {
    //TODO
  }

  updateChannelEnabled(input: ShippingMethodChannelEnabledInput) {
    return this.updateChannelEnabledGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShippingMethodChannelEnabled));
  }
}
