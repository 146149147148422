import { Injectable } from '@angular/core';
import { GridDatasource, GridDatasourceData, GridDatasourceQuery } from '@x/dashboard/grid';
import { IUserRowAddressObject, UserAddressService } from '@x/ecommerce/domain-client';
import { AddressFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

export interface UserAddressQueryArgs {}

const DEFAULT_DISPLAY_COLUMNS = [
  'id',
  'street',
  'city',
  'province',
  'country',
  'postalCode',
  'defaultBillingAddress',
  'defaultShippingAddress',
];

@Injectable()
export class UserAddressIndexDatasource extends GridDatasource<
  IUserRowAddressObject,
  AddressFilterInput,
  UserAddressQueryArgs
> {
  constructor(private userAddressService: UserAddressService) {
    super({
      defaultDisplayColumns: [...DEFAULT_DISPLAY_COLUMNS],
      defaultFilter: {},
      defaultSort: {
        column: 'id',
        order: 'asc',
      },
      pageSizeOptions: [20, 100, 1000],
    });
  }

  fetch({
    filter,
    sort,
    page,
  }: Readonly<GridDatasourceQuery<AddressFilterInput, UserAddressQueryArgs>>): Observable<
    GridDatasourceData<IUserRowAddressObject>
  > {
    return this.userAddressService.fetchRows({ filter, sort, page });
  }
}
