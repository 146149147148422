import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MemberDetailFragment = { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } };

export type MemberRowFragment = { __typename: 'MemberObject', id: number, state: Types.MemberState, createdAt: any, updatedAt: any, startDate?: any | null | undefined, expiryDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } };

export type MemberItemFragment = { __typename: 'MemberObject', id: number, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', firstName: string, lastName: string, email: string } };

export type MemberRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MemberRowQuery = { __typename: 'Query', member: { __typename: 'MemberObject', id: number, state: Types.MemberState, createdAt: any, updatedAt: any, startDate?: any | null | undefined, expiryDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type MemberRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.MemberFilterInput>;
}>;


export type MemberRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'MemberObject', id: number, state: Types.MemberState, createdAt: any, updatedAt: any, startDate?: any | null | undefined, expiryDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } }> };

export type MemberItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MemberItemQuery = { __typename: 'Query', member: { __typename: 'MemberObject', id: number, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', firstName: string, lastName: string, email: string } } };

export type MemberDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MemberDetailQuery = { __typename: 'Query', member: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type MemberSearchQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.MemberFilterInput>;
}>;


export type MemberSearchQuery = { __typename: 'Query', members: Array<{ __typename: 'MemberObject', id: number, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', firstName: string, lastName: string, email: string } }> };

export type MemberUpdateMutationVariables = Types.Exact<{
  data: Types.UpdateMemberInput;
}>;


export type MemberUpdateMutation = { __typename: 'Mutation', updateMember: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type MemberCreateMutationVariables = Types.Exact<{
  data: Types.CreateMemberInput;
}>;


export type MemberCreateMutation = { __typename: 'Mutation', createMember: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type MemberActivateMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MemberActivateMutation = { __typename: 'Mutation', activateMember: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type MemberDeactivateMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MemberDeactivateMutation = { __typename: 'Mutation', deactivateMember: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type ActivateUserMembershipMutationVariables = Types.Exact<{
  input: Types.ActivateUserMembershipInput;
}>;


export type ActivateUserMembershipMutation = { __typename: 'Mutation', activateUserMembership: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } };

export type DeactivateUserMembershipMutationVariables = Types.Exact<{
  input: Types.DeactivateUserMembershipInput;
}>;


export type DeactivateUserMembershipMutation = { __typename: 'Mutation', deactivateUserMembership?: { __typename: 'MemberObject', id: number, state: Types.MemberState, startDate?: any | null | undefined, pointBalance: number, intervalState: Types.MemberIntervalState, isActive: boolean, membership: { __typename: 'MembershipObject', id: number, name: string }, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string }, channel: { __typename: 'ChannelObject', id: number, name: string, code: string } } | null | undefined };

export type ExportMemberReportMutationVariables = Types.Exact<{
  filter: Types.MemberFilterInput;
  format?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ExportMemberReportMutation = { __typename: 'Mutation', exportMemberReport: { __typename: 'PrintResultObject', id: string, url: string } };

export const MemberDetailFragmentDoc = gql`
    fragment MemberDetail on MemberObject {
  id
  state
  startDate
  pointBalance
  intervalState
  isActive
  membership {
    id
    name
  }
  user {
    id
    firstName
    lastName
    email
  }
  channel {
    id
    name
    code
  }
}
    `;
export const MemberRowFragmentDoc = gql`
    fragment MemberRow on MemberObject {
  id
  state
  createdAt
  updatedAt
  startDate
  expiryDate
  pointBalance
  intervalState
  isActive
  membership {
    name
  }
  user {
    id
    firstName
    lastName
    email
  }
  channel {
    id
    name
    code
  }
}
    `;
export const MemberItemFragmentDoc = gql`
    fragment MemberItem on MemberObject {
  id
  membership {
    id
    name
  }
  user {
    firstName
    lastName
    email
  }
}
    `;
export const MemberRowDocument = gql`
    query MemberRow($id: Int!) {
  member(id: $id) {
    ...MemberRow
  }
}
    ${MemberRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberRowGQL extends Apollo.Query<MemberRowQuery, MemberRowQueryVariables> {
    document = MemberRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberRowsDocument = gql`
    query MemberRows($page: PageInput, $sort: SortInput, $filter: MemberFilterInput) {
  items: members(page: $page, sort: $sort, filter: $filter) {
    ...MemberRow
  }
  totalItemsCount: membersCount(filter: $filter)
}
    ${MemberRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberRowsGQL extends Apollo.Query<MemberRowsQuery, MemberRowsQueryVariables> {
    document = MemberRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberItemDocument = gql`
    query MemberItem($id: Int!) {
  member(id: $id) {
    ...MemberItem
  }
}
    ${MemberItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberItemGQL extends Apollo.Query<MemberItemQuery, MemberItemQueryVariables> {
    document = MemberItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberDetailDocument = gql`
    query MemberDetail($id: Int!) {
  member(id: $id) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberDetailGQL extends Apollo.Query<MemberDetailQuery, MemberDetailQueryVariables> {
    document = MemberDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberSearchDocument = gql`
    query MemberSearch($page: PageInput, $sort: SortInput, $filter: MemberFilterInput) {
  members(page: $page, sort: $sort, filter: $filter) {
    ...MemberItem
  }
}
    ${MemberItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberSearchGQL extends Apollo.Query<MemberSearchQuery, MemberSearchQueryVariables> {
    document = MemberSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberUpdateDocument = gql`
    mutation MemberUpdate($data: UpdateMemberInput!) {
  updateMember(data: $data) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberUpdateGQL extends Apollo.Mutation<MemberUpdateMutation, MemberUpdateMutationVariables> {
    document = MemberUpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberCreateDocument = gql`
    mutation MemberCreate($data: CreateMemberInput!) {
  createMember(data: $data) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberCreateGQL extends Apollo.Mutation<MemberCreateMutation, MemberCreateMutationVariables> {
    document = MemberCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberActivateDocument = gql`
    mutation MemberActivate($id: Int!) {
  activateMember(id: $id) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberActivateGQL extends Apollo.Mutation<MemberActivateMutation, MemberActivateMutationVariables> {
    document = MemberActivateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberDeactivateDocument = gql`
    mutation MemberDeactivate($id: Int!) {
  deactivateMember(id: $id) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberDeactivateGQL extends Apollo.Mutation<MemberDeactivateMutation, MemberDeactivateMutationVariables> {
    document = MemberDeactivateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ActivateUserMembershipDocument = gql`
    mutation ActivateUserMembership($input: ActivateUserMembershipInput!) {
  activateUserMembership(input: $input) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivateUserMembershipGQL extends Apollo.Mutation<ActivateUserMembershipMutation, ActivateUserMembershipMutationVariables> {
    document = ActivateUserMembershipDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeactivateUserMembershipDocument = gql`
    mutation DeactivateUserMembership($input: DeactivateUserMembershipInput!) {
  deactivateUserMembership(input: $input) {
    ...MemberDetail
  }
}
    ${MemberDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeactivateUserMembershipGQL extends Apollo.Mutation<DeactivateUserMembershipMutation, DeactivateUserMembershipMutationVariables> {
    document = DeactivateUserMembershipDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportMemberReportDocument = gql`
    mutation ExportMemberReport($filter: MemberFilterInput!, $format: String) {
  exportMemberReport(filter: $filter, format: $format) {
    id
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportMemberReportGQL extends Apollo.Mutation<ExportMemberReportMutation, ExportMemberReportMutationVariables> {
    document = ExportMemberReportDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }