import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { Observable } from 'rxjs';
import {
  WalletTransactionGQL,
  WalletTransactionQueryVariables,
  WalletTransactionsGQL,
  WalletTransactionsQueryVariables,
} from './gql/wallet-transaction.gql.generated';
import { IWalletTransactionObject } from './types/wallet';

@Injectable()
export class WalletTransactionService {
  constructor(
    private walletTransactionsGQL: WalletTransactionsGQL,
    private walletTransactionGQL: WalletTransactionGQL,
  ) {}

  fetchByWallet(
    input: WalletTransactionsQueryVariables,
  ): Observable<{ items: IWalletTransactionObject[]; totalItemsCount: number }> {
    return this.walletTransactionsGQL.fetch(input).pipe(mapFetchResultData((r) => r));
  }

  fetchById(input: WalletTransactionQueryVariables): Observable<IWalletTransactionObject> {
    return this.walletTransactionGQL
      .fetch(input)
      .pipe(mapFetchResultData((r) => r.walletTransaction));
  }
}
