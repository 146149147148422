import { CollectionViewer } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { GridDatasource, GridDatasourceData, GridDatasourceQuery } from '@x/dashboard/grid';
import { TaxonRowObject, TaxonService } from '@x/ecommerce/domain-client';
import { TaxonFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TaxonNode } from '../types/taxon-node';
import { TaxonTreeControl } from '../types/taxon-tree-control';

export interface TaxonQueryArgs {
  locale?: string | null;
}

@Injectable()
export class TaxonIndexDatasource extends GridDatasource<
  TaxonNode,
  TaxonFilterInput,
  TaxonQueryArgs
> {
  treeControl = new TaxonTreeControl();

  constructor(private taxonService: TaxonService) {
    super({
      defaultDisplayColumns: [],
      defaultFilter: {
        search: null,
        archived: false,
        createdAt: null,
        updatedAt: null,
      },
      defaultSort: {
        column: 'position',
        order: 'asc',
      },
      defaultArgs: {
        locale: null,
      },
      defaultPage: {
        index: 0,
        size: 10000,
      },
      pageSizeOptions: [10000],
    });
  }

  connect(collectionViewer: CollectionViewer): Observable<TaxonNode[]> {
    return super.connect(collectionViewer).pipe(tap((data) => (this.treeControl.dataNodes = data)));
  }

  fetch({
    filter,
    sort,
    page,
    args,
  }: Readonly<GridDatasourceQuery<TaxonFilterInput, TaxonQueryArgs>>): Observable<
    GridDatasourceData<TaxonNode>
  > {
    return this.taxonService.fetchRows({ ...args, filter, page, sort }).pipe(
      map(({ items, totalItemsCount }) => ({
        totalItemsCount,
        items: this.buildTreeFromArray(items),
      })),
    );
  }

  archive(id: number) {
    return this.observeMutation(id, (id) => this.taxonService.archive(Number(id)));
  }

  archiveMany(ids: Array<string | number>) {
    return this.observeBulkMutation(ids, (id) => this.taxonService.archive(Number(id)));
  }

  updatePosition(id: number, position: number) {
    return this.observeMutation(id, (i) => {
      return this.taxonService.update(id, { position });
    });
  }

  updateParent(id: number, parentId: number) {
    return this.observeMutation(id, () => {
      return this.taxonService.update(id, { parentId });
    });
  }

  setParentNull(id: number) {
    return this.observeMutation(id, () => {
      return this.taxonService.update(id, { parentId: null });
    });
  }

  findNodeById(id: number) {
    return this.findNodeRecursive(id, this.items);
  }

  private findNodeRecursive(id: number, nodes: TaxonNode[]): TaxonNode | null {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children && node.children.length > 0) {
        const child = this.findNodeRecursive(id, node.children);
        if (child) return child;
      }
    }
    return null;
  }

  private buildTreeFromArray(
    taxons: Array<TaxonRowObject>,
    parentId: number | null = null,
  ): Array<TaxonNode> {
    let branches = taxons.filter((taxon) => {
      return taxon.parentId === parentId;
    });

    return branches
      .map((branch) => {
        let node = new TaxonNode(branch.id, branch);
        let children = this.buildTreeFromArray(taxons, branch.id).map((child) => {
          child.parent = node;
          return child;
        });

        node.children = children;
        return node;
      })
      .sort(this._sortByPositon);
  }

  private _sortByPositon = (a: TaxonNode, b: TaxonNode) => {
    return a.taxon.position - b.taxon.position;
  };
}
