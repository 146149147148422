import { Injectable } from '@angular/core';
import { ContentSearchInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentFetchGQL, ContentSearchGQL } from './gql/content.gql.generated';
import { IContentRefObject } from './types/content-ref';

@Injectable()
export class ContentService {
  constructor(private fetchGQL: ContentFetchGQL, private searchGQL: ContentSearchGQL) {}

  fetchSearch(filter: ContentSearchInput, results = 10): Observable<IContentRefObject[]> {
    return this.searchGQL
      .fetch({
        filter,
        page: {
          index: 0,
          size: results,
        },
      })
      .pipe(map((r) => r.data.contents));
  }

  fetch(ref: string): Observable<IContentRefObject> {
    return this.fetchGQL
      .fetch({
        ref,
      })
      .pipe(map((r) => r.data.content));
  }
}
