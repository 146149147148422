import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[xDataAction]',
})
export class DataActionDefinitionDirective {
  @Input('xDataAction')
  id: string;

  @Input('xDataActionPrimary')
  primary = false;

  @Input('xDataActionWhen')
  when: (row: any) => boolean = () => true;

  constructor(public template: TemplateRef<any>) {}
}
