import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { IStockCategoryObject, StockCategoryService } from '@x/ecommerce/domain-client';
import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class StockCategoryAutocompleteDatasource extends ModelAutocompleteDatasource<IStockCategoryObject> {
  constructor(private service: StockCategoryService) {
    super((i: number, item: IStockCategoryObject) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<IStockCategoryObject[]> {
    return searchText.pipe(
      startWith(''),
      switchMap((text) => {
        if (text === null || (text && text.length === 0)) return of([]);
        return this.service.fetchSearch(text);
      }),
    );
  }

  disconnect(): void {}

  displayFn = (option: IStockCategoryObject) => {
    if (!option) return '';
    return option.name ?? '';
  };
}
