import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { FileUploadSevice } from '@x/dashboard/form';
import {
  CreateBulkOrderInput,
  CreateBulkOrderOrderInput,
  UpdateBulkOrderInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  BulkOrderGQL,
  BulkOrderRowsGQL,
  BulkOrderRowsQueryVariables,
  CreateBulkOrderGQL,
  CreateBulkOrderOrderGQL,
  ProcessBulkOrderGQL,
  UpdateBulkOrderGQL,
} from './gql/bulk-order.gql.generated';
import { IBulkOrderDetailObject, IBulkOrderRowObject } from './types/bulk-order';
import { IOrderItemObject } from './types/order';

@Injectable()
export class BulkOrderService {
  constructor(
    private readonly fileUploadService: FileUploadSevice,
    private bulkOrderGql: BulkOrderGQL,
    private bulkOrderRowsGql: BulkOrderRowsGQL,
    private createBulkOrderGql: CreateBulkOrderGQL,
    private updateBulkOrderGql: UpdateBulkOrderGQL,
    private processBulkOrderGql: ProcessBulkOrderGQL,
    private createBulkOrderOrderGql: CreateBulkOrderOrderGQL,
  ) {}

  fetchById(id: number): Observable<IBulkOrderDetailObject> {
    return this.bulkOrderGql.fetch({ id }).pipe(mapFetchResultData((r) => r.bulkOrder));
  }

  fetchRows(
    query: BulkOrderRowsQueryVariables,
  ): Observable<{ items: IBulkOrderRowObject[]; totalItemsCount: number }> {
    return this.bulkOrderRowsGql.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  create(input: CreateBulkOrderInput): Observable<IBulkOrderDetailObject> {
    return this.createBulkOrderGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createBulkOrder));
  }

  update(input: UpdateBulkOrderInput): Observable<IBulkOrderDetailObject> {
    return this.updateBulkOrderGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.updateBulkOrder));
  }

  process(id: number, orderIds?: number[]): Observable<IBulkOrderDetailObject> {
    return this.processBulkOrderGql
      .mutate({ id, orderIds })
      .pipe(mapFetchResultData((r) => r.processBulkOrder));
  }

  createOrder(input: CreateBulkOrderOrderInput): Observable<IOrderItemObject> {
    return this.createBulkOrderOrderGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createBulkOrderOrder));
  }

  uploadCsv(id: number, file: File) {
    return this.fileUploadService.createUpload<{
      success: boolean;
    }>(`/ecommerce/api/bulk-orders/${id}/upload-csv`, file);
  }

  uploadSpreadsheet(id: number, file: File) {
    return this.fileUploadService.createUpload<{
      success: boolean;
    }>(`/ecommerce/api/bulk-orders/${id}/upload-spreadsheet`, file);
  }
}
