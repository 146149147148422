import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { IShippingCategoryItemObject, ShippingCategoryService } from '@x/ecommerce/domain-client';
import { ShippingCategoryFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShippingCategoryItemCollectionProvider
  implements IDataCollectionProvider<IShippingCategoryItemObject>
{
  constructor(private shippingCategoryService: ShippingCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingCategoryItemObject>> {
    return this.shippingCategoryService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IShippingCategoryItemObject | Observable<IShippingCategoryItemObject> {
    return this.shippingCategoryService.fetchItem(Number(id));
  }

  toId(model: IShippingCategoryItemObject): string | number {
    return model.id;
  }

  toString(model: IShippingCategoryItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ShippingCategoryRowCollectionProvider
  implements
    IDataCollectionProvider<IShippingCategoryItemObject, ShippingCategoryFilterInput, any, number>
{
  constructor(private shippingCategoryService: ShippingCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingCategoryItemObject>> {
    console.log('fetchCollection', {
      searchText,
      page,
      sort,
      filter,
    });
    return this.shippingCategoryService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IShippingCategoryItemObject): number {
    return model.id;
  }

  toString(model: IShippingCategoryItemObject): string {
    return `${model.id}`;
  }
}
