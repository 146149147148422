import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  PageInput,
  ProductWishlistItemFilterInput,
  ProductWishlistItemInput,
  SortInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  AddProductWishlistItemGQL,
  ProductWishlistItemRowsGQL,
  RemoveProductWishlistItemGQL,
} from './gql/product-wishlist-item.gql.generated';
import { ProductWishlistItemRowObject } from './types/product-wishlist-items';

@Injectable()
export class ProductWishlistItemService {
  constructor(
    private readonly rowsGQL: ProductWishlistItemRowsGQL,
    private readonly addItemGQL: AddProductWishlistItemGQL,
    private readonly removeItemGQL: RemoveProductWishlistItemGQL,
  ) {}

  fetchRows({
    filter,
    page,
    sort,
  }: {
    filter?: ProductWishlistItemFilterInput;
    page?: PageInput | null;
    sort?: SortInput | null;
  }): Observable<{
    items: ProductWishlistItemRowObject[];
    totalItemsCount: number;
  }> {
    return this.rowsGQL
      .fetch({
        filter,
        page,
        sort,
      })
      .pipe(mapFetchResultData((d) => d));
  }

  addItem(input: ProductWishlistItemInput) {
    return this.addItemGQL
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.addProductWishlistItem));
  }

  removeItem(input: ProductWishlistItemInput) {
    return this.removeItemGQL
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.removeProductWishlistItem));
  }
}
