import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { IChannelDetailObject } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChannelContext } from '../services/channel-context.service';

@Injectable()
export class ChannelAutocompleteDataSource extends ModelAutocompleteDatasource<IChannelDetailObject> {
  channels$ = this.channelService.getAllChannels();

  constructor(private channelService: ChannelContext) {
    super((i: number, item: IChannelDetailObject) => item.code);
  }

  connect(searchText: Observable<string | null>): Observable<IChannelDetailObject[]> {
    return searchText.pipe(
      switchMap((text) =>
        this.channels$.pipe(
          map((channels) =>
            text
              ? channels.filter(
                  (c) =>
                    c.code.toLowerCase().includes(text.toLowerCase()) ||
                    c.name.toLowerCase().includes(text.toLowerCase()),
                )
              : channels,
          ),
        ),
      ),
    );
  }

  disconnect(): void {}

  displayFn = (channel: IChannelDetailObject) => {
    if (typeof channel !== 'object' || channel === null) return '';

    return `${channel.name} (${channel.code})`;
  };
}
