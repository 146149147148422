import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { IMembershipItemObject, MembershipService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MembershipIdTransformer implements ModelTransformer<IMembershipItemObject, number> {
  private _cache = new Map<number, IMembershipItemObject>();

  constructor(private membershipService: MembershipService) {}

  modelToValue(model: IMembershipItemObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<IMembershipItemObject> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as IMembershipItemObject);
    }

    return firstValueFrom(
      this.membershipService.fetchItem(id).pipe(tap((r) => this._cache.set(id, r))),
    );
  }
}
