import { Injectable } from '@angular/core';
import { IExpandedAddress } from '@x/geocode/client';
import { AddressInput, AddressType } from '@x/schemas/ecommerce';
import { map } from 'rxjs/operators';
import {
  AddressItemGQL,
  AddressItemsGQL,
  AddressItemsQueryVariables,
} from './gql/address.gql.generated';
import { IAddressInput, IAddressObject } from './types/address';

@Injectable()
export class AddressService {
  constructor(
    private readonly AddressItemsGQL: AddressItemsGQL,
    private readonly AddressItemGQL: AddressItemGQL,
  ) {}

  addressToIAddressInput(address: IAddressObject): IAddressInput {
    return {
      alias: address.alias ?? undefined,
      businessName: address.businessName ?? undefined,
      city: address.city ?? undefined,
      complex: address.complex ?? undefined,
      coordinates: {
        lat: address.coordinates?.lat || 0,
        lng: address.coordinates?.lng || 0,
      },
      country: address.country ?? '',
      email: address.email ?? undefined,
      firstName: address.firstName ?? undefined,
      instructions: address.instructions ?? undefined,
      lastName: address.lastName ?? undefined,
      phoneNumber: address.phoneNumber ?? undefined,
      postalCode: address.postalCode ?? undefined,
      province: address.province ?? undefined,
      street: address.street ?? undefined,
      suburb: address.suburb ?? undefined,
      type: address.type,
    };
  }

  addressToAddressInput(address: IAddressObject): AddressInput {
    return {
      alias: address.alias,
      businessName: address.businessName,
      city: address.city,
      complex: address.complex,
      coordinates: {
        lat: address.coordinates?.lat || 0,
        lng: address.coordinates?.lng || 0,
      },
      country: address.country ?? '',
      email: address.email,
      firstName: address.firstName,
      instructions: address.instructions,
      lastName: address.lastName,
      phoneNumber: address.phoneNumber,
      postalCode: address.postalCode,
      province: address.province,
      street: address.street,
      suburb: address.suburb,
      type: address.type,
    };
  }

  geoAddressToAddressInput(address: IExpandedAddress): AddressInput {
    const {
      provinceCode: province,
      countryCode: country,
      postcode: postalCode,
      lat,
      lng,
      streetNumber,
      streetName,
      suburb,
      city,
    } = address;

    const street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
    const coordinates = lat && lng ? { lat, lng } : null;

    return {
      city,
      coordinates,
      country: country ?? '',
      postalCode,
      province,
      street,
      suburb,
      type: AddressType.Physical,
    };
  }

  fetchItems(query: AddressItemsQueryVariables) {
    return this.AddressItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number) {
    return this.AddressItemGQL.fetch({ id }).pipe(map((r) => r.data.address));
  }
}
