import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ContentSearchRowFragment = { __typename: 'ContentRefObject', ref: string, locale: string, name: string, provider?: string | null | undefined };

export type ContentSearchQueryVariables = Types.Exact<{
  filter: Types.ContentSearchInput;
  page: Types.PageInput;
}>;


export type ContentSearchQuery = { __typename: 'Query', contents: Array<{ __typename: 'ContentRefObject', ref: string, locale: string, name: string, provider?: string | null | undefined }> };

export type ContentFetchQueryVariables = Types.Exact<{
  ref: Types.Scalars['String']['input'];
}>;


export type ContentFetchQuery = { __typename: 'Query', content: { __typename: 'ContentRefObject', ref: string, locale: string, name: string, provider?: string | null | undefined } };

export const ContentSearchRowFragmentDoc = gql`
    fragment ContentSearchRow on ContentRefObject {
  ref
  locale
  name
  provider
}
    `;
export const ContentSearchDocument = gql`
    query ContentSearch($filter: ContentSearchInput!, $page: PageInput!) {
  contents(filter: $filter, page: $page) {
    ...ContentSearchRow
  }
}
    ${ContentSearchRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentSearchGQL extends Apollo.Query<ContentSearchQuery, ContentSearchQueryVariables> {
    document = ContentSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContentFetchDocument = gql`
    query ContentFetch($ref: String!) {
  content(ref: $ref) {
    ...ContentSearchRow
  }
}
    ${ContentSearchRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentFetchGQL extends Apollo.Query<ContentFetchQuery, ContentFetchQueryVariables> {
    document = ContentFetchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }