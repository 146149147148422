import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateOrderIncidentInput, UpdateOrderIncidentInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  ApproveOrderIncidentGQL,
  CreateIncidentReplacementOrderGQL,
  CreateOrderIncidentGQL,
  DenyOrderIncidentGQL,
  OrderIncidentDetailGQL,
  OrderIncidentItemGQL,
  OrderIncidentItemsGQL,
  OrderIncidentRowGQL,
  OrderIncidentRowsGQL,
  OrderIncidentRowsQueryVariables,
  PublishOrderIncidentGQL,
  RemoveIncidentReplacementOrderGQL,
  UnpublishOrderIncidentGQL,
  UpdateOrderIncidentGQL,
} from './gql/order-incident.gql.generated';
import { IOrderIncidentDetailObject, IOrderIncidentItemObject } from './types/order-incident';

@Injectable()
export class OrderIncidentService {
  constructor(
    private readonly detailGQL: OrderIncidentDetailGQL,
    private readonly itemGQL: OrderIncidentItemGQL,
    private readonly itemsGQL: OrderIncidentItemsGQL,
    private readonly rowGQL: OrderIncidentRowGQL,
    private readonly rowsGQL: OrderIncidentRowsGQL,
    private readonly createGQL: CreateOrderIncidentGQL,
    private readonly updateGQL: UpdateOrderIncidentGQL,
    private readonly createReplacementOrderGQL: CreateIncidentReplacementOrderGQL,
    private readonly removeReplacementOrderGQL: RemoveIncidentReplacementOrderGQL,
    private readonly publishGQL: PublishOrderIncidentGQL,
    private readonly unpublishGQL: UnpublishOrderIncidentGQL,
    private readonly denyGQL: DenyOrderIncidentGQL,
    private readonly approveGQL: ApproveOrderIncidentGQL,
  ) {}

  fetchDetail(incidentId: Readonly<number>): Observable<IOrderIncidentDetailObject> {
    return this.detailGQL
      .fetch({ incidentId })
      .pipe(mapFetchResultData((result) => result.orderIncident));
  }

  fetchItem(id: Readonly<number>): Observable<IOrderIncidentItemObject> {
    return this.itemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.orderIncident));
  }

  fetchItems(query: OrderIncidentRowsQueryVariables): Observable<IOrderIncidentItemObject[]> {
    return this.itemsGQL.fetch(query).pipe(mapFetchResultData((r) => r.orderIncidents));
  }

  fetchRow(id: Readonly<number>): Observable<IOrderIncidentItemObject> {
    return this.rowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.orderIncident));
  }

  fetchRows(
    query: Readonly<OrderIncidentRowsQueryVariables>,
  ): Observable<{ items: IOrderIncidentItemObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  create(input: Readonly<CreateOrderIncidentInput>): Observable<IOrderIncidentItemObject> {
    return this.createGQL
      .mutate({
        input,
      })
      .pipe(mapFetchResultData((r) => r.createOrderIncident));
  }

  update(input: Readonly<UpdateOrderIncidentInput>): Observable<IOrderIncidentItemObject> {
    return this.updateGQL
      .mutate({
        input,
      })
      .pipe(mapFetchResultData((r) => r.updateOrderIncident));
  }

  createReplacementOrder(incidentId: Readonly<number>): Observable<IOrderIncidentItemObject> {
    return this.createReplacementOrderGQL
      .mutate({
        incidentId,
      })
      .pipe(mapFetchResultData((r) => r.createIncidentReplacementOrder));
  }

  removeReplacementOrder(incidentId: Readonly<number>): Observable<IOrderIncidentItemObject> {
    return this.removeReplacementOrderGQL
      .mutate({
        incidentId,
      })
      .pipe(mapFetchResultData((r) => r.removeIncidentReplacementOrder));
  }

  publish(incidentId: Readonly<number>) {
    return this.publishGQL
      .mutate({ incidentId })
      .pipe(mapFetchResultData((r) => r.publishOrderIncident));
  }

  unpublish(incidentId: Readonly<number>) {
    return this.unpublishGQL
      .mutate({ incidentId })
      .pipe(mapFetchResultData((r) => r.unpublishOrderIncident));
  }

  deny(incidentId: Readonly<number>) {
    return this.denyGQL.mutate({ incidentId }).pipe(mapFetchResultData((r) => r.denyOrderIncident));
  }

  approve(incidentId: Readonly<number>) {
    return this.approveGQL
      .mutate({ incidentId })
      .pipe(mapFetchResultData((r) => r.approveOrderIncident));
  }
}
