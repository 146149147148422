import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateManualPaymentInput,
  RedeemCartPaymentInput,
  RefundCartPaymentInput,
} from '@x/schemas/ecommerce';
import { QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CancelPaymentGQL,
  CheckCartPaymentBalanceGQL,
  CreateManualPaymentGQL,
  OrderPaymentsGQL,
  PaymentByIdGQL,
  PaymentByIdQuery,
  PaymentByIdQueryVariables,
  PaymentErrorDescriptionsGQL,
  PaymentErrorDescriptionsQueryVariables,
  ReconcilePaymentGQL,
  RedeemCartPaymentGQL,
  RefundPaymentGQL,
  RequestNewPaymentGQL,
  RequestPaymentGQL,
} from './gql/payment.gql.generated';
import { IPaymentDetailObject, IVoucherBalanceObject } from './types/payment';

@Injectable()
export class PaymentService {
  constructor(
    private orderPaymentsGQL: OrderPaymentsGQL,
    private requestCartPaymentGQL: RequestPaymentGQL,
    private requestNewCartPaymentGQL: RequestNewPaymentGQL,
    private reconCartPaymentGQL: ReconcilePaymentGQL,
    private cancelPaymentGQL: CancelPaymentGQL,
    private refundPaymentGQL: RefundPaymentGQL,
    private paymentByIdGQL: PaymentByIdGQL,
    private createManualPaymentGql: CreateManualPaymentGQL,
    private checkCartPaymentBalance: CheckCartPaymentBalanceGQL,
    private redeemCartPayment: RedeemCartPaymentGQL,
    private paymentErrorDescriptionsGQL: PaymentErrorDescriptionsGQL,
  ) {}

  fetchByOrderId(id: number): Observable<IPaymentDetailObject[]> {
    return this.orderPaymentsGQL.fetch({ id }).pipe(map((r) => r.data.order.payments));
  }

  fetchById(id: number): Observable<IPaymentDetailObject> {
    return this.paymentByIdGQL.fetch({ id }).pipe(map((r) => r.data.payment));
  }

  watchById(id: number): QueryRef<PaymentByIdQuery, PaymentByIdQueryVariables> {
    return this.paymentByIdGQL.watch({ id });
  }

  checkVoucherPaymentBalance(input: RedeemCartPaymentInput): Observable<IVoucherBalanceObject> {
    return this.checkCartPaymentBalance
      .fetch({ input })
      .pipe(map((r) => r.data.checkCartPaymentBalance));
  }

  requestPayment(orderId: number, userPaymentMethodId: number): Observable<IPaymentDetailObject> {
    return this.requestCartPaymentGQL
      .mutate({
        input: {
          orderId,
          userPaymentMethodId,
        },
      })
      .pipe(mapFetchResultData((data) => data.requestCartPayment));
  }

  requestNewPayment(
    orderId: number,
    paymentMethodId: number,
    save: boolean,
  ): Observable<IPaymentDetailObject> {
    return this.requestNewCartPaymentGQL
      .mutate({
        input: {
          orderId,
          paymentMethodId,
          save,
        },
      })
      .pipe(mapFetchResultData((data) => data.requestNewCartPayment));
  }

  recon(paymentId: number): Observable<IPaymentDetailObject> {
    return this.reconCartPaymentGQL
      .fetch({
        input: {
          paymentId,
        },
      })
      .pipe(map((result) => result.data.reconCartPayment));
  }

  cancel(paymentId: number) {
    return this.cancelPaymentGQL
      .mutate({
        input: {
          paymentId,
        },
      })
      .pipe(mapFetchResultData((data) => data.cancelCartPayment));
  }

  refund(input: RefundCartPaymentInput) {
    return this.refundPaymentGQL
      .mutate({
        input,
      })
      .pipe(mapFetchResultData((data) => data.refundCartPayment));
  }

  createManualPayment(input: CreateManualPaymentInput) {
    return this.createManualPaymentGql
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.createManualPayment));
  }

  redeemVoucherPayment(input: RedeemCartPaymentInput): Observable<IPaymentDetailObject> {
    return this.redeemCartPayment
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.redeemCartPayment));
  }

  getPaymentErrorDescriptions(query: PaymentErrorDescriptionsQueryVariables) {
    return this.paymentErrorDescriptionsGQL
      .fetch(query)
      .pipe(map((r) => r.data.paymentErrorDescriptions));
  }
}
