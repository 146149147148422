import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { GeocodeService, IAutocompleteAddressSuggestion } from '@x/geocode/client';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class GeocodeAutocompleteDatasource extends ModelAutocompleteDatasource<IAutocompleteAddressSuggestion> {
  constructor(private geocodeService: GeocodeService) {
    super((i: number, item: IAutocompleteAddressSuggestion) => item);
  }

  connect(
    searchText: Observable<string>,
    countryCode: Observable<string>,
  ): Observable<IAutocompleteAddressSuggestion[]> {
    return combineLatest([searchText, countryCode]).pipe(
      switchMap((args) => {
        return this.geocodeService
          .autocompleteAddress(args[0], args[1])
          .pipe(map((result) => result.suggestions)); //TODO @Dup
      }),
    );
  }

  disconnect(): void {}

  displayFn = (item: IAutocompleteAddressSuggestion | null | undefined) => {
    return item ? item : '';
  };
}
