import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
export type ShipmentParcelDetailFragment = { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined };

export type ShipmentParcelItemFragment = { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined };

export const ShipmentParcelDetailFragmentDoc = gql`
    fragment ShipmentParcelDetail on ShipmentParcelObject {
  id
  name
  reference
  dimensions {
    length
    height
    width
  }
  weight
}
    `;
export const ShipmentParcelItemFragmentDoc = gql`
    fragment ShipmentParcelItem on ShipmentParcelObject {
  id
  name
  reference
  dimensions {
    length
    height
    width
  }
  weight
}
    `;