import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { AdFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { AdService } from '../../domain-client/services/ad.service';
import {
  IAdDetailObject,
  IAdItemObject,
  IAdRowObject,
} from '../../domain-client/services/types/ad';

@Injectable({ providedIn: 'root' })
export class AdRowCollectionProvider
  implements
    IDataCollectionProvider<IAdRowObject, AdFilterInput, any, number>,
    IDataProvider<IAdRowObject, number>
{
  constructor(private adService: AdService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAdRowObject>> {
    return this.adService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): Observable<IAdRowObject> {
    return this.adService.fetchRow(id);
  }

  toId(model: IAdRowObject): number {
    return model.id;
  }

  toString(model: IAdRowObject): string {
    return `${model.name ? '#' : 'ID'}${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class AdItemCollectionProvider
  implements
    IDataCollectionProvider<IAdItemObject, AdFilterInput, any, number>,
    IDataProvider<IAdItemObject, number>
{
  constructor(private adService: AdService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAdItemObject>> {
    return this.adService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): Observable<IAdItemObject> {
    return this.adService.fetchItem(id);
  }

  toId(model: IAdItemObject): number {
    return model.id;
  }

  toString(model: IAdItemObject): string {
    return `${model.name ? '#' : 'ID'}${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class AdDetailProvider implements IDataProvider<IAdDetailObject, number> {
  constructor(private adService: AdService) {}

  fetchSingle(id: number): IAdDetailObject | Observable<IAdDetailObject> {
    return this.adService.fetchDetail(id);
  }

  toId(model: IAdDetailObject): number {
    return model.id;
  }

  toString(model: IAdDetailObject): string {
    return `${model.id}`;
  }
}
