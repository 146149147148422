import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IPromotionItemObject,
  PromotionRowObject,
  PromotionService,
} from '@x/ecommerce/domain-client';
import { PromotionFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PromotionItemCollectionProvider
  implements IDataCollectionProvider<IPromotionItemObject, PromotionFilterInput>
{
  constructor(private promotionService: PromotionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IPromotionItemObject>> {
    return this.promotionService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IPromotionItemObject | Observable<IPromotionItemObject> {
    return this.promotionService.fetchItem(Number(id));
  }

  toId(model: IPromotionItemObject): string | number {
    return model.id;
  }

  toString(model: IPromotionItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class PromotionRowCollectionProvider
  implements IDataCollectionProvider<PromotionRowObject, PromotionFilterInput>
{
  constructor(private promotionService: PromotionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<PromotionRowObject>> {
    return this.promotionService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): PromotionRowObject | Observable<PromotionRowObject> {
    return this.promotionService.fetchRow(Number(id));
  }

  toId(model: PromotionRowObject): string | number {
    return model.id;
  }

  toString(model: PromotionRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class CouponCodeCollectionProvider
  implements IDataCollectionProvider<string, PromotionFilterInput>
{
  defaultFilter = { isCoupon: true };

  constructor(private promotionService: PromotionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<string>> {
    return this.promotionService
      .fetchItems({
        filter: { search: searchText, ...filter },
        page,
        sort,
      })
      .pipe(
        map((p) => ({
          items: p.items.map((i) => i.couponCode).filter((c): c is string => !!c),
          totalItemsCount: p.totalItemsCount,
        })),
      );
  }

  fetchSingle(id: string | number): string | Observable<string> {
    return String(id);
  }

  toId(model: string): string | number {
    return model;
  }

  toString(model: string): string {
    return model;
  }
}
