import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  IUserDetailObject,
  IUserItemObject,
  IUserRowObject,
  UserService,
} from '@x/ecommerce/domain-client';
import { UserFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserItemCollectionProvider
  implements IDataCollectionProvider<IUserItemObject, UserFilterInput, any, number>
{
  constructor(private userService: UserService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IUserItemObject>> {
    return this.userService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): Observable<IUserItemObject> {
    return this.userService.fetchItem(id);
  }

  toId(model: IUserItemObject): number {
    return model.id;
  }

  toString(model: IUserItemObject): string {
    return `${model.firstName} ${model.lastName} <${model.email}>`;
  }
}

@Injectable({ providedIn: 'root' })
export class UserRowCollectionProvider
  implements IDataCollectionProvider<IUserRowObject, UserFilterInput, any, number>
{
  constructor(private userService: UserService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IUserRowObject>> {
    return this.userService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IUserRowObject): number {
    return model.id;
  }

  toString(model: IUserRowObject): string {
    return `${model.firstName} ${model.lastName}, <${model.email}>`;
  }
}

@Injectable({ providedIn: 'root' })
export class UserDetailProvider implements IDataProvider<IUserDetailObject, number> {
  constructor(private userService: UserService) {}

  fetchSingle(id: number): IUserDetailObject | Observable<IUserDetailObject> {
    return this.userService.fetchDetail(id);
  }

  toId(model: IUserDetailObject): number {
    return model.id;
  }

  toString(model: IUserDetailObject): string {
    return `${model.firstName} ${model.lastName}, <${model.email}>`;
  }
}
