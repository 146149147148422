import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateStockCategoryInput, UpdateStockCategoryInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateGQL,
  StockCategoriesGQL,
  StockCategoryDetailGQL,
  StockCategoryItemGQL,
  StockCategoryItemsGQL,
  StockCategoryItemsQueryVariables,
  StockCategoryRowGQL,
  StockCategoryRowsGQL,
  StockCategoryRowsQueryVariables,
  StockCategorySearchGQL,
  UpdateGQL,
} from './gql/stock-category.gql.generated';
import {
  IStockCategoryItemObject,
  IStockCategoryObject,
  IStockCategoryRowObject,
} from './types/stock-category';

@Injectable()
export class StockCategoryService {
  constructor(
    private rowGQL: StockCategoryRowGQL,
    private rowsGQL: StockCategoryRowsGQL,
    private stockCategoryDetailGQL: StockCategoryDetailGQL,
    private stockCategoriesGQL: StockCategoriesGQL,
    private stockCategorySearchGQL: StockCategorySearchGQL,
    private stockCategoryItemGQL: StockCategoryItemGQL,
    private stockCategoryItemsGQL: StockCategoryItemsGQL,
    private updateGQL: UpdateGQL,
    private createGQL: CreateGQL,
  ) {}

  fetchItem(id: number): Observable<IStockCategoryItemObject> {
    return this.stockCategoryItemGQL.fetch({ id }).pipe(map((r) => r.data.stockCategory));
  }

  fetchItems(
    query: StockCategoryItemsQueryVariables,
  ): Observable<{ items: IStockCategoryItemObject[]; totalItemsCount: number }> {
    return this.stockCategoryItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number): Observable<IStockCategoryRowObject> {
    return this.rowGQL.fetch({ id }).pipe(map((r) => r.data.stockCategory));
  }

  fetchRows(
    query: StockCategoryRowsQueryVariables,
  ): Observable<{ items: IStockCategoryRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchAll(): Observable<IStockCategoryObject[]> {
    return this.stockCategoriesGQL.fetch().pipe(mapFetchResultData((r) => r.stockCategories));
  }

  fetchSearch(searchText: string) {
    return this.stockCategorySearchGQL
      .fetch({ searchText })
      .pipe(mapFetchResultData((r) => r.stockCategories));
  }

  fetchById(id: number): Observable<IStockCategoryObject> {
    return this.stockCategoryDetailGQL
      .fetch({ id })
      .pipe(mapFetchResultData((r) => r.stockCategory));
  }

  update(input: UpdateStockCategoryInput) {
    return this.updateGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.updateStockCategory));
  }

  create(input: CreateStockCategoryInput) {
    return this.createGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.createStockCategory));
  }
}
