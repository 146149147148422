import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { PaymentMethodItemFragmentDoc } from './payment-method.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserPaymentMethodItemFragment = { __typename: 'UserPaymentMethodObject', id: number, description: string, errorDescription?: string | null | undefined, createdAt: any, updatedAt: any, expiresAt?: any | null | undefined, activatedAt?: any | null | undefined, archivedAt?: any | null | undefined, expired: boolean, reusable: boolean, state: Types.UserPaymentMethodState, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string } };

export type UserPaymentMethodRowFragment = { __typename: 'UserPaymentMethodObject', id: number, description: string, errorDescription?: string | null | undefined, createdAt: any, updatedAt: any, expiresAt?: any | null | undefined, activatedAt?: any | null | undefined, archivedAt?: any | null | undefined, expired: boolean, reusable: boolean, state: Types.UserPaymentMethodState, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string }, subscribers?: Array<{ __typename: 'SubscriberObject', id: number, variant: { __typename: 'SubscriptionVariantObject', name: string } }> | null | undefined };

export type UserPaymentMethodItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type UserPaymentMethodItemQuery = { __typename: 'Query', userPaymentMethod: { __typename: 'UserPaymentMethodObject', id: number, description: string, errorDescription?: string | null | undefined, createdAt: any, updatedAt: any, expiresAt?: any | null | undefined, activatedAt?: any | null | undefined, archivedAt?: any | null | undefined, expired: boolean, reusable: boolean, state: Types.UserPaymentMethodState, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string } } };

export type UserPaymentMethodItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UserPaymentMethodFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type UserPaymentMethodItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'UserPaymentMethodObject', id: number, description: string, errorDescription?: string | null | undefined, createdAt: any, updatedAt: any, expiresAt?: any | null | undefined, activatedAt?: any | null | undefined, archivedAt?: any | null | undefined, expired: boolean, reusable: boolean, state: Types.UserPaymentMethodState, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string } }> };

export type UserPaymentMethodRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UserPaymentMethodFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type UserPaymentMethodRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'UserPaymentMethodObject', id: number, description: string, errorDescription?: string | null | undefined, createdAt: any, updatedAt: any, expiresAt?: any | null | undefined, activatedAt?: any | null | undefined, archivedAt?: any | null | undefined, expired: boolean, reusable: boolean, state: Types.UserPaymentMethodState, method: { __typename: 'PaymentMethodObject', id: number, name: string, code: string }, subscribers?: Array<{ __typename: 'SubscriberObject', id: number, variant: { __typename: 'SubscriptionVariantObject', name: string } }> | null | undefined }> };

export const UserPaymentMethodItemFragmentDoc = gql`
    fragment UserPaymentMethodItem on UserPaymentMethodObject {
  id
  description
  errorDescription
  method {
    ...PaymentMethodItem
  }
  createdAt
  updatedAt
  expiresAt
  activatedAt
  archivedAt
  expired
  reusable
  state
}
    ${PaymentMethodItemFragmentDoc}`;
export const UserPaymentMethodRowFragmentDoc = gql`
    fragment UserPaymentMethodRow on UserPaymentMethodObject {
  id
  description
  errorDescription
  method {
    ...PaymentMethodItem
  }
  createdAt
  updatedAt
  expiresAt
  activatedAt
  archivedAt
  expired
  reusable
  state
  subscribers {
    id
    variant {
      name
    }
  }
}
    ${PaymentMethodItemFragmentDoc}`;
export const UserPaymentMethodItemDocument = gql`
    query UserPaymentMethodItem($id: Int!) {
  userPaymentMethod(id: $id) {
    ...UserPaymentMethodItem
  }
}
    ${UserPaymentMethodItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPaymentMethodItemGQL extends Apollo.Query<UserPaymentMethodItemQuery, UserPaymentMethodItemQueryVariables> {
    document = UserPaymentMethodItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPaymentMethodItemsDocument = gql`
    query UserPaymentMethodItems($filter: UserPaymentMethodFilterInput, $page: PageInput, $sort: SortInput) {
  items: userPaymentMethods(page: $page, filter: $filter, sort: $sort) {
    ...UserPaymentMethodItem
  }
  totalItemsCount: userPaymentMethodsCount(filter: $filter)
}
    ${UserPaymentMethodItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPaymentMethodItemsGQL extends Apollo.Query<UserPaymentMethodItemsQuery, UserPaymentMethodItemsQueryVariables> {
    document = UserPaymentMethodItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPaymentMethodRowsDocument = gql`
    query UserPaymentMethodRows($filter: UserPaymentMethodFilterInput, $page: PageInput, $sort: SortInput) {
  items: userPaymentMethods(page: $page, filter: $filter, sort: $sort) {
    ...UserPaymentMethodRow
  }
  totalItemsCount: userPaymentMethodsCount(filter: $filter)
}
    ${UserPaymentMethodRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPaymentMethodRowsGQL extends Apollo.Query<UserPaymentMethodRowsQuery, UserPaymentMethodRowsQueryVariables> {
    document = UserPaymentMethodRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }