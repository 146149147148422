import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  AdFilterInput,
  CloneAdInput,
  CreateAdInput,
  PageInput,
  SortInput,
  UpdateAdInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  AdDetailGQL,
  AdItemGQL,
  AdItemsGQL,
  AdRowGQL,
  AdRowsGQL,
  CloneAdGQL,
  CreateAdGQL,
  UpdateAdGQL,
} from './gql/ad.gql.generated';
import { IAdDetailObject, IAdItemObject, IAdRowObject } from './types/ad';

@Injectable()
export class AdService {
  constructor(
    private readonly detailGQL: AdDetailGQL,
    private readonly rowGQL: AdRowGQL,
    private readonly rowsGQL: AdRowsGQL,
    private readonly itemGQL: AdItemGQL,
    private readonly itemsGQL: AdItemsGQL,
    private readonly createGQL: CreateAdGQL,
    private readonly updateGQL: UpdateAdGQL,
    private readonly cloneGQL: CloneAdGQL,
  ) {}

  fetchDetail(id: number): Observable<IAdDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.ad));
  }

  fetchRow(id: number): Observable<IAdRowObject> {
    return this.rowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.ad));
  }

  fetchRows({
    filter,
    page,
    sort,
  }: {
    filter: AdFilterInput;
    sort?: SortInput | null;
    page?: PageInput | null;
  }): Observable<{
    items: IAdRowObject[];
    totalItemsCount: number;
  }> {
    return this.rowsGQL.fetch({ filter, sort, page }).pipe(
      mapFetchResultData((r) => ({
        items: r.items,
        totalItemsCount: r.totalItemsCount,
      })),
    );
  }

  fetchItem(id: number): Observable<IAdItemObject> {
    return this.itemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.ad));
  }

  fetchItems({
    filter,
    page,
    sort,
  }: {
    filter: AdFilterInput;
    sort?: SortInput | null;
    page?: PageInput | null;
  }): Observable<{
    items: IAdItemObject[];
    totalItemsCount: number;
  }> {
    return this.itemsGQL.fetch({ filter, sort, page }).pipe(
      mapFetchResultData((r) => ({
        items: r.items,
        totalItemsCount: r.totalItemsCount,
      })),
    );
  }

  create(input: CreateAdInput) {
    return this.createGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.createAd));
  }

  update(input: UpdateAdInput) {
    return this.updateGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.updateAd));
  }

  clone(input: CloneAdInput) {
    return this.cloneGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.cloneAd));
  }
}
