import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GeoRegionFragment = { __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, primaryChannel: { __typename: 'ChannelObject', id: number, code: string }, parent?: { __typename: 'GeoRegionObject', id: number } | null | undefined };

export type GeoRegionItemFragment = { __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined };

export type GeoRegionsQueryVariables = Types.Exact<{
  filter: Types.GeoRegionFilterInput;
}>;


export type GeoRegionsQuery = { __typename: 'Query', geoRegions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, primaryChannel: { __typename: 'ChannelObject', id: number, code: string }, parent?: { __typename: 'GeoRegionObject', id: number } | null | undefined }> };

export type GeoRegionItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.GeoRegionFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type GeoRegionItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined }> };

export type GeoRegionQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GeoRegionQuery = { __typename: 'Query', geoRegion: { __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, primaryChannel: { __typename: 'ChannelObject', id: number, code: string }, parent?: { __typename: 'GeoRegionObject', id: number } | null | undefined } };

export type GeoRegionItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GeoRegionItemQuery = { __typename: 'Query', geoRegion: { __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined } };

export type CreateGeoRegionMutationVariables = Types.Exact<{
  data: Types.CreateGeoRegionInput;
}>;


export type CreateGeoRegionMutation = { __typename: 'Mutation', createGeoRegion: { __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, primaryChannel: { __typename: 'ChannelObject', id: number, code: string }, parent?: { __typename: 'GeoRegionObject', id: number } | null | undefined } };

export type UpdateGeoRegionMutationVariables = Types.Exact<{
  data: Types.UpdateGeoRegionInput;
}>;


export type UpdateGeoRegionMutation = { __typename: 'Mutation', updateGeoRegion: { __typename: 'GeoRegionObject', id: number, name: string, code: string, label?: string | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, primaryChannel: { __typename: 'ChannelObject', id: number, code: string }, parent?: { __typename: 'GeoRegionObject', id: number } | null | undefined } };

export type GeoRegionDefinitionFragment = { __typename: 'GeoRegionDefinitionObject', id: number, type: Types.GeoRegionDefinitionType, include: boolean, boundary?: any | null | undefined, center?: any | null | undefined, country?: string | null | undefined, province?: string | null | undefined, postalCode?: string | null | undefined, city?: string | null | undefined, suburb?: string | null | undefined, region: { __typename: 'GeoRegionObject', id: number } };

export type GeoRegionDefinitionsQueryVariables = Types.Exact<{
  filter: Types.GeoRegionDefinitionFilterInput;
}>;


export type GeoRegionDefinitionsQuery = { __typename: 'Query', geoRegionDefinitions: Array<{ __typename: 'GeoRegionDefinitionObject', id: number, type: Types.GeoRegionDefinitionType, include: boolean, boundary?: any | null | undefined, center?: any | null | undefined, country?: string | null | undefined, province?: string | null | undefined, postalCode?: string | null | undefined, city?: string | null | undefined, suburb?: string | null | undefined, region: { __typename: 'GeoRegionObject', id: number } }> };

export type CreateGeoRegionDefinitionMutationVariables = Types.Exact<{
  data: Types.CreateGeoRegionDefinitionInput;
}>;


export type CreateGeoRegionDefinitionMutation = { __typename: 'Mutation', createGeoRegionDefinition: { __typename: 'GeoRegionDefinitionObject', id: number, type: Types.GeoRegionDefinitionType, include: boolean, boundary?: any | null | undefined, center?: any | null | undefined, country?: string | null | undefined, province?: string | null | undefined, postalCode?: string | null | undefined, city?: string | null | undefined, suburb?: string | null | undefined, region: { __typename: 'GeoRegionObject', id: number } } };

export type UpdateGeoRegionDefinitionMutationVariables = Types.Exact<{
  data: Types.UpdateGeoRegionDefinitionInput;
}>;


export type UpdateGeoRegionDefinitionMutation = { __typename: 'Mutation', updateGeoRegionDefinition: { __typename: 'GeoRegionDefinitionObject', id: number, type: Types.GeoRegionDefinitionType, include: boolean, boundary?: any | null | undefined, center?: any | null | undefined, country?: string | null | undefined, province?: string | null | undefined, postalCode?: string | null | undefined, city?: string | null | undefined, suburb?: string | null | undefined, region: { __typename: 'GeoRegionObject', id: number } } };

export type DeleteGeoRegionDefinitionMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteGeoRegionDefinitionMutation = { __typename: 'Mutation', deleteGeoRegionDefinition: number };

export const GeoRegionFragmentDoc = gql`
    fragment GeoRegion on GeoRegionObject {
  id
  name
  code
  label
  channels {
    id
    code
  }
  primaryChannel {
    id
    code
  }
  parent {
    id
  }
}
    `;
export const GeoRegionItemFragmentDoc = gql`
    fragment GeoRegionItem on GeoRegionObject {
  id
  name
  code
  label
}
    `;
export const GeoRegionDefinitionFragmentDoc = gql`
    fragment GeoRegionDefinition on GeoRegionDefinitionObject {
  id
  type
  include
  boundary
  center
  region {
    id
  }
  country
  province
  postalCode
  city
  suburb
}
    `;
export const GeoRegionsDocument = gql`
    query GeoRegions($filter: GeoRegionFilterInput!) {
  geoRegions(filter: $filter) {
    ...GeoRegion
  }
}
    ${GeoRegionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeoRegionsGQL extends Apollo.Query<GeoRegionsQuery, GeoRegionsQueryVariables> {
    document = GeoRegionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeoRegionItemsDocument = gql`
    query GeoRegionItems($filter: GeoRegionFilterInput, $page: PageInput, $sort: SortInput) {
  items: geoRegions(filter: $filter, page: $page, sort: $sort) {
    ...GeoRegionItem
  }
  totalItemsCount: geoRegionsCount(filter: $filter)
}
    ${GeoRegionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeoRegionItemsGQL extends Apollo.Query<GeoRegionItemsQuery, GeoRegionItemsQueryVariables> {
    document = GeoRegionItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeoRegionDocument = gql`
    query GeoRegion($id: Int!) {
  geoRegion(id: $id) {
    ...GeoRegion
  }
}
    ${GeoRegionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeoRegionGQL extends Apollo.Query<GeoRegionQuery, GeoRegionQueryVariables> {
    document = GeoRegionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeoRegionItemDocument = gql`
    query GeoRegionItem($id: Int!) {
  geoRegion(id: $id) {
    ...GeoRegionItem
  }
}
    ${GeoRegionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeoRegionItemGQL extends Apollo.Query<GeoRegionItemQuery, GeoRegionItemQueryVariables> {
    document = GeoRegionItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGeoRegionDocument = gql`
    mutation CreateGeoRegion($data: CreateGeoRegionInput!) {
  createGeoRegion(data: $data) {
    ...GeoRegion
  }
}
    ${GeoRegionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGeoRegionGQL extends Apollo.Mutation<CreateGeoRegionMutation, CreateGeoRegionMutationVariables> {
    document = CreateGeoRegionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGeoRegionDocument = gql`
    mutation UpdateGeoRegion($data: UpdateGeoRegionInput!) {
  updateGeoRegion(data: $data) {
    ...GeoRegion
  }
}
    ${GeoRegionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGeoRegionGQL extends Apollo.Mutation<UpdateGeoRegionMutation, UpdateGeoRegionMutationVariables> {
    document = UpdateGeoRegionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeoRegionDefinitionsDocument = gql`
    query GeoRegionDefinitions($filter: GeoRegionDefinitionFilterInput!) {
  geoRegionDefinitions(filter: $filter) {
    ...GeoRegionDefinition
  }
}
    ${GeoRegionDefinitionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeoRegionDefinitionsGQL extends Apollo.Query<GeoRegionDefinitionsQuery, GeoRegionDefinitionsQueryVariables> {
    document = GeoRegionDefinitionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGeoRegionDefinitionDocument = gql`
    mutation CreateGeoRegionDefinition($data: CreateGeoRegionDefinitionInput!) {
  createGeoRegionDefinition(data: $data) {
    ...GeoRegionDefinition
  }
}
    ${GeoRegionDefinitionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGeoRegionDefinitionGQL extends Apollo.Mutation<CreateGeoRegionDefinitionMutation, CreateGeoRegionDefinitionMutationVariables> {
    document = CreateGeoRegionDefinitionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGeoRegionDefinitionDocument = gql`
    mutation UpdateGeoRegionDefinition($data: UpdateGeoRegionDefinitionInput!) {
  updateGeoRegionDefinition(data: $data) {
    ...GeoRegionDefinition
  }
}
    ${GeoRegionDefinitionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGeoRegionDefinitionGQL extends Apollo.Mutation<UpdateGeoRegionDefinitionMutation, UpdateGeoRegionDefinitionMutationVariables> {
    document = UpdateGeoRegionDefinitionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGeoRegionDefinitionDocument = gql`
    mutation DeleteGeoRegionDefinition($id: Int!) {
  deleteGeoRegionDefinition(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGeoRegionDefinitionGQL extends Apollo.Mutation<DeleteGeoRegionDefinitionMutation, DeleteGeoRegionDefinitionMutationVariables> {
    document = DeleteGeoRegionDefinitionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }