import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type VoucherProgramItemFragment = { __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, productVariants: Array<{ __typename: 'ProductVariantObject', name: string, id: number }> };

export type VoucherProgramRowFragment = { __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined };

export type VoucherProgramArchiveItemFragment = { __typename: 'VoucherProgramObject', id: number, name: string, archivedAt?: any | null | undefined };

export type VoucherProviderItemFragment = { __typename: 'VoucherProviderObject', id: string, label: string, currencies: Array<string> };

export type VoucherProgramRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.VoucherProgramFilterInput>;
}>;


export type VoucherProgramRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined }> };

export type VoucherProgramItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type VoucherProgramItemQuery = { __typename: 'Query', voucherProgram: { __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, productVariants: Array<{ __typename: 'ProductVariantObject', name: string, id: number }> } };

export type VoucherProgramSearchQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.VoucherProgramFilterInput>;
}>;


export type VoucherProgramSearchQuery = { __typename: 'Query', voucherPrograms: Array<{ __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, productVariants: Array<{ __typename: 'ProductVariantObject', name: string, id: number }> }> };

export type VoucherProvidersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VoucherProvidersQuery = { __typename: 'Query', voucherProviders: Array<{ __typename: 'VoucherProviderObject', id: string, label: string, currencies: Array<string> }> };

export type VoucherProgramArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type VoucherProgramArchiveMutation = { __typename: 'Mutation', archiveVoucherProgram: { __typename: 'VoucherProgramObject', id: number, name: string, archivedAt?: any | null | undefined } };

export type VoucherProgramUpdateMutationVariables = Types.Exact<{
  input: Types.UpdateVoucherProgramInput;
}>;


export type VoucherProgramUpdateMutation = { __typename: 'Mutation', updateVoucherProgram: { __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, productVariants: Array<{ __typename: 'ProductVariantObject', name: string, id: number }> } };

export type VoucherProgramCreateMutationVariables = Types.Exact<{
  input: Types.CreateVoucherProgramInput;
}>;


export type VoucherProgramCreateMutation = { __typename: 'Mutation', createVoucherProgram: { __typename: 'VoucherProgramObject', id: number, code: string, name: string, value: number, currency: string, voucherProviderId: string, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, productVariants: Array<{ __typename: 'ProductVariantObject', name: string, id: number }> } };

export const VoucherProgramItemFragmentDoc = gql`
    fragment VoucherProgramItem on VoucherProgramObject {
  id
  code
  name
  value
  currency
  voucherProviderId
  createdAt
  updatedAt
  archivedAt
  productVariants {
    name
    id
  }
}
    `;
export const VoucherProgramRowFragmentDoc = gql`
    fragment VoucherProgramRow on VoucherProgramObject {
  id
  code
  name
  value
  currency
  voucherProviderId
  createdAt
  updatedAt
  archivedAt
}
    `;
export const VoucherProgramArchiveItemFragmentDoc = gql`
    fragment VoucherProgramArchiveItem on VoucherProgramObject {
  id
  name
  archivedAt
}
    `;
export const VoucherProviderItemFragmentDoc = gql`
    fragment VoucherProviderItem on VoucherProviderObject {
  id
  label
  currencies
}
    `;
export const VoucherProgramRowsDocument = gql`
    query VoucherProgramRows($page: PageInput, $sort: SortInput, $filter: VoucherProgramFilterInput) {
  items: voucherPrograms(page: $page, sort: $sort, filter: $filter) {
    ...VoucherProgramRow
  }
  totalItemsCount: voucherProgramsCount(filter: $filter)
}
    ${VoucherProgramRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProgramRowsGQL extends Apollo.Query<VoucherProgramRowsQuery, VoucherProgramRowsQueryVariables> {
    document = VoucherProgramRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherProgramItemDocument = gql`
    query VoucherProgramItem($id: Int!) {
  voucherProgram(id: $id) {
    ...VoucherProgramItem
  }
}
    ${VoucherProgramItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProgramItemGQL extends Apollo.Query<VoucherProgramItemQuery, VoucherProgramItemQueryVariables> {
    document = VoucherProgramItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherProgramSearchDocument = gql`
    query VoucherProgramSearch($page: PageInput, $sort: SortInput, $filter: VoucherProgramFilterInput) {
  voucherPrograms(page: $page, sort: $sort, filter: $filter) {
    ...VoucherProgramItem
  }
}
    ${VoucherProgramItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProgramSearchGQL extends Apollo.Query<VoucherProgramSearchQuery, VoucherProgramSearchQueryVariables> {
    document = VoucherProgramSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherProvidersDocument = gql`
    query VoucherProviders {
  voucherProviders {
    ...VoucherProviderItem
  }
}
    ${VoucherProviderItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProvidersGQL extends Apollo.Query<VoucherProvidersQuery, VoucherProvidersQueryVariables> {
    document = VoucherProvidersDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherProgramArchiveDocument = gql`
    mutation VoucherProgramArchive($id: Int!) {
  archiveVoucherProgram(id: $id) {
    ...VoucherProgramArchiveItem
  }
}
    ${VoucherProgramArchiveItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProgramArchiveGQL extends Apollo.Mutation<VoucherProgramArchiveMutation, VoucherProgramArchiveMutationVariables> {
    document = VoucherProgramArchiveDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherProgramUpdateDocument = gql`
    mutation VoucherProgramUpdate($input: UpdateVoucherProgramInput!) {
  updateVoucherProgram(input: $input) {
    ...VoucherProgramItem
  }
}
    ${VoucherProgramItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProgramUpdateGQL extends Apollo.Mutation<VoucherProgramUpdateMutation, VoucherProgramUpdateMutationVariables> {
    document = VoucherProgramUpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherProgramCreateDocument = gql`
    mutation VoucherProgramCreate($input: CreateVoucherProgramInput!) {
  createVoucherProgram(input: $input) {
    ...VoucherProgramItem
  }
}
    ${VoucherProgramItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherProgramCreateGQL extends Apollo.Mutation<VoucherProgramCreateMutation, VoucherProgramCreateMutationVariables> {
    document = VoucherProgramCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }