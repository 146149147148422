import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { IProductItemObject, ProductRowObject, ProductService } from '@x/ecommerce/domain-client';
import { ProductFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { ProductItemFragment } from '../../domain-client/services/gql/product.gql.generated';

@Injectable({ providedIn: 'root' })
export class ProductRowCollectionProvider
  implements IDataCollectionProvider<ProductRowObject, ProductFilterInput, {}, number>
{
  constructor(private productService: ProductService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ProductRowObject>> {
    console.info({ sort });
    return this.productService.fetchRowsT({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: ProductRowObject): number {
    return model.id;
  }

  toString(model: ProductRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ProductItemCollectionProvider
  implements
    IDataProvider<IProductItemObject, number>,
    IDataCollectionProvider<IProductItemObject, ProductFilterInput, {}, number>
{
  constructor(private productService: ProductService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IProductItemObject>> {
    return this.productService.fetchItems({
      filter: { search: searchText, ...filter },
      page: page ?? {
        index: 0,
        size: 10,
      },
      sort: sort ?? {
        column: 'id',
        order: 'desc',
      },
      args: {},
    });
  }

  fetchSingle(id: number): Observable<ProductItemFragment> {
    return this.productService.fetchItem(id);
  }

  toId(model: IProductItemObject): number {
    return model.id;
  }

  toString(model: IProductItemObject): string {
    return model.name;
  }
}
