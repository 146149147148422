import { Injectable } from '@angular/core';
import { CreateVoucherProgramInput, UpdateVoucherProgramInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  VoucherProgramArchiveGQL,
  VoucherProgramCreateGQL,
  VoucherProgramItemGQL,
  VoucherProgramRowsGQL,
  VoucherProgramRowsQueryVariables,
  VoucherProgramSearchGQL,
  VoucherProgramUpdateGQL,
  VoucherProvidersGQL,
} from './gql/voucher-program.gql.generated';
import { IVoucherProgramItemObject, IVoucherProgramRowObject } from './types/voucher-program';

@Injectable()
export class VoucherProgramService {
  constructor(
    private readonly rowsGQL: VoucherProgramRowsGQL,
    private readonly itemGQL: VoucherProgramItemGQL,
    private readonly searchGQL: VoucherProgramSearchGQL,
    private readonly archiveGQL: VoucherProgramArchiveGQL,
    private readonly updateGQL: VoucherProgramUpdateGQL,
    private readonly createGQL: VoucherProgramCreateGQL,
    private readonly providersGQL: VoucherProvidersGQL,
  ) {}

  fetchRows(
    query: VoucherProgramRowsQueryVariables,
  ): Observable<{ items: IVoucherProgramRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<IVoucherProgramItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.voucherProgram));
  }

  fetchItemSearch(search?: string | null, limit = 10): Observable<IVoucherProgramItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.voucherPrograms));
  }

  archive(id: number) {
    return this.archiveGQL.mutate({ id }).pipe(map((r) => r.data?.archiveVoucherProgram));
  }

  update(input: UpdateVoucherProgramInput) {
    return this.updateGQL.mutate({ input }).pipe(map((r) => r.data?.updateVoucherProgram));
  }

  create(input: CreateVoucherProgramInput) {
    return this.createGQL.mutate({ input }).pipe(map((r) => r.data?.createVoucherProgram));
  }

  fetchVoucherProviders() {
    return this.providersGQL.fetch().pipe(map((r) => r.data.voucherProviders));
  }
}
