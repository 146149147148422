import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragBoundaryDirective } from './drag-boundary.directive';
import { DraggableDirective } from './draggable.directive';
import { DropZoneDirective } from './drop-zone.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DraggableDirective, DragBoundaryDirective, DropZoneDirective],
  exports: [DraggableDirective, DragBoundaryDirective, DropZoneDirective],
})
export class DragModule {}
