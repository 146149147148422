import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockLotItemFragment = { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } };

export type StockLotRowFragment = { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } };

export type StockLotDetailFragment = { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } };

export type StockLotRefDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type StockLotRefDetailQuery = { __typename: 'Query', stockLotRef: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type StockLotRefRowQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type StockLotRefRowQuery = { __typename: 'Query', stockLotRef: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type StockLotRefsQueryVariables = Types.Exact<{
  filter: Types.StockLotRefFilterInput;
}>;


export type StockLotRefsQuery = { __typename: 'Query', stockLotRefs: Array<{ __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } }> };

export type ManifestStockLotMutationVariables = Types.Exact<{
  ref: Types.Scalars['String']['input'];
}>;


export type ManifestStockLotMutation = { __typename: 'Mutation', manifestStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type CreateStockLotMutationVariables = Types.Exact<{
  input: Types.CreateStockLotInput;
}>;


export type CreateStockLotMutation = { __typename: 'Mutation', createStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type UpdateStockLotMutationVariables = Types.Exact<{
  input: Types.UpdateStockLotInput;
}>;


export type UpdateStockLotMutation = { __typename: 'Mutation', updateStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export type AdjustStockLotMutationVariables = Types.Exact<{
  ref: Types.Scalars['String']['input'];
  adjustment: Types.Scalars['Int']['input'];
}>;


export type AdjustStockLotMutation = { __typename: 'Mutation', adjustStockLot: { __typename: 'StockLotObject', id: string, priority: number, tracked: boolean, quantity: number, initialQuantity: number, reservedQuantity: number, allocatedQuantity: number, releasedQuantity: number, availableQuantity?: number | null | undefined, availableAt?: any | null | undefined, expiresAt?: any | null | undefined, createdAt: any, updatedAt: any, exists: boolean, schedule?: { __typename: 'StockScheduleObject', id: number, name: string } | null | undefined, warehouse: { __typename: 'WarehouseObject', id: number, name: string, timezone: string } } };

export const StockLotItemFragmentDoc = gql`
    fragment StockLotItem on StockLotObject {
  id
  priority
  tracked
  quantity
  initialQuantity
  reservedQuantity
  allocatedQuantity
  releasedQuantity
  availableQuantity
  availableAt
  expiresAt
  createdAt
  updatedAt
  exists
  schedule {
    id
    name
  }
  warehouse {
    id
    name
    timezone
  }
}
    `;
export const StockLotRowFragmentDoc = gql`
    fragment StockLotRow on StockLotObject {
  id
  priority
  tracked
  quantity
  initialQuantity
  reservedQuantity
  allocatedQuantity
  releasedQuantity
  availableQuantity
  availableAt
  expiresAt
  createdAt
  updatedAt
  exists
  schedule {
    id
    name
  }
  warehouse {
    id
    name
    timezone
  }
}
    `;
export const StockLotDetailFragmentDoc = gql`
    fragment StockLotDetail on StockLotObject {
  id
  priority
  tracked
  quantity
  initialQuantity
  reservedQuantity
  allocatedQuantity
  releasedQuantity
  availableQuantity
  availableAt
  expiresAt
  createdAt
  updatedAt
  exists
  item {
    id
    sku
    name
  }
  schedule {
    id
    name
  }
  warehouse {
    id
    name
    timezone
  }
}
    `;
export const StockLotRefDetailDocument = gql`
    query StockLotRefDetail($id: String!) {
  stockLotRef(id: $id) {
    ...StockLotDetail
  }
}
    ${StockLotDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockLotRefDetailGQL extends Apollo.Query<StockLotRefDetailQuery, StockLotRefDetailQueryVariables> {
    document = StockLotRefDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockLotRefRowDocument = gql`
    query StockLotRefRow($id: String!) {
  stockLotRef(id: $id) {
    ...StockLotRow
  }
}
    ${StockLotRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockLotRefRowGQL extends Apollo.Query<StockLotRefRowQuery, StockLotRefRowQueryVariables> {
    document = StockLotRefRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockLotRefsDocument = gql`
    query StockLotRefs($filter: StockLotRefFilterInput!) {
  stockLotRefs(filter: $filter) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockLotRefsGQL extends Apollo.Query<StockLotRefsQuery, StockLotRefsQueryVariables> {
    document = StockLotRefsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManifestStockLotDocument = gql`
    mutation ManifestStockLot($ref: String!) {
  manifestStockLot(ref: $ref) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ManifestStockLotGQL extends Apollo.Mutation<ManifestStockLotMutation, ManifestStockLotMutationVariables> {
    document = ManifestStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStockLotDocument = gql`
    mutation CreateStockLot($input: CreateStockLotInput!) {
  createStockLot(input: $input) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStockLotGQL extends Apollo.Mutation<CreateStockLotMutation, CreateStockLotMutationVariables> {
    document = CreateStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStockLotDocument = gql`
    mutation UpdateStockLot($input: UpdateStockLotInput!) {
  updateStockLot(input: $input) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStockLotGQL extends Apollo.Mutation<UpdateStockLotMutation, UpdateStockLotMutationVariables> {
    document = UpdateStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdjustStockLotDocument = gql`
    mutation AdjustStockLot($ref: String!, $adjustment: Int!) {
  adjustStockLot(ref: $ref, adjustment: $adjustment) {
    ...StockLotItem
  }
}
    ${StockLotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdjustStockLotGQL extends Apollo.Mutation<AdjustStockLotMutation, AdjustStockLotMutationVariables> {
    document = AdjustStockLotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }