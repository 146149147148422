import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  ActivateUserMembershipInput,
  CreateMemberInput,
  DeactivateUserMembershipInput,
  MemberFilterInput,
  UpdateMemberInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ActivateUserMembershipGQL,
  DeactivateUserMembershipGQL,
  ExportMemberReportGQL,
  MemberActivateGQL,
  MemberCreateGQL,
  MemberDeactivateGQL,
  MemberDetailGQL,
  MemberItemGQL,
  MemberRowGQL,
  MemberRowQueryVariables,
  MemberRowsGQL,
  MemberRowsQueryVariables,
  MemberSearchGQL,
  MemberUpdateGQL,
} from './gql/member.gql.generated';
import { IMemberDetailObject, IMemberItemObject, IMemberRowObject } from './types/member';

@Injectable()
export class MemberService {
  constructor(
    private readonly rowGQL: MemberRowGQL,
    private readonly rowsGQL: MemberRowsGQL,
    private readonly itemGQL: MemberItemGQL,
    private readonly detailGQL: MemberDetailGQL,
    private readonly searchGQL: MemberSearchGQL,
    private readonly activateGQL: MemberActivateGQL,
    private readonly deactivateGQL: MemberDeactivateGQL,
    private readonly updateGQL: MemberUpdateGQL,
    private readonly createGQL: MemberCreateGQL,
    private readonly activateUserMembershipGQL: ActivateUserMembershipGQL,
    private readonly deactivateUserMembershipGQL: DeactivateUserMembershipGQL,
    private readonly exportMemberReportGQL: ExportMemberReportGQL,
  ) {}

  fetchRows(
    query: MemberRowsQueryVariables,
  ): Observable<{ items: IMemberRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(query: MemberRowQueryVariables): Observable<IMemberRowObject> {
    return this.rowGQL.fetch(query).pipe(map((r) => r.data.member));
  }

  fetchItem(id: number): Observable<IMemberItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.member));
  }

  fetchDetail(id: number): Observable<IMemberDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.member));
  }

  fetchItemSearch(search?: string | null, limit = 10): Observable<IMemberItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.members));
  }

  activate(id: number) {
    return this.activateGQL.mutate({ id }).pipe(map((r) => r.data?.activateMember));
  }

  deactivate(id: number) {
    return this.deactivateGQL.mutate({ id }).pipe(map((r) => r.data?.deactivateMember));
  }

  update(data: UpdateMemberInput) {
    return this.updateGQL.mutate({ data }).pipe(map((r) => r.data?.updateMember));
  }

  create(data: CreateMemberInput) {
    return this.createGQL.mutate({ data }).pipe(map((r) => r.data?.createMember));
  }

  activateUserMembership(input: ActivateUserMembershipInput) {
    return this.activateUserMembershipGQL
      .mutate({ input })
      .pipe(map((r) => r.data?.activateUserMembership));
  }

  deactivateUserMembership(input: DeactivateUserMembershipInput) {
    return this.deactivateUserMembershipGQL
      .mutate({ input })
      .pipe(map((r) => r.data?.deactivateUserMembership));
  }

  exportMemberSpreadsheet(filter: MemberFilterInput, format?: string) {
    return this.exportMemberReportGQL
      .mutate({ filter, format })
      .pipe(mapFetchResultData((d) => d.exportMemberReport));
  }
}
