import { DraggableDirective } from './draggable.directive';
import { DropZoneDirective } from './drop-zone.directive';

export class DropZoneDragEvent {
  constructor(
    public event: DragEvent,
    public dropzone: DropZoneDirective,
    public draggable: DraggableDirective | null = null,
  ) {}
}

export class DraggableDragEvent {
  constructor(public event: DragEvent, public draggable: DraggableDirective) {}
}
