import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateShippingCategoryInput, UpdateShippingCategoryInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateShippingCategoryGQL,
  ShippingCategoryItemGQL,
  ShippingCategoryItemsGQL,
  ShippingCategoryItemsQueryVariables,
  UpdateShippingCategoryGQL,
} from './gql/shipping-category.gql.generated';
import { IShippingCategoryItemObject } from './types/shipping-category';

@Injectable()
export class ShippingCategoryService {
  constructor(
    private shippingCategoryItemGQL: ShippingCategoryItemGQL,
    private shippingCategoryItemsGQL: ShippingCategoryItemsGQL,
    private createGQL: CreateShippingCategoryGQL,
    private updateGQL: UpdateShippingCategoryGQL,
  ) {}

  fetchItem(id: number): Observable<IShippingCategoryItemObject> {
    return this.shippingCategoryItemGQL.fetch({ id }).pipe(map((r) => r.data.shippingCategory));
  }

  fetchItems(
    query: ShippingCategoryItemsQueryVariables,
  ): Observable<{ items: IShippingCategoryItemObject[]; totalItemsCount: number }> {
    return this.shippingCategoryItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  create(input: CreateShippingCategoryInput) {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShippingCategory));
  }

  update(input: UpdateShippingCategoryInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShippingCategory));
  }
}
