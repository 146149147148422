import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { MembershipRowFragmentDoc } from './membership.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubscriptionMembershipBenefitDetailFragment = { __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } };

export type SubscriptionMembershipBenefitSimpleItemFragment = { __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, membership: { __typename: 'MembershipObject', id: number, name: string }, variant: { __typename: 'SubscriptionVariantObject', id: number } };

export type SubscriptionMembershipBenefitItemFragment = { __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined };

export type SubscriptionMembershipBenefitCreateMutationVariables = Types.Exact<{
  data: Types.SubscriptionMembershipBenefitInput;
}>;


export type SubscriptionMembershipBenefitCreateMutation = { __typename: 'Mutation', createSubscriptionMembershipBenefit: { __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } } };

export type SubscriptionMembershipBenefitArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionMembershipBenefitArchiveMutation = { __typename: 'Mutation', archiveSubscriptionMembershipBenefit: { __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } } };

export const SubscriptionMembershipBenefitDetailFragmentDoc = gql`
    fragment SubscriptionMembershipBenefitDetail on SubscriptionMembershipBenefitObject {
  id
  duration
  createdAt
  updatedAt
  archivedAt
  variant {
    id
  }
  membership {
    ...MembershipRow
  }
}
    ${MembershipRowFragmentDoc}`;
export const SubscriptionMembershipBenefitSimpleItemFragmentDoc = gql`
    fragment SubscriptionMembershipBenefitSimpleItem on SubscriptionMembershipBenefitObject {
  id
  duration
  createdAt
  updatedAt
  archivedAt
  membership {
    id
    name
  }
  variant {
    id
  }
}
    `;
export const SubscriptionMembershipBenefitItemFragmentDoc = gql`
    fragment SubscriptionMembershipBenefitItem on SubscriptionMembershipBenefitObject {
  id
  duration
  createdAt
  updatedAt
  archivedAt
}
    `;
export const SubscriptionMembershipBenefitCreateDocument = gql`
    mutation SubscriptionMembershipBenefitCreate($data: SubscriptionMembershipBenefitInput!) {
  createSubscriptionMembershipBenefit(data: $data) {
    ...SubscriptionMembershipBenefitDetail
  }
}
    ${SubscriptionMembershipBenefitDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionMembershipBenefitCreateGQL extends Apollo.Mutation<SubscriptionMembershipBenefitCreateMutation, SubscriptionMembershipBenefitCreateMutationVariables> {
    document = SubscriptionMembershipBenefitCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionMembershipBenefitArchiveDocument = gql`
    mutation SubscriptionMembershipBenefitArchive($id: Int!) {
  archiveSubscriptionMembershipBenefit(id: $id) {
    ...SubscriptionMembershipBenefitDetail
  }
}
    ${SubscriptionMembershipBenefitDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionMembershipBenefitArchiveGQL extends Apollo.Mutation<SubscriptionMembershipBenefitArchiveMutation, SubscriptionMembershipBenefitArchiveMutationVariables> {
    document = SubscriptionMembershipBenefitArchiveDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }