import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateOrderIncidentCategoryInput,
  PositionOrderIncidentCategoryInput,
  UpdateOrderIncidentCategoryInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  CreateOrderIncidentCategoryGQL,
  OrderIncidentCategoryDetailGQL,
  OrderIncidentCategoryItemGQL,
  OrderIncidentCategoryItemsGQL,
  OrderIncidentCategoryRowGQL,
  OrderIncidentCategoryRowsGQL,
  OrderIncidentCategoryRowsQueryVariables,
  PositionOrderIncidentCategoryGQL,
  UpdateOrderIncidentCategoryGQL,
} from './gql/order-incident-category.gql.generated';
import {
  IOrderIncidentCategoryDetailObject,
  IOrderIncidentCategoryItemObject,
} from './types/order-incident';

@Injectable()
export class OrderIncidentCategoryService {
  constructor(
    private readonly detailGQL: OrderIncidentCategoryDetailGQL,
    private readonly itemGQL: OrderIncidentCategoryItemGQL,
    private readonly itemsGQL: OrderIncidentCategoryItemsGQL,
    private readonly rowGQL: OrderIncidentCategoryRowGQL,
    private readonly rowsGQL: OrderIncidentCategoryRowsGQL,
    private readonly createGQL: CreateOrderIncidentCategoryGQL,
    private readonly updateGQL: UpdateOrderIncidentCategoryGQL,
    private readonly positionGQL: PositionOrderIncidentCategoryGQL,
  ) {}

  fetchDetail(id: Readonly<number>): Observable<IOrderIncidentCategoryDetailObject> {
    return this.detailGQL
      .fetch({ id })
      .pipe(mapFetchResultData((result) => result.orderIncidentCategory));
  }

  fetchItem(id: Readonly<number>): Observable<IOrderIncidentCategoryItemObject> {
    return this.itemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.orderIncidentCategory));
  }

  fetchItems(
    query: OrderIncidentCategoryRowsQueryVariables,
  ): Observable<IOrderIncidentCategoryItemObject[]> {
    return this.itemsGQL.fetch(query).pipe(mapFetchResultData((r) => r.orderIncidentCategories));
  }

  fetchRow(id: Readonly<number>): Observable<IOrderIncidentCategoryItemObject> {
    return this.rowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.orderIncidentCategory));
  }

  fetchRows(
    query: Readonly<OrderIncidentCategoryRowsQueryVariables>,
  ): Observable<{ items: IOrderIncidentCategoryItemObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  create(input: CreateOrderIncidentCategoryInput): Observable<IOrderIncidentCategoryItemObject> {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createOrderIncidentCategory));
  }

  update(input: UpdateOrderIncidentCategoryInput): Observable<IOrderIncidentCategoryItemObject> {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.updateOrderIncidentCategory));
  }

  position(
    input: PositionOrderIncidentCategoryInput,
  ): Observable<IOrderIncidentCategoryItemObject> {
    return this.positionGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.positionOrderIncidentCategory));
  }
}
