import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  ISubscriptionDetailObject,
  ISubscriptionItemObject,
  ISubscriptionRowObject,
  SubscriptionService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionItemCollectionProvider
  implements IDataCollectionProvider<ISubscriptionItemObject, any, any, number>
{
  constructor(private subscriptionService: SubscriptionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriptionItemObject>> {
    return this.subscriptionService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): ISubscriptionItemObject | Observable<ISubscriptionItemObject> {
    return this.subscriptionService.fetchItem(Number(id));
  }

  toId(model: ISubscriptionItemObject): number {
    return model.id;
  }

  toString(model: ISubscriptionItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class SubscriptionRowCollectionProvider
  implements IDataCollectionProvider<ISubscriptionRowObject, any, any, number>
{
  constructor(private subscriptionService: SubscriptionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriptionRowObject>> {
    return this.subscriptionService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: ISubscriptionRowObject): number {
    return model.id;
  }

  toString(model: ISubscriptionRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class SubscriptionDetailProvider
  implements IDataProvider<ISubscriptionDetailObject, number>
{
  constructor(private subscriptionService: SubscriptionService) {}

  fetchSingle(id: number): ISubscriptionDetailObject | Observable<ISubscriptionDetailObject> {
    return this.subscriptionService.fetchDetail(id);
  }

  toId(model: ISubscriptionDetailObject): number {
    return model.id;
  }

  toString(model: ISubscriptionDetailObject): string {
    return model.name;
  }
}
