import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { makeId } from '../utils';
import { DragBoundaryDirective } from './drag-boundary.directive';
import { DropZoneDragEvent } from './drag-events';
import { DraggableDirective } from './draggable.directive';

export type DropPredicateFn = (
  dropzone: DropZoneDirective,
  draggable: DraggableDirective | null,
) => boolean;

@Directive({
  selector: '[xDropZone]',
  host: {
    class: 'x-dropzone',
  },
})
export class DropZoneDirective implements OnInit, OnDestroy {
  id = makeId(6);

  @Input()
  dropZoneData: any = null;

  @Input()
  droppablePredicate: DropPredicateFn = () => true;

  @Output()
  zoneDrop = new EventEmitter<DropZoneDragEvent>();

  @Output()
  zoneDragOver = new EventEmitter<DropZoneDragEvent>();

  @Output()
  zoneDragEnter = new EventEmitter<DropZoneDragEvent>();

  @Output()
  zoneDragLeave = new EventEmitter<DropZoneDragEvent>();

  @HostBinding('class.x-dragover')
  isDragOver = false;

  @HostBinding('class.x-dropblocked')
  isDropBlocked = false;

  @HostBinding('class.x-dropopen')
  isDropOpen = false;

  constructor(
    @Optional()
    private dragBoundary?: DragBoundaryDirective,
  ) {
    if (!dragBoundary) {
      console.warn('DropZone requires a DragBoundary');
    }
  }

  ngOnInit() {
    this.dragBoundary?.registerDropZone(this);
  }

  ngOnDestroy() {
    this.dragBoundary?.unregisterDropZone(this);
  }

  @HostListener('drop', ['$event'])
  _drop(event: DragEvent) {
    const droppable = this.dragBoundary?.getDragging() || null;

    if (!this.isDropBlocked) {
      this.zoneDrop.emit(new DropZoneDragEvent(event, this, droppable));
    }

    this.isDragOver = false;

    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  _dragover(event: DragEvent) {
    // prevent default to allow drop
    event.stopPropagation();
    event.preventDefault();

    // this.isDragOver = true;
    // console.log('dragover', event);
    // this.zoneDragOver.emit(new DropZoneDragEvent(event, this, this.dragBoundary?.getDragging()));
    // return false;
  }

  @HostListener('dragenter', ['$event'])
  _dragenter(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.isDragOver = true;

    this.zoneDragEnter.emit(new DropZoneDragEvent(event, this, this.dragBoundary?.getDragging()));
    return false;
  }

  @HostListener('dragleave', ['$event'])
  _dragleave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.isDragOver = false;
    this.zoneDragLeave.emit(new DropZoneDragEvent(event, this, this.dragBoundary?.getDragging()));

    return false;
  }
}
