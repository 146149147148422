import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'x-pill-group-column',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['pill-group-column.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'x-pill-group-column',
  },
})
export class PillGroupColumnComponent {}
