import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { IUserItemObject, UserService } from '@x/ecommerce/domain-client';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class UserAutocompleteDatasource extends ModelAutocompleteDatasource<IUserItemObject> {
  constructor(private userService: UserService) {
    super((i: number, item: IUserItemObject) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<IUserItemObject[]> {
    return searchText.pipe(
      switchMap((text) => {
        if (text === null || (text && text.length === 0)) return of([]);

        return this.userService.fetchUsers({ filter: { search: text } });
      }),
    );
  }

  disconnect(): void {}

  displayFn = (option: IUserItemObject) => {
    if (!option) return '';
    return `${option.firstName} ${option.lastName} <${option.email}>` ?? '';
  };
}
