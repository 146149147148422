import { Injectable } from '@angular/core';
import { CreateSubscriptionInput, UpdateSubscriptionInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SubscriptionArchiveGQL,
  SubscriptionCreateGQL,
  SubscriptionDetailGQL,
  SubscriptionItemGQL,
  SubscriptionItemsGQL,
  SubscriptionItemsQueryVariables,
  SubscriptionRowsGQL,
  SubscriptionRowsQueryVariables,
  SubscriptionSearchGQL,
  SubscriptionUpdateGQL,
} from './gql/subscription.gql.generated';
import {
  ISubscriptionDetailObject,
  ISubscriptionItemObject,
  ISubscriptionRowObject,
} from './types/subscription';

@Injectable()
export class SubscriptionService {
  constructor(
    private readonly rowsGQL: SubscriptionRowsGQL,
    private readonly itemGQL: SubscriptionItemGQL,
    private readonly detailGQL: SubscriptionDetailGQL,
    private readonly subscriptionItemsGQL: SubscriptionItemsGQL,
    private readonly searchGQL: SubscriptionSearchGQL,
    private readonly archiveGQL: SubscriptionArchiveGQL,
    private readonly updateGQL: SubscriptionUpdateGQL,
    private readonly createGQL: SubscriptionCreateGQL,
  ) {}

  fetchItem(id: number): Observable<ISubscriptionItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.subscription));
  }

  fetchItems(
    query: SubscriptionItemsQueryVariables,
  ): Observable<{ items: ISubscriptionItemObject[]; totalItemsCount: number }> {
    return this.subscriptionItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRows(
    query: SubscriptionRowsQueryVariables,
  ): Observable<{ items: ISubscriptionRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<ISubscriptionDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.subscription));
  }

  fetchItemSearch(search?: string | null, limit = 10): Observable<ISubscriptionItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.subscriptions));
  }

  archive(id: number) {
    return this.archiveGQL.mutate({ id }).pipe(map((r) => r.data?.archiveSubscription));
  }

  update(data: UpdateSubscriptionInput) {
    return this.updateGQL.mutate({ data }).pipe(map((r) => r.data?.updateSubscription));
  }

  create(data: CreateSubscriptionInput) {
    return this.createGQL.mutate({ data }).pipe(map((r) => r.data?.createSubscription));
  }
}
