import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubscriberTagItemFragment = { __typename: 'SubscriberTagObject', id: number, label: string, color?: string | null | undefined, icon?: string | null | undefined };

export type SubscriberTagItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.SubscriberTagFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type SubscriberTagItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'SubscriberTagObject', id: number, label: string, color?: string | null | undefined, icon?: string | null | undefined }> };

export type SubscriberTagItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriberTagItemQuery = { __typename: 'Query', subscriberTag: { __typename: 'SubscriberTagObject', id: number, label: string, color?: string | null | undefined, icon?: string | null | undefined } };

export type AddSubscriberTagsMutationVariables = Types.Exact<{
  input: Types.SubscriberTagInput;
}>;


export type AddSubscriberTagsMutation = { __typename: 'Mutation', addSubscriberTags: { __typename: 'SubscriberObject', id: number } };

export type RemoveSubscriberTagsMutationVariables = Types.Exact<{
  input: Types.SubscriberTagInput;
}>;


export type RemoveSubscriberTagsMutation = { __typename: 'Mutation', removeSubscriberTags: { __typename: 'SubscriberObject', id: number } };

export const SubscriberTagItemFragmentDoc = gql`
    fragment SubscriberTagItem on SubscriberTagObject {
  id
  label
  color
  icon
}
    `;
export const SubscriberTagItemsDocument = gql`
    query SubscriberTagItems($page: PageInput, $filter: SubscriberTagFilterInput, $sort: SortInput) {
  items: subscriberTags(page: $page, filter: $filter, sort: $sort) {
    ...SubscriberTagItem
  }
  totalItemsCount: subscriberTagsCount(filter: $filter)
}
    ${SubscriberTagItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriberTagItemsGQL extends Apollo.Query<SubscriberTagItemsQuery, SubscriberTagItemsQueryVariables> {
    document = SubscriberTagItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriberTagItemDocument = gql`
    query SubscriberTagItem($id: Int!) {
  subscriberTag(id: $id) {
    ...SubscriberTagItem
  }
}
    ${SubscriberTagItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriberTagItemGQL extends Apollo.Query<SubscriberTagItemQuery, SubscriberTagItemQueryVariables> {
    document = SubscriberTagItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddSubscriberTagsDocument = gql`
    mutation AddSubscriberTags($input: SubscriberTagInput!) {
  addSubscriberTags(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddSubscriberTagsGQL extends Apollo.Mutation<AddSubscriberTagsMutation, AddSubscriberTagsMutationVariables> {
    document = AddSubscriberTagsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveSubscriberTagsDocument = gql`
    mutation RemoveSubscriberTags($input: SubscriberTagInput!) {
  removeSubscriberTags(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveSubscriberTagsGQL extends Apollo.Mutation<RemoveSubscriberTagsMutation, RemoveSubscriberTagsMutationVariables> {
    document = RemoveSubscriberTagsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }