import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShippingMethodOrderIntervalQueryVariables = Types.Exact<{
  filter: Types.ShippingMethodOrderIntervalFilterInput;
}>;


export type ShippingMethodOrderIntervalQuery = { __typename: 'Query', shippingMethodOrderInterval: Array<{ __typename: 'ShippingMethodOrderIntervalObject', orderQuantity?: number | null | undefined, deliveredQuantity?: number | null | undefined, interval: { __typename: 'IntervalObject', start: any, end: any } }> };

export const ShippingMethodOrderIntervalDocument = gql`
    query ShippingMethodOrderInterval($filter: ShippingMethodOrderIntervalFilterInput!) {
  shippingMethodOrderInterval(filter: $filter) {
    interval {
      start
      end
    }
    orderQuantity
    deliveredQuantity
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodOrderIntervalGQL extends Apollo.Query<ShippingMethodOrderIntervalQuery, ShippingMethodOrderIntervalQueryVariables> {
    document = ShippingMethodOrderIntervalDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }