import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  DATA_TABLE_VIEW_STORAGE,
  DataTableView,
  IDataTableViewState,
  IDataTableViewStorage,
} from '@x/common/data';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'x-data-table-states',
  templateUrl: 'data-table-states.component.html',
  styleUrls: ['data-table-states.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-data-table-states',
  },
})
export class DataTableStatesComponent implements OnInit, OnDestroy {
  @Input()
  view?: DataTableView<any, any, any, any>;

  states$ = new BehaviorSubject<IDataTableViewState[]>([]);

  trackById = (i: number, s: any) => s.id;

  @ViewChild('viewTitleInput')
  viewTitleInput: ElementRef<HTMLInputElement>;

  viewTitleControl = new UntypedFormControl();
  createNewView = false;

  stateUnsaved = true;

  private _destroy = new Subject<void>();

  constructor(
    @Inject(DATA_TABLE_VIEW_STORAGE)
    private viewStorage: IDataTableViewStorage,
    private ngZone: NgZone,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.view) {
      throw new Error('DataTableViewsComponent no DataTableView provided.');
    }

    this.viewStorage
      .watch(this.getRepositoryId())
      .pipe(takeUntil(this._destroy))
      .subscribe((states) => this.states$.next(states));

    this.view
      .stateChanges()
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        if (this.viewTitleControl.value !== this.view?.stateTitle) {
          this.viewTitleControl.setValue(this.view?.stateTitle, { emitEvent: false });
        }

        this.changeRef.markForCheck();
      });

    this.view
      .stateChanges()
      .pipe(takeUntil(this._destroy), debounceTime(800))
      .subscribe(() => this.checkUnsavedChanges());

    this.viewTitleControl.setValue(this.view?.stateTitle, { emitEvent: false });
    this.viewTitleControl.disable();
  }

  onViewSelect(state: IDataTableViewState) {
    if (this.view) {
      this.view.setState(state, { restorePageIndex: false, restoreSelection: false });
    }
    this.stopEditing();
  }

  onViewSave() {
    if (this.view) {
      let state = this.createNewView ? this.view.cloneState() : this.view.getState();
      state.title = this.viewTitleControl.value;
      state.page = { index: 0, size: state.page?.size ?? this.view.defaultPageSize };
      state.selected = null;
      // state.searchText = null;

      console.log('save view', state);

      if (this.createNewView) {
        this.viewStorage
          .create(this.getRepositoryId(), state)
          .subscribe((v) => this.view?.setState(v));
      } else {
        this.viewStorage
          .update(this.getRepositoryId(), state)
          .subscribe((v) => this.view?.setState(v));
      }
    }
    this.stopEditing();
  }

  onViewEdit() {
    this.startEditing();
  }

  onViewCreate() {
    this.startEditing(true);
  }

  onDefaultViewSelect() {
    if (this.view) {
      this.view.reset();
    }
    this.stopEditing();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private getRepositoryId() {
    return `data_table_view.${this.view?.id ?? 'default'}`;
  }

  private startEditing(createNewView = false) {
    if (this.view && this.viewTitleInput) {
      this.createNewView = createNewView;
      this.viewTitleControl.enable();
      setTimeout(() => {
        this.ngZone.run(() => {
          this.viewTitleInput.nativeElement.focus();
          this.viewTitleInput.nativeElement.select();
        });
      }, 100);
    }
  }

  private stopEditing() {
    this.createNewView = false;
    this.viewTitleControl.disable();
  }

  private checkUnsavedChanges() {
    if (this.view) {
      // const originalState = this.getOriginalSelectedState();
      // const currentState = this.view.getState();

      // this.stateUnsaved = JSON.stringify(originalState) !== JSON.stringify(currentState);

      // console.log('stateUnsaved', this.stateUnsaved, originalState, currentState);

      this.changeRef.markForCheck();
    }
  }

  private getOriginalSelectedState() {
    if (this.view) {
      const id = this.view?.stateId;
      return this.states$.getValue().find((s) => s.id === id) ?? null;
    }

    return null;
  }
}
