import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { UpdateShippingSlotInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ShippingSlotRefItemGQL,
  ShippingSlotRefItemsGQL,
  ShippingSlotRefItemsQueryVariables,
  UpdateShippingSlotGQL,
} from './gql/shipping-slot.gql.generated';
import { IShippingSlotItem } from './types/shipping-slot';

@Injectable()
export class ShippingSlotService {
  constructor(
    private readonly shippingSlotRefItemGQL: ShippingSlotRefItemGQL,
    private readonly shippingSlotRefItemsGQL: ShippingSlotRefItemsGQL,
    private updateGQL: UpdateShippingSlotGQL,
  ) {}

  fetchItem(id: string): Observable<IShippingSlotItem> {
    return this.shippingSlotRefItemGQL.fetch({ id }).pipe(map((d) => d.data.shippingSlotRef));
  }

  fetchItems(query: ShippingSlotRefItemsQueryVariables): Observable<IShippingSlotItem[]> {
    return this.shippingSlotRefItemsGQL.fetch(query).pipe(map((r) => r.data.shippingSlotRefs));
  }

  update(input: UpdateShippingSlotInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShippingSlot));
  }
}
