import { Injectable } from '@angular/core';
import { GridDatasource, GridDatasourceData, GridDatasourceQuery } from '@x/dashboard/grid';
import { ProductRowObject, ProductService } from '@x/ecommerce/domain-client';
import { CloneProductInput, ProductFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

export interface ProductQueryArgs {
  locale?: string | null;
}

@Injectable()
export class TaxonProductsDatasource extends GridDatasource<
  ProductRowObject,
  ProductFilterInput,
  ProductQueryArgs
> {
  constructor(private productsService: ProductService) {
    super({
      defaultDisplayColumns: [
        'image',
        'name',
        'mainTaxon',
        'taxons',
        'priority',
        'channels',
        'availableRegions',
      ],
      defaultFilter: {
        taxon: {
          taxonIds: [],
          mainTaxon: false,
          includeDescendents: true,
        },
        search: null,
        // archived: false,
        // createdAt: null,
        // updatedAt: null,
        // channel: null,
        priority: [],
      },
      defaultSort: {
        column: 'priority',
        order: 'desc',
      },
      defaultArgs: {
        locale: null,
      },
      pageSizeOptions: [20, 100, 1000],
    });
  }

  fetch({
    filter,
    sort,
    page,
    args,
  }: Readonly<GridDatasourceQuery<ProductFilterInput, ProductQueryArgs>>): Observable<
    GridDatasourceData<ProductRowObject>
  > {
    return this.productsService.fetchRowsDepr({ ...args, filter, page, sort });
  }

  archive(id: number) {
    this.observeMutation(id, (id) => this.productsService.archive(Number(id))).subscribe();
  }

  clone(id: number, name: string) {
    const input: CloneProductInput = {
      clone_id: id,
      name: name + '_clone',
    };
    this.observeMutation(id, (id) => this.productsService.clone(input)).subscribe();
  }

  archiveMany(ids: Array<string | number>) {
    this.observeBulkMutation(ids, (id) => this.productsService.archive(Number(id))).subscribe();
  }

  updateMainTaxon(id: number, mainTaxonId: number) {
    return this.observeMutation(id, (id) => {
      return this.productsService.update({
        id: Number(id),
        mainTaxonId: mainTaxonId,
      });
    });
  }

  updateManyMainTaxon(ids: number[], mainTaxonId: number) {
    console.log('updateManyMainTaxon', ids, mainTaxonId);
    return this.observeBulkMutation(ids, (id) => {
      return this.productsService.update({
        id: Number(id),
        mainTaxonId: mainTaxonId,
      });
    });
  }

  updateTaxons(id: number, taxonIds: number[]) {
    return this.observeMutation(id, (i) => {
      return this.productsService.update({
        id,
        taxonIds,
      });
    });
  }

  updateManyTaxons(ids: number[], taxonIds: number[]) {
    return this.observeBulkMutation(ids, (id) => {
      return this.productsService.update({
        id: Number(id),
        taxonIds,
      });
    });
  }

  addTaxon(id: number, taxonId: number) {
    return this.observeMutation(id, (i) => {
      return this.productsService.addTaxon({
        id,
        taxonIds: [taxonId],
      });
    });
  }

  addTaxonToMany(ids: number[], taxonId: number) {
    return this.observeBulkMutation(ids, (id) => {
      return this.productsService.addTaxon({
        id: Number(id),
        taxonIds: [taxonId],
      });
    });
  }

  removeTaxon(id: number, taxonId: number) {
    return this.observeMutation(id, (i) => {
      return this.productsService.removeTaxon({
        id,
        taxonIds: [taxonId],
      });
    });
  }
}
