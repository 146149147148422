import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface Timestampable {
  createdAt?: string;
  updatedAt?: string;
  archivedAt?: string;
}

@Component({
  selector: 'x-timestamp-column',
  template: `
    <span *ngIf="createdAt">
      <time [attr.datetime]="createdAt">Created {{ createdAt | datetime: 'short' }}</time>
    </span>
    <br />
    <time *ngIf="updatedAt" [attr.datetime]="updatedAt">
      Updated {{ updatedAt | datetime: 'short' }}
    </time>
    <br />
    <time *ngIf="archivedAt" [attr.datetime]="archivedAt">
      Archived {{ archivedAt | datetime: 'short' }}
    </time>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'mat-hint',
  },
})
export class TimestampColumnComponent {
  @Input()
  set row(row: Timestampable) {
    this.createdAt = row.createdAt;
    this.updatedAt = row.updatedAt;
    this.archivedAt = row.archivedAt;
  }

  @Input()
  createdAt?: string;

  @Input()
  updatedAt?: string;

  @Input()
  archivedAt?: string;
}
