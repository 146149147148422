import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { CurrencyItem, CurrencyService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CurrencyItemIdTransformer implements ModelTransformer<CurrencyItem, string> {
  private _cache = new Map<string, CurrencyItem>();

  constructor(private service: CurrencyService) {}

  modelToValue(model: CurrencyItem): string {
    return model.id;
  }

  valueToModel(id: string): Promise<CurrencyItem> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as CurrencyItem);
    }

    return firstValueFrom(
      this.service.search(id).pipe(
        tap((r) => this._cache.set(id, r[0])),
        map((r) => r[0]),
      ),
    );
  }
}
