import { Injectable } from '@angular/core';
import { CreateVoucherInput, PrintResultObject } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  VoucherBalanceGQL,
  VoucherCancelGQL,
  VoucherCreateGQL,
  VoucherDetailGQL,
  VoucherItemGQL,
  VoucherResendEmailGQL,
  VoucherRowsGQL,
  VoucherRowsQueryVariables,
  VoucherSearchGQL,
  VouchersPrintGQL,
} from './gql/voucher.gql.generated';
import {
  IVoucherBalanceItemObject,
  IVoucherCreateItemObject,
  IVoucherDetailObject,
  IVoucherItemObject,
  IVoucherRowObject,
} from './types/voucher';

@Injectable()
export class VoucherService {
  constructor(
    private readonly rowsGQL: VoucherRowsGQL,
    private readonly itemGQL: VoucherItemGQL,
    private readonly detailGQL: VoucherDetailGQL,
    private readonly searchGQL: VoucherSearchGQL,
    private readonly balanceGQL: VoucherBalanceGQL,
    private readonly createGQL: VoucherCreateGQL,
    private readonly cancelGQL: VoucherCancelGQL,
    private readonly printGQL: VouchersPrintGQL,
    private readonly sendEmailGQL: VoucherResendEmailGQL,
  ) {}

  fetchRows(
    query: VoucherRowsQueryVariables,
  ): Observable<{ items: IVoucherRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<IVoucherItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.voucher));
  }

  fetchDetail(id: number): Observable<IVoucherDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.voucher));
  }

  fetchItemSearch(search?: string | null, limit = 10): Observable<IVoucherItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'code', order: 'asc' },
      })
      .pipe(map((r) => r.data.vouchers));
  }

  create(input: CreateVoucherInput): Observable<IVoucherCreateItemObject | null | undefined> {
    return this.createGQL.mutate({ input }).pipe(map((r) => r.data?.createVoucher));
  }

  cancel(id: number, reason: string): Observable<IVoucherDetailObject | null | undefined> {
    return this.cancelGQL.mutate({ id, reason }).pipe(map((r) => r.data?.cancelVoucher));
  }

  resend(id: number) {
    return this.sendEmailGQL.mutate({ voucherId: id }).pipe(map((r) => r.data?.sendVoucherEmail));
  }

  balance(id: number): Observable<IVoucherBalanceItemObject> {
    return this.balanceGQL.fetch({ id }).pipe(map((r) => r.data?.voucher));
  }

  print(ids: number[]): Observable<PrintResultObject | undefined> {
    return this.printGQL.mutate({ ids }).pipe(map((r) => r.data?.printVouchersManifest));
  }
}
