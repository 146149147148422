import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ReportObjectFragment = { __typename: 'ReportObject', id: string, name: string, url: string };

export type ReportProductsMutationVariables = Types.Exact<{
  input: Types.ReportProductInput;
}>;


export type ReportProductsMutation = { __typename: 'Mutation', reportProducts: { __typename: 'ReportObject', id: string, name: string, url: string } };

export const ReportObjectFragmentDoc = gql`
    fragment ReportObject on ReportObject {
  id
  name
  url
}
    `;
export const ReportProductsDocument = gql`
    mutation ReportProducts($input: ReportProductInput!) {
  reportProducts(input: $input) {
    ...ReportObject
  }
}
    ${ReportObjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportProductsGQL extends Apollo.Mutation<ReportProductsMutation, ReportProductsMutationVariables> {
    document = ReportProductsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }