import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: '[x-data-button]',
  template: `
    <fa-icon *ngIf="icon" class="action-icon" [icon]="icon"></fa-icon>
    <span class="action-label">&nbsp;{{ label }}</span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-data-button x-data-action-base',
  },
})
export class DataButtonComponent {
  @Input()
  label: string;

  @Input()
  icon?: IconProp;

  @Input()
  color: 'primary' | 'accent' | 'warn' | null = null;

  @HostBinding('class')
  get colorClass() {
    return `color-${this.color ? this.color : 'default'}`;
  }
}
