import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  IStockItemDetailObject,
  IStockItemItemObject,
  IStockItemRowObject,
  StockItemService,
} from '@x/ecommerce/domain-client';
import { StockItemFilterInput, StockQuantityFixedIntervalInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StockItemItemCollectionProvider
  implements IDataCollectionProvider<IStockItemItemObject>
{
  constructor(private service: StockItemService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IStockItemItemObject>> {
    return this.service.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IStockItemItemObject> {
    return this.service.fetchItem(Number(id));
  }

  toId(model: IStockItemItemObject): string | number {
    return model.id;
  }

  toString(model: IStockItemItemObject): string {
    return `${model.sku}: ${model.name}`;
  }
}

export interface IStockItemRowArgs {
  stockQuantityFixedIntervalInput?: StockQuantityFixedIntervalInput | null;
}

@Injectable({ providedIn: 'root' })
export class StockItemRowCollectionProvider
  implements
    IDataCollectionProvider<IStockItemRowObject, StockItemFilterInput, IStockItemRowArgs, number>
{
  constructor(private stockItemService: StockItemService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
    args,
  }: Readonly<IDataQuery<StockItemFilterInput, IStockItemRowArgs>>): Observable<
    IDataCollection<IStockItemRowObject>
  > {
    return this.stockItemService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
      stockQuantityFixedIntervalInput: args?.stockQuantityFixedIntervalInput,
    });
  }

  toId(model: IStockItemRowObject): number {
    return model.id;
  }

  toString(model: IStockItemRowObject): string {
    return `${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class StockItemDetailProvider implements IDataProvider<IStockItemDetailObject, number> {
  constructor(private stockItemService: StockItemService) {}

  fetchSingle(id: number): Observable<IStockItemDetailObject> {
    return this.stockItemService.fetchDetail(id);
  }

  toId(model: IStockItemDetailObject): number {
    return model.id;
  }

  toString(model: IStockItemDetailObject): string {
    return `${model.id}`;
  }
}
