import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateWarehouseInput, UpdateWarehouseInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateGQL,
  UpdateGQL,
  WarehouseDetailGQL,
  WarehouseItemGQL,
  WarehouseItemsGQL,
  WarehouseItemsQueryVariables,
  WarehouseRowGQL,
  WarehouseRowsGQL,
  WarehouseRowsQueryVariables,
} from './gql/warehouse.gql.generated';
import {
  IWarehouseDetailObject,
  IWarehouseItemObject,
  IWarehouseRowObject,
} from './types/warehouse';

@Injectable()
export class WarehouseService {
  constructor(
    private rowGQL: WarehouseRowGQL,
    private warehouseRowsGQL: WarehouseRowsGQL,
    private warehouseDetailGQL: WarehouseDetailGQL,
    private warehouseItemsGQL: WarehouseItemsGQL,
    private warehouseItemGQL: WarehouseItemGQL,
    private updateGQL: UpdateGQL,
    private createGQL: CreateGQL,
  ) {}

  fetchSearch(
    searchText: string,
    query?: WarehouseItemsQueryVariables,
  ): Observable<{ items: IWarehouseItemObject[]; totalItemsCount: number }> {
    return this.warehouseItemsGQL
      .fetch({
        filter: { search: searchText, ...query?.filter },
        page: query?.page,
        sort: query?.sort,
      })
      .pipe(map((r) => r.data));
  }

  search(
    searchText?: string | '', //: Observable<{ items: IWarehouseItemObject[]; totalItemsCount: number }>
  ): Observable<IWarehouseItemObject[]> {
    const query: WarehouseRowsQueryVariables = {
      filter: { search: searchText },
      page: {},
      sort: { column: 'name' },
    };
    return this.fetchRows(query).pipe(map((r) => r.items));
  }

  fetchItem(id: string | number): Observable<IWarehouseItemObject> {
    return this.warehouseItemGQL.fetch({ id: Number(id) }).pipe(map((r) => r.data.warehouse));
  }

  fetchItems(query: WarehouseItemsQueryVariables) {
    return this.warehouseItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: string | number): Observable<IWarehouseRowObject> {
    return this.rowGQL.fetch({ id: Number(id) }).pipe(map((r) => r.data.warehouse));
  }

  fetchRows(
    query: WarehouseRowsQueryVariables,
  ): Observable<{ items: IWarehouseRowObject[]; totalItemsCount: number }> {
    return this.warehouseRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchDetail(id: number): Observable<IWarehouseDetailObject> {
    return this.warehouseDetailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.warehouse));
  }

  fetchAll(): Observable<IWarehouseItemObject[]> {
    return this.warehouseItemsGQL.fetch({}).pipe(mapFetchResultData((r) => r.items));
  }

  update(input: UpdateWarehouseInput) {
    return this.updateGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.updateWarehouse));
  }

  create(input: CreateWarehouseInput) {
    return this.createGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.createWarehouse));
  }
}
