import { ITaxonItemObject } from '@x/ecommerce/domain-client';

export class TaxonNode {
  constructor(
    public id: number,
    public taxon: ITaxonItemObject,
    public children: Array<TaxonNode> = [],
    public parent?: TaxonNode,
  ) {}
}
