import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaymentProvidersGQL } from './gql/payment-provider.gql.generated';

@Injectable()
export class PaymentProviderService {
  constructor(private readonly paymentProvidersGQL: PaymentProvidersGQL) {}

  getAllProviders() {
    return this.paymentProvidersGQL.fetch().pipe(map((result) => result.data.paymentProviders));
  }
}
