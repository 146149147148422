import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'x-boolean-filter-field',
  templateUrl: './boolean-filter-field.component.html',
  styleUrls: ['./boolean-filter-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanFilterFieldComponent implements ControlValueAccessor {
  @Input()
  label: string = 'Enabled';

  @Input()
  nullable: boolean = true;

  @Input()
  icon: IconProp;

  @Input()
  trueLabel: string = 'Yes';

  @Input()
  falseLabel: string = 'No';

  @Input()
  nullLabel: string = 'Any';

  control = new UntypedFormControl();

  constructor(
    @Optional()
    ngControl?: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  onTouch: () => void = () => {};

  writeValue(obj: any): void {
    this.control.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
}
