import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
// import { SubscriptionVariantSmsType } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateSubscriptionRunGQL,
  GenerateSubscriptionEntriesGQL,
  ProcessSubscriptionEntriesGQL,
  ProcessSubscriptionEntriesPaymentGQL,
  QueueSubscriptionRunProcessEntriesGQL,
  QueueSubscriptionRunProcessPaymentsGQL,
  // QueueSubscriptionRunSendSmsesGQL,
  SubscriptionRunDetailGQL,
  SubscriptionRunItemGQL,
  SubscriptionRunRowGQL,
  SubscriptionRunRowsGQL,
  SubscriptionRunRowsQueryVariables,
  UnlockSubscriptionRunProcessingGQL,
  UpdateSubscriptionRunGQL,
} from './gql/subscription-run.gql.generated';
import {
  ISubscriptionRunDetailObject,
  ISubscriptionRunItemObject,
  ISubscriptionRunRowObject,
} from './types/subscription-run';

@Injectable()
export class SubscriptionRunService {
  constructor(
    private readonly rowGQL: SubscriptionRunRowGQL,
    private readonly rowsGQL: SubscriptionRunRowsGQL,
    private readonly itemGQL: SubscriptionRunItemGQL,
    private readonly detailGQL: SubscriptionRunDetailGQL,
    private readonly queueSubscriptionRunProcessEntriesGQL: QueueSubscriptionRunProcessEntriesGQL,
    private readonly queueSubscriptionRunProcessPaymentsGQL: QueueSubscriptionRunProcessPaymentsGQL,
    // private readonly queueSubscriptionRunSendSmsesGQL: QueueSubscriptionRunSendSmsesGQL,
    private readonly createSubscriptionRunGQL: CreateSubscriptionRunGQL,
    private readonly updateSubscriptionRunGQL: UpdateSubscriptionRunGQL,
    private readonly unlockSubscriptionRunProcessingGQL: UnlockSubscriptionRunProcessingGQL,
    private readonly generateSubscriptionEntriesGQL: GenerateSubscriptionEntriesGQL,
    private readonly processSubscriptionEntriesGQL: ProcessSubscriptionEntriesGQL,
    private readonly processSubscriptionEntriesPaymentGQL: ProcessSubscriptionEntriesPaymentGQL,
  ) {}

  fetchRow(id: number): Observable<ISubscriptionRunRowObject> {
    return this.rowGQL
      .fetch({ id })
      .pipe(map((r) => r.data.subscriptionRun as ISubscriptionRunRowObject));
  }

  fetchRows(
    query: SubscriptionRunRowsQueryVariables,
  ): Observable<{ items: ISubscriptionRunRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<ISubscriptionRunItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionRun));
  }

  fetchDetail(id: number): Observable<ISubscriptionRunDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionRun));
  }

  createRun(variantId: number, input: any) {
    return this.createSubscriptionRunGQL
      .mutate({ variantId, input })
      .pipe(mapFetchResultData((d) => d.createSubscriptionRun));
  }

  updateRun(runId: number, input: any) {
    return this.updateSubscriptionRunGQL
      .mutate({ id: runId, input })
      .pipe(mapFetchResultData((d) => d.updateSubscriptionRun));
  }

  queueProcessEntries(runId: number) {
    return this.queueSubscriptionRunProcessEntriesGQL
      .mutate({ runId })
      .pipe(mapFetchResultData((d) => d.queueSubscriptionRunProcessEntries));
  }

  queueProcessPayments(runId: number) {
    return this.queueSubscriptionRunProcessPaymentsGQL
      .mutate({ runId })
      .pipe(mapFetchResultData((d) => d.queueSubscriptionRunProcessPayments));
  }

  // queueSendSmses(runId: number, smsType: SubscriptionVariantSmsType) {
  //   return this.queueSubscriptionRunSendSmsesGQL
  //     .mutate({ runId, smsType })
  //     .pipe(mapFetchResultData((d) => d.queueSubscriptionRunSendSmses));
  // }

  unlockProcessing(runId: number) {
    return this.unlockSubscriptionRunProcessingGQL
      .mutate({ runId })
      .pipe(mapFetchResultData((d) => d.unlockSubscriptionRunProcessing));
  }

  generateEntries(runId: number) {
    return this.generateSubscriptionEntriesGQL
      .mutate({ runId })
      .pipe(mapFetchResultData((d) => d.generateSubscriptionEntries));
  }

  processEntries(entryIds: number[]) {
    return this.processSubscriptionEntriesGQL
      .mutate({ entryIds })
      .pipe(mapFetchResultData((d) => d.processSubscriptionEntries));
  }

  processEntriesPayment(entryIds: number[]) {
    return this.processSubscriptionEntriesPaymentGQL
      .mutate({ entryIds })
      .pipe(mapFetchResultData((d) => d.processSubscriptionEntriesPayment));
  }
}
