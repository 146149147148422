import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { IUserDetailObject, UserService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserIdTransformer implements ModelTransformer<IUserDetailObject, number> {
  private _cache = new Map<number, IUserDetailObject>();

  constructor(private userService: UserService) {}

  modelToValue(model: IUserDetailObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<IUserDetailObject> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as IUserDetailObject);
    }

    return firstValueFrom(
      this.userService.fetchDetail(id).pipe(tap((r) => this._cache.set(id, r))),
    );
  }
}
