import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AuthRoleRowFragment = { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string, color: string }> };

export type AuthRoleDetailFragment = { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> };

export type AuthRoleItemFragment = { __typename: 'AuthRoleObject', id: number, name: string };

export type AuthPermissionFragment = { __typename: 'PermissionObject', permission: string, description?: string | null | undefined };

export type AuthRoleItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AuthRoleItemQuery = { __typename: 'Query', authRole: { __typename: 'AuthRoleObject', id: number, name: string } };

export type AuthRoleItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AuthRoleFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type AuthRoleItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AuthRoleObject', id: number, name: string }> };

export type AuthRoleRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AuthRoleRowQuery = { __typename: 'Query', authRole: { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string, color: string }> } };

export type AuthRoleRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AuthRoleFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type AuthRoleRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string, color: string }> }> };

export type AuthRoleSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type AuthRoleSearchQuery = { __typename: 'Query', authRoles: Array<{ __typename: 'AuthRoleObject', id: number, name: string }> };

export type AuthRoleDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AuthRoleDetailQuery = { __typename: 'Query', authRole: { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> } };

export type AuthPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthPermissionsQuery = { __typename: 'Query', authPermissions: Array<{ __typename: 'PermissionObject', permission: string, description?: string | null | undefined }> };

export type CreateAuthRoleMutationVariables = Types.Exact<{
  input: Types.CreateAuthRoleInput;
}>;


export type CreateAuthRoleMutation = { __typename: 'Mutation', createAuthRole: { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> } };

export type UpdateAuthRoleMutationVariables = Types.Exact<{
  input: Types.UpdateAuthRoleInput;
}>;


export type UpdateAuthRoleMutation = { __typename: 'Mutation', updateAuthRole: { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> } };

export type ArchiveAuthRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ArchiveAuthRoleMutation = { __typename: 'Mutation', archiveAuthRole: { __typename: 'AuthRoleObject', id: number, name: string, code: string, permissions: Array<string>, createdAt: any, updatedAt: any, allChannels: boolean, channels: Array<{ __typename: 'ChannelObject', id: number }> } };

export const AuthRoleRowFragmentDoc = gql`
    fragment AuthRoleRow on AuthRoleObject {
  id
  name
  code
  permissions
  createdAt
  updatedAt
  channels {
    id
    name
    code
    color
  }
  allChannels
}
    `;
export const AuthRoleDetailFragmentDoc = gql`
    fragment AuthRoleDetail on AuthRoleObject {
  id
  name
  code
  permissions
  createdAt
  updatedAt
  channels {
    id
  }
  allChannels
}
    `;
export const AuthRoleItemFragmentDoc = gql`
    fragment AuthRoleItem on AuthRoleObject {
  id
  name
}
    `;
export const AuthPermissionFragmentDoc = gql`
    fragment AuthPermission on PermissionObject {
  permission
  description
}
    `;
export const AuthRoleItemDocument = gql`
    query AuthRoleItem($id: Int!) {
  authRole(id: $id) {
    ...AuthRoleItem
  }
}
    ${AuthRoleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRoleItemGQL extends Apollo.Query<AuthRoleItemQuery, AuthRoleItemQueryVariables> {
    document = AuthRoleItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRoleItemsDocument = gql`
    query AuthRoleItems($filter: AuthRoleFilterInput, $page: PageInput, $sort: SortInput) {
  items: authRoles(page: $page, filter: $filter, sort: $sort) {
    ...AuthRoleItem
  }
  totalItemsCount: authRolesCount(filter: $filter)
}
    ${AuthRoleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRoleItemsGQL extends Apollo.Query<AuthRoleItemsQuery, AuthRoleItemsQueryVariables> {
    document = AuthRoleItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRoleRowDocument = gql`
    query AuthRoleRow($id: Int!) {
  authRole(id: $id) {
    ...AuthRoleRow
  }
}
    ${AuthRoleRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRoleRowGQL extends Apollo.Query<AuthRoleRowQuery, AuthRoleRowQueryVariables> {
    document = AuthRoleRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRoleRowsDocument = gql`
    query AuthRoleRows($filter: AuthRoleFilterInput, $page: PageInput, $sort: SortInput) {
  items: authRoles(page: $page, filter: $filter, sort: $sort) {
    ...AuthRoleRow
  }
  totalItemsCount: authRolesCount(filter: $filter)
}
    ${AuthRoleRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRoleRowsGQL extends Apollo.Query<AuthRoleRowsQuery, AuthRoleRowsQueryVariables> {
    document = AuthRoleRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRoleSearchDocument = gql`
    query AuthRoleSearch($searchText: String, $results: Int!) {
  authRoles(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    ...AuthRoleItem
  }
}
    ${AuthRoleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRoleSearchGQL extends Apollo.Query<AuthRoleSearchQuery, AuthRoleSearchQueryVariables> {
    document = AuthRoleSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRoleDetailDocument = gql`
    query AuthRoleDetail($id: Int!) {
  authRole(id: $id) {
    ...AuthRoleDetail
  }
}
    ${AuthRoleDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRoleDetailGQL extends Apollo.Query<AuthRoleDetailQuery, AuthRoleDetailQueryVariables> {
    document = AuthRoleDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthPermissionsDocument = gql`
    query AuthPermissions {
  authPermissions {
    ...AuthPermission
  }
}
    ${AuthPermissionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthPermissionsGQL extends Apollo.Query<AuthPermissionsQuery, AuthPermissionsQueryVariables> {
    document = AuthPermissionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAuthRoleDocument = gql`
    mutation CreateAuthRole($input: CreateAuthRoleInput!) {
  createAuthRole(input: $input) {
    ...AuthRoleDetail
  }
}
    ${AuthRoleDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAuthRoleGQL extends Apollo.Mutation<CreateAuthRoleMutation, CreateAuthRoleMutationVariables> {
    document = CreateAuthRoleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAuthRoleDocument = gql`
    mutation UpdateAuthRole($input: UpdateAuthRoleInput!) {
  updateAuthRole(input: $input) {
    ...AuthRoleDetail
  }
}
    ${AuthRoleDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAuthRoleGQL extends Apollo.Mutation<UpdateAuthRoleMutation, UpdateAuthRoleMutationVariables> {
    document = UpdateAuthRoleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveAuthRoleDocument = gql`
    mutation ArchiveAuthRole($id: Int!) {
  archiveAuthRole(id: $id) {
    ...AuthRoleDetail
  }
}
    ${AuthRoleDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveAuthRoleGQL extends Apollo.Mutation<ArchiveAuthRoleMutation, ArchiveAuthRoleMutationVariables> {
    document = ArchiveAuthRoleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }