import { Injectable } from '@angular/core';
import { GridDatasource, GridDatasourceData, GridDatasourceQuery } from '@x/dashboard/grid';
import { ParcelOptionRowObject, ParcelOptionService } from '@x/ecommerce/domain-client';
import { ParcelOptionFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

export interface ParcelOptionQueryArgs {}

@Injectable()
export class ParcelOptionIndexDatasource extends GridDatasource<
  ParcelOptionRowObject,
  ParcelOptionFilterInput,
  ParcelOptionQueryArgs
> {
  constructor(private ParcelOptionsService: ParcelOptionService) {
    super({
      defaultDisplayColumns: ['name', 'dimensions', 'weight', 'timestamps'],
      defaultFilter: {
        search: null,
        createdAt: null,
        updatedAt: null,
      },
      defaultSort: {
        column: 'name',
        order: 'asc',
      },
      defaultArgs: {},
      pageSizeOptions: [20, 100, 1000],
    });
  }

  fetch({
    filter,
    sort,
    page,
    args,
  }: Readonly<GridDatasourceQuery<ParcelOptionFilterInput, ParcelOptionQueryArgs>>): Observable<
    GridDatasourceData<ParcelOptionRowObject>
  > {
    return this.ParcelOptionsService.fetchRows({ ...args, filter, page, sort });
  }
}
