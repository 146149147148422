import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrderIncidentCategoryDetailFragment = { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> };

export type OrderIncidentCategoryItemFragment = { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> };

export type OrderIncidentSubcategoryItemFragment = { __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined };

export type OrderIncidentCategoryDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderIncidentCategoryDetailQuery = { __typename: 'Query', orderIncidentCategory: { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> } };

export type OrderIncidentCategoryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderIncidentCategoryItemQuery = { __typename: 'Query', orderIncidentCategory: { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> } };

export type OrderIncidentCategoryItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderIncidentCategoryFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  page?: Types.InputMaybe<Types.PageInput>;
}>;


export type OrderIncidentCategoryItemsQuery = { __typename: 'Query', orderIncidentCategories: Array<{ __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> }> };

export type OrderIncidentCategoryRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderIncidentCategoryRowQuery = { __typename: 'Query', orderIncidentCategory: { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> } };

export type OrderIncidentCategoryRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderIncidentCategoryFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  page?: Types.InputMaybe<Types.PageInput>;
}>;


export type OrderIncidentCategoryRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> }> };

export type CreateOrderIncidentCategoryMutationVariables = Types.Exact<{
  input: Types.CreateOrderIncidentCategoryInput;
}>;


export type CreateOrderIncidentCategoryMutation = { __typename: 'Mutation', createOrderIncidentCategory: { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> } };

export type UpdateOrderIncidentCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateOrderIncidentCategoryInput;
}>;


export type UpdateOrderIncidentCategoryMutation = { __typename: 'Mutation', updateOrderIncidentCategory: { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> } };

export type PositionOrderIncidentCategoryMutationVariables = Types.Exact<{
  input: Types.PositionOrderIncidentCategoryInput;
}>;


export type PositionOrderIncidentCategoryMutation = { __typename: 'Mutation', positionOrderIncidentCategory: { __typename: 'OrderIncidentCategoryObject', id: number, name: string, position: number, description?: string | null | undefined, instructions?: string | null | undefined, requireApproval: boolean, allowedOrderStates: Array<Types.OrderState>, itemActionRequired: Array<Types.OrderIncidentItemAction>, itemActionAllowed: Array<Types.OrderIncidentItemAction>, otherItemActionDefault: Array<Types.OrderIncidentItemAction>, createdAt: any, updatedAt: any, config?: any | null | undefined, steps: Array<string>, subcategories: Array<{ __typename: 'OrderIncidentSubcategoryObject', id: number, name: string, instructions?: string | null | undefined }> } };

export const OrderIncidentSubcategoryItemFragmentDoc = gql`
    fragment OrderIncidentSubcategoryItem on OrderIncidentSubcategoryObject {
  id
  name
  instructions
}
    `;
export const OrderIncidentCategoryItemFragmentDoc = gql`
    fragment OrderIncidentCategoryItem on OrderIncidentCategoryObject {
  id
  name
  position
  description
  instructions
  requireApproval
  allowedOrderStates
  itemActionRequired
  itemActionAllowed
  otherItemActionDefault
  createdAt
  updatedAt
  subcategories {
    ...OrderIncidentSubcategoryItem
  }
  config
  steps
}
    ${OrderIncidentSubcategoryItemFragmentDoc}`;
export const OrderIncidentCategoryDetailFragmentDoc = gql`
    fragment OrderIncidentCategoryDetail on OrderIncidentCategoryObject {
  ...OrderIncidentCategoryItem
}
    ${OrderIncidentCategoryItemFragmentDoc}`;
export const OrderIncidentCategoryDetailDocument = gql`
    query OrderIncidentCategoryDetail($id: Int!) {
  orderIncidentCategory(id: $id) {
    ...OrderIncidentCategoryDetail
  }
}
    ${OrderIncidentCategoryDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderIncidentCategoryDetailGQL extends Apollo.Query<OrderIncidentCategoryDetailQuery, OrderIncidentCategoryDetailQueryVariables> {
    document = OrderIncidentCategoryDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderIncidentCategoryItemDocument = gql`
    query OrderIncidentCategoryItem($id: Int!) {
  orderIncidentCategory(id: $id) {
    ...OrderIncidentCategoryItem
  }
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderIncidentCategoryItemGQL extends Apollo.Query<OrderIncidentCategoryItemQuery, OrderIncidentCategoryItemQueryVariables> {
    document = OrderIncidentCategoryItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderIncidentCategoryItemsDocument = gql`
    query OrderIncidentCategoryItems($filter: OrderIncidentCategoryFilterInput, $sort: SortInput, $page: PageInput) {
  orderIncidentCategories(filter: $filter, sort: $sort, page: $page) {
    ...OrderIncidentCategoryItem
  }
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderIncidentCategoryItemsGQL extends Apollo.Query<OrderIncidentCategoryItemsQuery, OrderIncidentCategoryItemsQueryVariables> {
    document = OrderIncidentCategoryItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderIncidentCategoryRowDocument = gql`
    query OrderIncidentCategoryRow($id: Int!) {
  orderIncidentCategory(id: $id) {
    ...OrderIncidentCategoryItem
  }
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderIncidentCategoryRowGQL extends Apollo.Query<OrderIncidentCategoryRowQuery, OrderIncidentCategoryRowQueryVariables> {
    document = OrderIncidentCategoryRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderIncidentCategoryRowsDocument = gql`
    query OrderIncidentCategoryRows($filter: OrderIncidentCategoryFilterInput, $sort: SortInput, $page: PageInput) {
  items: orderIncidentCategories(filter: $filter, sort: $sort, page: $page) {
    ...OrderIncidentCategoryItem
  }
  totalItemsCount: orderIncidentCategoriesCount(filter: $filter)
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderIncidentCategoryRowsGQL extends Apollo.Query<OrderIncidentCategoryRowsQuery, OrderIncidentCategoryRowsQueryVariables> {
    document = OrderIncidentCategoryRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrderIncidentCategoryDocument = gql`
    mutation CreateOrderIncidentCategory($input: CreateOrderIncidentCategoryInput!) {
  createOrderIncidentCategory(input: $input) {
    ...OrderIncidentCategoryItem
  }
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderIncidentCategoryGQL extends Apollo.Mutation<CreateOrderIncidentCategoryMutation, CreateOrderIncidentCategoryMutationVariables> {
    document = CreateOrderIncidentCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrderIncidentCategoryDocument = gql`
    mutation UpdateOrderIncidentCategory($input: UpdateOrderIncidentCategoryInput!) {
  updateOrderIncidentCategory(input: $input) {
    ...OrderIncidentCategoryItem
  }
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderIncidentCategoryGQL extends Apollo.Mutation<UpdateOrderIncidentCategoryMutation, UpdateOrderIncidentCategoryMutationVariables> {
    document = UpdateOrderIncidentCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PositionOrderIncidentCategoryDocument = gql`
    mutation PositionOrderIncidentCategory($input: PositionOrderIncidentCategoryInput!) {
  positionOrderIncidentCategory(input: $input) {
    ...OrderIncidentCategoryItem
  }
}
    ${OrderIncidentCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PositionOrderIncidentCategoryGQL extends Apollo.Mutation<PositionOrderIncidentCategoryMutation, PositionOrderIncidentCategoryMutationVariables> {
    document = PositionOrderIncidentCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }