import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { WarehouseService } from '@x/ecommerce/domain-client';
import { WarehouseObject } from '@x/schemas/ecommerce';
import { tap } from 'rxjs/operators';

@Injectable()
export class WarehouseIdTransformer implements ModelTransformer<WarehouseObject, number> {
  private _cache = new Map<number, WarehouseObject>();

  constructor(private service: WarehouseService) {}

  modelToValue(model: WarehouseObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<any> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as WarehouseObject);
    }

    return this.service
      .fetchItem(id)
      .pipe(tap((r) => this._cache.set(id, r as WarehouseObject)))
      .toPromise();
  }
}
