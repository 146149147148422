import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { WalletRowObject, WalletService } from '@x/ecommerce/domain-client';
import { WalletFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WalletRowCollectionProvider
  implements
    IDataCollectionProvider<WalletRowObject, WalletFilterInput>,
    IDataProvider<WalletRowObject>
{
  constructor(private walletService: WalletService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<WalletRowObject>> {
    return this.walletService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<WalletRowObject> {
    return this.walletService.fetchById(Number(id));
  }

  toId(model: WalletRowObject): number {
    return model.id;
  }

  toString(model: WalletRowObject): string {
    return `Wallet ID: ${model.id}`;
  }
}
