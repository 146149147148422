import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateGeoRegionDefinitionInput,
  CreateGeoRegionInput,
  GeoRegionDefinitionFilterInput,
  GeoRegionFilterInput,
  UpdateGeoRegionDefinitionInput,
  UpdateGeoRegionInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateGeoRegionDefinitionGQL,
  CreateGeoRegionGQL,
  DeleteGeoRegionDefinitionGQL,
  GeoRegionDefinitionsGQL,
  GeoRegionGQL,
  GeoRegionItemGQL,
  GeoRegionItemsGQL,
  GeoRegionItemsQueryVariables,
  GeoRegionsGQL,
  UpdateGeoRegionDefinitionGQL,
  UpdateGeoRegionGQL,
} from './gql/georegion.gql.generated';
import {
  GeoRegionDefinitionObject,
  GeoRegionObject,
  IGeoRegionItemObject,
} from './types/geo-region';

@Injectable()
export class GeoRegionService {
  constructor(
    private geoRegionItemGQL: GeoRegionItemGQL,
    private geoRegionItemsGQL: GeoRegionItemsGQL,
    private geoRegionGQL: GeoRegionGQL,
    private geoRegionsGQL: GeoRegionsGQL,
    private createGeoRegionGQL: CreateGeoRegionGQL,
    private updateGeoRegionGQL: UpdateGeoRegionGQL,
    private geoRegionDefinitionsGQL: GeoRegionDefinitionsGQL,
    private createGeoRegionDefinitionGQL: CreateGeoRegionDefinitionGQL,
    private updateGeoRegionDefinitionGQL: UpdateGeoRegionDefinitionGQL,
    private deleteGeoRegionDefinitionGQL: DeleteGeoRegionDefinitionGQL,
  ) {}

  fetchItems(
    query: GeoRegionItemsQueryVariables,
  ): Observable<{ items: IGeoRegionItemObject[]; totalItemsCount: number }> {
    return this.geoRegionItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<IGeoRegionItemObject> {
    return this.geoRegionItemGQL.fetch({ id }).pipe(map((r) => r.data.geoRegion));
  }

  fetchDetails(filter: GeoRegionFilterInput = {}): Observable<GeoRegionObject[]> {
    return this.geoRegionsGQL.fetch({ filter }).pipe(map((result) => result.data.geoRegions));
  }

  fetchDetail(id: number): Observable<GeoRegionObject> {
    return this.geoRegionGQL.fetch({ id }).pipe(map((result) => result.data.geoRegion));
  }

  createRegion(data: CreateGeoRegionInput) {
    return this.createGeoRegionGQL
      .mutate({ data })
      .pipe(map((result) => result.data?.createGeoRegion));
  }

  updateRegion(data: UpdateGeoRegionInput) {
    return this.updateGeoRegionGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateGeoRegion));
  }

  definitions(
    filter: GeoRegionDefinitionFilterInput = {},
  ): Observable<GeoRegionDefinitionObject[]> {
    return this.geoRegionDefinitionsGQL
      .fetch({ filter })
      .pipe(map((result) => result.data.geoRegionDefinitions));
  }

  createDefinition(data: CreateGeoRegionDefinitionInput) {
    return this.createGeoRegionDefinitionGQL
      .mutate({ data })
      .pipe(map((result) => result.data?.createGeoRegionDefinition));
  }

  updateDefinition(data: UpdateGeoRegionDefinitionInput) {
    return this.updateGeoRegionDefinitionGQL
      .mutate({ data })
      .pipe(map((result) => result.data?.updateGeoRegionDefinition));
  }

  deleteDefinition(id: number) {
    return this.deleteGeoRegionDefinitionGQL
      .mutate({ id })
      .pipe(map((result) => result.data?.deleteGeoRegionDefinition));
  }
}
