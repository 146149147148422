import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DateTimeModule } from '@x/common/datetime';
import { DragModule } from '@x/common/drag';
import { IconModule } from '@x/common/icon';
import { LocaleModule } from '@x/common/locale';
import { DashboardCoreModule } from '../core';
import { PillGroupColumnComponent } from './columns/pill-group-column/pill-group-column.component';
import { TimestampColumnComponent } from './columns/timestamp-column.component';
import { DataTableControlsComponent } from './data-table-controls/data-table-controls.component';
import { DataTableStatesComponent } from './data-table-states/data-table-states.component';
import { DataActionDefinitionDirective } from './data-table/data-action.directive';
import { DataBulkActionDefinitionDirective } from './data-table/data-bulk-action.directive';
import { DataButtonComponent } from './data-table/data-button.component';
import { DataColumnDefinitionDirective } from './data-table/data-column.directive';
import { DataLinkComponent } from './data-table/data-link.component';
import { DataTableComponent } from './data-table/data-table.component';
import { GridDatasourceFactory } from './datasource/grid-datasource-factory';
import { BooleanFilterFieldComponent } from './filters/boolean-filter-field/boolean-filter-field.component';
import { DateFilterFieldComponent } from './filters/date-filter-field/date-filter-field.component';
import { DateRangeFilterFieldComponent } from './filters/date-range-filter-field/date-range-filter-field.component';
import { MultipleSelectFilterFieldComponent } from './filters/multiple-select-filter-field/multiple-select-filter-field.component';
import { GridControlsComponent } from './grid-controls/grid-controls.component';
import { GridBulkActionDefinitionDirective } from './grid-table/grid-bulk-action-definition.directive';
import { GridBulkActionDirective } from './grid-table/grid-bulk-action.directive';
import { GridColumnDefinitionDirective } from './grid-table/grid-column-definition.directive';
import { GridRowActionDefinitionDirective } from './grid-table/grid-row-action-definition.directive';
import { GridRowActionDirective } from './grid-table/grid-row-action.directive';
import { GridTableComponent } from './grid-table/grid-table.component';
import { GRID_LANGUAGE } from './grid.language';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    IconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatListModule,
    RouterModule,
    LocaleModule.forChild(GRID_LANGUAGE),
    MatInputModule,
    DragModule,
    DashboardCoreModule,
    OverlayModule,
    DateTimeModule,
  ],
  declarations: [
    GridTableComponent,
    GridColumnDefinitionDirective,
    GridRowActionDefinitionDirective,
    GridBulkActionDefinitionDirective,
    GridControlsComponent,
    TimestampColumnComponent,
    BooleanFilterFieldComponent,
    DateRangeFilterFieldComponent,
    DateFilterFieldComponent,
    GridRowActionDirective,
    GridBulkActionDirective,
    MultipleSelectFilterFieldComponent,
    PillGroupColumnComponent,
    DataLinkComponent,
    DataButtonComponent,
    DataTableComponent,
    DataTableStatesComponent,
    DataTableControlsComponent,
    DataActionDefinitionDirective,
    DataBulkActionDefinitionDirective,
    DataColumnDefinitionDirective,
  ],
  exports: [
    GridTableComponent,
    GridColumnDefinitionDirective,
    GridRowActionDefinitionDirective,
    GridBulkActionDefinitionDirective,
    GridRowActionDirective,
    GridBulkActionDirective,
    GridControlsComponent,
    TimestampColumnComponent,
    BooleanFilterFieldComponent,
    DateRangeFilterFieldComponent,
    DateFilterFieldComponent,
    MultipleSelectFilterFieldComponent,
    PillGroupColumnComponent,
    DataTableComponent,
    DataTableStatesComponent,
    DataTableControlsComponent,
    DataActionDefinitionDirective,
    DataColumnDefinitionDirective,
    DataBulkActionDefinitionDirective,
    DataLinkComponent,
    DataButtonComponent,
  ],
  providers: [GridDatasourceFactory],
})
export class DashboardGridModule {}
