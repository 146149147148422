import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  NotificationLogChannelItemsGQL,
  NotificationLogChannelItemsQueryVariables,
  NotificationLogRowsGQL,
  NotificationLogRowsQueryVariables,
  NotificationLogTypeItemsGQL,
  NotificationLogTypeItemsQueryVariables,
} from './gql/notification-log.gql.generated';
import {
  INotificationChannelItemObject,
  INotificationLogRowObject,
} from './types/notification-log';

@Injectable()
export class NotificationLogService {
  constructor(
    private readonly rowsGQL: NotificationLogRowsGQL,
    private readonly channelItemsGQL: NotificationLogChannelItemsGQL,
    private readonly typeItemsGQL: NotificationLogTypeItemsGQL,
  ) {}

  fetchRows(
    query: NotificationLogRowsQueryVariables,
  ): Observable<{ items: INotificationLogRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchChannelItems(
    query: NotificationLogChannelItemsQueryVariables,
  ): Observable<{ items: INotificationChannelItemObject[]; totalItemsCount: number }> {
    return this.channelItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchTypeItems(query: NotificationLogTypeItemsQueryVariables) {
    return this.typeItemsGQL.fetch(query).pipe(map((r) => r.data));
  }
}
