import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MembershipDetailFragment = { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, activationUrl?: string | null | undefined, archivedAt?: any | null | undefined, translations: Array<{ __typename: 'MembershipTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, renewalMessage?: string | null | undefined, slug: string, termsUrl?: string | null | undefined }>, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string }>, taxons: Array<{ __typename: 'TaxonObject', id: number, name: string, code: string }> };

export type MembershipRowFragment = { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined };

export type MembershipItemFragment = { __typename: 'MembershipObject', id: number, name: string, code: string };

export type MembershipArchiveItemFragment = { __typename: 'MembershipObject', id: number, name: string, archivedAt?: any | null | undefined };

export type MembershipItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MembershipItemQuery = { __typename: 'Query', membership: { __typename: 'MembershipObject', id: number, name: string, code: string } };

export type MembershipItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.MembershipFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type MembershipItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'MembershipObject', id: number, name: string, code: string }> };

export type MembershipRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MembershipRowQuery = { __typename: 'Query', membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } };

export type MembershipRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.MembershipFilterInput>;
}>;


export type MembershipRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined }> };

export type MembershipDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MembershipDetailQuery = { __typename: 'Query', membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, activationUrl?: string | null | undefined, archivedAt?: any | null | undefined, translations: Array<{ __typename: 'MembershipTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, renewalMessage?: string | null | undefined, slug: string, termsUrl?: string | null | undefined }>, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string }>, taxons: Array<{ __typename: 'TaxonObject', id: number, name: string, code: string }> } };

export type MembershipSearchQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.MembershipFilterInput>;
}>;


export type MembershipSearchQuery = { __typename: 'Query', memberships: Array<{ __typename: 'MembershipObject', id: number, name: string, code: string }> };

export type MembershipArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type MembershipArchiveMutation = { __typename: 'Mutation', archiveMembership: { __typename: 'MembershipObject', id: number, name: string, archivedAt?: any | null | undefined } };

export type MembershipUpdateMutationVariables = Types.Exact<{
  data: Types.UpdateMembershipInput;
}>;


export type MembershipUpdateMutation = { __typename: 'Mutation', updateMembership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, activationUrl?: string | null | undefined, archivedAt?: any | null | undefined, translations: Array<{ __typename: 'MembershipTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, renewalMessage?: string | null | undefined, slug: string, termsUrl?: string | null | undefined }>, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string }>, taxons: Array<{ __typename: 'TaxonObject', id: number, name: string, code: string }> } };

export type MembershipCreateMutationVariables = Types.Exact<{
  data: Types.CreateMembershipInput;
}>;


export type MembershipCreateMutation = { __typename: 'Mutation', createMembership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, activationUrl?: string | null | undefined, archivedAt?: any | null | undefined, translations: Array<{ __typename: 'MembershipTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, renewalMessage?: string | null | undefined, slug: string, termsUrl?: string | null | undefined }>, channels: Array<{ __typename: 'ChannelObject', id: number, name: string, code: string }>, taxons: Array<{ __typename: 'TaxonObject', id: number, name: string, code: string }> } };

export const MembershipDetailFragmentDoc = gql`
    fragment MembershipDetail on MembershipObject {
  id
  name
  code
  isPublic
  activationUrl
  archivedAt
  translations {
    id
    locale
    name
    description
    renewalMessage
    slug
    termsUrl
  }
  channels {
    id
    name
    code
  }
  taxons {
    id
    name
    code
  }
}
    `;
export const MembershipRowFragmentDoc = gql`
    fragment MembershipRow on MembershipObject {
  id
  name
  code
  isPublic
  createdAt
  updatedAt
  archivedAt
}
    `;
export const MembershipItemFragmentDoc = gql`
    fragment MembershipItem on MembershipObject {
  id
  name
  code
}
    `;
export const MembershipArchiveItemFragmentDoc = gql`
    fragment MembershipArchiveItem on MembershipObject {
  id
  name
  archivedAt
}
    `;
export const MembershipItemDocument = gql`
    query MembershipItem($id: Int!) {
  membership(id: $id) {
    ...MembershipItem
  }
}
    ${MembershipItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipItemGQL extends Apollo.Query<MembershipItemQuery, MembershipItemQueryVariables> {
    document = MembershipItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipItemsDocument = gql`
    query MembershipItems($page: PageInput, $filter: MembershipFilterInput, $sort: SortInput) {
  items: memberships(page: $page, filter: $filter, sort: $sort) {
    ...MembershipItem
  }
  totalItemsCount: membershipsCount(filter: $filter)
}
    ${MembershipItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipItemsGQL extends Apollo.Query<MembershipItemsQuery, MembershipItemsQueryVariables> {
    document = MembershipItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipRowDocument = gql`
    query MembershipRow($id: Int!) {
  membership(id: $id) {
    ...MembershipRow
  }
}
    ${MembershipRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipRowGQL extends Apollo.Query<MembershipRowQuery, MembershipRowQueryVariables> {
    document = MembershipRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipRowsDocument = gql`
    query MembershipRows($page: PageInput, $sort: SortInput, $filter: MembershipFilterInput) {
  items: memberships(page: $page, sort: $sort, filter: $filter) {
    ...MembershipRow
  }
  totalItemsCount: membershipsCount(filter: $filter)
}
    ${MembershipRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipRowsGQL extends Apollo.Query<MembershipRowsQuery, MembershipRowsQueryVariables> {
    document = MembershipRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipDetailDocument = gql`
    query MembershipDetail($id: Int!) {
  membership(id: $id) {
    ...MembershipDetail
  }
}
    ${MembershipDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipDetailGQL extends Apollo.Query<MembershipDetailQuery, MembershipDetailQueryVariables> {
    document = MembershipDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipSearchDocument = gql`
    query MembershipSearch($page: PageInput, $sort: SortInput, $filter: MembershipFilterInput) {
  memberships(page: $page, sort: $sort, filter: $filter) {
    ...MembershipItem
  }
}
    ${MembershipItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipSearchGQL extends Apollo.Query<MembershipSearchQuery, MembershipSearchQueryVariables> {
    document = MembershipSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipArchiveDocument = gql`
    mutation MembershipArchive($id: Int!) {
  archiveMembership(id: $id) {
    ...MembershipArchiveItem
  }
}
    ${MembershipArchiveItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipArchiveGQL extends Apollo.Mutation<MembershipArchiveMutation, MembershipArchiveMutationVariables> {
    document = MembershipArchiveDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipUpdateDocument = gql`
    mutation MembershipUpdate($data: UpdateMembershipInput!) {
  updateMembership(data: $data) {
    ...MembershipDetail
  }
}
    ${MembershipDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipUpdateGQL extends Apollo.Mutation<MembershipUpdateMutation, MembershipUpdateMutationVariables> {
    document = MembershipUpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembershipCreateDocument = gql`
    mutation MembershipCreate($data: CreateMembershipInput!) {
  createMembership(data: $data) {
    ...MembershipDetail
  }
}
    ${MembershipDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MembershipCreateGQL extends Apollo.Mutation<MembershipCreateMutation, MembershipCreateMutationVariables> {
    document = MembershipCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }