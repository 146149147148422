import { Injectable } from '@angular/core';
import { SubscriptionVariantProductQuantityInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SubscriptionVariantProductQuantityCreateGQL,
  SubscriptionVariantProductQuantityDetailGQL,
  SubscriptionVariantProductQuantityItemGQL,
  SubscriptionVariantProductQuantityRowsGQL,
  SubscriptionVariantProductQuantityRowsQueryVariables,
  SubscriptionVariantProductQuantitySearchGQL,
  SubscriptionVariantProductQuantityUpdateGQL,
} from './gql/subscription-variant-product-quantity.gql.generated';
import {
  ISubscriptionVariantProductQuantityDetailObject,
  ISubscriptionVariantProductQuantityItemObject,
  ISubscriptionVariantProductQuantityRowObject,
} from './types/subscription-variant-product-quantity';

@Injectable()
export class SubscriptionVariantProductQuantityService {
  constructor(
    private readonly rowsGQL: SubscriptionVariantProductQuantityRowsGQL,
    private readonly itemGQL: SubscriptionVariantProductQuantityItemGQL,
    private readonly detailGQL: SubscriptionVariantProductQuantityDetailGQL,
    private readonly searchGQL: SubscriptionVariantProductQuantitySearchGQL,
    private readonly updateGQL: SubscriptionVariantProductQuantityUpdateGQL,
    private readonly createGQL: SubscriptionVariantProductQuantityCreateGQL,
  ) {}

  fetchRows(query: SubscriptionVariantProductQuantityRowsQueryVariables): Observable<{
    items: ISubscriptionVariantProductQuantityRowObject[];
    totalItemsCount: number;
  }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<ISubscriptionVariantProductQuantityItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionVariantProductQuantity));
  }

  fetchDetail(id: number): Observable<ISubscriptionVariantProductQuantityDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionVariantProductQuantity));
  }

  fetchItemSearch(
    search?: string | null,
    limit = 10,
  ): Observable<ISubscriptionVariantProductQuantityItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.subscriptionVariantProductQuantities));
  }

  update(data: SubscriptionVariantProductQuantityInput) {
    return this.updateGQL
      .mutate({ data })
      .pipe(map((r) => r.data?.updateSubscriptionVariantProductQuantity));
  }

  create(data: SubscriptionVariantProductQuantityInput) {
    return this.createGQL
      .mutate({ data })
      .pipe(map((r) => r.data?.createSubscriptionVariantProductQuantity));
  }
}
