import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { IShippingScheduleRowObject, ShippingScheduleService } from '@x/ecommerce/domain-client';
import { ShippingScheduleFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShippingScheduleCollectionProvider
  implements
    IDataCollectionProvider<IShippingScheduleRowObject, ShippingScheduleFilterInput, any, number>
{
  constructor(private shippingScheduleService: ShippingScheduleService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingScheduleRowObject>> {
    return this.shippingScheduleService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IShippingScheduleRowObject): number {
    return model.id;
  }

  toString(model: IShippingScheduleRowObject): string {
    return model.name;
  }
}
