import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateProductAttributeInput, UpdateProductAttributeInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ArchiveProductAttributeGQL,
  CreateProductAttributeGQL,
  ProductAttributeByIdGQL,
  ProductAttributeDetailGQL,
  ProductAttributeItemGQL,
  ProductAttributeItemsGQL,
  ProductAttributeItemsQueryVariables,
  ProductAttributeRowGQL,
  ProductAttributeRowsGQL,
  ProductAttributeRowsQueryVariables,
  ProductAttributeSearchGQL,
  UpdateProductAttributeGQL,
} from './gql/product-attribute.gql.generated';
import {
  IProductAttributeItemObject,
  IProductAttributeRowObject,
  ProductAttributeDetailObject,
  ProductAttributeSearchObject,
} from './types/product-attribute';

@Injectable()
export class ProductAttributeService {
  constructor(
    private productAttributeItemGQL: ProductAttributeItemGQL,
    private productAttributeItemsGQL: ProductAttributeItemsGQL,
    private detailGQL: ProductAttributeDetailGQL,
    private rowsGQL: ProductAttributeRowsGQL,
    private rowGQL: ProductAttributeRowGQL,
    private searchGQL: ProductAttributeSearchGQL,
    private byIdGQL: ProductAttributeByIdGQL,
    private createAttributeGQL: CreateProductAttributeGQL,
    private updateAttributeGQL: UpdateProductAttributeGQL,
    private archiveAttributeGQL: ArchiveProductAttributeGQL,
  ) {}

  fetchItem(id: number): Observable<IProductAttributeItemObject> {
    return this.productAttributeItemGQL.fetch({ id }).pipe(map((r) => r.data.productAttribute));
  }

  fetchItems(
    query: ProductAttributeItemsQueryVariables,
  ): Observable<{ items: IProductAttributeItemObject[]; totalItemsCount: number }> {
    return this.productAttributeItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number): Observable<IProductAttributeRowObject> {
    return this.rowGQL.fetch({ id }).pipe(map((r) => r.data.productAttribute));
  }

  fetchRows(
    query: ProductAttributeRowsQueryVariables,
  ): Observable<{ items: IProductAttributeRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((result) => result.data));
  }

  fetchDetail(id: number): Observable<ProductAttributeDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((result) => result.data.productAttribute));
  }

  fetchSearch(search?: string | null, results = 10): Observable<ProductAttributeSearchObject[]> {
    return this.searchGQL
      .fetch({ search, results })
      .pipe(map((result) => result.data.productAttributes));
  }

  fetchById(id: number): Observable<ProductAttributeSearchObject> {
    return this.byIdGQL.fetch({ id }).pipe(map((result) => result.data.productAttribute));
  }

  create(data: CreateProductAttributeInput): Observable<ProductAttributeDetailObject> {
    return this.createAttributeGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.createProductAttribute));
  }

  update(id: number, data: UpdateProductAttributeInput): Observable<ProductAttributeDetailObject> {
    return this.updateAttributeGQL
      .mutate({ id, data })
      .pipe(mapFetchResultData((data) => data.updateProductAttribute));
  }

  archive(productAttributeId: number): Observable<ProductAttributeDetailObject> {
    return this.archiveAttributeGQL
      .mutate({ productAttributeId })
      .pipe(mapFetchResultData((r) => r.archiveProductAttribute));
  }
}
