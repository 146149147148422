import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { AddressFragmentDoc } from './address.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserAddressRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AddressFilterInput>;
  page: Types.PageInput;
  sort: Types.SortInput;
}>;


export type UserAddressRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string }> };

export type UserAddressesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AddressFilterInput>;
  page: Types.PageInput;
  sort: Types.SortInput;
}>;


export type UserAddressesQuery = { __typename: 'Query', addresses: Array<{ __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string }> };

export const UserAddressRowsDocument = gql`
    query UserAddressRows($filter: AddressFilterInput, $page: PageInput!, $sort: SortInput!) {
  items: addresses(page: $page, filter: $filter, sort: $sort) {
    ...address
  }
  totalItemsCount: addressesCount(filter: $filter)
}
    ${AddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserAddressRowsGQL extends Apollo.Query<UserAddressRowsQuery, UserAddressRowsQueryVariables> {
    document = UserAddressRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserAddressesDocument = gql`
    query UserAddresses($filter: AddressFilterInput, $page: PageInput!, $sort: SortInput!) {
  addresses(page: $page, filter: $filter, sort: $sort) {
    ...address
  }
}
    ${AddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserAddressesGQL extends Apollo.Query<UserAddressesQuery, UserAddressesQueryVariables> {
    document = UserAddressesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }