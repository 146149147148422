import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IMembershipItemObject,
  IMembershipRowObject,
  MembershipService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MembershipItemCollectionProvider
  implements IDataCollectionProvider<IMembershipItemObject>
{
  constructor(private membershipService: MembershipService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IMembershipItemObject>> {
    return this.membershipService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IMembershipItemObject | Observable<IMembershipItemObject> {
    return this.membershipService.fetchItem(Number(id));
  }

  toId(model: IMembershipItemObject): string | number {
    return model.id;
  }

  toString(model: IMembershipItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class MembershipRowCollectionProvider
  implements IDataCollectionProvider<IMembershipRowObject>
{
  constructor(private membershipService: MembershipService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IMembershipRowObject>> {
    return this.membershipService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IMembershipRowObject | Observable<IMembershipRowObject> {
    return this.membershipService.fetchRow(Number(id));
  }

  toId(model: IMembershipRowObject): string | number {
    return model.id;
  }

  toString(model: IMembershipRowObject): string {
    return model.name;
  }
}
