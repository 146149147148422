import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry, shareReplay } from 'rxjs/operators';
import { IGoogleProductTaxon } from './types/google-product-type';

@Injectable()
export class GoogleProductTypeService {
  private taxonomies$: Observable<IGoogleProductTaxon[]>;

  constructor(private http: HttpClient) {
    const collator = new Intl.Collator(undefined, {
      sensitivity: 'base',
    });

    this.taxonomies$ = http
      .get(
        'https://storage.googleapis.com/x-media-helium-prod/google/producttype-taxonomy-with-ids.en-US.txt',
        { responseType: 'text' },
      )
      .pipe(
        retry(3),
        map((doc) => {
          const items = doc
            .split('\n')
            .map((row) => {
              const [id, name] = row.split(' - ');

              if (typeof id === 'string' && typeof name === 'string') {
                return {
                  id,
                  name,
                };
              }

              return null;
            })
            .filter((item): item is IGoogleProductTaxon => !!item);

          items.shift();

          return items.sort((a, b) => {
            return collator.compare(`${a.name}`, `${b.name}`);
          });
        }),
        shareReplay(),
      );
  }

  listTaxonomies(searchText?: string | null, page?: { index: number; size: number } | null) {
    return this.taxonomies$.pipe(
      map((items) => {
        items = searchText ? items.filter((i) => i.name.includes(searchText)) : items;

        if (page) {
          items = items.slice(page.index * page.size, page.index * page.size + page.size);
        }

        return {
          totalItemsCount: items.length,
          items,
        };
      }),
    );
  }

  fetchTaxonomy(id: string) {
    return this.taxonomies$.pipe(
      map((list) => {
        const item = list.find((item) => item.id === id);
        if (!item) {
          throw new Error(`GoogleProductTaxon with id ${id} not found`);
        }

        return item;
      }),
    );
  }
}
