import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { ProductVariantSearchObject, ProductVariantService } from '@x/ecommerce/domain-client';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ProductVariantAutocompleteDatasource extends ModelAutocompleteDatasource<ProductVariantSearchObject> {
  constructor(private service: ProductVariantService) {
    super((i: number, item: ProductVariantSearchObject) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<ProductVariantSearchObject[]> {
    return searchText.pipe(
      switchMap((text) => {
        if (text === null || (text && text.length === 0)) return of([]);
        return this.service.fetchVariantSearch(text);
      }),
    );
  }

  disconnect(): void {}

  displayFn = (option: ProductVariantSearchObject) => {
    if (!option) return '';
    return option.name ?? '';
  };
}
