import { SessionStorage } from '@x/common/storage';

export class GridScrollStateModel {
  top: number;
  left: number;
}

export interface GridScrollStateStorage {
  store(state: GridScrollStateModel): void;
  load(): GridScrollStateModel | undefined | null;
}

export class GridScrollStateSessionStorage implements GridScrollStateStorage {
  constructor(private key: string, private storage: SessionStorage) {}

  store(state: GridScrollStateModel): void {
    this.storage.setItem(this.key, state);
  }

  load(): GridScrollStateModel | null | undefined {
    return this.storage.getItem(this.key);
  }
}
