import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { WalletTransactionFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { IWalletTransactionObject } from '../../domain-client/services/types/wallet';
import { WalletTransactionService } from '../../domain-client/services/wallet-transaction.service';

@Injectable({ providedIn: 'root' })
export class WalletTransactionCollectionProvider
  implements
    IDataCollectionProvider<IWalletTransactionObject, WalletTransactionFilterInput>,
    IDataProvider<IWalletTransactionObject>
{
  constructor(private walletTransactionService: WalletTransactionService) {}

  fetchCollection({
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IWalletTransactionObject>> {
    return this.walletTransactionService.fetchByWallet({
      sort,
      page,
      filter,
    });
  }

  fetchSingle(id: string | number): Observable<IWalletTransactionObject> {
    return this.walletTransactionService.fetchById({ id: Number(id) });
  }

  toId(model: IWalletTransactionObject): number {
    return model.id;
  }

  toString(model: IWalletTransactionObject): string {
    return `Wallet ID: ${model.id}`;
  }
}
