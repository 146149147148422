import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FulfilmentIntervalItemFragment = { __typename: 'FulfilmentIntervalObject', id: string, isAvailable: boolean, unavailableReason?: string | null | undefined, interval: { __typename: 'IntervalObject', start: any, end: any }, slotAvailability?: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, availableCapacity: number, groupingAvailable?: boolean | null | undefined, unavailableReason?: string | null | undefined, slotStartAt: any, slotEndAt: any, slotId: string, usedCapacity: number } | null | undefined, stockAvailability?: { __typename: 'StockAvailabilityIntervalObject', isAvailable: boolean, interval: { __typename: 'IntervalObject', start: any, end: any }, availableQuantities: Array<{ __typename: 'StockItemAvailableQuantityObject', quantity?: number | null | undefined, requiredQuantity: number, item: { __typename: 'StockItemObject', id: number, sku: string } }>, availableLots: Array<{ __typename: 'StockLotObject', id: string }>, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } } | null | undefined };

export type OrderFulfilmentIntervalItemsQueryVariables = Types.Exact<{
  orderId: Types.Scalars['Int']['input'];
  shippingMethodId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type OrderFulfilmentIntervalItemsQuery = { __typename: 'Query', orderFulfilmentIntervals: Array<{ __typename: 'FulfilmentIntervalObject', id: string, isAvailable: boolean, unavailableReason?: string | null | undefined, interval: { __typename: 'IntervalObject', start: any, end: any }, slotAvailability?: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, availableCapacity: number, groupingAvailable?: boolean | null | undefined, unavailableReason?: string | null | undefined, slotStartAt: any, slotEndAt: any, slotId: string, usedCapacity: number } | null | undefined, stockAvailability?: { __typename: 'StockAvailabilityIntervalObject', isAvailable: boolean, interval: { __typename: 'IntervalObject', start: any, end: any }, availableQuantities: Array<{ __typename: 'StockItemAvailableQuantityObject', quantity?: number | null | undefined, requiredQuantity: number, item: { __typename: 'StockItemObject', id: number, sku: string } }>, availableLots: Array<{ __typename: 'StockLotObject', id: string }>, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } } | null | undefined }> };

export const FulfilmentIntervalItemFragmentDoc = gql`
    fragment FulfilmentIntervalItem on FulfilmentIntervalObject {
  id
  interval {
    start
    end
  }
  isAvailable
  unavailableReason
  slotAvailability {
    isAvailable
    availableCapacity
    groupingAvailable
    unavailableReason
    slotStartAt
    slotEndAt
    slotId
    usedCapacity
  }
  stockAvailability {
    interval {
      start
      end
    }
    isAvailable
    availableQuantities {
      item {
        id
        sku
      }
      quantity
      requiredQuantity
    }
    availableLots {
      id
    }
    warehouse {
      id
      code
      name
    }
  }
}
    `;
export const OrderFulfilmentIntervalItemsDocument = gql`
    query OrderFulfilmentIntervalItems($orderId: Int!, $shippingMethodId: Int) {
  orderFulfilmentIntervals(orderId: $orderId, shippingMethodId: $shippingMethodId) {
    ...FulfilmentIntervalItem
  }
}
    ${FulfilmentIntervalItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderFulfilmentIntervalItemsGQL extends Apollo.Query<OrderFulfilmentIntervalItemsQuery, OrderFulfilmentIntervalItemsQueryVariables> {
    document = OrderFulfilmentIntervalItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }