import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { camelCaseToWords } from '@x/common/utils';
import { Observable } from 'rxjs';
import { INotificationTypeItemObject, NotificationLogService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class NotificationLogTypeItemCollectionProvider
  implements IDataCollectionProvider<INotificationTypeItemObject>
{
  constructor(private notificationLogService: NotificationLogService) {}

  fetchCollection({
    page,
    sort,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<INotificationTypeItemObject>> {
    return this.notificationLogService.fetchTypeItems({
      page,
      sort,
    });
  }

  fetchSingle(id: string): Partial<INotificationTypeItemObject> {
    return {
      id,
      name: id,
    };
  }

  toId(model: INotificationTypeItemObject): string {
    return model.id;
  }

  toString(model: INotificationTypeItemObject): string {
    return camelCaseToWords(model.name);
  }
}
