import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { CountryService, IProvinceItem } from '@x/geocode/client';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProvinceCodeTransformer implements ModelTransformer<IProvinceItem, string> {
  private _cache = new Map<string, IProvinceItem>();

  constructor(private countryService: CountryService) {}

  modelToValue(model: IProvinceItem): string {
    return model.code;
  }

  valueToModel(provinceCode: string): Promise<IProvinceItem | any> {
    if (this._cache.has(provinceCode)) {
      return Promise.resolve(this._cache.get(provinceCode) as IProvinceItem);
    }

    return this.countryService
      .fetchProvinceByCode(provinceCode)
      .pipe(
        tap((result) => {
          if (result) this._cache.set(provinceCode, result);
        }),
      )
      .toPromise();
  }
}
