<mat-form-field appearance="fill">
  <mat-label>
    <fa-icon matTextPrefix *ngIf="icon" [icon]="icon"></fa-icon>
    {{ label }}
  </mat-label>
  <mat-select [formControl]="control">
    <mat-option *ngIf="nullable" [value]="null">{{ nullLabel }}</mat-option>
    <mat-option [value]="true">{{ trueLabel }}</mat-option>
    <mat-option [value]="false">{{ falseLabel }}</mat-option>
  </mat-select>
</mat-form-field>
