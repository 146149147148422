import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateTaxCategoryInput, UpdateTaxCategoryInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateTaxCategoryGQL,
  TaxCategoryByIdGQL,
  TaxCategoryItemsGQL,
  TaxCategoryListGqlGQL,
  TaxCategoryRowGQL,
  TaxCategoryRowsGQL,
  TaxCategoryRowsQueryVariables,
  TaxCategorySearchGQL,
  UpdateTaxCategoryGQL,
} from './gql/tax-category.gql.generated';
import {
  ITaxCategoryItemObject,
  ITaxCategoryRowObject,
  TaxCategorySearchObject,
} from './types/tax-category';

@Injectable()
export class TaxCategoryService {
  constructor(
    private rowGql: TaxCategoryRowGQL,
    private rowsGql: TaxCategoryRowsGQL,
    private itemsGql: TaxCategoryItemsGQL,
    private searchGql: TaxCategorySearchGQL,
    private byIdGql: TaxCategoryByIdGQL,
    private createGql: CreateTaxCategoryGQL,
    private updateGql: UpdateTaxCategoryGQL,
    private listGql: TaxCategoryListGqlGQL,
  ) {}

  fetchById(id: number): Observable<TaxCategorySearchObject> {
    return this.byIdGql.fetch({ id }).pipe(map((r) => r.data.taxCategory));
  }

  fetchRow(id: number): Observable<ITaxCategoryRowObject> {
    return this.rowGql.fetch({ id }).pipe(map((r) => r.data.taxCategory));
  }

  fetchRows(
    query: TaxCategoryRowsQueryVariables,
  ): Observable<{ items: ITaxCategoryRowObject[]; totalItemsCount: number }> {
    return this.rowsGql.fetch(query).pipe(map((r) => r.data));
  }

  fetchItems(
    query: TaxCategoryRowsQueryVariables,
  ): Observable<{ items: ITaxCategoryItemObject[]; totalItemsCount: number }> {
    return this.itemsGql.fetch(query).pipe(map((r) => r.data));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<TaxCategorySearchObject[]> {
    return this.searchGql.fetch({ searchText, results }).pipe(map((r) => r.data.taxCategories));
  }

  getAllTaxCategories() {
    return this.listGql.fetch().pipe(map((r) => r.data.taxCategories));
  }

  create(input: CreateTaxCategoryInput) {
    return this.createGql.mutate({ input }).pipe(mapFetchResultData((r) => r.createTaxCategory));
  }

  update(input: UpdateTaxCategoryInput) {
    return this.updateGql.mutate({ input }).pipe(mapFetchResultData((r) => r.updateTaxCategory));
  }
}
