import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { VoucherFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { IVoucherRowObject, VoucherService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class VoucherRowCollectionProvider
  implements IDataCollectionProvider<IVoucherRowObject, VoucherFilterInput, any, number>
{
  constructor(private voucherService: VoucherService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IVoucherRowObject>> {
    return this.voucherService.fetchRows({
      filter,
      page,
      sort,
    });
  }

  toId(model: IVoucherRowObject): number {
    return model.id;
  }

  toString(model: IVoucherRowObject): string {
    return `Voucher ID${model.id}`;
  }
}
