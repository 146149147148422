import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  ISubscriptionRunDetailObject,
  ISubscriptionRunRowObject,
  SubscriptionRunService,
} from '@x/ecommerce/domain-client';
import { SubscriptionRunFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionRunRowCollectionProvider
  implements
    IDataCollectionProvider<ISubscriptionRunRowObject, SubscriptionRunFilterInput, any, number>
{
  constructor(private runService: SubscriptionRunService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriptionRunRowObject>> {
    return this.runService.fetchRows({
      filter,
      page,
      sort,
    });
  }

  toId(model: ISubscriptionRunRowObject): number {
    return model.id;
  }

  toString(model: ISubscriptionRunRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class SubscriptionRunDetailProvider
  implements IDataProvider<ISubscriptionRunDetailObject, number>
{
  constructor(private runService: SubscriptionRunService) {}

  fetchSingle(id: number): ISubscriptionRunDetailObject | Observable<ISubscriptionRunDetailObject> {
    return this.runService.fetchDetail(id);
  }

  toId(model: ISubscriptionRunDetailObject): number {
    return model.id;
  }

  toString(model: ISubscriptionRunDetailObject): string {
    return model.name;
  }
}
