import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  ISubscriptionVariantItemObject,
  SubscriptionVariantService,
} from '@x/ecommerce/domain-client';
import { SubscriptionVariantFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionVariantItemCollectionProvider
  implements
    IDataCollectionProvider<
      ISubscriptionVariantItemObject,
      SubscriptionVariantFilterInput,
      any,
      number
    >
{
  constructor(private subscriptionVariantService: SubscriptionVariantService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriptionVariantItemObject>> {
    return this.subscriptionVariantService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: number,
  ): ISubscriptionVariantItemObject | Observable<ISubscriptionVariantItemObject> {
    return this.subscriptionVariantService.fetchItem(Number(id));
  }

  toId(model: ISubscriptionVariantItemObject): number {
    return model.id;
  }

  toString(model: ISubscriptionVariantItemObject): string {
    return model.name;
  }
}
