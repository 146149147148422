import { Injectable } from '@angular/core';
import { StockAvailabilityIntervalFilterInput } from '@x/schemas/ecommerce';
import { map } from 'rxjs/operators';
import { StockAvailabilityIntervalGQL } from './gql/stock-availability.gql.generated';

@Injectable()
export class StockAvailabilityService {
  constructor(private intervalGQL: StockAvailabilityIntervalGQL) {}

  fetchIntervals(filter: StockAvailabilityIntervalFilterInput) {
    return this.intervalGQL.fetch({ filter }).pipe(map((r) => r.data.stockAvailabilityInterval));
  }
}
