import { Injectable } from '@angular/core';
import { CreateAgreementInput, UpdateAgreementInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AgreementDetailGQL,
  AgreementRowGQL,
  AgreementRowsGQL,
  AgreementRowsQueryVariables,
  ArchiveAgreementGQL,
  CreateAgreementGQL,
  UpdateAgreementGQL,
} from './gql/agreement.gql.generated';
import { IAgreementDetailObject, IAgreementRowObject } from './types/agreement';

@Injectable()
export class AgreementService {
  constructor(
    private createGQL: CreateAgreementGQL,
    private updateGQL: UpdateAgreementGQL,
    private archiveGQL: ArchiveAgreementGQL,
    private rowGQL: AgreementRowGQL,
    private rowsGQL: AgreementRowsGQL,
    private detailGQL: AgreementDetailGQL,
  ) {}

  fetchRow(id: number): Observable<IAgreementRowObject> {
    return this.rowGQL.fetch({ id }).pipe(map((r) => r.data.agreement));
  }

  fetchRows(
    query: AgreementRowsQueryVariables,
  ): Observable<{ items: IAgreementRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<IAgreementDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.agreement));
  }

  update(input: UpdateAgreementInput) {
    return this.updateGQL.mutate({ input }).pipe(map((r) => r.data?.updateAgreement));
  }

  create(input: CreateAgreementInput) {
    return this.createGQL.mutate({ input }).pipe(map((r) => r.data?.createAgreement));
  }

  archive(id: number) {
    return this.archiveGQL.mutate({ id }).pipe(map((r) => r.data));
  }
}
