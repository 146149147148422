<mat-form-field appearance="fill" style="width: 100%">
  <mat-label>
    <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
    {{ label }}
  </mat-label>

  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      [value]="value.start"
      (dateChange)="onStartChange($event)"
      placeholder="Start date"
    />
    <input
      matEndDate
      [value]="value.end"
      (dateChange)="onEndChange($event)"
      placeholder="End date"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <fa-icon icon="calendar" matDatepickerToggleIcon></fa-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-button (click)="clear()">Clear</button>
      <button mat-flat-button color="primary" matDateRangePickerApply>Ok</button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <!-- <mat-error *ngIf="control.controls.start.hasError('matStartDateInvalid')">
    Invalid start date
  </mat-error>
  <mat-error *ngIf="control.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
</mat-form-field>
