import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { MediaUploadInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  BrowseMediaFolderGQL,
  BrowseMediaGQL,
  GetMediaGQL,
  GetMediaUploadUrlGQL,
  SyncMediaToContentGQL,
} from './gql/media.gql.generated';
import { Media, MediaFolder, MediaUploadUrl } from './types/media';

@Injectable()
export class MediaService {
  constructor(
    private readonly getMediaGQL: GetMediaGQL,
    private readonly browseMediaGQL: BrowseMediaGQL,
    private readonly folderGQL: BrowseMediaFolderGQL,
    private readonly getUploadUrlGQL: GetMediaUploadUrlGQL,
    private readonly syncMediaContent: SyncMediaToContentGQL,
  ) {}

  getMedia(id: string) {
    return this.getMediaGQL.fetch({ id }).pipe(mapFetchResultData((d) => d.getMedia));
  }

  browseMedia({
    path,
    limit,
    nextToken,
  }: {
    path: string;
    limit?: number;
    nextToken?: string;
  }): Observable<{
    items: Media[];
    totalItemsCount: number;
  }> {
    return this.browseMediaGQL
      .fetch({ path, limit, nextToken })
      .pipe(mapFetchResultData((d) => d.browseMedia));
  }

  browseMediaFolder(path: string): Observable<MediaFolder[]> {
    return this.folderGQL.fetch({ path }).pipe(mapFetchResultData((d) => d.browseMediaFolder));
  }

  getUploadUrl(input: MediaUploadInput): Observable<MediaUploadUrl> {
    return this.getUploadUrlGQL
      .fetch({ input })
      .pipe(mapFetchResultData((d) => d.getMediaUploadUrl));
  }

  syncMediaToContent(url: string): Observable<boolean> {
    return this.syncMediaContent
      .mutate({ url })
      .pipe(mapFetchResultData((d) => d.syncMediaToContent));
  }
}
