import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { Observable, map, of } from 'rxjs';
import { PaymentService } from '../../domain-client/services/payment.service';

@Injectable({ providedIn: 'root' })
export class PaymentErrorDescriptionCollectionProvider
  implements IDataCollectionProvider<string, string, any, string>, IDataProvider<string, string>
{
  constructor(private paymentService: PaymentService) {}

  fetchCollection({
    searchText,
    page,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<string>> {
    return this.paymentService
      .getPaymentErrorDescriptions({ filter: { search: searchText }, page })
      .pipe(map((items) => ({ items, totalItemsCount: items.length }) as IDataCollection<string>));
  }

  fetchSingle(error: string): Observable<string> {
    return of(error);
  }

  toId(model: string): string {
    return model;
  }

  toString(model: string): string {
    return model;
  }
}
