<div class="state-header">
  <input
    #viewTitleInput
    class="state-header-title"
    matInput
    type="text"
    [formControl]="viewTitleControl"
    autocomplete="off"
    (keyup.enter)="onViewSave()"
  />

  <!-- <span class="state-save-label mat-hint">{{ stateUnsaved ? 'Unsaved Changes' : '' }}</span> -->

  <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="viewTitleControl.disabled">
    <fa-icon icon="ellipsis-vertical"></fa-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="viewTitleControl.enabled"
    [matTooltip]="createNewView ? 'Save New View' : 'Save Current View'"
    (click)="onViewSave()"
  >
    <fa-icon icon="floppy-disk"></fa-icon>
  </button>
</div>
<mat-action-list>
  <ng-container *ngIf="states$ | async as states">
    <mat-list-item
      *ngFor="let state of states; trackBy: trackById"
      [class.active]="state.id === view?.stateId"
      (click)="onViewSelect(state)"
    >
      <span matListItemTitle>{{ state.title }}</span>
      <fa-icon icon="arrow-right" matListItemMeta></fa-icon>
    </mat-list-item>
  </ng-container>

  <!-- <mat-list-item>
    <span matLine>Default View</span>
    <button mat-icon-button (click)="onDefaultViewSelect()">
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </mat-list-item> -->
</mat-action-list>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onViewEdit()">
    <fa-icon icon="pen-fancy"></fa-icon>
    <span>Edit View Title</span>
  </button>
  <button mat-menu-item (click)="onViewSave()">
    <fa-icon icon="floppy-disk"></fa-icon>
    <span>Save View</span>
  </button>
  <button mat-menu-item (click)="onViewCreate()">
    <fa-icon icon="floppy-disk-circle-arrow-right"></fa-icon>
    <span>Save View As</span>
  </button>
</mat-menu>
