import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateAuthRoleInput, UpdateAuthRoleInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  ArchiveAuthRoleGQL,
  AuthPermissionsGQL,
  AuthRoleDetailGQL,
  AuthRoleItemGQL,
  AuthRoleItemsGQL,
  AuthRoleItemsQueryVariables,
  AuthRoleRowGQL,
  AuthRoleRowsGQL,
  AuthRoleRowsQueryVariables,
  AuthRoleSearchGQL,
  CreateAuthRoleGQL,
  UpdateAuthRoleGQL,
} from './gql/auth-role.gql.generated';
import { AuthRoleSearchObject, IAuthRoleDetailObject } from './types/auth';
import { IAuthPermissionObject, IAuthRoleItemObject, IAuthRoleRowObject } from './types/auth-role';

@Injectable()
export class AuthRoleService {
  constructor(
    private authRoleRowGQL: AuthRoleRowGQL,
    private authRowsGQL: AuthRoleRowsGQL,
    private searchGQL: AuthRoleSearchGQL,
    private authRoleDetailGQL: AuthRoleDetailGQL,
    private authPermissionsGQL: AuthPermissionsGQL,
    private createAuthRoleGQL: CreateAuthRoleGQL,
    private updateAuthRoleGQL: UpdateAuthRoleGQL,
    private archiveAuthRoleGQL: ArchiveAuthRoleGQL,
    private authRoleItemsGQL: AuthRoleItemsGQL,
    private authRoleItemGQL: AuthRoleItemGQL,
  ) {}

  fetchItem(id: number): Observable<IAuthRoleItemObject> {
    return this.authRoleItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.authRole));
  }

  fetchItems(
    query: AuthRoleItemsQueryVariables,
  ): Observable<{ items: IAuthRoleItemObject[]; totalItemsCount: number }> {
    return this.authRoleItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchRow(id: number): Observable<IAuthRoleRowObject> {
    return this.authRoleRowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.authRole));
  }

  fetchRows(
    query: AuthRoleRowsQueryVariables,
  ): Observable<{ items: IAuthRoleRowObject[]; totalItemsCount: number }> {
    return this.authRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchById(id: number): Observable<IAuthRoleDetailObject> {
    return this.authRoleDetailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.authRole));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<AuthRoleSearchObject[]> {
    return this.searchGQL
      .fetch({ searchText, results })
      .pipe(mapFetchResultData((r) => r.authRoles));
  }

  create(input: CreateAuthRoleInput) {
    return this.createAuthRoleGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createAuthRole));
  }

  update(input: UpdateAuthRoleInput) {
    return this.updateAuthRoleGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.updateAuthRole));
  }

  archive(id: number) {
    return this.archiveAuthRoleGQL
      .mutate({ id })
      .pipe(mapFetchResultData((r) => r.archiveAuthRole));
  }

  fetchPermissions(): Observable<Array<IAuthPermissionObject>> {
    return this.authPermissionsGQL.fetch().pipe(mapFetchResultData((r) => r.authPermissions));
  }
}
