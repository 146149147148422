import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { ShipmentRowObject, ShipmentService } from '@x/ecommerce/domain-client';
import { ShipmentFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShipmentCollectionProvider
  implements IDataCollectionProvider<ShipmentRowObject, ShipmentFilterInput, any, number>
{
  constructor(private shipmentService: ShipmentService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ShipmentRowObject>> {
    return this.shipmentService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: ShipmentRowObject): number {
    return model.id;
  }

  toString(model: ShipmentRowObject): string {
    return model.description ?? '';
  }
}
