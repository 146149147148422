import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IProductOptionItemObject,
  IProductOptionRowObject,
  ProductOptionService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductOptionItemCollectionProvider
  implements IDataCollectionProvider<IProductOptionItemObject>
{
  constructor(private productOptionService: ProductOptionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IProductOptionItemObject>> {
    return this.productOptionService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IProductOptionItemObject | Observable<IProductOptionItemObject> {
    return this.productOptionService.fetchItem(Number(id));
  }

  toId(model: IProductOptionItemObject): string | number {
    return model.id;
  }

  toString(model: IProductOptionItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ProductOptionRowCollectionProvider
  implements IDataCollectionProvider<IProductOptionRowObject>
{
  constructor(private productOptionService: ProductOptionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IProductOptionRowObject>> {
    console.log({ filter });
    return this.productOptionService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IProductOptionRowObject | Observable<IProductOptionRowObject> {
    return this.productOptionService.fetchRow(Number(id));
  }

  toId(model: IProductOptionRowObject): string | number {
    return model.id;
  }

  toString(model: IProductOptionRowObject): string {
    return model.name;
  }
}
