import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { IOrderTagItem, OrderTagService } from '@x/ecommerce/domain-client';
import { OrderTagFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrderTagItemCollectionProvider
  implements
    IDataCollectionProvider<IOrderTagItem, OrderTagFilterInput, any, number>,
    IDataProvider<IOrderTagItem, number>
{
  constructor(private orderTagService: OrderTagService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<OrderTagFilterInput, any>>): Observable<IDataCollection<IOrderTagItem>> {
    return this.orderTagService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): IOrderTagItem | Observable<IOrderTagItem> {
    return this.orderTagService.fetchItem(id);
  }

  toId(model: IOrderTagItem): number {
    return model.id;
  }

  toString(model: IOrderTagItem): string {
    return model.label;
  }
}
