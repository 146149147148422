import { Injectable } from '@angular/core';
import { IDataPageModel, IDataSortModel } from '@x/common/data';
import { mapFetchResultData } from '@x/common/graph';
import {
  ConfigureProductAssociationInput,
  CreateProductAssociationInput,
  PositionProductAssociationInput,
  ProductAssociationFilterInput,
  UpdateProductAssociationInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  AssociatedProductsGQL,
  ConfigureProductAssociationGQL,
  CreateProductAssociationGQL,
  PositionAssociationGQL,
  ProductAssociationDetailGQL,
  ProductAssociationItemGQL,
  ProductAssociationItemsGQL,
  ProductAssociationRowGQL,
  ProductAssociationRowsGQL,
  UpdateProductAssociationGQL,
} from './gql/product-association.service.gql.generated';
import {
  ProdectAssociationItemObject,
  ProdectAssociationRowObject,
  ProductAssociatedProductObject,
  ProductAssociationDetailObject,
} from './types/product-association';

@Injectable()
export class ProductAssociationService {
  constructor(
    private readonly fetchItemGql: ProductAssociationItemGQL,
    private readonly fetchItemsGql: ProductAssociationItemsGQL,
    private readonly fetchRowGql: ProductAssociationRowGQL,
    private readonly fetchRowsGql: ProductAssociationRowsGQL,
    private readonly fetchDetailGql: ProductAssociationDetailGQL,
    private readonly fetchAssociatedProductsGql: AssociatedProductsGQL,
    private readonly createGql: CreateProductAssociationGQL,
    private readonly updateGql: UpdateProductAssociationGQL,
    private readonly positionAssociationGQL: PositionAssociationGQL,
    private readonly configureProductAssociationConfigGQL: ConfigureProductAssociationGQL,
  ) {}

  fetchItem(id: number): Observable<ProdectAssociationItemObject> {
    return this.fetchItemGql
      .fetch({ id })
      .pipe(mapFetchResultData(({ productAssociation }) => productAssociation));
  }

  fetchItems(args?: {
    filter?: ProductAssociationFilterInput;
    page?: IDataPageModel | null;
    sort?: IDataSortModel | null;
  }): Observable<{
    items: ProdectAssociationItemObject[];
    totalItemsCount: number;
  }> {
    return this.fetchItemsGql.fetch(args).pipe(
      mapFetchResultData(({ items, totalItemsCount }) => ({
        items,
        totalItemsCount,
      })),
    );
  }

  fetchRow(id: number): Observable<ProdectAssociationRowObject> {
    return this.fetchRowGql
      .fetch({ id })
      .pipe(mapFetchResultData(({ productAssociation }) => productAssociation));
  }

  fetchRows(args?: {
    filter?: ProductAssociationFilterInput;
    page?: IDataPageModel | null;
    sort?: IDataSortModel | null;
    locale?: string | null;
  }): Observable<{
    items: ProdectAssociationRowObject[];
    totalItemsCount: number;
  }> {
    return this.fetchRowsGql.fetch(args).pipe(
      mapFetchResultData(({ items, totalItemsCount }) => ({
        items,
        totalItemsCount,
      })),
    );
  }

  fetchDetail(id: number): Observable<ProductAssociationDetailObject> {
    return this.fetchDetailGql
      .fetch({ id })
      .pipe(mapFetchResultData(({ productAssociation }) => productAssociation));
  }

  fetchAssociatedProducts(
    associationId: number,
    productId: number,
  ): Observable<ProductAssociatedProductObject[]> {
    return this.fetchAssociatedProductsGql
      .fetch({ associationId, productId })
      .pipe(mapFetchResultData(({ associatedProducts }) => associatedProducts));
  }

  create(input: CreateProductAssociationInput): Observable<ProductAssociationDetailObject> {
    return this.createGql
      .mutate({ input })
      .pipe(mapFetchResultData(({ createProductAssociation }) => createProductAssociation));
  }

  update(input: UpdateProductAssociationInput): Observable<ProductAssociationDetailObject> {
    return this.updateGql
      .mutate({ input })
      .pipe(mapFetchResultData(({ updateProductAssociation }) => updateProductAssociation));
  }

  positionAssociation(input: PositionProductAssociationInput) {
    return this.positionAssociationGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.positionProductAssociation));
  }

  configureProductAssociation(input: ConfigureProductAssociationInput) {
    return this.configureProductAssociationConfigGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.configureProductAssociation));
  }
}
