import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UserPaymentMethodItemGQL,
  UserPaymentMethodItemsGQL,
  UserPaymentMethodItemsQueryVariables,
  UserPaymentMethodRowsGQL,
  UserPaymentMethodRowsQueryVariables,
} from './gql/user-payment-methods.gql.generated';
import { IUserPaymentMethodItemObject, IUserPaymentMethodRowObject } from './types/user';

@Injectable()
export class UserPaymentMethodsService {
  constructor(
    private readonly userPaymentMethodItemsGql: UserPaymentMethodItemsGQL,
    private readonly userPaymentMethodItemGql: UserPaymentMethodItemGQL,
    private readonly userPaymentMethodRowsGql: UserPaymentMethodRowsGQL,
  ) {}

  fetchItem(id: number): Observable<IUserPaymentMethodItemObject> {
    return this.userPaymentMethodItemGql
      .fetch({ id })
      .pipe(mapFetchResultData((d) => d.userPaymentMethod));
  }

  fetchItems(
    query: UserPaymentMethodItemsQueryVariables,
  ): Observable<{ items: IUserPaymentMethodItemObject[]; totalItemsCount: number }> {
    return this.userPaymentMethodItemsGql.fetch(query).pipe(map((result) => result.data));
  }

  fetchRows(
    query: UserPaymentMethodRowsQueryVariables,
  ): Observable<{ items: IUserPaymentMethodRowObject[]; totalItemsCount: number }> {
    return this.userPaymentMethodRowsGql.fetch(query).pipe(mapFetchResultData((r) => r));
  }
}
