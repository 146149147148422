import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { camelCaseToWords } from '@x/common/utils';
import { NotificationLogFilterInput } from '@x/schemas/ecommerce';
import { map, Observable } from 'rxjs';
import { INotificationLogRowObject, NotificationLogService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class NotificationLogRowCollectionProvider
  implements
    IDataCollectionProvider<INotificationLogRowObject, NotificationLogFilterInput, any, string>
{
  constructor(private notificationLogService: NotificationLogService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<INotificationLogRowObject>> {
    return this.notificationLogService
      .fetchRows({
        filter: { ...filter, search: searchText },
        page,
        sort,
      })
      .pipe(
        map((response) => {
          if (Array.isArray(response.items)) {
            return {
              items: response.items.map((item) => {
                if (item.channelName) {
                  return {
                    ...item,
                    channelName: camelCaseToWords(item.channelName),
                    notificationName: camelCaseToWords(item.notificationName),
                  };
                }
                return item;
              }),
              totalItemsCount: response.totalItemsCount,
            };
          }
          return response;
        }),
      );
  }

  toId(model: INotificationLogRowObject): string {
    return model.id;
  }

  toString(model: INotificationLogRowObject): string {
    return `NotificationLog ID${model.id}`;
  }
}
