import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { ContentService, IContentRefObject } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ContentRefTransformer implements ModelTransformer<IContentRefObject, string> {
  private _cache = new Map<string, IContentRefObject>();

  constructor(private service: ContentService) {}

  modelToValue(model: IContentRefObject): string {
    return model.ref;
  }

  valueToModel(ref: string): Promise<IContentRefObject> {
    if (this._cache.has(ref)) {
      return Promise.resolve(this._cache.get(ref) as IContentRefObject);
    }

    return firstValueFrom(this.service.fetch(ref).pipe(tap((r) => this._cache.set(ref, r))));
  }
}
