import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductAssociationItemFragment = { __typename: 'ProductAssociationObject', id: number, title: string, position: number };

export type ProductAssociationTranslationFragment = { __typename: 'ProductAssociationTranslationObject', id: number, locale: string, title: string };

export type ProductAssociationConfigFragment = { __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined };

export type ProductAssociationRowFragment = { __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string, ascendants: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }> }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string, name: string, ascendants: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }> } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }> };

export type ProductAssociationDetailFragment = { __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }>, translations: Array<{ __typename: 'ProductAssociationTranslationObject', id: number, locale: string, title: string }> };

export type ProductAssociationItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAssociationItemQuery = { __typename: 'Query', productAssociation: { __typename: 'ProductAssociationObject', id: number, title: string, position: number } };

export type ProductAssociationItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductAssociationFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductAssociationItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductAssociationObject', id: number, title: string, position: number }> };

export type ProductAssociationRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAssociationRowQuery = { __typename: 'Query', productAssociation: { __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string, ascendants: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }> }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string, name: string, ascendants: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }> } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }> } };

export type ProductAssociationRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductAssociationFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductAssociationRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string, ascendants: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }> }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string, name: string, ascendants: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }> } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }> }> };

export type ProductAssociationDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAssociationDetailQuery = { __typename: 'Query', productAssociation: { __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }>, translations: Array<{ __typename: 'ProductAssociationTranslationObject', id: number, locale: string, title: string }> } };

export type AssociatedProductsQueryVariables = Types.Exact<{
  associationId: Types.Scalars['Int']['input'];
  productId: Types.Scalars['Int']['input'];
}>;


export type AssociatedProductsQuery = { __typename: 'Query', associatedProducts: Array<{ __typename: 'ProductAssociatedProductObject', position: number, associatedProduct: { __typename: 'ProductObject', id: number } }> };

export type CreateProductAssociationMutationVariables = Types.Exact<{
  input: Types.CreateProductAssociationInput;
}>;


export type CreateProductAssociationMutation = { __typename: 'Mutation', createProductAssociation: { __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }>, translations: Array<{ __typename: 'ProductAssociationTranslationObject', id: number, locale: string, title: string }> } };

export type UpdateProductAssociationMutationVariables = Types.Exact<{
  input: Types.UpdateProductAssociationInput;
}>;


export type UpdateProductAssociationMutation = { __typename: 'Mutation', updateProductAssociation: { __typename: 'ProductAssociationObject', id: number, title: string, position: number, targets?: Array<Types.ProductAssociationTarget> | null | undefined, sourceMainTaxonEnable: boolean, targetMainTaxonEnable: boolean, enabled: boolean, createdAt: any, updatedAt: any, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string }>, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string }>, configs: Array<{ __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined }>, translations: Array<{ __typename: 'ProductAssociationTranslationObject', id: number, locale: string, title: string }> } };

export type PositionAssociationMutationVariables = Types.Exact<{
  input: Types.PositionProductAssociationInput;
}>;


export type PositionAssociationMutation = { __typename: 'Mutation', positionProductAssociation: { __typename: 'ProductAssociationObject', id: number, title: string, position: number } };

export type ConfigureProductAssociationMutationVariables = Types.Exact<{
  input: Types.ConfigureProductAssociationInput;
}>;


export type ConfigureProductAssociationMutation = { __typename: 'Mutation', configureProductAssociation: { __typename: 'ProductAssociationConfigObject', sourceMainTaxonEnable?: boolean | null | undefined, enabled?: boolean | null | undefined, createdAt: any, updatedAt: any, product: { __typename: 'ProductObject', id: number }, association: { __typename: 'ProductAssociationObject', id: number }, sourceTaxon?: { __typename: 'TaxonObject', id: number, code: string } | null | undefined } };

export const ProductAssociationItemFragmentDoc = gql`
    fragment ProductAssociationItem on ProductAssociationObject {
  id
  title
  position
}
    `;
export const ProductAssociationConfigFragmentDoc = gql`
    fragment ProductAssociationConfig on ProductAssociationConfigObject {
  product {
    id
  }
  association {
    id
  }
  sourceTaxon {
    id
    code
  }
  sourceMainTaxonEnable
  enabled
  createdAt
  updatedAt
}
    `;
export const ProductAssociationRowFragmentDoc = gql`
    fragment ProductAssociationRow on ProductAssociationObject {
  id
  title
  position
  targets
  targetTaxons {
    id
    code
    name
    ascendants {
      id
      code
      name
    }
  }
  sourceTaxon {
    id
    code
    name
    ascendants {
      id
      code
      name
    }
  }
  sourceMainTaxonEnable
  targetMainTaxonEnable
  enabled
  channels {
    id
    code
  }
  configs {
    ...ProductAssociationConfig
  }
  createdAt
  updatedAt
}
    ${ProductAssociationConfigFragmentDoc}`;
export const ProductAssociationTranslationFragmentDoc = gql`
    fragment ProductAssociationTranslation on ProductAssociationTranslationObject {
  id
  locale
  title
}
    `;
export const ProductAssociationDetailFragmentDoc = gql`
    fragment ProductAssociationDetail on ProductAssociationObject {
  id
  title
  position
  targets
  targetTaxons {
    id
    code
  }
  sourceTaxon {
    id
    code
  }
  sourceMainTaxonEnable
  targetMainTaxonEnable
  enabled
  channels {
    id
    code
  }
  configs {
    ...ProductAssociationConfig
  }
  translations {
    ...ProductAssociationTranslation
  }
  createdAt
  updatedAt
}
    ${ProductAssociationConfigFragmentDoc}
${ProductAssociationTranslationFragmentDoc}`;
export const ProductAssociationItemDocument = gql`
    query ProductAssociationItem($id: Int!) {
  productAssociation(id: $id) {
    ...ProductAssociationItem
  }
}
    ${ProductAssociationItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAssociationItemGQL extends Apollo.Query<ProductAssociationItemQuery, ProductAssociationItemQueryVariables> {
    document = ProductAssociationItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAssociationItemsDocument = gql`
    query ProductAssociationItems($filter: ProductAssociationFilterInput, $page: PageInput, $sort: SortInput) {
  items: productAssociations(filter: $filter, page: $page, sort: $sort) {
    ...ProductAssociationItem
  }
  totalItemsCount: productAssociationsCount(filter: $filter)
}
    ${ProductAssociationItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAssociationItemsGQL extends Apollo.Query<ProductAssociationItemsQuery, ProductAssociationItemsQueryVariables> {
    document = ProductAssociationItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAssociationRowDocument = gql`
    query ProductAssociationRow($id: Int!) {
  productAssociation(id: $id) {
    ...ProductAssociationRow
  }
}
    ${ProductAssociationRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAssociationRowGQL extends Apollo.Query<ProductAssociationRowQuery, ProductAssociationRowQueryVariables> {
    document = ProductAssociationRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAssociationRowsDocument = gql`
    query ProductAssociationRows($filter: ProductAssociationFilterInput, $page: PageInput, $sort: SortInput) {
  items: productAssociations(filter: $filter, page: $page, sort: $sort) {
    ...ProductAssociationRow
  }
  totalItemsCount: productAssociationsCount(filter: $filter)
}
    ${ProductAssociationRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAssociationRowsGQL extends Apollo.Query<ProductAssociationRowsQuery, ProductAssociationRowsQueryVariables> {
    document = ProductAssociationRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAssociationDetailDocument = gql`
    query ProductAssociationDetail($id: Int!) {
  productAssociation(id: $id) {
    ...ProductAssociationDetail
  }
}
    ${ProductAssociationDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAssociationDetailGQL extends Apollo.Query<ProductAssociationDetailQuery, ProductAssociationDetailQueryVariables> {
    document = ProductAssociationDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssociatedProductsDocument = gql`
    query AssociatedProducts($associationId: Int!, $productId: Int!) {
  associatedProducts(associationId: $associationId, productId: $productId) {
    position
    associatedProduct {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssociatedProductsGQL extends Apollo.Query<AssociatedProductsQuery, AssociatedProductsQueryVariables> {
    document = AssociatedProductsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductAssociationDocument = gql`
    mutation CreateProductAssociation($input: CreateProductAssociationInput!) {
  createProductAssociation(input: $input) {
    ...ProductAssociationDetail
  }
}
    ${ProductAssociationDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductAssociationGQL extends Apollo.Mutation<CreateProductAssociationMutation, CreateProductAssociationMutationVariables> {
    document = CreateProductAssociationDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductAssociationDocument = gql`
    mutation UpdateProductAssociation($input: UpdateProductAssociationInput!) {
  updateProductAssociation(input: $input) {
    ...ProductAssociationDetail
  }
}
    ${ProductAssociationDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductAssociationGQL extends Apollo.Mutation<UpdateProductAssociationMutation, UpdateProductAssociationMutationVariables> {
    document = UpdateProductAssociationDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PositionAssociationDocument = gql`
    mutation positionAssociation($input: PositionProductAssociationInput!) {
  positionProductAssociation(input: $input) {
    ...ProductAssociationItem
  }
}
    ${ProductAssociationItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PositionAssociationGQL extends Apollo.Mutation<PositionAssociationMutation, PositionAssociationMutationVariables> {
    document = PositionAssociationDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfigureProductAssociationDocument = gql`
    mutation configureProductAssociation($input: ConfigureProductAssociationInput!) {
  configureProductAssociation(input: $input) {
    ...ProductAssociationConfig
  }
}
    ${ProductAssociationConfigFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigureProductAssociationGQL extends Apollo.Mutation<ConfigureProductAssociationMutation, ConfigureProductAssociationMutationVariables> {
    document = ConfigureProductAssociationDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }