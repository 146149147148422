import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  OrderDetailProductVariantItemGQL,
  OrderDetailProductVariantItemsGQL,
  OrderDetailProductVariantItemsQueryVariables,
  ProductVariantByIdGQL,
  ProductVariantItemGQL,
  ProductVariantItemsGQL,
  ProductVariantItemsQueryVariables,
  ProductVariantSearchGQL,
} from './gql/product-variant.gql.generated';
import {
  IOrderDetailProductVariantItemObject,
  IProductVariantItemObject,
  ProductVariantSearchObject,
} from './types/product-variant';

@Injectable()
export class ProductVariantService {
  constructor(
    private productVariantItemGQL: ProductVariantItemGQL,
    private productVariantItemsGQL: ProductVariantItemsGQL,
    private productVariantSearchGQL: ProductVariantSearchGQL,
    private productVariantByIdGQL: ProductVariantByIdGQL,
    private orderDetailProductVariantItemGQL: OrderDetailProductVariantItemGQL,
    private orderDetailProductVariantItemsGQL: OrderDetailProductVariantItemsGQL,
  ) {}

  fetchItem(id: number): Observable<IProductVariantItemObject> {
    return this.productVariantItemGQL
      .fetch({ id })
      .pipe(mapFetchResultData((r) => r.productVariant));
  }

  fetchItems(
    query: ProductVariantItemsQueryVariables,
  ): Observable<{ items: IProductVariantItemObject[]; totalItemsCount: number }> {
    return this.productVariantItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchVariantSearch(searchText: string): Observable<ProductVariantSearchObject[]> {
    return this.productVariantSearchGQL
      .fetch({ searchText })
      .pipe(mapFetchResultData((r) => r.productVariants));
  }

  fetchById(id: number): Observable<ProductVariantSearchObject> {
    return this.productVariantByIdGQL.fetch({ id }).pipe(map((r) => r.data.productVariant));
  }

  fetchItemsForOrderDetail(
    query: OrderDetailProductVariantItemsQueryVariables,
  ): Observable<{ items: IOrderDetailProductVariantItemObject[]; totalItemsCount: number }> {
    return this.orderDetailProductVariantItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItemForOrderDetail(id: number): Observable<IOrderDetailProductVariantItemObject> {
    return this.orderDetailProductVariantItemGQL
      .fetch({ id })
      .pipe(mapFetchResultData((r) => r.productVariant));
  }
}
