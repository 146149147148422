import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { FileUploadSevice } from '@x/dashboard/form';
import {
  CreateStockItemInput,
  StockItemFilterInput,
  UpdateStockItemCategoryInput,
  UpdateStockItemInput,
  UpdateStockItemTagsInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateGQL,
  PrintStockItemReportGQL,
  PrintStockLotAdjustmentGQL,
  StockItemDetailGQL,
  StockItemItemGQL,
  StockItemItemsGQL,
  StockItemItemsQueryVariables,
  StockItemRowsGQL,
  StockItemRowsQueryVariables,
  UpdateGQL,
  UpdateStockItemCategoryGQL,
  UpdateStockItemTagsGQL,
} from './gql/stock-item.gql.generated';
import {
  IStockItemDetailObject,
  IStockItemItemObject,
  IStockItemRowObject,
} from './types/stock-item';

@Injectable()
export class StockItemService {
  constructor(
    private stockItemRowsGQL: StockItemRowsGQL,
    private stockItemDetailGQL: StockItemDetailGQL,
    private stockItemItemsGQL: StockItemItemsGQL,
    private stockItemItemGQL: StockItemItemGQL,
    private updateGQL: UpdateGQL,
    private createGQL: CreateGQL,
    private UpdateStockItemTagsGQL: UpdateStockItemTagsGQL,
    private UpdateStockItemCategoryGQL: UpdateStockItemCategoryGQL,
    private printStockItemReportGQL: PrintStockItemReportGQL,
    private printStockLotAdjustmentGQL: PrintStockLotAdjustmentGQL,
    private readonly fileUploadService: FileUploadSevice,
  ) {}

  fetchRows(
    query: StockItemRowsQueryVariables,
  ): Observable<{ items: IStockItemRowObject[]; totalItemsCount: number }> {
    return this.stockItemRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchItems(
    query: StockItemItemsQueryVariables,
  ): Observable<{ items: IStockItemItemObject[]; totalItemsCount: number }> {
    return this.stockItemItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchItem(id: number): Observable<IStockItemItemObject> {
    return this.stockItemItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.stockItem));
  }

  fetchDetail(id: number): Observable<IStockItemDetailObject> {
    return this.stockItemDetailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.stockItem));
  }

  fetchById(id: number): Observable<IStockItemItemObject> {
    return this.stockItemItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.stockItem));
  }

  update(input: UpdateStockItemInput): Observable<IStockItemItemObject> {
    return this.updateGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.updateStockItem));
  }

  updateCategory(input: UpdateStockItemCategoryInput): Observable<IStockItemItemObject> {
    return this.UpdateStockItemCategoryGQL.mutate({ input }).pipe(
      mapFetchResultData((r) => r.updateStockItemCategory),
    );
  }

  updateTags(input: UpdateStockItemTagsInput): Observable<IStockItemItemObject> {
    return this.UpdateStockItemTagsGQL.mutate({ input }).pipe(
      mapFetchResultData((r) => r.updateStockItemTags),
    );
  }

  create(input: CreateStockItemInput): Observable<IStockItemItemObject> {
    return this.createGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.createStockItem));
  }

  search(
    searchText?: string | '', //: Observable<{ items: IWarehouseItemObject[]; totalItemsCount: number }>
  ): Observable<IStockItemItemObject[]> {
    const query: StockItemRowsQueryVariables = {
      filter: { search: searchText },
      page: {},
      sort: { column: 'name' },
    };
    return this.fetchRows(query).pipe(map((r) => r.items));
  }

  printStockItemsReport(filter: StockItemFilterInput, format?: string) {
    return this.printStockItemReportGQL
      .mutate({ filter, format })
      .pipe(mapFetchResultData((d) => d.printStockItemReport));
  }

  printStockLotAdjustment(filter: StockItemFilterInput, format?: string) {
    return this.printStockLotAdjustmentGQL
      .mutate({ filter, format })
      .pipe(mapFetchResultData((d) => d.printStockLotAdjustment));
  }

  uploadLotAdjustmentSpreadsheet(file: File) {
    return this.fileUploadService.createUpload<{
      success: boolean;
    }>(`/ecommerce/api/lot-quantity-adjustments/upload-spreadsheet`, file);
  }
}
