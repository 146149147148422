import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { ProductService, TaxonProductRowObject } from '@x/ecommerce/domain-client';
import { ProductPublishState } from '@x/schemas/ecommerce';
import { Observable, of } from 'rxjs';

export interface ITaxonProductRowCollectionFilter {
  includeDescendents: boolean;
  mainTaxon: boolean;
  hidden: boolean;
  publishStates: ProductPublishState[];
}
export interface ITaxconProductRowCollectionArgs {
  taxonId: number;
}

@Injectable({ providedIn: 'root' })
export class TaxonProductRowCollectionProvider
  implements
    IDataCollectionProvider<
      TaxonProductRowObject,
      ITaxonProductRowCollectionFilter,
      ITaxconProductRowCollectionArgs,
      number
    >
{
  defaultFilter = {
    includeDescendents: true,
    mainTaxon: false,
    hidden: false,
    publishStates: [ProductPublishState.Published],
  };

  constructor(private productService: ProductService) {}

  fetchCollection({
    filter,
    searchText,
    args,
    page,
  }: Readonly<
    IDataQuery<ITaxonProductRowCollectionFilter, ITaxconProductRowCollectionArgs>
  >): Observable<IDataCollection<TaxonProductRowObject>> {
    if (!args || (args.taxonId ?? null) === null) {
      return of({
        items: [],
        totalItemsCount: 0,
      });
    }

    const { taxonId } = args;

    return this.productService.fetchRowsForTaxon({
      page,
      taxonId,
      filter: {
        taxon: {
          taxonIds: [taxonId],
          mainTaxon: filter?.mainTaxon,
          includeDescendents: filter?.includeDescendents,
        },
        hidden: filter?.hidden,
        publishStates: filter?.publishStates,
        search: searchText,
      },
    });
  }

  toId(model: TaxonProductRowObject): number {
    return model.id;
  }

  toString(model: TaxonProductRowObject): string {
    return model.name;
  }
}
