import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { toTitleCase } from '@x/common/utils';
import { Observable } from 'rxjs';
import { IOrderSourceItemObject, OrderService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class OrderSourceItemCollectionProvider
  implements IDataCollectionProvider<IOrderSourceItemObject>
{
  constructor(private orderService: OrderService) {}

  fetchCollection({
    page,
    sort,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IOrderSourceItemObject>> {
    return this.orderService.fetchSourceItems({
      page,
      sort,
    });
  }

  fetchSingle(id: string): Partial<IOrderSourceItemObject> {
    return {
      id,
      name: id,
    };
  }

  toId(model: IOrderSourceItemObject): string {
    return model.id;
  }

  toString(model: IOrderSourceItemObject): string {
    return toTitleCase(model.name);
  }
}
