import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockScheduleItemFragment = { __typename: 'StockScheduleObject', id: number, name: string, interval: string, tracked: boolean, quantity: number, priority: number, shelfLife?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } };

export type StockScheduleItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type StockScheduleItemQuery = { __typename: 'Query', stockSchedule: { __typename: 'StockScheduleObject', id: number, name: string, interval: string, tracked: boolean, quantity: number, priority: number, shelfLife?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } } };

export type CreateStockScheduleMutationVariables = Types.Exact<{
  input: Types.StockScheduleCreateInput;
}>;


export type CreateStockScheduleMutation = { __typename: 'Mutation', createStockSchedule: { __typename: 'StockScheduleObject', id: number, name: string, interval: string, tracked: boolean, quantity: number, priority: number, shelfLife?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } } };

export type UpdateStockScheduleMutationVariables = Types.Exact<{
  input: Types.StockScheduleUpdateInput;
}>;


export type UpdateStockScheduleMutation = { __typename: 'Mutation', updateStockSchedule: { __typename: 'StockScheduleObject', id: number, name: string, interval: string, tracked: boolean, quantity: number, priority: number, shelfLife?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } } };

export type UpdateStockScheduleEnabledMutationVariables = Types.Exact<{
  input: Types.StockScheduleUpdateEnabledInput;
}>;


export type UpdateStockScheduleEnabledMutation = { __typename: 'Mutation', updateStockScheduleEnabled: { __typename: 'StockScheduleObject', id: number, name: string, interval: string, tracked: boolean, quantity: number, priority: number, shelfLife?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, item: { __typename: 'StockItemObject', id: number, sku: string, name: string }, warehouse: { __typename: 'WarehouseObject', id: number, code: string, name: string } } };

export const StockScheduleItemFragmentDoc = gql`
    fragment StockScheduleItem on StockScheduleObject {
  id
  name
  interval
  tracked
  quantity
  priority
  shelfLife
  startAt
  endAt
  enabled
  createdAt
  updatedAt
  item {
    id
    sku
    name
  }
  warehouse {
    id
    code
    name
  }
}
    `;
export const StockScheduleItemDocument = gql`
    query StockScheduleItem($id: Int!) {
  stockSchedule(id: $id) {
    ...StockScheduleItem
  }
}
    ${StockScheduleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockScheduleItemGQL extends Apollo.Query<StockScheduleItemQuery, StockScheduleItemQueryVariables> {
    document = StockScheduleItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStockScheduleDocument = gql`
    mutation CreateStockSchedule($input: StockScheduleCreateInput!) {
  createStockSchedule(input: $input) {
    ...StockScheduleItem
  }
}
    ${StockScheduleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStockScheduleGQL extends Apollo.Mutation<CreateStockScheduleMutation, CreateStockScheduleMutationVariables> {
    document = CreateStockScheduleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStockScheduleDocument = gql`
    mutation UpdateStockSchedule($input: StockScheduleUpdateInput!) {
  updateStockSchedule(input: $input) {
    ...StockScheduleItem
  }
}
    ${StockScheduleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStockScheduleGQL extends Apollo.Mutation<UpdateStockScheduleMutation, UpdateStockScheduleMutationVariables> {
    document = UpdateStockScheduleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStockScheduleEnabledDocument = gql`
    mutation UpdateStockScheduleEnabled($input: StockScheduleUpdateEnabledInput!) {
  updateStockScheduleEnabled(input: $input) {
    ...StockScheduleItem
  }
}
    ${StockScheduleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStockScheduleEnabledGQL extends Apollo.Mutation<UpdateStockScheduleEnabledMutation, UpdateStockScheduleEnabledMutationVariables> {
    document = UpdateStockScheduleEnabledDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }