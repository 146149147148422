import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateStockLotInput, UpdateStockLotInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AdjustStockLotGQL,
  CreateStockLotGQL,
  ManifestStockLotGQL,
  StockLotRefDetailGQL,
  StockLotRefRowGQL,
  StockLotRefsGQL,
  UpdateStockLotGQL,
} from './gql/stock-lot.gql.generated';
import { IStockLotDetail, IStockLotFilterInput, IStockLotItem } from './types/stock-lot';

@Injectable()
export class StockLotRefService {
  constructor(
    private readonly stockLotRefsGQL: StockLotRefsGQL,
    private readonly stockLotRefRowGQL: StockLotRefRowGQL,
    private readonly manifestGQL: ManifestStockLotGQL,
    private readonly refDetailGQL: StockLotRefDetailGQL,
    private readonly createGQL: CreateStockLotGQL,
    private readonly updateGQL: UpdateStockLotGQL,
    private readonly AdjustStockLotGQL: AdjustStockLotGQL,
  ) {}

  fetchDetail(id: string): Observable<IStockLotDetail> {
    return this.refDetailGQL.fetch({ id }).pipe(map((r) => r.data.stockLotRef));
  }

  fetchItem(id: string): Observable<IStockLotItem> {
    return this.stockLotRefRowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.stockLotRef));
  }

  fetchRows({
    filter,
  }: {
    filter: IStockLotFilterInput;
  }): Observable<{ items: IStockLotItem[]; totalItemsCount: number }> {
    return this.stockLotRefsGQL.fetch({ filter }).pipe(
      mapFetchResultData((r) => ({
        items: r.stockLotRefs,
        totalItemsCount: r.stockLotRefs.length,
      })),
    );
  }

  create(input: CreateStockLotInput) {
    return this.createGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.createStockLot));
  }

  update(input: UpdateStockLotInput) {
    return this.updateGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.updateStockLot));
  }

  adjust(ref: string, adjustment: number) {
    console.log({ ref, adjustment });
    return this.AdjustStockLotGQL.mutate({ ref, adjustment }).pipe(
      mapFetchResultData((r) => r.adjustStockLot),
    );
  }

  manifest(ref: string) {
    return this.manifestGQL.mutate({ ref }).pipe(mapFetchResultData((d) => d.manifestStockLot));
  }
}
