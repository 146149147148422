import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { ISubscriberTagItem, SubscriberTagService } from '@x/ecommerce/domain-client';
import { SubscriberTagFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriberTagItemCollectionProvider
  implements
    IDataCollectionProvider<ISubscriberTagItem, SubscriberTagFilterInput, any, number>,
    IDataProvider<ISubscriberTagItem, number>
{
  constructor(private subscriberTagService: SubscriberTagService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<SubscriberTagFilterInput, any>>): Observable<
    IDataCollection<ISubscriberTagItem>
  > {
    return this.subscriberTagService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): ISubscriberTagItem | Observable<ISubscriberTagItem> {
    return this.subscriberTagService.fetchItem(id);
  }

  toId(model: ISubscriberTagItem): number {
    return model.id;
  }

  toString(model: ISubscriberTagItem): string {
    return model.label;
  }
}
