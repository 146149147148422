import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { StockItemItemFragmentDoc } from './stock-item.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockItemComponentItemFragment = { __typename: 'StockItemComponentObject', id: number, componentQuantity: number, parentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string }, componentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string } };

export type StockItemComponentItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type StockItemComponentItemQuery = { __typename: 'Query', stockItemComponent: { __typename: 'StockItemComponentObject', id: number, componentQuantity: number, parentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string }, componentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string } } };

export type CreateStockItemComponentMutationVariables = Types.Exact<{
  input: Types.CreateStockItemComponentInput;
}>;


export type CreateStockItemComponentMutation = { __typename: 'Mutation', createStockItemComponent: { __typename: 'StockItemComponentObject', id: number, componentQuantity: number, parentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string }, componentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string } } };

export type UpdateStockItemComponentMutationVariables = Types.Exact<{
  input: Types.UpdateStockItemComponentInput;
}>;


export type UpdateStockItemComponentMutation = { __typename: 'Mutation', updateStockItemComponent: { __typename: 'StockItemComponentObject', id: number, componentQuantity: number, parentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string }, componentItem: { __typename: 'StockItemObject', id: number, name: string, sku: string } } };

export type DeleteStockItemComponentMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteStockItemComponentMutation = { __typename: 'Mutation', deleteStockItemComponent: number };

export const StockItemComponentItemFragmentDoc = gql`
    fragment StockItemComponentItem on StockItemComponentObject {
  id
  parentItem {
    ...StockItemItem
  }
  componentItem {
    ...StockItemItem
  }
  componentQuantity
}
    ${StockItemItemFragmentDoc}`;
export const StockItemComponentItemDocument = gql`
    query StockItemComponentItem($id: Int!) {
  stockItemComponent(id: $id) {
    ...StockItemComponentItem
  }
}
    ${StockItemComponentItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockItemComponentItemGQL extends Apollo.Query<StockItemComponentItemQuery, StockItemComponentItemQueryVariables> {
    document = StockItemComponentItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStockItemComponentDocument = gql`
    mutation CreateStockItemComponent($input: CreateStockItemComponentInput!) {
  createStockItemComponent(input: $input) {
    ...StockItemComponentItem
  }
}
    ${StockItemComponentItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStockItemComponentGQL extends Apollo.Mutation<CreateStockItemComponentMutation, CreateStockItemComponentMutationVariables> {
    document = CreateStockItemComponentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStockItemComponentDocument = gql`
    mutation UpdateStockItemComponent($input: UpdateStockItemComponentInput!) {
  updateStockItemComponent(input: $input) {
    ...StockItemComponentItem
  }
}
    ${StockItemComponentItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStockItemComponentGQL extends Apollo.Mutation<UpdateStockItemComponentMutation, UpdateStockItemComponentMutationVariables> {
    document = UpdateStockItemComponentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteStockItemComponentDocument = gql`
    mutation DeleteStockItemComponent($id: Int!) {
  deleteStockItemComponent(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteStockItemComponentGQL extends Apollo.Mutation<DeleteStockItemComponentMutation, DeleteStockItemComponentMutationVariables> {
    document = DeleteStockItemComponentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }