import { Directive, HostBinding } from '@angular/core';
import { DraggableDirective } from './draggable.directive';
import { DropZoneDirective } from './drop-zone.directive';

@Directive({
  selector: '[xDragBoundary]',
  host: {
    class: 'x-drag-boundary',
  },
})
export class DragBoundaryDirective {
  @HostBinding('class.x-dragging')
  isDragging = false;

  private dragging: DraggableDirective | null = null;
  private dropZones: { [key: string]: DropZoneDirective } = {};

  setDragging(dragging: DraggableDirective) {
    this.dragging = dragging;
    this.isDragging = true;
    this.updateAllDropZoneBlock();
  }

  getDragging() {
    return this.dragging;
  }

  resetDragging() {
    this.dragging = null;
    this.isDragging = false;
    this.updateAllDropZoneBlock();
  }

  registerDropZone(dropzone: DropZoneDirective) {
    this.dropZones[dropzone.id] = dropzone;
  }

  unregisterDropZone(dropzone: DropZoneDirective) {
    delete this.dropZones[dropzone.id];
  }

  private updateAllDropZoneBlock() {
    Object.values(this.dropZones).forEach((zone) => {
      this.updateDropZoneBlock(zone);
    });
  }

  private updateDropZoneBlock(zone: DropZoneDirective) {
    if (!this.isDragging) {
      zone.isDropBlocked = false;
      zone.isDropOpen = false;
    } else {
      const canDrop = zone.droppablePredicate(zone, this.dragging);
      zone.isDropBlocked = !canDrop;
      zone.isDropOpen = canDrop;
    }
  }
}
