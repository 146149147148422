import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CredentialsInput, ForgotPasswordInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { CurrentUserGQL, ForgotPasswordGQL, LoginUserGQL } from './gql/auth.gql.generated';
import { ILoginObject } from './types/auth';
import { IUserDetailObject } from './types/user';

@Injectable()
export class AuthService {
  constructor(
    private loginUserGQL: LoginUserGQL,
    private forgotPasswordGQL: ForgotPasswordGQL,
    private currentUserGQL: CurrentUserGQL,
  ) {}

  loginUser(credentials: CredentialsInput): Observable<ILoginObject> {
    return this.loginUserGQL.mutate({ credentials }).pipe(mapFetchResultData((r) => r.loginUser));
  }

  forgotPassword(credentials: ForgotPasswordInput) {
    return this.forgotPasswordGQL
      .mutate({ credentials })
      .pipe(mapFetchResultData((r) => r.forgotPassword));
  }

  currentUser(): Observable<IUserDetailObject> {
    return this.currentUserGQL.fetch().pipe(mapFetchResultData((r) => r.authUser));
  }
}
