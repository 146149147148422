import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AddressFragment = { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string };

export type AddressItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.AddressFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type AddressItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string }> };

export type AddressItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AddressItemQuery = { __typename: 'Query', address: { __typename: 'AddressObject', id: number, alias?: string | null | undefined, businessName?: string | null | undefined, city?: string | null | undefined, complex?: string | null | undefined, coordinates?: any | null | undefined, country: string, email?: string | null | undefined, firstName?: string | null | undefined, instructions?: string | null | undefined, lastName?: string | null | undefined, phoneNumber?: string | null | undefined, postalCode?: string | null | undefined, province?: string | null | undefined, street?: string | null | undefined, suburb?: string | null | undefined, type: Types.AddressType, archivedAt?: any | null | undefined, hash: string } };

export const AddressFragmentDoc = gql`
    fragment address on AddressObject {
  id
  alias
  businessName
  city
  complex
  coordinates
  country
  email
  firstName
  instructions
  lastName
  phoneNumber
  postalCode
  province
  street
  suburb
  type
  archivedAt
  hash
}
    `;
export const AddressItemsDocument = gql`
    query AddressItems($page: PageInput, $filter: AddressFilterInput, $sort: SortInput) {
  items: addresses(page: $page, filter: $filter, sort: $sort) {
    ...address
  }
  totalItemsCount: addressesCount(filter: $filter)
}
    ${AddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressItemsGQL extends Apollo.Query<AddressItemsQuery, AddressItemsQueryVariables> {
    document = AddressItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddressItemDocument = gql`
    query AddressItem($id: Int!) {
  address(id: $id) {
    ...address
  }
}
    ${AddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressItemGQL extends Apollo.Query<AddressItemQuery, AddressItemQueryVariables> {
    document = AddressItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }