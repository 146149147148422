import {
  AccountType,
  CouponType,
  FulfilmentState,
  OrderIncidentApprovalState,
  OrderIncidentItemAction,
  OrderIncidentResolutionState,
  OrderPaymentState,
  OrderState,
  PaymentState,
  ProductAssociationTarget,
  RelativeDateType,
  ShipmentState,
  ShippingCategoryComparison,
  StockType,
  SubscriberInterval,
  SubscriberState,
  SubscriptionRunInterval,
  SubscriptionVariantSmsType,
  TargetAuthorizationType,
  WalletType,
} from '@x/schemas/ecommerce';

export enum AdPlacement {
  CARD = 'CARD',
  V_BANNER = 'V_BANNER',
  H_BANNER = 'H_BANNER',
}

export const DOMAIN_LANGUAGE = {
  OrderState: {
    [OrderState.Cancelled]: 'Cancelled',
    [OrderState.Expired]: 'Expired',
    [OrderState.Cart]: 'Cart',
    [OrderState.Attention]: 'Attention',
    [OrderState.Completed]: 'Completed',
    [OrderState.Enquiry]: 'Enquiry',
    [OrderState.Confirmed]: 'Confirmed',
    [OrderState.Processing]: 'Processing',
    [OrderState.Quoted]: 'Quoted',
  },
  OrderPaymentState: {
    [OrderPaymentState.AwaitingPayment]: 'Awaiting Payment',
    [OrderPaymentState.Cancelled]: 'Cancelled',
    [OrderPaymentState.Cart]: 'Cart',
    [OrderPaymentState.Paid]: 'Paid',
    [OrderPaymentState.PartiallyPaid]: 'Partially Paid',
    [OrderPaymentState.PartiallyRefunded]: 'Partially Refunded',
    [OrderPaymentState.Refunded]: 'Refunded',
    [OrderPaymentState.Skipped]: 'Skipped',
  },
  PaymentState: {
    [PaymentState.Authorized]: 'Authorized',
    [PaymentState.Cancelled]: 'Cancelled',
    [PaymentState.Completed]: 'Completed',
    [PaymentState.Failed]: 'Failed',
    [PaymentState.New]: 'New',
    [PaymentState.Processing]: 'Processing',
    [PaymentState.Redemption]: 'Redemption',
    [PaymentState.Refunded]: 'Refunded',
  },
  ShipmentState: {
    [ShipmentState.Cancelled]: 'Cancelled',
    [ShipmentState.Delivered]: 'Delivered',
    [ShipmentState.New]: 'Cart',
    [ShipmentState.Reserved]: 'Reserved',
    [ShipmentState.Pending]: 'Pending',
    [ShipmentState.Shipped]: 'Shipped',
    [ShipmentState.WaybillRequired]: 'Waybill Required',
  },
  FulfilmentState: {
    [FulfilmentState.New]: 'Cart',
    [FulfilmentState.Allocated]: 'Allocated',
    [FulfilmentState.Cancelled]: 'Cancelled',
    [FulfilmentState.Packed]: 'Fulfilled',
    [FulfilmentState.Reserved]: 'Reserved',
    [FulfilmentState.Unallocated]: 'Unallocated',
  },
  OrderAdjustmentType: {
    CUSTOM: 'Custom',
    SHIPPING_FEE: 'Shipping Fee',
    SHIPPING_DISCOUNT: 'Shipping Discount',
    SHIPPING_TAX: 'Shipping Tax',
    PROMOTION_DISCOUNT: 'Promotion Discount',
  },
  SubscriptionRunInterval: {
    [SubscriptionRunInterval.Weekly]: 'Weekly',
    [SubscriptionRunInterval.Biweekly]: 'Bi-weekly',
    [SubscriptionRunInterval.Bimonthly]: 'Bi-monthly',
    [SubscriptionRunInterval.Monthly]: 'Monthly',
    [SubscriptionRunInterval.Quarterly]: 'Quarterly',
    [SubscriptionRunInterval.Yearly]: 'Yearly',
  },
  SubscriptionVariantSmsType: {
    [SubscriptionVariantSmsType.Custom]: 'Custom',
    [SubscriptionVariantSmsType.Deduction]: 'Deduction',
    [SubscriptionVariantSmsType.Delivery]: 'Delivery',
    [SubscriptionVariantSmsType.Paid]: 'Paid',
    [SubscriptionVariantSmsType.Unpaid]: 'Unpaid',
  },
  SubscriberState: {
    [SubscriberState.Active]: 'Active',
    [SubscriberState.Paused]: 'Paused',
    [SubscriberState.Cart]: 'Cart',
  },
  SubscriberIntervalState: {
    [SubscriberInterval.Active]: 'Active',
    [SubscriberInterval.Pending]: 'Pending',
    [SubscriberInterval.Expired]: 'Expired',
  },
  StockType: {
    [StockType.Physical]: 'Physical',
    [StockType.Virtual]: 'Virtual',
  },
  CouponType: {
    [CouponType.Single]: 'Single Coupon Code',
    [CouponType.Bulk]: 'Bulk Coupon Codes',
  },
  AccountType: {
    [AccountType.Sales]: 'Sales',
    [AccountType.Credit]: 'Credit',
  },
  RelativeDateType: {
    [RelativeDateType.Yesterday]: 'Yesterday',
    [RelativeDateType.Today]: 'Today',
    [RelativeDateType.Tomorrow]: 'Tomorrow',
    [RelativeDateType.DayAfterTomorrow]: 'Day After Tomorrow',
    [RelativeDateType.LastWeek]: 'Last Week',
    [RelativeDateType.ThisWeek]: 'This Week',
    [RelativeDateType.NextWeek]: 'Next Week',
    [RelativeDateType.LastYear]: 'Last Year',
    [RelativeDateType.ThisYear]: 'This Year',
    [RelativeDateType.NextYear]: 'Next Year',
  },
  ShippingCategoryComparison: {
    [ShippingCategoryComparison.IncludeOrNone]:
      'Only match items in these categories (or no category)',
    [ShippingCategoryComparison.Include]: 'Only match items in these categories',
    [ShippingCategoryComparison.Exclude]: 'Match any item except these categories',
  },
  ProductAssociationTarget: {
    [ProductAssociationTarget.ProductDetail]: 'Product Detail',
    [ProductAssociationTarget.Checkout]: 'Checkout',
    [ProductAssociationTarget.Cart]: 'Cart',
  },
  OrderIncidentItemAction: {
    [OrderIncidentItemAction.Refund]: 'Refund',
    [OrderIncidentItemAction.Replace]: 'Replace',
    [OrderIncidentItemAction.Return]: 'Return',
  },
  OrderIncidentApprovalState: {
    [OrderIncidentApprovalState.Draft]: 'Draft',
    [OrderIncidentApprovalState.Pending]: 'Pending',
    [OrderIncidentApprovalState.Approved]: 'Approved',
    [OrderIncidentApprovalState.Denied]: 'Denied',
  },
  OrderIncidentResolutionState: {
    [OrderIncidentResolutionState.Unresolved]: 'Unresolved',
    [OrderIncidentResolutionState.Pending]: 'Pending',
    [OrderIncidentResolutionState.Resolved]: 'Resolved',
    [OrderIncidentResolutionState.Failed]: 'Failed',
    [OrderIncidentResolutionState.Skipped]: 'Skipped',
  },
  OrderIncidentCategoryConfiguredSteps: {
    ['affectedItems']: 'Select Affected Items',
    ['replacementItems']: 'Select Replacement Items',
  },
  WalletType: {
    [WalletType.Shop]: 'Shop',
    [WalletType.Temp]: 'Temporary',
  },
  TargetAuthorizationType: {
    [TargetAuthorizationType.Any]: 'All Customers',
    [TargetAuthorizationType.Anonymous]: 'Only logged out customers',
    [TargetAuthorizationType.Authenticated]: 'Only logged in customers',
  },
  AdPlacement: {
    [AdPlacement.CARD]: 'Card',
    [AdPlacement.H_BANNER]: 'Horizontal Banner',
    [AdPlacement.V_BANNER]: 'Vertical Banner',
  },
  MediaQuery: {
    XS: 'Phones (0 - 576px)',
    SM: 'Small Devices (568px - 786px)',
    MD: 'Medium Devices (787px - 992px)',
    LG: 'Large Devices (993px and larger)',
  },
  MediaQueryLabel: {
    XS: 'Phones',
    SM: 'Small Devices',
    MD: 'Medium Devices',
    LG: 'Large Devices',
  },
};
