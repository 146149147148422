import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { SubscriptionMembershipBenefitDetailFragmentDoc } from './subscription-membership-benefit.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubscriptionVariantDetailFragment = { __typename: 'SubscriptionVariantObject', id: number, name: string, createdAt: any, updatedAt: any, interval: Types.SubscriptionRunInterval, duration?: any | null | undefined, autoRenew: boolean, enabled: boolean, channel: { __typename: 'ChannelObject', id: number, name: string, code: string }, translations: Array<{ __typename: 'SubscriptionVariantTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, slug: string, meta: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined }, smses?: Array<{ __typename: 'SubscriptionVariantSmsObject', message?: string | null | undefined, type: Types.SubscriptionVariantSmsType }> | null | undefined }>, subscription: { __typename: 'SubscriptionObject', id: number, name: string, code: string }, price: { __typename: 'SubscriptionVariantCurrencyObject', amount: number, currency: string }, productQuantities: Array<{ __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string } }>, membershipBenefits: Array<{ __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } }> };

export type SubscriptionVariantRowFragment = { __typename: 'SubscriptionVariantObject', id: number, name: string, createdAt: any, updatedAt: any, interval: Types.SubscriptionRunInterval, duration?: any | null | undefined, autoRenew: boolean, enabled: boolean, subscriberCount: number, runCount: number, channel: { __typename: 'ChannelObject', name: string, code: string, color: string }, subscription: { __typename: 'SubscriptionObject', name: string, code: string } };

export type SubscriptionVariantItemFragment = { __typename: 'SubscriptionVariantObject', id: number, name: string };

export type SubscriptionVariantRunRowFragment = { __typename: 'SubscriptionVariantObject', id: number };

export type SubscriptionVariantRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.SubscriptionVariantFilterInput>;
}>;


export type SubscriptionVariantRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'SubscriptionVariantObject', id: number, name: string, createdAt: any, updatedAt: any, interval: Types.SubscriptionRunInterval, duration?: any | null | undefined, autoRenew: boolean, enabled: boolean, subscriberCount: number, runCount: number, channel: { __typename: 'ChannelObject', name: string, code: string, color: string }, subscription: { __typename: 'SubscriptionObject', name: string, code: string } }> };

export type SubscriptionVariantItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionVariantItemQuery = { __typename: 'Query', subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string } };

export type SubscriptionVariantDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionVariantDetailQuery = { __typename: 'Query', subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string, createdAt: any, updatedAt: any, interval: Types.SubscriptionRunInterval, duration?: any | null | undefined, autoRenew: boolean, enabled: boolean, channel: { __typename: 'ChannelObject', id: number, name: string, code: string }, translations: Array<{ __typename: 'SubscriptionVariantTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, slug: string, meta: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined }, smses?: Array<{ __typename: 'SubscriptionVariantSmsObject', message?: string | null | undefined, type: Types.SubscriptionVariantSmsType }> | null | undefined }>, subscription: { __typename: 'SubscriptionObject', id: number, name: string, code: string }, price: { __typename: 'SubscriptionVariantCurrencyObject', amount: number, currency: string }, productQuantities: Array<{ __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string } }>, membershipBenefits: Array<{ __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } }> } };

export type SubscriptionVariantItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.SubscriptionVariantFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type SubscriptionVariantItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'SubscriptionVariantObject', id: number, name: string }> };

export type SubscriptionVariantSearchQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.SubscriptionVariantFilterInput>;
}>;


export type SubscriptionVariantSearchQuery = { __typename: 'Query', subscriptionVariants: Array<{ __typename: 'SubscriptionVariantObject', id: number, name: string }> };

export type SubscriptionVariantUpdateMutationVariables = Types.Exact<{
  data: Types.SubscriptionVariantInput;
}>;


export type SubscriptionVariantUpdateMutation = { __typename: 'Mutation', updateSubscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string, createdAt: any, updatedAt: any, interval: Types.SubscriptionRunInterval, duration?: any | null | undefined, autoRenew: boolean, enabled: boolean, channel: { __typename: 'ChannelObject', id: number, name: string, code: string }, translations: Array<{ __typename: 'SubscriptionVariantTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, slug: string, meta: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined }, smses?: Array<{ __typename: 'SubscriptionVariantSmsObject', message?: string | null | undefined, type: Types.SubscriptionVariantSmsType }> | null | undefined }>, subscription: { __typename: 'SubscriptionObject', id: number, name: string, code: string }, price: { __typename: 'SubscriptionVariantCurrencyObject', amount: number, currency: string }, productQuantities: Array<{ __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string } }>, membershipBenefits: Array<{ __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } }> } };

export type SubscriptionVariantCreateMutationVariables = Types.Exact<{
  data: Types.SubscriptionVariantInput;
}>;


export type SubscriptionVariantCreateMutation = { __typename: 'Mutation', createSubscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number, name: string, createdAt: any, updatedAt: any, interval: Types.SubscriptionRunInterval, duration?: any | null | undefined, autoRenew: boolean, enabled: boolean, channel: { __typename: 'ChannelObject', id: number, name: string, code: string }, translations: Array<{ __typename: 'SubscriptionVariantTranslationObject', id: number, locale: string, name: string, description?: string | null | undefined, slug: string, meta: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined }, smses?: Array<{ __typename: 'SubscriptionVariantSmsObject', message?: string | null | undefined, type: Types.SubscriptionVariantSmsType }> | null | undefined }>, subscription: { __typename: 'SubscriptionObject', id: number, name: string, code: string }, price: { __typename: 'SubscriptionVariantCurrencyObject', amount: number, currency: string }, productQuantities: Array<{ __typename: 'SubscriptionVariantProductQuantityObject', id: number, quantity: number, productVariant: { __typename: 'ProductVariantObject', id: number, name: string } }>, membershipBenefits: Array<{ __typename: 'SubscriptionMembershipBenefitObject', id: number, duration?: any | null | undefined, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined, variant: { __typename: 'SubscriptionVariantObject', id: number }, membership: { __typename: 'MembershipObject', id: number, name: string, code: string, isPublic: boolean, createdAt: any, updatedAt: any, archivedAt?: any | null | undefined } }> } };

export type SubscriptionVariantRunsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionVariantRunsQuery = { __typename: 'Query', subscriptionVariant: { __typename: 'SubscriptionVariantObject', id: number } };

export const SubscriptionVariantDetailFragmentDoc = gql`
    fragment SubscriptionVariantDetail on SubscriptionVariantObject {
  id
  name
  createdAt
  updatedAt
  interval
  duration
  autoRenew
  enabled
  channel {
    id
    name
    code
  }
  translations {
    id
    locale
    name
    description
    slug
    meta {
      title
      description
      keywords
    }
    smses {
      message
      type
    }
  }
  subscription {
    id
    name
    code
  }
  price {
    amount
    currency
  }
  productQuantities {
    id
    quantity
    productVariant {
      id
      name
    }
  }
  membershipBenefits {
    ...SubscriptionMembershipBenefitDetail
  }
}
    ${SubscriptionMembershipBenefitDetailFragmentDoc}`;
export const SubscriptionVariantRowFragmentDoc = gql`
    fragment SubscriptionVariantRow on SubscriptionVariantObject {
  id
  name
  createdAt
  updatedAt
  interval
  duration
  autoRenew
  enabled
  subscriberCount
  runCount
  channel {
    name
    code
    color
  }
  subscription {
    name
    code
  }
}
    `;
export const SubscriptionVariantItemFragmentDoc = gql`
    fragment SubscriptionVariantItem on SubscriptionVariantObject {
  id
  name
}
    `;
export const SubscriptionVariantRunRowFragmentDoc = gql`
    fragment SubscriptionVariantRunRow on SubscriptionVariantObject {
  id
}
    `;
export const SubscriptionVariantRowsDocument = gql`
    query SubscriptionVariantRows($page: PageInput, $sort: SortInput, $filter: SubscriptionVariantFilterInput) {
  items: subscriptionVariants(page: $page, sort: $sort, filter: $filter) {
    ...SubscriptionVariantRow
  }
  totalItemsCount: subscriptionVariantsCount(filter: $filter)
}
    ${SubscriptionVariantRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantRowsGQL extends Apollo.Query<SubscriptionVariantRowsQuery, SubscriptionVariantRowsQueryVariables> {
    document = SubscriptionVariantRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantItemDocument = gql`
    query SubscriptionVariantItem($id: Int!) {
  subscriptionVariant(id: $id) {
    ...SubscriptionVariantItem
  }
}
    ${SubscriptionVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantItemGQL extends Apollo.Query<SubscriptionVariantItemQuery, SubscriptionVariantItemQueryVariables> {
    document = SubscriptionVariantItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantDetailDocument = gql`
    query SubscriptionVariantDetail($id: Int!) {
  subscriptionVariant(id: $id) {
    ...SubscriptionVariantDetail
  }
}
    ${SubscriptionVariantDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantDetailGQL extends Apollo.Query<SubscriptionVariantDetailQuery, SubscriptionVariantDetailQueryVariables> {
    document = SubscriptionVariantDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantItemsDocument = gql`
    query SubscriptionVariantItems($page: PageInput, $filter: SubscriptionVariantFilterInput, $sort: SortInput) {
  items: subscriptionVariants(page: $page, filter: $filter, sort: $sort) {
    ...SubscriptionVariantItem
  }
  totalItemsCount: subscriptionVariantsCount(filter: $filter)
}
    ${SubscriptionVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantItemsGQL extends Apollo.Query<SubscriptionVariantItemsQuery, SubscriptionVariantItemsQueryVariables> {
    document = SubscriptionVariantItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantSearchDocument = gql`
    query SubscriptionVariantSearch($page: PageInput, $sort: SortInput, $filter: SubscriptionVariantFilterInput) {
  subscriptionVariants(page: $page, sort: $sort, filter: $filter) {
    ...SubscriptionVariantItem
  }
}
    ${SubscriptionVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantSearchGQL extends Apollo.Query<SubscriptionVariantSearchQuery, SubscriptionVariantSearchQueryVariables> {
    document = SubscriptionVariantSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantUpdateDocument = gql`
    mutation SubscriptionVariantUpdate($data: SubscriptionVariantInput!) {
  updateSubscriptionVariant(data: $data) {
    ...SubscriptionVariantDetail
  }
}
    ${SubscriptionVariantDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantUpdateGQL extends Apollo.Mutation<SubscriptionVariantUpdateMutation, SubscriptionVariantUpdateMutationVariables> {
    document = SubscriptionVariantUpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantCreateDocument = gql`
    mutation SubscriptionVariantCreate($data: SubscriptionVariantInput!) {
  createSubscriptionVariant(data: $data) {
    ...SubscriptionVariantDetail
  }
}
    ${SubscriptionVariantDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantCreateGQL extends Apollo.Mutation<SubscriptionVariantCreateMutation, SubscriptionVariantCreateMutationVariables> {
    document = SubscriptionVariantCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionVariantRunsDocument = gql`
    query SubscriptionVariantRuns($id: Int!) {
  subscriptionVariant(id: $id) {
    ...SubscriptionVariantRunRow
  }
}
    ${SubscriptionVariantRunRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionVariantRunsGQL extends Apollo.Query<SubscriptionVariantRunsQuery, SubscriptionVariantRunsQueryVariables> {
    document = SubscriptionVariantRunsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }