import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateTaxRateInput, UpdateTaxRateInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateTaxRateGQL,
  TaxRateByIdGQL,
  TaxRateListGqlGQL,
  TaxRateRowGQL,
  TaxRateRowsGQL,
  TaxRateRowsQueryVariables,
  TaxRateSearchGQL,
  UpdateTaxRateGQL,
} from './gql/tax-rate.gql.generated';
import { ITaxRateRowObject, TaxRateSearchObject } from './types/tax-rate';

@Injectable()
export class TaxRateService {
  constructor(
    private rowGql: TaxRateRowGQL,
    private rowsGql: TaxRateRowsGQL,
    private searchGql: TaxRateSearchGQL,
    private byIdGql: TaxRateByIdGQL,
    private createGql: CreateTaxRateGQL,
    private updateGql: UpdateTaxRateGQL,
    private listGql: TaxRateListGqlGQL,
  ) {}

  fetchById(id: number): Observable<TaxRateSearchObject> {
    return this.byIdGql.fetch({ id }).pipe(map((r) => r.data.taxRate));
  }

  fetchRow(id: number): Observable<ITaxRateRowObject> {
    return this.rowGql.fetch({ id }).pipe(map((r) => r.data.taxRate));
  }

  fetchRows(
    query: TaxRateRowsQueryVariables,
  ): Observable<{ items: ITaxRateRowObject[]; totalItemsCount: number }> {
    return this.rowsGql.fetch(query).pipe(map((r) => r.data));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<TaxRateSearchObject[]> {
    return this.searchGql.fetch({ searchText, results }).pipe(map((r) => r.data.taxRates));
  }

  getAllTaxRates() {
    return this.listGql.fetch().pipe(map((r) => r.data.taxRates));
  }

  create(input: CreateTaxRateInput) {
    return this.createGql.mutate({ input }).pipe(mapFetchResultData((r) => r.createTaxRate));
  }

  update(input: UpdateTaxRateInput) {
    return this.updateGql.mutate({ input }).pipe(mapFetchResultData((r) => r.updateTaxRate));
  }
}
