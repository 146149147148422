export const GRID_LANGUAGE = {
  grid: {
    action: {
      edit: 'Edit',
      create: 'Create',
      archive: 'Archive',
    },
    column: {
      id: 'ID',
      name: 'Name',
      createdAt: 'Created Date',
      updatedAt: 'Updated Date',
    },
    sort: {
      id: 'ID',
      name: 'Name',
      createdAt: 'Created Date',
      updatedAt: 'Updated Date',
    },
  },
};
