import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataPageModel,
  IDataQuery,
} from '@x/common/data';
import { IGoogleProductTaxon } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';
import { GoogleProductTypeService } from '../../domain-client/services/google-product-type.service';

@Injectable({ providedIn: 'root' })
export class GoogleProductTaxonCollectionProvider
  implements IDataCollectionProvider<IGoogleProductTaxon, any, any, string>
{
  defaultPage?: IDataPageModel | undefined = {
    index: 0,
    size: 50,
  };

  constructor(private service: GoogleProductTypeService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IGoogleProductTaxon>> {
    return this.service.listTaxonomies(searchText, page);
  }

  fetchSingle(id: string): IGoogleProductTaxon | Observable<IGoogleProductTaxon> {
    return this.service.fetchTaxonomy(id);
  }

  toId(model: IGoogleProductTaxon): string {
    return model.id;
  }

  toString(model: IGoogleProductTaxon): string {
    return model.name;
  }
}
