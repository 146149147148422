import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { IWarehouseItemObject, WarehouseService } from '@x/ecommerce/domain-client';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class WarehouseAutocompleteDataSource extends ModelAutocompleteDatasource<IWarehouseItemObject> {
  constructor(private service: WarehouseService) {
    super((i: number, item: IWarehouseItemObject) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<IWarehouseItemObject[]> {
    return searchText.pipe(
      switchMap((search) => {
        if (typeof search === 'string' && search.length > 0) {
          return this.service.search(search);
        }

        return of([]);
      }),
    );
  }

  disconnect(): void {}

  displayFn = (option: IWarehouseItemObject) => {
    if (typeof option !== 'object' || option === null) return '';
    return option.name || String(option.id);
  };
}
