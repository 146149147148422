import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { TaxonSearchObject, TaxonService } from '@x/ecommerce/domain-client';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class TaxonAutocompleteDataSource extends ModelAutocompleteDatasource<TaxonSearchObject> {
  constructor(private service: TaxonService) {
    super((i: number, item: TaxonSearchObject) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<TaxonSearchObject[]> {
    return searchText.pipe(
      switchMap((text) => {
        if (typeof text === 'string' && text.length > 0) {
          // only look at last part of search text
          // if still contains displayFn formatting
          if (text.indexOf('|') !== -1) {
            text = text.split('|').shift()?.trim() || '';
          }

          if (text.length === 0) return of([]);

          return this.service
            .fetchItems({
              filter: {
                search: text,
              },
              sort: {
                column: 'path',
                order: 'asc',
              },
              page: {
                size: 30,
                index: 0,
              },
            })
            .pipe(map((c) => c.items));
        }

        return of([]);
      }),
    );
  }

  disconnect(): void {}

  displayFn = (option: TaxonSearchObject) => {
    if (typeof option !== 'object' || option === null) return '';

    // if (option.ascendants && option.ascendants.length)
    //   return [option, ...option.ascendants.concat().reverse()].map((t) => t.name).join(' | ');

    if (option.ascendants && option.ascendants.length) {
      let path: string = option.name;

      for (let i = option.ascendants.length - 1; i >= 0; i--) {
        const element = option.ascendants[i].name;
        path = element + ' | ' + path;
      }
      return path;
    }

    return option.name || String(option.id);
  };

  displayStr() {}
}
