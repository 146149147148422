import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderFulfilmentIntervalItemsGQL } from './gql/fulfilment-intervals.gql.generated';
import { IFulfilmentIntervalItem } from './types/fulfilment-interval';

@Injectable()
export class FulfilmentIntervalService {
  constructor(private orderFulfilmentIntervalItemsGql: OrderFulfilmentIntervalItemsGQL) {}

  fetchItems(
    orderId: number,
    shippingMethodId?: number | null,
  ): Observable<IFulfilmentIntervalItem[]> {
    return this.orderFulfilmentIntervalItemsGql
      .fetch({ orderId, shippingMethodId })
      .pipe(map((r) => r.data.orderFulfilmentIntervals));
  }
}
