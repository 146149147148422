import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { ProductWishlistItemService } from '@x/ecommerce/domain-client';
import { ProductWishlistItemFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { ProductWishlistItemRowFragment } from '../../domain-client/services/gql/product-wishlist-item.gql.generated';
import { ProductWishlistItemRowObject } from '../../domain-client/services/types/product-wishlist-items';

@Injectable({ providedIn: 'root' })
export class ProductWishlistItemRowCollectionProvider
  implements
    IDataCollectionProvider<
      ProductWishlistItemRowObject,
      ProductWishlistItemFilterInput,
      any,
      number
    >
{
  constructor(private productWishlistItemService: ProductWishlistItemService) {}

  fetchCollection(
    query: Readonly<IDataQuery<any, any>>,
  ): Observable<IDataCollection<ProductWishlistItemRowFragment>> {
    return this.productWishlistItemService.fetchRows(query);
  }

  toId(model: ProductWishlistItemRowFragment): number {
    return model.id;
  }

  toString(model: ProductWishlistItemRowFragment): string {
    return String(model.id);
  }
}
