import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { CountryService, ICountryItem } from '@x/geocode/client';
import { tap } from 'rxjs/operators';

@Injectable()
export class CountryCodeTransformer implements ModelTransformer<ICountryItem, string> {
  private _cache = new Map<string, ICountryItem>();

  constructor(private countryService: CountryService) {}

  modelToValue(model: ICountryItem): string {
    return model.code;
  }

  valueToModel(code: string): Promise<ICountryItem | any> {
    if (this._cache.has(code)) {
      return Promise.resolve(this._cache.get(code) as ICountryItem);
    }

    return this.countryService
      .fetchCountryByCode(code)
      .pipe(
        tap((result) => {
          if (result) this._cache.set(code, result);
        }),
      )
      .toPromise();
  }
}
