import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CurrencyItem, CurrencyMap } from './types/currency';

@Injectable()
export class CurrencyService {
  private currencies$: Observable<CurrencyMap>;

  constructor(private http: HttpClient) {
    this.currencies$ = http
      .get<CurrencyMap>('/mediabucket/assets/currencies.json')
      .pipe(shareReplay());
  }

  list(): Observable<CurrencyItem[]> {
    return this.currencies$.pipe(map((map) => this.mapToList(map)));
  }

  search(text: string): Observable<CurrencyItem[]> {
    text = text.toLowerCase();

    return this.list().pipe(
      map((list) => {
        return list.filter((item) => {
          return item.id.toLowerCase().includes(text) || item.name.toLowerCase().includes(text);
        });
      }),
    );
  }

  private mapToList(map: CurrencyMap): CurrencyItem[] {
    return Object.keys(map).map((id) => {
      return { id, name: map[id] };
    });
  }
}
