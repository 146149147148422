import { Injectable } from '@angular/core';
import { LocaleItem, LocaleService } from '@x/common/locale';
import { ModelTransformer } from '@x/dashboard/form';
import { firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LocaleItemIdTransformer implements ModelTransformer<LocaleItem, string> {
  private _cache = new Map<string, LocaleItem>();

  constructor(private service: LocaleService) {}

  modelToValue(model: LocaleItem): string {
    return model.id;
  }

  valueToModel(id: string): Promise<LocaleItem> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as LocaleItem);
    }

    return firstValueFrom(
      this.service.search(id).pipe(
        tap((r) => this._cache.set(id, r[0])),
        map((r) => r[0]),
      ),
    );
  }
}
