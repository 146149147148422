import { Injectable } from '@angular/core';

import { mapFetchResultData } from '@x/common/graph';
import { CreateOrderTemplateInput, OrderTemplateItemInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  CreateOrderTemplateGQL,
  OrderTemplateItemGQL,
  OrderTemplateItemsGQL,
  OrderTemplateItemsQueryVariables,
  SetOrderTemplateItemGQL,
} from './gql/order-template.gql.generated';
import { IOrderTemplateItemObject } from './types/order-template';

@Injectable()
export class OrderTemplateService {
  constructor(
    private orderTemplateItemsGQL: OrderTemplateItemsGQL,
    private orderTemplateItemGQL: OrderTemplateItemGQL,
    private createOrderTemplateGQL: CreateOrderTemplateGQL,
    private setOrderTemplateGQL: SetOrderTemplateItemGQL,
  ) {}

  fetchItems(
    query: OrderTemplateItemsQueryVariables,
  ): Observable<{ items: IOrderTemplateItemObject[]; totalItemsCount: number }> {
    return this.orderTemplateItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchItem(id: number) {
    return this.orderTemplateItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.orderTemplate));
  }

  create(input: CreateOrderTemplateInput) {
    return this.createOrderTemplateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createOrderTemplate));
  }

  setItem(input: OrderTemplateItemInput) {
    return this.setOrderTemplateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.setOrderTemplateItem));
  }
}
