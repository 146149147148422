import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateWalletInput,
  CreditWalletInput,
  DebitWalletInput,
  DebitWalletPaymentInput,
  RefundOrderToWalletInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateWalletGQL,
  CreditWalletGQL,
  DebitWalletGQL,
  DebitWalletPaymentGQL,
  RefundOrderToWalletGQL,
  WalletDetailGQL,
  WalletRowsGQL,
  WalletRowsQueryVariables,
} from './gql/wallet.gql.generated';
import { WalletObject, WalletRowObject, WalletTransactionObject } from './types/wallet';

@Injectable()
export class WalletService {
  constructor(
    private walletRowsGQL: WalletRowsGQL,
    private walletDetailGQL: WalletDetailGQL,
    private createWalletGQL: CreateWalletGQL,
    private creditWalletGQL: CreditWalletGQL,
    private debitWalletGQL: DebitWalletGQL,
    private debitWalletPaymentGQL: DebitWalletPaymentGQL,
    private refundOrderToWalletGQL: RefundOrderToWalletGQL,
  ) {}

  fetchRows(
    query: WalletRowsQueryVariables,
  ): Observable<{ items: WalletRowObject[]; totalItemsCount: number }> {
    return this.walletRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchForUser(userId: number, currency?: string) {
    return this.fetchRows({
      sort: { column: 'id' },
      filter: { userIds: [userId] },
    }).pipe(map((r) => r.items));
  }

  fetchById(walletId: number): Observable<WalletObject> {
    return this.walletDetailGQL.fetch({ walletId }).pipe(mapFetchResultData((r) => r.wallet));
  }

  create(input: CreateWalletInput): Observable<WalletObject> {
    return this.createWalletGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.createWallet));
  }

  credit(input: CreditWalletInput): Observable<WalletTransactionObject> {
    return this.creditWalletGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.creditWallet));
  }

  debit(input: DebitWalletInput): Observable<WalletTransactionObject> {
    return this.debitWalletGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.debitWallet));
  }

  debitPayment(input: DebitWalletPaymentInput): Observable<WalletTransactionObject> {
    return this.debitWalletPaymentGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.debitWalletPayment));
  }

  refundOrderToWallet(input: RefundOrderToWalletInput) {
    return this.refundOrderToWalletGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.refundOrderToWallet));
  }
}
