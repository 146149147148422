import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AdItemFragment = { __typename: 'AdObject', id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any };

export type AdMediaFragment = { __typename: 'AdMediaObject', id: number, type: string, url: string, width?: number | null | undefined, height?: number | null | undefined, createdAt: any, updatedAt: any, alt?: string | null | undefined, quality?: number | null | undefined, mediaQuery: string, originalName?: string | null | undefined, placement?: string | null | undefined };

export type AdDetailFragment = { __typename: 'AdObject', targetChildrenTaxons: boolean, id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any, targetChannel: { __typename: 'ChannelObject', id: number, code: string, name: string }, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }>, targetRegions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, media: Array<{ __typename: 'AdMediaObject', id: number, type: string, url: string, width?: number | null | undefined, height?: number | null | undefined, createdAt: any, updatedAt: any, alt?: string | null | undefined, quality?: number | null | undefined, mediaQuery: string, originalName?: string | null | undefined, placement?: string | null | undefined }> };

export type AdRowFragment = { __typename: 'AdObject', targetChildrenTaxons: boolean, id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any, targetChannel: { __typename: 'ChannelObject', id: number, code: string, name: string }, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }>, targetRegions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, media: Array<{ __typename: 'AdMediaObject', id: number, type: string, url: string, width?: number | null | undefined, height?: number | null | undefined, createdAt: any, updatedAt: any, alt?: string | null | undefined, quality?: number | null | undefined, mediaQuery: string, originalName?: string | null | undefined, placement?: string | null | undefined }> };

export type AdDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AdDetailQuery = { __typename: 'Query', ad: { __typename: 'AdObject', targetChildrenTaxons: boolean, id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any, targetChannel: { __typename: 'ChannelObject', id: number, code: string, name: string }, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }>, targetRegions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, media: Array<{ __typename: 'AdMediaObject', id: number, type: string, url: string, width?: number | null | undefined, height?: number | null | undefined, createdAt: any, updatedAt: any, alt?: string | null | undefined, quality?: number | null | undefined, mediaQuery: string, originalName?: string | null | undefined, placement?: string | null | undefined }> } };

export type AdRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AdRowQuery = { __typename: 'Query', ad: { __typename: 'AdObject', targetChildrenTaxons: boolean, id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any, targetChannel: { __typename: 'ChannelObject', id: number, code: string, name: string }, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }>, targetRegions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, media: Array<{ __typename: 'AdMediaObject', id: number, type: string, url: string, width?: number | null | undefined, height?: number | null | undefined, createdAt: any, updatedAt: any, alt?: string | null | undefined, quality?: number | null | undefined, mediaQuery: string, originalName?: string | null | undefined, placement?: string | null | undefined }> } };

export type AdRowsQueryVariables = Types.Exact<{
  filter: Types.AdFilterInput;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type AdRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AdObject', targetChildrenTaxons: boolean, id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any, targetChannel: { __typename: 'ChannelObject', id: number, code: string, name: string }, targetTaxons: Array<{ __typename: 'TaxonObject', id: number, code: string, name: string }>, targetRegions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, media: Array<{ __typename: 'AdMediaObject', id: number, type: string, url: string, width?: number | null | undefined, height?: number | null | undefined, createdAt: any, updatedAt: any, alt?: string | null | undefined, quality?: number | null | undefined, mediaQuery: string, originalName?: string | null | undefined, placement?: string | null | undefined }> }> };

export type AdItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AdItemQuery = { __typename: 'Query', ad: { __typename: 'AdObject', id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any } };

export type AdItemsQueryVariables = Types.Exact<{
  filter: Types.AdFilterInput;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type AdItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AdObject', id: number, name: string, url: string, urlTarget: string, weight: number, enabled: boolean, targetAuthorizationType: Types.TargetAuthorizationType, startsAt?: any | null | undefined, endsAt?: any | null | undefined, createdAt: any, updatedAt: any }> };

export type CreateAdMutationVariables = Types.Exact<{
  input: Types.CreateAdInput;
}>;


export type CreateAdMutation = { __typename: 'Mutation', createAd: { __typename: 'AdObject', id: number } };

export type UpdateAdMutationVariables = Types.Exact<{
  input: Types.UpdateAdInput;
}>;


export type UpdateAdMutation = { __typename: 'Mutation', updateAd: { __typename: 'AdObject', id: number } };

export type CloneAdMutationVariables = Types.Exact<{
  input: Types.CloneAdInput;
}>;


export type CloneAdMutation = { __typename: 'Mutation', cloneAd: { __typename: 'AdObject', id: number } };

export const AdItemFragmentDoc = gql`
    fragment AdItem on AdObject {
  id
  name
  url
  urlTarget
  weight
  enabled
  targetAuthorizationType
  startsAt
  endsAt
  createdAt
  updatedAt
}
    `;
export const AdMediaFragmentDoc = gql`
    fragment AdMedia on AdMediaObject {
  id
  type
  url
  width
  height
  createdAt
  updatedAt
  alt
  quality
  mediaQuery
  originalName
  placement
}
    `;
export const AdDetailFragmentDoc = gql`
    fragment AdDetail on AdObject {
  ...AdItem
  targetChannel {
    id
    code
    name
  }
  targetChildrenTaxons
  targetTaxons {
    id
    code
    name
  }
  targetRegions {
    id
    name
    code
  }
  media {
    ...AdMedia
  }
}
    ${AdItemFragmentDoc}
${AdMediaFragmentDoc}`;
export const AdRowFragmentDoc = gql`
    fragment AdRow on AdObject {
  ...AdItem
  targetChannel {
    id
    code
    name
  }
  targetChildrenTaxons
  targetTaxons {
    id
    code
    name
  }
  targetRegions {
    id
    name
    code
  }
  media {
    ...AdMedia
  }
}
    ${AdItemFragmentDoc}
${AdMediaFragmentDoc}`;
export const AdDetailDocument = gql`
    query AdDetail($id: Int!) {
  ad(id: $id) {
    ...AdDetail
  }
}
    ${AdDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdDetailGQL extends Apollo.Query<AdDetailQuery, AdDetailQueryVariables> {
    document = AdDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdRowDocument = gql`
    query AdRow($id: Int!) {
  ad(id: $id) {
    ...AdRow
  }
}
    ${AdRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdRowGQL extends Apollo.Query<AdRowQuery, AdRowQueryVariables> {
    document = AdRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdRowsDocument = gql`
    query AdRows($filter: AdFilterInput!, $page: PageInput, $sort: SortInput) {
  items: ads(filter: $filter, page: $page, sort: $sort) {
    ...AdRow
  }
  totalItemsCount: adsCount(filter: $filter)
}
    ${AdRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdRowsGQL extends Apollo.Query<AdRowsQuery, AdRowsQueryVariables> {
    document = AdRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdItemDocument = gql`
    query AdItem($id: Int!) {
  ad(id: $id) {
    ...AdItem
  }
}
    ${AdItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdItemGQL extends Apollo.Query<AdItemQuery, AdItemQueryVariables> {
    document = AdItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdItemsDocument = gql`
    query AdItems($filter: AdFilterInput!, $page: PageInput, $sort: SortInput) {
  items: ads(filter: $filter, page: $page, sort: $sort) {
    ...AdItem
  }
  totalItemsCount: adsCount(filter: $filter)
}
    ${AdItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdItemsGQL extends Apollo.Query<AdItemsQuery, AdItemsQueryVariables> {
    document = AdItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAdDocument = gql`
    mutation CreateAd($input: CreateAdInput!) {
  createAd(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdGQL extends Apollo.Mutation<CreateAdMutation, CreateAdMutationVariables> {
    document = CreateAdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdDocument = gql`
    mutation UpdateAd($input: UpdateAdInput!) {
  updateAd(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdGQL extends Apollo.Mutation<UpdateAdMutation, UpdateAdMutationVariables> {
    document = UpdateAdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloneAdDocument = gql`
    mutation CloneAd($input: CloneAdInput!) {
  cloneAd(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloneAdGQL extends Apollo.Mutation<CloneAdMutation, CloneAdMutationVariables> {
    document = CloneAdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }