import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  BulkOrderService,
  IBulkOrderDetailObject,
  IBulkOrderRowObject,
} from '@x/ecommerce/domain-client';
import { OrderFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BulkOrderRowCollectionProvider
  implements IDataCollectionProvider<IBulkOrderRowObject, OrderFilterInput, any, number>
{
  constructor(private bulkOrderService: BulkOrderService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IBulkOrderRowObject>> {
    return this.bulkOrderService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IBulkOrderRowObject): number {
    return model.id;
  }

  toString(model: IBulkOrderRowObject): string {
    return `${model.name ? '#' : 'ID'}${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class BulkOrderDetailProvider implements IDataProvider<IBulkOrderDetailObject, number> {
  constructor(private bulkOrderService: BulkOrderService) {}

  fetchSingle(id: number): IBulkOrderDetailObject | Observable<IBulkOrderDetailObject> {
    return this.bulkOrderService.fetchById(id);
  }

  toId(model: IBulkOrderDetailObject): number {
    return model.id;
  }

  toString(model: IBulkOrderDetailObject): string {
    return `${model.id}`;
  }
}
