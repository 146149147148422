import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { SubscriberFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  ISubscriberItemObject,
  ISubscriberRowObject,
  SubscriberService,
} from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class SubscriberItemCollectionProvider
  implements IDataCollectionProvider<ISubscriberItemObject, SubscriberFilterInput, any, number>
{
  constructor(private subscriberService: SubscriberService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriberItemObject>> {
    return this.subscriberService.fetchItems({
      filter: { ...filter, search: searchText },
      page,
      sort,
    });
  }

  fetchSingle(id: number): Observable<ISubscriberItemObject> {
    return this.subscriberService.fetchItem(id);
  }

  toId(model: ISubscriberItemObject): number {
    return model.id;
  }

  toString(model: ISubscriberItemObject): string {
    return `${model.user?.firstName} ${model.user?.lastName} <${model.user.email}>`;
  }
}

@Injectable({ providedIn: 'root' })
export class SubscriberRowCollectionProvider
  implements IDataCollectionProvider<ISubscriberRowObject, SubscriberFilterInput, any, number>
{
  constructor(private subscriberService: SubscriberService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ISubscriberRowObject>> {
    return this.subscriberService.fetchRows({
      filter: { ...filter, search: searchText },
      page,
      sort,
    });
  }

  toId(model: ISubscriberRowObject): number {
    return model.id;
  }

  toString(model: ISubscriberRowObject): string {
    return `Subscriber ID${model.id}`;
  }
}
