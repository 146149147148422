import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { mapFetchResultData } from '@x/common/graph';
import { CreateProductOptionInput, UpdateProductOptionInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ArchiveProductOptionGQL,
  CreateProductOptionGQL,
  ProductOptionDetailGQL,
  ProductOptionItemGQL,
  ProductOptionItemsGQL,
  ProductOptionItemsQueryVariables,
  ProductOptionRowGQL,
  ProductOptionRowsGQL,
  ProductOptionRowsQueryVariables,
  ProductOptionSearchGQL,
  UpdateProductOptionGQL,
} from './gql/product-option.gql.generated';
import {
  IProductOptionItemObject,
  IProductOptionRowObject,
  ProductOptionDetailObject,
  ProductOptionSearchObject,
} from './types/product-option';

@Injectable()
export class ProductOptionService {
  constructor(
    private productOptionItemGQL: ProductOptionItemGQL,
    private productOptionItemsGQL: ProductOptionItemsGQL,
    private store: Store,
    private searchGQL: ProductOptionSearchGQL,
    private rowGql: ProductOptionRowGQL,
    private rowsGql: ProductOptionRowsGQL,
    private createOptionGQL: CreateProductOptionGQL,
    private updateOptionGQL: UpdateProductOptionGQL,
    private detailGql: ProductOptionDetailGQL,
    private archiveOptionGQL: ArchiveProductOptionGQL,
  ) {}

  fetchItem(id: number): Observable<IProductOptionItemObject> {
    return this.productOptionItemGQL.fetch({ id }).pipe(map((r) => r.data.productOption));
  }

  fetchItems(
    query: ProductOptionItemsQueryVariables,
  ): Observable<{ items: IProductOptionItemObject[]; totalItemsCount: number }> {
    return this.productOptionItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number): Observable<IProductOptionRowObject> {
    return this.rowGql.fetch({ id }).pipe(map((r) => r.data.productOption));
  }

  fetchRows(query: ProductOptionRowsQueryVariables): Observable<{
    items: IProductOptionRowObject[];
    totalItemsCount: number;
  }> {
    return this.rowsGql.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<ProductOptionDetailObject> {
    return this.detailGql.fetch({ id }).pipe(map((r) => r.data.productOption));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<ProductOptionSearchObject[]> {
    return this.searchGQL
      .fetch({ searchText, results })
      .pipe(map((result) => result.data.productOptions));
  }

  create(data: CreateProductOptionInput) {
    return this.createOptionGQL
      .mutate({
        data,
      })
      .pipe(mapFetchResultData((data) => data.createProductOption));
  }

  update(data: UpdateProductOptionInput) {
    return this.updateOptionGQL
      .mutate({ data })
      .pipe(mapFetchResultData((data) => data.updateProductOption));
  }

  archive(productOptionId: number): Observable<ProductOptionDetailObject> {
    return this.archiveOptionGQL
      .mutate({ productOptionId })
      .pipe(mapFetchResultData((r) => r.archiveProductOption));
  }
}
