import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { ContentService, IContentRefObject } from '@x/ecommerce/domain-client';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface ContentSearchArguments {
  contentType: string;
  locale?: string | null;
  provider?: string | null;
}

@Injectable()
export class ContentAutocompleteDataSource extends ModelAutocompleteDatasource<
  IContentRefObject,
  ContentSearchArguments
> {
  constructor(private contentService: ContentService) {
    super((i: number, item: IContentRefObject) => item.ref);
  }

  connect(
    searchText: Observable<string | null>,
    searchArguments: Observable<ContentSearchArguments>,
  ): Observable<IContentRefObject[]> {
    return combineLatest([searchText, searchArguments]).pipe(
      switchMap((args) =>
        args[0] && args[0]?.length > 0
          ? this.contentService.fetchSearch({
              contentType: args[1].contentType,
              locale: args[1].locale,
              provider: args[1].provider,
              search: args[0],
            })
          : of([]),
      ),
    );
  }

  disconnect(): void {}

  displayFn = (c: IContentRefObject) => {
    if (typeof c !== 'object' || c === null) return '';
    return `${c.name}`;
  };
}
