import { Injectable, Injector, ProviderToken } from '@angular/core';
import { OperationObserverService } from '@x/common/operation';
import { LocalStorage, SessionStorage } from '@x/common/storage';
import { Observable } from 'rxjs';
import { GridScrollStateSessionStorage } from './grid-scroll-storage';
import { GridStateLocalStorage } from './grid-state-storage';
import {
  GridDatasource,
  GridDatasourceData,
  GridDatasourceOptions,
  GridDatasourceQuery,
  GridFetchFn,
} from './grid.datasource';

export interface IGridDatasourceService<
  TGridModel extends Record<string, any> = any,
  TGridFilter = any,
  TGridArgs = any,
> {
  fetchRows(
    query: Readonly<GridDatasourceQuery<TGridFilter, TGridArgs>>,
  ): Observable<GridDatasourceData<TGridModel>> | GridDatasourceData<TGridModel>;
}

@Injectable()
export class GridDatasourceFactory {
  constructor(
    private operationService: OperationObserverService,
    private injector: Injector,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
  ) {}

  make<
    TGridModel extends Record<string, any> = any,
    TGridFilter extends Record<string, any> = any,
    TGridArgs = any,
  >(
    key: string,
    type: ProviderToken<IGridDatasourceService<TGridModel, TGridFilter, TGridArgs>>,
    options: GridDatasourceOptions<TGridFilter, TGridArgs>,
  ): GridDatasource<TGridModel, TGridFilter, TGridArgs> {
    const service =
      this.injector.get<IGridDatasourceService<TGridModel, TGridFilter, TGridArgs>>(type);

    const fetchFn: GridFetchFn = (query) => service.fetchRows(query);

    const storage = new GridStateLocalStorage(`${key}_state`, this.localStorage);
    const scrollStorage = new GridScrollStateSessionStorage(`${key}_scroll`, this.sessionStorage);

    const datasource = new GridDatasource(options, fetchFn, storage, scrollStorage);

    return datasource;
  }
}
