import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { ProductAttributeSearchObject, ProductAttributeService } from '@x/ecommerce/domain-client';
import { Observable, of } from 'rxjs';
import { shareReplay, startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class ProductAttributeAutocompleteDataSource extends ModelAutocompleteDatasource<ProductAttributeSearchObject> {
  constructor(private service: ProductAttributeService) {
    super((i: number, item: ProductAttributeSearchObject) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<ProductAttributeSearchObject[]> {
    const defaultSearch = this.service.fetchSearch().pipe(shareReplay());

    return searchText.pipe(
      startWith(null),
      switchMap((text) => {
        if (typeof text === 'string' && text.length > 0) {
          // only look at last part of search text
          // if still contains displayFn formatting

          if (text.length === 0) return of([]);

          return this.service.fetchSearch(text);
        }

        return defaultSearch;
      }),
    );
  }

  disconnect(): void {}

  displayFn = (option: ProductAttributeSearchObject) => {
    if (!option) return '';
    return option.name ?? String(option.id ?? '');
  };
}
