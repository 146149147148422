import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EcommerceAuthTokenContextService } from './auth-token-context.service';

function isGqlRequest(req: HttpRequest<any>): boolean {
  return req.url === '/ecommerce/graphql';
}

function isApiRequest(req: HttpRequest<any>): boolean {
  return req.url.includes('/ecommerce/api');
}

@Injectable()
export class EcommerceAuthTokenInterceptor implements HttpInterceptor {
  constructor(private authTokenContext: EcommerceAuthTokenContextService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authTokenContext.token;

    if (token !== null && (isGqlRequest(req) || isApiRequest(req))) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          ...(isGqlRequest(req) ? { 'Content-Type': 'application/json' } : {}),
        },
      });
    }

    return next.handle(req);
  }
}
