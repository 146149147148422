import { Injectable } from '@angular/core';
import { LocaleItem } from '@x/common/locale';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { ChannelLocaleObject } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChannelContext } from '../services/channel-context.service';

@Injectable()
export class LocaleAutocompleteDataSource extends ModelAutocompleteDatasource<LocaleItem> {
  locales$ = this.channelContext.getLocaleOptionsInAllChannels();

  constructor(private channelContext: ChannelContext) {
    super((i: number, item: LocaleItem) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<LocaleItem[]> {
    return searchText.pipe(
      switchMap((text) =>
        this.locales$.pipe(
          map((locales) =>
            text
              ? locales.filter(
                  (l) =>
                    l.id.toLowerCase().includes(text.toLowerCase()) ||
                    l.name.toLowerCase().includes(text.toLowerCase()),
                )
              : locales,
          ),
        ),
      ),
    );
  }

  disconnect(): void {}

  displayFn = (locale: LocaleItem | ChannelLocaleObject) => {
    if (typeof locale !== 'object' || locale === null) return '';

    return `${locale.name}`;
  };
}
