import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IProductAttributeItemObject,
  IProductAttributeRowObject,
  ProductAttributeService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductAttributeItemCollectionProvider
  implements IDataCollectionProvider<IProductAttributeItemObject>
{
  constructor(private productAttributeService: ProductAttributeService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IProductAttributeItemObject>> {
    return this.productAttributeService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IProductAttributeItemObject | Observable<IProductAttributeItemObject> {
    return this.productAttributeService.fetchItem(Number(id));
  }

  toId(model: IProductAttributeItemObject): string | number {
    return model.id;
  }

  toString(model: IProductAttributeItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ProductAttributeRowCollectionProvider
  implements IDataCollectionProvider<IProductAttributeRowObject>
{
  constructor(private productAttributeService: ProductAttributeService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IProductAttributeRowObject>> {
    return this.productAttributeService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IProductAttributeRowObject | Observable<IProductAttributeRowObject> {
    return this.productAttributeService.fetchRow(Number(id));
  }

  toId(model: IProductAttributeRowObject): string | number {
    return model.id;
  }

  toString(model: IProductAttributeRowObject): string {
    return model.name;
  }
}
