import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
  IDataSortModel,
} from '@x/common/data';
import { ProductAssociationService } from '@x/ecommerce/domain-client';
import { ProductAssociationFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  ProdectAssociationItemObject,
  ProdectAssociationRowObject,
} from '../../domain-client/services/types/product-association';

@Injectable({ providedIn: 'root' })
export class ProductAssociationItemCollectionProvider
  implements
    IDataProvider<ProdectAssociationItemObject>,
    IDataCollectionProvider<ProdectAssociationItemObject, ProductAssociationFilterInput>
{
  defaultSort?: IDataSortModel = {
    column: 'position',
    order: 'asc',
  };

  constructor(private productAssociationService: ProductAssociationService) {}

  fetchSingle(id: number): Observable<ProdectAssociationItemObject> {
    return this.productAssociationService.fetchItem(id);
  }

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ProdectAssociationItemObject>> {
    return this.productAssociationService.fetchItems({
      filter: { search: searchText, ...filter },
      page: page
        ? {
            index: page.index,
            size: page.size,
          }
        : undefined,
      sort: sort
        ? {
            column: sort.column,
            order: sort.order,
          }
        : undefined,
    });
  }

  toId(model: ProdectAssociationItemObject): number {
    return model.id;
  }

  toString(model: ProdectAssociationItemObject): string {
    return model.title;
  }
}

@Injectable({ providedIn: 'root' })
export class ProductAssociationRowCollectionProvider
  implements IDataCollectionProvider<ProdectAssociationRowObject>
{
  constructor(private productAssociationService: ProductAssociationService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ProdectAssociationRowObject>> {
    console.log({ filter });
    return this.productAssociationService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<ProdectAssociationRowObject> {
    return this.productAssociationService.fetchRow(Number(id));
  }

  toId(model: ProdectAssociationRowObject): number | string {
    return model.id;
  }

  toString(model: ProdectAssociationRowObject): string {
    return model.title;
  }
}
