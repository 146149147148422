import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateChannelInput, UpdateChannelInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChannelByIdGQL,
  ChannelDetailByCodeGQL,
  ChannelDetailGQL,
  ChannelItemGQL,
  ChannelItemsGQL,
  ChannelItemsQueryVariables,
  ChannelRowGQL,
  ChannelRowsGQL,
  ChannelRowsQueryVariables,
  ChannelSearchGQL,
  ChannelSetAsDefaultGQL,
  ChannelsGQL,
  CreateChannelGQL,
  DefaultChannelGQL,
  UpdateChannelGQL,
} from './gql/channel.gql.generated';
import { IChannelDetailObject, IChannelItemObject, IChannelRowObject } from './types/channel';

@Injectable()
export class ChannelService {
  constructor(
    private allGQL: ChannelsGQL,
    private createGQL: CreateChannelGQL,
    private updateGQL: UpdateChannelGQL,
    // private updateManyGQL: UpdateManyChannelsGQL,
    private rowGQL: ChannelRowGQL,
    private rowsGQL: ChannelRowsGQL,
    private itemsGQL: ChannelItemsGQL,
    private itemGQL: ChannelItemGQL,
    private searchGQL: ChannelSearchGQL,
    private byIdGQL: ChannelByIdGQL,
    private detailGQL: ChannelDetailGQL,
    private detailByCodeGQL: ChannelDetailByCodeGQL,
    private setAsDefaultGQL: ChannelSetAsDefaultGQL,
    private fetchDefaultChannelGQL: DefaultChannelGQL,
  ) {}

  fetchDefaultChannel(): Observable<IChannelDetailObject> {
    return this.fetchDefaultChannelGQL.fetch().pipe(map((r) => r.data.defaultChannel));
  }

  fetchItem(id: number): Observable<IChannelItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.channel));
  }

  fetchItems(
    query: ChannelItemsQueryVariables,
  ): Observable<{ items: IChannelItemObject[]; totalItemsCount: number }> {
    return this.itemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number): Observable<IChannelRowObject> {
    return this.rowGQL.fetch({ id }).pipe(map((r) => r.data.channel));
  }

  fetchRows(
    query: ChannelRowsQueryVariables,
  ): Observable<{ items: IChannelRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchAll() {
    return this.allGQL.fetch().pipe(map((r) => r.data.channels));
  }

  fetchDetail(id: number): Observable<IChannelDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.channel));
  }

  fetchDetailByCode(code: string): Observable<IChannelDetailObject> {
    return this.detailByCodeGQL.fetch({ code }).pipe(map((r) => r.data.channelByCode));
  }

  fetchById(id: number): Observable<IChannelItemObject> {
    return this.byIdGQL.fetch({ id }).pipe(map((r) => r.data.channel));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<IChannelItemObject[]> {
    return this.searchGQL.fetch({ searchText, results }).pipe(map((r) => r.data.channels));
  }

  create(data: CreateChannelInput) {
    return this.createGQL.mutate({ data }).pipe(mapFetchResultData((data) => data.createChannel));
  }

  update(data: UpdateChannelInput) {
    return this.updateGQL.mutate({ data }).pipe(mapFetchResultData((data) => data.updateChannel));
  }

  setAsDefault(id: number) {
    return this.setAsDefaultGQL
      .mutate({ id })
      .pipe(mapFetchResultData((data) => data.channelSetDefault));
  }

  // updateMany(datas: UpdateChannelInput[]) {
  //   return this.updateManyGQL
  //     .mutate({ datas })
  //     .pipe(mapFetchResultData((data) => data.updateManyChannels));
  // }
}
