import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { OrderIncidentFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { OrderIncidentService } from '../../domain-client';
import {
  IOrderIncidentDetailObject,
  IOrderIncidentItemObject,
} from '../../domain-client/services/types/order-incident';

@Injectable({ providedIn: 'root' })
export class OrderIncidentItemCollectionProvider
  implements
    IDataCollectionProvider<IOrderIncidentItemObject, OrderIncidentFilterInput, any, number>,
    IDataProvider<IOrderIncidentItemObject>
{
  constructor(private readonly service: OrderIncidentService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<OrderIncidentFilterInput, any>>): Observable<
    IDataCollection<IOrderIncidentItemObject>
  > {
    return this.service.fetchRows({
      filter: { ...filter, search: searchText },
      page,
      sort,
    });
  }

  fetchSingle(id: number): Observable<IOrderIncidentItemObject> {
    return this.service.fetchItem(id);
  }

  toId(model: IOrderIncidentItemObject): number {
    return model.id;
  }

  toString(model: IOrderIncidentItemObject): string {
    return `Order Incident ID${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class OrderIncidentRowCollectionProvider
  implements
    IDataCollectionProvider<IOrderIncidentItemObject, OrderIncidentFilterInput, any, number>
{
  constructor(private service: OrderIncidentService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IOrderIncidentItemObject>> {
    return this.service.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IOrderIncidentItemObject): number {
    return model.id;
  }

  toString(model: IOrderIncidentItemObject): string {
    return `Order Incident ID${model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class OrderIncidentDetailProvider
  implements IDataProvider<IOrderIncidentDetailObject, number>
{
  constructor(private service: OrderIncidentService) {}

  fetchSingle(id: number): IOrderIncidentDetailObject | Observable<IOrderIncidentDetailObject> {
    return this.service.fetchDetail(id);
  }

  toId(model: IOrderIncidentDetailObject): number {
    return model.id;
  }

  toString(model: IOrderIncidentDetailObject): string {
    return `Order Incident ID${model.id}`;
  }
}
