import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IStockCategoryItemObject,
  IStockCategoryRowObject,
  StockCategoryService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StockCategoryItemCollectionProvider
  implements IDataCollectionProvider<IStockCategoryItemObject, any, any, number>
{
  defaultSort = {
    column: 'name',
    order: 'asc',
  };

  constructor(private stockCategoryService: StockCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IStockCategoryItemObject>> {
    return this.stockCategoryService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: number): IStockCategoryItemObject | Observable<IStockCategoryItemObject> {
    return this.stockCategoryService.fetchItem(Number(id));
  }

  toId(model: IStockCategoryItemObject): number {
    return model.id;
  }

  toString(model: IStockCategoryItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class StockCategoryRowCollectionProvider
  implements IDataCollectionProvider<IStockCategoryRowObject>
{
  constructor(private stockCategoryService: StockCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IStockCategoryRowObject>> {
    return this.stockCategoryService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): IStockCategoryRowObject | Observable<IStockCategoryRowObject> {
    return this.stockCategoryService.fetchRow(Number(id));
  }

  toId(model: IStockCategoryRowObject): string | number {
    return model.id;
  }

  toString(model: IStockCategoryRowObject): string {
    return model.name;
  }
}
