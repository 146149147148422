import { InjectLogger, Logger } from '../log';
import { StorageProviderInterface } from './storage';

const VERSION = 'version';

export abstract class StorageService {
  @InjectLogger()
  log: Logger;

  constructor(
    private _storage: StorageProviderInterface,
    private _prefix: string,
    private _version: number,
  ) {
    const version = this.getItem(VERSION);

    if (version && version !== this._version) {
      this.clear();
    }

    this.setItem(VERSION, this._version);
  }

  clear(): void {
    this._storage.clear();
  }

  getItem(key: string): any | null {
    let value = this._storage.getItem(`${this._prefix}${key}`);

    try {
      if (typeof value === 'string') return JSON.parse(value);
    } catch (e) {
      this.log.warn(`Error occured reading storage key '${key}'`, e);
      return null;
    }
  }

  removeItem(key: string): void {
    this._storage.removeItem(`${this._prefix}${key}`);
  }

  setItem(key: string, value: any): void {
    try {
      this._storage.setItem(`${this._prefix}${key}`, JSON.stringify(value));
    } catch (e) {
      this.log.warn(`Error occured writing storage key '${key}'`, e);
    }
  }
}
