import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PaymentProvidersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PaymentProvidersQuery = { __typename: 'Query', paymentProviders: Array<{ __typename: 'PaymentProviderObject', id: string, label: string }> };

export const PaymentProvidersDocument = gql`
    query PaymentProviders {
  paymentProviders {
    id
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentProvidersGQL extends Apollo.Query<PaymentProvidersQuery, PaymentProvidersQueryVariables> {
    document = PaymentProvidersDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }