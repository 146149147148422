import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateOrderShipmentParcelInput, UpdateShipmentParcelInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateOrderShipmentParcelGQL,
  OrderShipmentsGQL,
  RemoveShipmentParcelGQL,
  ShipmentParcelByIdGQL,
  ShipmentRowsGQL,
  ShipmentRowsQueryVariables,
  UpdateShipmentParcelGQL,
} from './gql/shipment.gql.generated';
import { ShipmentObject, ShipmentParcelObject, ShipmentRowObject } from './types/shipment';

@Injectable()
export class ShipmentService {
  constructor(
    private orderShipmentsGQL: OrderShipmentsGQL,
    private createOrderShipmentParcelGQL: CreateOrderShipmentParcelGQL,
    private updateShipmentParcelGQL: UpdateShipmentParcelGQL,
    private removeShipmentParcelGQL: RemoveShipmentParcelGQL,
    private shipmentParcelByIdGQL: ShipmentParcelByIdGQL,
    private shipmentRowsGQL: ShipmentRowsGQL,
  ) {}

  fetchByOrderId(id: number): Observable<ShipmentObject | null | undefined> {
    return this.orderShipmentsGQL.fetch({ id }).pipe(map((r) => r.data.order.shipment));
  }

  createOrderShipmentParcel(
    input: CreateOrderShipmentParcelInput,
  ): Observable<ShipmentParcelObject> {
    return this.createOrderShipmentParcelGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShipmentParcel));
  }

  updateShipmentParcel(input: UpdateShipmentParcelInput): Observable<number> {
    return this.updateShipmentParcelGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShipmentParcel.id));
  }

  removeShipmentParcel(id: number): Observable<number> {
    return this.removeShipmentParcelGQL
      .mutate({ id })
      .pipe(mapFetchResultData((data) => data.removeShipmentParcel));
  }

  shipmentParcelById(id: number): Observable<ShipmentParcelObject> {
    return this.shipmentParcelByIdGQL
      .fetch({ id })
      .pipe(mapFetchResultData((data) => data.shipmentParcel));
  }

  fetchRows(
    query: ShipmentRowsQueryVariables,
  ): Observable<{ items: ShipmentRowObject[]; totalItemsCount: number }> {
    return this.shipmentRowsGQL.fetch(query).pipe(map((r) => r.data));
  }
}
