import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  OrderItemRowGQL,
  OrderItemRowsGQL,
  OrderItemRowsQueryVariables,
} from './gql/order-item.gql.generated';
import { IOrderItemRowObject } from './types/order-item';

@Injectable()
export class OrderItemService {
  constructor(
    private readonly OrderItemRowGQL: OrderItemRowGQL,
    private readonly OrderItemRowsGQL: OrderItemRowsGQL,
  ) {}

  fetchRows(
    query: OrderItemRowsQueryVariables,
  ): Observable<{ items: IOrderItemRowObject[]; totalItemsCount: number }> {
    return this.OrderItemRowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(orderItemId: number): Observable<IOrderItemRowObject> {
    return this.OrderItemRowGQL.fetch({ orderItemId }).pipe(map((r) => r.data.orderItem));
  }
}
