import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { IShippingSlotItem, ShippingSlotService } from '@x/ecommerce/domain-client';
import { ShippingSlotRefFilterInput } from '@x/schemas/ecommerce';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShippingSlotItemCollectionProvider
  implements
    IDataCollectionProvider<IShippingSlotItem, ShippingSlotRefFilterInput>,
    IDataProvider<IShippingSlotItem>
{
  constructor(private service: ShippingSlotService) {}

  fetchCollection({
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingSlotItem>> {
    return this.service
      .fetchItems({ filter })
      .pipe(map((items) => ({ items, totalItemsCount: items.length })));
  }

  fetchSingle(id: string | number): IShippingSlotItem | Observable<IShippingSlotItem> {
    return this.service.fetchItem(String(id));
  }

  toId(model: IShippingSlotItem): number | string {
    return model.id;
  }

  toString(model: IShippingSlotItem): string {
    return `
    ${DateTime.fromISO(model.startAt).toFormat('DDDD T')}
     -
     ${DateTime.fromISO(model.endAt).toFormat('T')}
     `;
  }
}
