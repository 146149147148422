import { Injectable } from '@angular/core';
import { CreateMembershipInput, UpdateMembershipInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  MembershipArchiveGQL,
  MembershipCreateGQL,
  MembershipDetailGQL,
  MembershipItemGQL,
  MembershipItemsGQL,
  MembershipItemsQueryVariables,
  MembershipRowGQL,
  MembershipRowsGQL,
  MembershipRowsQueryVariables,
  MembershipSearchGQL,
  MembershipUpdateGQL,
} from './gql/membership.gql.generated';
import {
  IMembershipDetailObject,
  IMembershipItemObject,
  IMembershipRowObject,
} from './types/membership';

@Injectable()
export class MembershipService {
  constructor(
    private readonly itemGQL: MembershipItemGQL,
    private readonly itemsGQL: MembershipItemsGQL,
    private readonly rowGQL: MembershipRowGQL,
    private readonly rowsGQL: MembershipRowsGQL,
    private readonly detailGQL: MembershipDetailGQL,
    private readonly searchGQL: MembershipSearchGQL,
    private readonly archiveGQL: MembershipArchiveGQL,
    private readonly updateGQL: MembershipUpdateGQL,
    private readonly createGQL: MembershipCreateGQL,
  ) {}

  fetchItem(id: number): Observable<IMembershipItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.membership));
  }

  fetchItems(
    query: MembershipItemsQueryVariables,
  ): Observable<{ items: IMembershipItemObject[]; totalItemsCount: number }> {
    return this.itemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number): Observable<IMembershipRowObject> {
    return this.rowGQL.fetch({ id }).pipe(map((r) => r.data.membership));
  }

  fetchRows(
    query: MembershipRowsQueryVariables,
  ): Observable<{ items: IMembershipRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<IMembershipDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.membership));
  }

  fetchItemSearch(search?: string | null, limit = 10): Observable<IMembershipItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.memberships));
  }

  archive(id: number) {
    return this.archiveGQL.mutate({ id }).pipe(map((r) => r.data?.archiveMembership));
  }

  update(data: UpdateMembershipInput) {
    return this.updateGQL.mutate({ data }).pipe(map((r) => r.data?.updateMembership));
  }

  create(data: CreateMembershipInput) {
    return this.createGQL.mutate({ data }).pipe(map((r) => r.data?.createMembership));
  }
}
