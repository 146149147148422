import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateSubscriberInput,
  SubscriberFilterInput,
  SubscriberTagInput,
  UpdateSubscriberAddressInput,
  UpdateSubscriberInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddSubscriberTagsGQL, RemoveSubscriberTagsGQL } from './gql/subscriber-tag.gql.generated';
import {
  PrintSubscriberReportGQL,
  SubscriberActivateGQL,
  SubscriberCreateGQL,
  SubscriberDetailGQL,
  SubscriberItemGQL,
  SubscriberItemsGQL,
  SubscriberItemsQueryVariables,
  SubscriberPauseGQL,
  SubscriberRowsGQL,
  SubscriberRowsQueryVariables,
  SubscriberSearchGQL,
  SubscriberUpdateAddressGQL,
  SubscriberUpdateGQL,
} from './gql/subscriber.gql.generated';
import {
  ISubscriberDetailObject,
  ISubscriberItemObject,
  ISubscriberRowObject,
} from './types/subscriber';

@Injectable()
export class SubscriberService {
  constructor(
    private readonly rowsGQL: SubscriberRowsGQL,
    private readonly itemGQL: SubscriberItemGQL,
    private readonly itemsGQL: SubscriberItemsGQL,
    private readonly detailGQL: SubscriberDetailGQL,
    private readonly searchGQL: SubscriberSearchGQL,
    private readonly activateGQL: SubscriberActivateGQL,
    private readonly pauseGQL: SubscriberPauseGQL,
    private readonly updateGQL: SubscriberUpdateGQL,
    private readonly createGQL: SubscriberCreateGQL,
    private readonly updateAddressGQL: SubscriberUpdateAddressGQL,
    private readonly printSubscriberRecordsGQL: PrintSubscriberReportGQL,
    private addSubscriberTagsGQL: AddSubscriberTagsGQL,
    private removeSubscriberTagsGQL: RemoveSubscriberTagsGQL,
  ) {}

  fetchItem(id: number): Observable<ISubscriberItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.subscriber));
  }
  fetchItems(
    query: SubscriberItemsQueryVariables,
  ): Observable<{ items: ISubscriberItemObject[]; totalItemsCount: number }> {
    return this.itemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchRows(
    query: SubscriberRowsQueryVariables,
  ): Observable<{ items: ISubscriberRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<ISubscriberDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.subscriber));
  }

  fetchItemSearch(search?: string | null, limit = 10): Observable<ISubscriberItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.subscribers));
  }

  activate(id: number) {
    return this.activateGQL.mutate({ id }).pipe(map((r) => r.data?.activateSubscriber));
  }

  pause(id: number) {
    return this.pauseGQL.mutate({ id }).pipe(map((r) => r.data?.pauseSubscriber));
  }

  update(data: UpdateSubscriberInput) {
    return this.updateGQL.mutate({ data }).pipe(map((r) => r.data?.updateSubscriber));
  }

  updateAddress(data: UpdateSubscriberAddressInput) {
    return this.updateAddressGQL.mutate({ data }).pipe(map((r) => r.data?.updateSubscriberAddress));
  }

  create(data: CreateSubscriberInput) {
    return this.createGQL.mutate({ data }).pipe(map((r) => r.data?.createSubscriber));
  }

  downloadRecords(filter: SubscriberFilterInput, format?: string) {
    return this.printSubscriberRecordsGQL
      .mutate({ filter, format })
      .pipe(map((r) => r.data?.printSubscriberReport));
  }

  addTags(input: SubscriberTagInput) {
    return this.addSubscriberTagsGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.addSubscriberTags));
  }

  removeTags(input: SubscriberTagInput) {
    return this.removeSubscriberTagsGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.removeSubscriberTags));
  }
}
