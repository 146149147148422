import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { ITaxonItemObject, TaxonService } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaxonItemCollectionProvider implements IDataCollectionProvider<ITaxonItemObject> {
  defaultSort = {
    column: 'path',
    order: 'asc',
  };

  defaultPage = {
    size: 30,
    index: 0,
  };

  constructor(private taxonService: TaxonService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ITaxonItemObject>> {
    return this.taxonService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): ITaxonItemObject | Observable<ITaxonItemObject> {
    return this.taxonService.fetchItem(Number(id));
  }

  toId(model: ITaxonItemObject): string | number {
    return model.id;
  }

  toString(model: ITaxonItemObject): string {
    if (typeof model !== 'object' || model === null) return '';

    if (model.ascendants && model.ascendants.length) {
      let path: string = model.name;

      for (let i = model.ascendants.length - 1; i >= 0; i--) {
        const element = model.ascendants[i].name;
        path = element + ' | ' + path;
      }
      return path;
    }

    return model.name || String(model.id);
  }
}
