import { Component, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subject } from 'rxjs';

@Component({
  selector: 'x-date-range-filter-field',
  templateUrl: './date-range-filter-field.component.html',
  styleUrls: ['./date-range-filter-field.component.scss'],
})
export class DateRangeFilterFieldComponent implements ControlValueAccessor {
  private _onTouched: any = () => {};
  private _onChange: any = () => {};

  stateChanges = new Subject<void>();

  @Input()
  label: string = 'Date Range';

  @Input()
  icon: IconProp;

  @Input()
  expand: string = 'day';

  @ViewChild(MatDateRangePicker)
  picker: MatDateRangePicker<any>;

  value: { start: string | null; end: string | null } = { start: null, end: null };

  constructor(
    @Optional()
    ngControl?: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(obj: any): void {
    if (typeof obj === 'object') {
      this.value = { start: obj?.start ?? null, end: obj?.end ?? null };
      this.expand = obj?.expand ?? 'day';
    } else {
      this.expand = 'day';
      this.value = { start: null, end: null };
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  clear() {
    this.value = { start: null, end: null };
    this.picker.close();
    this.emitChange();
  }

  onStartChange(dateChange: MatDatepickerInputEvent<any>) {
    this.value.start = dateChange.value;
    this.emitChange();
  }

  onEndChange(dateChange: MatDatepickerInputEvent<any>) {
    this.value.end = dateChange.value;
    this.emitChange();
  }

  private emitChange() {
    if (!this.value.start && !this.value.end) {
      this._onChange(null);
    } else {
      this._onChange({
        start: this.value.start,
        end: this.value.end,
        expand: this.expand ?? 'day',
      });
    }
  }
}
