import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MediaItemFragment = { __typename: 'MediaObject', type: string, placement?: string | null | undefined, url: string, alt?: string | null | undefined, originalName?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, quality?: number | null | undefined };

export type MediaFolderItemFragment = { __typename: 'MediaFolderObject', name: string, path: string };

export type MediaUploadUrlFragment = { __typename: 'MediaUploadUrlObject', postUrl: string, fields?: any | null | undefined };

export type GetMediaQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetMediaQuery = { __typename: 'Query', getMedia?: { __typename: 'MediaObject', type: string, placement?: string | null | undefined, url: string, alt?: string | null | undefined, originalName?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, quality?: number | null | undefined } | null | undefined };

export type BrowseMediaQueryVariables = Types.Exact<{
  path: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nextToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type BrowseMediaQuery = { __typename: 'Query', browseMedia: { __typename: 'BrowseMediaObject', totalItemsCount: number, nextToken?: string | null | undefined, items: Array<{ __typename: 'MediaObject', type: string, placement?: string | null | undefined, url: string, alt?: string | null | undefined, originalName?: string | null | undefined, width?: number | null | undefined, height?: number | null | undefined, quality?: number | null | undefined }> } };

export type BrowseMediaFolderQueryVariables = Types.Exact<{
  path: Types.Scalars['String']['input'];
}>;


export type BrowseMediaFolderQuery = { __typename: 'Query', browseMediaFolder: Array<{ __typename: 'MediaFolderObject', name: string, path: string }> };

export type GetMediaUploadUrlQueryVariables = Types.Exact<{
  input: Types.MediaUploadInput;
}>;


export type GetMediaUploadUrlQuery = { __typename: 'Query', getMediaUploadUrl: { __typename: 'MediaUploadUrlObject', postUrl: string, fields?: any | null | undefined } };

export type SyncMediaToContentMutationVariables = Types.Exact<{
  url: Types.Scalars['String']['input'];
}>;


export type SyncMediaToContentMutation = { __typename: 'Mutation', syncMediaToContent: boolean };

export const MediaItemFragmentDoc = gql`
    fragment MediaItem on MediaObject {
  type
  placement
  url
  alt
  originalName
  width
  height
  quality
}
    `;
export const MediaFolderItemFragmentDoc = gql`
    fragment MediaFolderItem on MediaFolderObject {
  name
  path
}
    `;
export const MediaUploadUrlFragmentDoc = gql`
    fragment MediaUploadUrl on MediaUploadUrlObject {
  postUrl
  fields
}
    `;
export const GetMediaDocument = gql`
    query GetMedia($id: String!) {
  getMedia(id: $id) {
    ...MediaItem
  }
}
    ${MediaItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMediaGQL extends Apollo.Query<GetMediaQuery, GetMediaQueryVariables> {
    document = GetMediaDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BrowseMediaDocument = gql`
    query BrowseMedia($path: String!, $limit: Int, $nextToken: String) {
  browseMedia(path: $path, limit: $limit, nextToken: $nextToken) {
    items {
      ...MediaItem
    }
    totalItemsCount
    nextToken
  }
}
    ${MediaItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrowseMediaGQL extends Apollo.Query<BrowseMediaQuery, BrowseMediaQueryVariables> {
    document = BrowseMediaDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BrowseMediaFolderDocument = gql`
    query BrowseMediaFolder($path: String!) {
  browseMediaFolder(path: $path) {
    ...MediaFolderItem
  }
}
    ${MediaFolderItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrowseMediaFolderGQL extends Apollo.Query<BrowseMediaFolderQuery, BrowseMediaFolderQueryVariables> {
    document = BrowseMediaFolderDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMediaUploadUrlDocument = gql`
    query GetMediaUploadUrl($input: MediaUploadInput!) {
  getMediaUploadUrl(input: $input) {
    ...MediaUploadUrl
  }
}
    ${MediaUploadUrlFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMediaUploadUrlGQL extends Apollo.Query<GetMediaUploadUrlQuery, GetMediaUploadUrlQueryVariables> {
    document = GetMediaUploadUrlDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncMediaToContentDocument = gql`
    mutation SyncMediaToContent($url: String!) {
  syncMediaToContent(url: $url)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncMediaToContentGQL extends Apollo.Mutation<SyncMediaToContentMutation, SyncMediaToContentMutationVariables> {
    document = SyncMediaToContentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }