import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type VoucherDetailFragment = { __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined, issuingUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } | null | undefined, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } };

export type VoucherRowFragment = { __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined, cancelled: boolean, cancelledAt?: any | null | undefined, cancelledReason?: string | null | undefined, createdAt: any, updatedAt: any, downloadUrl: string, orderNumber: string, orderId?: number | null | undefined, issuingUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } | null | undefined, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } };

export type VoucherItemFragment = { __typename: 'VoucherObject', id: number, code: string };

export type VoucherCreateItemFragment = { __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined };

export type VoucherBalanceItemFragment = { __typename: 'VoucherObject', balance: { __typename: 'VoucherBalanceObject', balance: number, currency: string } };

export type EmailResultFragment = { __typename: 'EmailResultObject', sent: boolean, recipientName: string, recipientEmail: string };

export type VoucherRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.VoucherFilterInput>;
}>;


export type VoucherRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined, cancelled: boolean, cancelledAt?: any | null | undefined, cancelledReason?: string | null | undefined, createdAt: any, updatedAt: any, downloadUrl: string, orderNumber: string, orderId?: number | null | undefined, issuingUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } | null | undefined, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } }> };

export type VoucherItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type VoucherItemQuery = { __typename: 'Query', voucher: { __typename: 'VoucherObject', id: number, code: string } };

export type VoucherDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type VoucherDetailQuery = { __typename: 'Query', voucher: { __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined, issuingUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } | null | undefined, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } } };

export type VoucherSearchQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.VoucherFilterInput>;
}>;


export type VoucherSearchQuery = { __typename: 'Query', vouchers: Array<{ __typename: 'VoucherObject', id: number, code: string }> };

export type VoucherBalanceQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type VoucherBalanceQuery = { __typename: 'Query', voucher: { __typename: 'VoucherObject', balance: { __typename: 'VoucherBalanceObject', balance: number, currency: string } } };

export type VoucherCreateMutationVariables = Types.Exact<{
  input: Types.CreateVoucherInput;
}>;


export type VoucherCreateMutation = { __typename: 'Mutation', createVoucher: { __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined } };

export type VoucherCancelMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  reason: Types.Scalars['String']['input'];
}>;


export type VoucherCancelMutation = { __typename: 'Mutation', cancelVoucher: { __typename: 'VoucherObject', id: number, code: string, currency: string, value: number, reason?: string | null | undefined, issuingUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } | null | undefined, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, email: string } } };

export type VouchersPrintMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type VouchersPrintMutation = { __typename: 'Mutation', printVouchersManifest: { __typename: 'PrintResultObject', id: string, url: string } };

export type VoucherResendEmailMutationVariables = Types.Exact<{
  voucherId: Types.Scalars['Int']['input'];
}>;


export type VoucherResendEmailMutation = { __typename: 'Mutation', sendVoucherEmail: { __typename: 'EmailResultObject', sent: boolean, recipientName: string, recipientEmail: string } };

export const VoucherDetailFragmentDoc = gql`
    fragment VoucherDetail on VoucherObject {
  id
  code
  currency
  value
  reason
  issuingUser {
    id
    firstName
    lastName
    email
  }
  user {
    id
    firstName
    lastName
    email
  }
}
    `;
export const VoucherRowFragmentDoc = gql`
    fragment VoucherRow on VoucherObject {
  id
  code
  currency
  value
  reason
  cancelled
  cancelledAt
  cancelledReason
  createdAt
  updatedAt
  downloadUrl
  issuingUser {
    id
    firstName
    lastName
    email
  }
  user {
    id
    firstName
    lastName
    email
  }
  orderNumber
  orderId
}
    `;
export const VoucherItemFragmentDoc = gql`
    fragment VoucherItem on VoucherObject {
  id
  code
}
    `;
export const VoucherCreateItemFragmentDoc = gql`
    fragment VoucherCreateItem on VoucherObject {
  id
  code
  currency
  value
  reason
}
    `;
export const VoucherBalanceItemFragmentDoc = gql`
    fragment VoucherBalanceItem on VoucherObject {
  balance {
    balance
    currency
  }
}
    `;
export const EmailResultFragmentDoc = gql`
    fragment EmailResult on EmailResultObject {
  sent
  recipientName
  recipientEmail
}
    `;
export const VoucherRowsDocument = gql`
    query VoucherRows($page: PageInput, $sort: SortInput, $filter: VoucherFilterInput) {
  items: vouchers(page: $page, sort: $sort, filter: $filter) {
    ...VoucherRow
  }
  totalItemsCount: vouchersCount(filter: $filter)
}
    ${VoucherRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherRowsGQL extends Apollo.Query<VoucherRowsQuery, VoucherRowsQueryVariables> {
    document = VoucherRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherItemDocument = gql`
    query VoucherItem($id: Int!) {
  voucher(id: $id) {
    ...VoucherItem
  }
}
    ${VoucherItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherItemGQL extends Apollo.Query<VoucherItemQuery, VoucherItemQueryVariables> {
    document = VoucherItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherDetailDocument = gql`
    query VoucherDetail($id: Int!) {
  voucher(id: $id) {
    ...VoucherDetail
  }
}
    ${VoucherDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherDetailGQL extends Apollo.Query<VoucherDetailQuery, VoucherDetailQueryVariables> {
    document = VoucherDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherSearchDocument = gql`
    query VoucherSearch($page: PageInput, $sort: SortInput, $filter: VoucherFilterInput) {
  vouchers(page: $page, sort: $sort, filter: $filter) {
    ...VoucherItem
  }
}
    ${VoucherItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherSearchGQL extends Apollo.Query<VoucherSearchQuery, VoucherSearchQueryVariables> {
    document = VoucherSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherBalanceDocument = gql`
    query VoucherBalance($id: Int!) {
  voucher(id: $id) {
    ...VoucherBalanceItem
  }
}
    ${VoucherBalanceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherBalanceGQL extends Apollo.Query<VoucherBalanceQuery, VoucherBalanceQueryVariables> {
    document = VoucherBalanceDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherCreateDocument = gql`
    mutation VoucherCreate($input: CreateVoucherInput!) {
  createVoucher(input: $input) {
    ...VoucherCreateItem
  }
}
    ${VoucherCreateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherCreateGQL extends Apollo.Mutation<VoucherCreateMutation, VoucherCreateMutationVariables> {
    document = VoucherCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherCancelDocument = gql`
    mutation VoucherCancel($id: Int!, $reason: String!) {
  cancelVoucher(id: $id, reason: $reason) {
    ...VoucherDetail
  }
}
    ${VoucherDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherCancelGQL extends Apollo.Mutation<VoucherCancelMutation, VoucherCancelMutationVariables> {
    document = VoucherCancelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VouchersPrintDocument = gql`
    mutation VouchersPrint($ids: [Int!]!) {
  printVouchersManifest(voucherIds: $ids) {
    id
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VouchersPrintGQL extends Apollo.Mutation<VouchersPrintMutation, VouchersPrintMutationVariables> {
    document = VouchersPrintDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherResendEmailDocument = gql`
    mutation VoucherResendEmail($voucherId: Int!) {
  sendVoucherEmail(voucherId: $voucherId) {
    ...EmailResult
  }
}
    ${EmailResultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherResendEmailGQL extends Apollo.Mutation<VoucherResendEmailMutation, VoucherResendEmailMutationVariables> {
    document = VoucherResendEmailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }