import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  IWarehouseItemObject,
  IWarehouseRowObject,
  WarehouseService,
} from '@x/ecommerce/domain-client';
import { WarehouseFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WarehouseItemCollectionProvider
  implements IDataCollectionProvider<IWarehouseItemObject, WarehouseFilterInput>
{
  constructor(private service: WarehouseService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IWarehouseItemObject>> {
    return this.service.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IWarehouseItemObject> {
    return this.service.fetchItem(Number(id));
  }

  toId(model: IWarehouseItemObject): string | number {
    return model.id;
  }

  toString(model: IWarehouseItemObject): string {
    return `${model.name}`;
  }
}

@Injectable({ providedIn: 'root' })
export class WarehouseRowCollectionProvider
  implements IDataCollectionProvider<IWarehouseRowObject, WarehouseFilterInput>
{
  constructor(private service: WarehouseService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IWarehouseRowObject>> {
    return this.service.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IWarehouseRowObject> {
    return this.service.fetchRow(Number(id));
  }

  toId(model: IWarehouseRowObject): string | number {
    return model.id;
  }

  toString(model: IWarehouseRowObject): string {
    return `${model.name}`;
  }
}
