import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { UserItemFragmentDoc, OrderSalesStatsFragmentDoc, OrderStateCountsFragmentDoc, UserDetailFragmentDoc } from './user.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrderNoteItemFragment = { __typename: 'OrderNoteObject', id: number, message: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined };

export type CreateOrderNoteMutationVariables = Types.Exact<{
  input: Types.OrderNoteInput;
}>;


export type CreateOrderNoteMutation = { __typename: 'Mutation', createOrderNote: { __typename: 'OrderNoteObject', id: number, message: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined } };

export type ArchiveOrderNoteMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ArchiveOrderNoteMutation = { __typename: 'Mutation', archiveOrderNote: { __typename: 'OrderNoteObject', id: number, message: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined } };

export const OrderNoteItemFragmentDoc = gql`
    fragment OrderNoteItem on OrderNoteObject {
  id
  message
  authorUser {
    ...UserItem
  }
  createdAt
  updatedAt
}
    ${UserItemFragmentDoc}`;
export const CreateOrderNoteDocument = gql`
    mutation CreateOrderNote($input: OrderNoteInput!) {
  createOrderNote(input: $input) {
    ...OrderNoteItem
  }
}
    ${OrderNoteItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderNoteGQL extends Apollo.Mutation<CreateOrderNoteMutation, CreateOrderNoteMutationVariables> {
    document = CreateOrderNoteDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveOrderNoteDocument = gql`
    mutation ArchiveOrderNote($id: Int!) {
  archiveOrderNote(id: $id) {
    ...OrderNoteItem
  }
}
    ${OrderNoteItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveOrderNoteGQL extends Apollo.Mutation<ArchiveOrderNoteMutation, ArchiveOrderNoteMutationVariables> {
    document = ArchiveOrderNoteDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }