import { Injectable } from '@angular/core';
import { GridDatasource, GridDatasourceData, GridDatasourceQuery } from '@x/dashboard/grid';
import { IUserPaymentMethodObject, UserPaymentMethodsService } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

export interface UserPaymentMethodQueryArgs {
  userId: number;
}

export interface UserPaymentMethodFilterInput {}

@Injectable()
export class UserPaymentMethodDatasource extends GridDatasource<
  IUserPaymentMethodObject,
  UserPaymentMethodFilterInput,
  UserPaymentMethodQueryArgs
> {
  constructor(private userPaymentMethodService: UserPaymentMethodsService) {
    super({
      defaultDisplayColumns: ['method', 'description', 'hidden', 'timestamps'],
      defaultFilter: {},
      defaultSort: {
        column: 'createdAt',
        order: 'desc',
      },
    });
  }

  fetch({
    filter,
    args,
  }: Readonly<
    GridDatasourceQuery<UserPaymentMethodFilterInput, UserPaymentMethodQueryArgs>
  >): Observable<GridDatasourceData<IUserPaymentMethodObject>> {
    return this.userPaymentMethodService.fetchItems({ filter: { userIds: [args.userId] } });
  }
}
