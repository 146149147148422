import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type NotificationLogItemFragment = { __typename: 'NotificationLogObject', id: string, notificationName: string, status: Types.NotificationSendStatus, sentAt: any };

export type NotificationLogRowFragment = { __typename: 'NotificationLogObject', id: string, channelName: string, notificationName: string, recipientName: string, status: Types.NotificationSendStatus, previewUrl?: string | null | undefined, sentAt: any, references?: Array<{ __typename: 'NotificationRefObject', entityType?: string | null | undefined, entityId?: string | null | undefined }> | null | undefined };

export type NotificationChannelItemFragment = { __typename: 'NotificationChannelObject', id: string, name: string };

export type NotificationTypeItemFragment = { __typename: 'NotificationTypeObject', id: string, name: string };

export type NotificationLogRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.NotificationLogFilterInput>;
}>;


export type NotificationLogRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'NotificationLogObject', id: string, channelName: string, notificationName: string, recipientName: string, status: Types.NotificationSendStatus, previewUrl?: string | null | undefined, sentAt: any, references?: Array<{ __typename: 'NotificationRefObject', entityType?: string | null | undefined, entityId?: string | null | undefined }> | null | undefined }> };

export type NotificationLogChannelItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type NotificationLogChannelItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'NotificationChannelObject', id: string, name: string }> };

export type NotificationLogTypeItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type NotificationLogTypeItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'NotificationTypeObject', id: string, name: string }> };

export const NotificationLogItemFragmentDoc = gql`
    fragment NotificationLogItem on NotificationLogObject {
  id
  notificationName
  status
  sentAt
}
    `;
export const NotificationLogRowFragmentDoc = gql`
    fragment NotificationLogRow on NotificationLogObject {
  id
  channelName
  notificationName
  recipientName
  status
  previewUrl
  sentAt
  references {
    entityType
    entityId
  }
}
    `;
export const NotificationChannelItemFragmentDoc = gql`
    fragment NotificationChannelItem on NotificationChannelObject {
  id
  name
}
    `;
export const NotificationTypeItemFragmentDoc = gql`
    fragment NotificationTypeItem on NotificationTypeObject {
  id
  name
}
    `;
export const NotificationLogRowsDocument = gql`
    query NotificationLogRows($page: PageInput, $sort: SortInput, $filter: NotificationLogFilterInput) {
  items: notificationLogs(page: $page, sort: $sort, filter: $filter) {
    ...NotificationLogRow
  }
  totalItemsCount: notificationLogsCount(filter: $filter)
}
    ${NotificationLogRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationLogRowsGQL extends Apollo.Query<NotificationLogRowsQuery, NotificationLogRowsQueryVariables> {
    document = NotificationLogRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationLogChannelItemsDocument = gql`
    query NotificationLogChannelItems($page: PageInput, $sort: SortInput) {
  items: notificationLogChannels(page: $page, sort: $sort) {
    ...NotificationChannelItem
  }
  totalItemsCount: notificationLogChannelsCount
}
    ${NotificationChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationLogChannelItemsGQL extends Apollo.Query<NotificationLogChannelItemsQuery, NotificationLogChannelItemsQueryVariables> {
    document = NotificationLogChannelItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationLogTypeItemsDocument = gql`
    query NotificationLogTypeItems($page: PageInput, $sort: SortInput) {
  items: notificationLogTypes(page: $page, sort: $sort) {
    ...NotificationTypeItem
  }
  totalItemsCount: notificationLogTypesCount
}
    ${NotificationTypeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationLogTypeItemsGQL extends Apollo.Query<NotificationLogTypeItemsQuery, NotificationLogTypeItemsQueryVariables> {
    document = NotificationLogTypeItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }