import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductAttributeItemFragment = { __typename: 'ProductAttributeObject', id: number, name: string, code: string };

export type ProductAttributeRowFragment = { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> };

export type ProductAttributeDetailFragment = { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> };

export type ProductAttributeItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAttributeItemQuery = { __typename: 'Query', productAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, code: string } };

export type ProductAttributeItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ProductAttributeFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductAttributeItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductAttributeObject', id: number, name: string, code: string }> };

export type ProductAttributeRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAttributeRowQuery = { __typename: 'Query', productAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> } };

export type ProductAttributeRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  locale?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ProductAttributeFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductAttributeRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductAttributeObject', id: number, code: string, name: string, type: Types.ProductAttributeType, createdAt: any, updatedAt: any, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> }> };

export type ProductAttributeByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAttributeByIdQuery = { __typename: 'Query', productAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', id: number, locale: string, name: string }> } };

export type ProductAttributeSearchQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type ProductAttributeSearchQuery = { __typename: 'Query', productAttributes: Array<{ __typename: 'ProductAttributeObject', id: number, name: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', id: number, locale: string, name: string }> }> };

export type ProductAttributeDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductAttributeDetailQuery = { __typename: 'Query', productAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> } };

export type CreateProductAttributeMutationVariables = Types.Exact<{
  data: Types.CreateProductAttributeInput;
}>;


export type CreateProductAttributeMutation = { __typename: 'Mutation', createProductAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> } };

export type UpdateProductAttributeMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  data: Types.UpdateProductAttributeInput;
}>;


export type UpdateProductAttributeMutation = { __typename: 'Mutation', updateProductAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> } };

export type ArchiveProductAttributeMutationVariables = Types.Exact<{
  productAttributeId: Types.Scalars['Int']['input'];
}>;


export type ArchiveProductAttributeMutation = { __typename: 'Mutation', archiveProductAttribute: { __typename: 'ProductAttributeObject', id: number, name: string, code: string, type: Types.ProductAttributeType, position: number, translations: Array<{ __typename: 'ProductAttributeTranslationObject', locale: string, name: string }> } };

export const ProductAttributeItemFragmentDoc = gql`
    fragment ProductAttributeItem on ProductAttributeObject {
  id
  name
  code
}
    `;
export const ProductAttributeRowFragmentDoc = gql`
    fragment ProductAttributeRow on ProductAttributeObject {
  id
  name
  code
  type
  position
  translations {
    locale
    name
  }
}
    `;
export const ProductAttributeDetailFragmentDoc = gql`
    fragment ProductAttributeDetail on ProductAttributeObject {
  id
  name
  code
  type
  position
  translations {
    locale
    name
  }
}
    `;
export const ProductAttributeItemDocument = gql`
    query ProductAttributeItem($id: Int!) {
  productAttribute(id: $id) {
    ...ProductAttributeItem
  }
}
    ${ProductAttributeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeItemGQL extends Apollo.Query<ProductAttributeItemQuery, ProductAttributeItemQueryVariables> {
    document = ProductAttributeItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAttributeItemsDocument = gql`
    query ProductAttributeItems($page: PageInput, $filter: ProductAttributeFilterInput, $sort: SortInput) {
  items: productAttributes(page: $page, filter: $filter, sort: $sort) {
    ...ProductAttributeItem
  }
  totalItemsCount: productAttributesCount(filter: $filter)
}
    ${ProductAttributeItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeItemsGQL extends Apollo.Query<ProductAttributeItemsQuery, ProductAttributeItemsQueryVariables> {
    document = ProductAttributeItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAttributeRowDocument = gql`
    query ProductAttributeRow($id: Int!) {
  productAttribute(id: $id) {
    ...ProductAttributeRow
  }
}
    ${ProductAttributeRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeRowGQL extends Apollo.Query<ProductAttributeRowQuery, ProductAttributeRowQueryVariables> {
    document = ProductAttributeRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAttributeRowsDocument = gql`
    query ProductAttributeRows($page: PageInput, $locale: String, $filter: ProductAttributeFilterInput, $sort: SortInput) {
  items: productAttributes(page: $page, filter: $filter, sort: $sort) {
    id
    code
    name
    type
    createdAt
    updatedAt
    position
    translations(locale: $locale) {
      locale
      name
    }
  }
  totalItemsCount: productAttributesCount(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeRowsGQL extends Apollo.Query<ProductAttributeRowsQuery, ProductAttributeRowsQueryVariables> {
    document = ProductAttributeRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAttributeByIdDocument = gql`
    query ProductAttributeById($id: Int!) {
  productAttribute(id: $id) {
    id
    name
    type
    position
    translations {
      id
      locale
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeByIdGQL extends Apollo.Query<ProductAttributeByIdQuery, ProductAttributeByIdQueryVariables> {
    document = ProductAttributeByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAttributeSearchDocument = gql`
    query ProductAttributeSearch($search: String, $results: Int!) {
  productAttributes(
    filter: {search: $search}
    page: {size: $results, index: 0}
    sort: {column: "name", order: "desc"}
  ) {
    id
    name
    type
    position
    translations {
      id
      locale
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeSearchGQL extends Apollo.Query<ProductAttributeSearchQuery, ProductAttributeSearchQueryVariables> {
    document = ProductAttributeSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductAttributeDetailDocument = gql`
    query ProductAttributeDetail($id: Int!) {
  productAttribute(id: $id) {
    ...ProductAttributeDetail
  }
}
    ${ProductAttributeDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAttributeDetailGQL extends Apollo.Query<ProductAttributeDetailQuery, ProductAttributeDetailQueryVariables> {
    document = ProductAttributeDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductAttributeDocument = gql`
    mutation CreateProductAttribute($data: CreateProductAttributeInput!) {
  createProductAttribute(data: $data) {
    ...ProductAttributeDetail
  }
}
    ${ProductAttributeDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductAttributeGQL extends Apollo.Mutation<CreateProductAttributeMutation, CreateProductAttributeMutationVariables> {
    document = CreateProductAttributeDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductAttributeDocument = gql`
    mutation UpdateProductAttribute($id: Int!, $data: UpdateProductAttributeInput!) {
  updateProductAttribute(data: $data, id: $id) {
    ...ProductAttributeDetail
  }
}
    ${ProductAttributeDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductAttributeGQL extends Apollo.Mutation<UpdateProductAttributeMutation, UpdateProductAttributeMutationVariables> {
    document = UpdateProductAttributeDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveProductAttributeDocument = gql`
    mutation ArchiveProductAttribute($productAttributeId: Int!) {
  archiveProductAttribute(id: $productAttributeId) {
    ...ProductAttributeDetail
  }
}
    ${ProductAttributeDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveProductAttributeGQL extends Apollo.Mutation<ArchiveProductAttributeMutation, ArchiveProductAttributeMutationVariables> {
    document = ArchiveProductAttributeDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }