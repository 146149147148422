import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'x-date-filter-field',
  templateUrl: './date-filter-field.component.html',
  styleUrls: ['./date-filter-field.component.scss'],
})
export class DateFilterFieldComponent implements ControlValueAccessor {
  private _onTouched: any = () => {};

  @Input()
  label: string = 'Date';

  @Input()
  icon: IconProp;

  date = new UntypedFormControl();

  constructor(
    @Optional()
    ngControl?: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(obj: any): void {
    if (!obj) return;
    this.date.setValue({ ...obj }, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.date.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
