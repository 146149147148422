import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrderTagItemFragment = { __typename: 'OrderTagObject', id: number, label: string, color?: string | null | undefined, icon?: string | null | undefined };

export type OrderTagItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.OrderTagFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type OrderTagItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'OrderTagObject', id: number, label: string, color?: string | null | undefined, icon?: string | null | undefined }> };

export type OrderTagItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderTagItemQuery = { __typename: 'Query', orderTag: { __typename: 'OrderTagObject', id: number, label: string, color?: string | null | undefined, icon?: string | null | undefined } };

export type AddOrderTagsMutationVariables = Types.Exact<{
  input: Types.OrderTagInput;
}>;


export type AddOrderTagsMutation = { __typename: 'Mutation', addOrderTags: { __typename: 'OrderObject', id: number } };

export type RemoveOrderTagsMutationVariables = Types.Exact<{
  input: Types.OrderTagInput;
}>;


export type RemoveOrderTagsMutation = { __typename: 'Mutation', removeOrderTags: { __typename: 'OrderObject', id: number } };

export const OrderTagItemFragmentDoc = gql`
    fragment OrderTagItem on OrderTagObject {
  id
  label
  color
  icon
}
    `;
export const OrderTagItemsDocument = gql`
    query OrderTagItems($page: PageInput, $filter: OrderTagFilterInput, $sort: SortInput) {
  items: orderTags(page: $page, filter: $filter, sort: $sort) {
    ...OrderTagItem
  }
  totalItemsCount: orderTagsCount(filter: $filter)
}
    ${OrderTagItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderTagItemsGQL extends Apollo.Query<OrderTagItemsQuery, OrderTagItemsQueryVariables> {
    document = OrderTagItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderTagItemDocument = gql`
    query OrderTagItem($id: Int!) {
  orderTag(id: $id) {
    ...OrderTagItem
  }
}
    ${OrderTagItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderTagItemGQL extends Apollo.Query<OrderTagItemQuery, OrderTagItemQueryVariables> {
    document = OrderTagItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddOrderTagsDocument = gql`
    mutation AddOrderTags($input: OrderTagInput!) {
  addOrderTags(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrderTagsGQL extends Apollo.Mutation<AddOrderTagsMutation, AddOrderTagsMutationVariables> {
    document = AddOrderTagsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveOrderTagsDocument = gql`
    mutation RemoveOrderTags($input: OrderTagInput!) {
  removeOrderTags(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveOrderTagsGQL extends Apollo.Mutation<RemoveOrderTagsMutation, RemoveOrderTagsMutationVariables> {
    document = RemoveOrderTagsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }