import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ChannelDetailFragment = { __typename: 'ChannelObject', id: number, code: string, name: string, host?: string | null | undefined, defaultLocale: string, defaultCountry: string, currency: string, enabled: boolean, color: string, createdAt: any, updatedAt: any, defaultWarehouse?: { __typename: 'WarehouseObject', id: number, name: string } | null | undefined, defaultTaxCategory?: { __typename: 'TaxCategoryObject', id: number, code: string, name: string } | null | undefined, warehouses: Array<{ __typename: 'WarehouseObject', id: number, name: string }>, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, locales: Array<{ __typename: 'ChannelLocaleObject', locale: string, name?: string | null | undefined }>, translations: Array<{ __typename: 'ChannelTranslationObject', id: number, locale: string, name: string, contentRef?: string | null | undefined, meta?: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined } | null | undefined }> };

export type ChannelItemFragment = { __typename: 'ChannelObject', id: number, code: string, name: string, color: string };

export type ChannelRowFragment = { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean };

export type CreateChannelMutationVariables = Types.Exact<{
  data: Types.CreateChannelInput;
}>;


export type CreateChannelMutation = { __typename: 'Mutation', createChannel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } };

export type UpdateChannelMutationVariables = Types.Exact<{
  data: Types.UpdateChannelInput;
}>;


export type UpdateChannelMutation = { __typename: 'Mutation', updateChannel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } };

export type ChannelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ChannelsQuery = { __typename: 'Query', channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, host?: string | null | undefined, defaultLocale: string, defaultCountry: string, currency: string, enabled: boolean, color: string, createdAt: any, updatedAt: any, defaultWarehouse?: { __typename: 'WarehouseObject', id: number, name: string } | null | undefined, defaultTaxCategory?: { __typename: 'TaxCategoryObject', id: number, code: string, name: string } | null | undefined, warehouses: Array<{ __typename: 'WarehouseObject', id: number, name: string }>, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, locales: Array<{ __typename: 'ChannelLocaleObject', locale: string, name?: string | null | undefined }>, translations: Array<{ __typename: 'ChannelTranslationObject', id: number, locale: string, name: string, contentRef?: string | null | undefined, meta?: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined } | null | undefined }> }> };

export type ChannelSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type ChannelSearchQuery = { __typename: 'Query', channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, color: string }> };

export type ChannelByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ChannelByIdQuery = { __typename: 'Query', channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } };

export type DefaultChannelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DefaultChannelQuery = { __typename: 'Query', defaultChannel: { __typename: 'ChannelObject', id: number, code: string, name: string, host?: string | null | undefined, defaultLocale: string, defaultCountry: string, currency: string, enabled: boolean, color: string, createdAt: any, updatedAt: any, defaultWarehouse?: { __typename: 'WarehouseObject', id: number, name: string } | null | undefined, defaultTaxCategory?: { __typename: 'TaxCategoryObject', id: number, code: string, name: string } | null | undefined, warehouses: Array<{ __typename: 'WarehouseObject', id: number, name: string }>, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, locales: Array<{ __typename: 'ChannelLocaleObject', locale: string, name?: string | null | undefined }>, translations: Array<{ __typename: 'ChannelTranslationObject', id: number, locale: string, name: string, contentRef?: string | null | undefined, meta?: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined } | null | undefined }> } };

export type ChannelDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ChannelDetailQuery = { __typename: 'Query', channel: { __typename: 'ChannelObject', id: number, code: string, name: string, host?: string | null | undefined, defaultLocale: string, defaultCountry: string, currency: string, enabled: boolean, color: string, createdAt: any, updatedAt: any, defaultWarehouse?: { __typename: 'WarehouseObject', id: number, name: string } | null | undefined, defaultTaxCategory?: { __typename: 'TaxCategoryObject', id: number, code: string, name: string } | null | undefined, warehouses: Array<{ __typename: 'WarehouseObject', id: number, name: string }>, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, locales: Array<{ __typename: 'ChannelLocaleObject', locale: string, name?: string | null | undefined }>, translations: Array<{ __typename: 'ChannelTranslationObject', id: number, locale: string, name: string, contentRef?: string | null | undefined, meta?: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined } | null | undefined }> } };

export type ChannelDetailByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type ChannelDetailByCodeQuery = { __typename: 'Query', channelByCode: { __typename: 'ChannelObject', id: number, code: string, name: string, host?: string | null | undefined, defaultLocale: string, defaultCountry: string, currency: string, enabled: boolean, color: string, createdAt: any, updatedAt: any, defaultWarehouse?: { __typename: 'WarehouseObject', id: number, name: string } | null | undefined, defaultTaxCategory?: { __typename: 'TaxCategoryObject', id: number, code: string, name: string } | null | undefined, warehouses: Array<{ __typename: 'WarehouseObject', id: number, name: string }>, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, locales: Array<{ __typename: 'ChannelLocaleObject', locale: string, name?: string | null | undefined }>, translations: Array<{ __typename: 'ChannelTranslationObject', id: number, locale: string, name: string, contentRef?: string | null | undefined, meta?: { __typename: 'MetaContentObject', title?: string | null | undefined, description?: string | null | undefined, keywords?: string | null | undefined } | null | undefined }> } };

export type ChannelItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ChannelItemQuery = { __typename: 'Query', channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } };

export type ChannelItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ChannelFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ChannelItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, color: string }> };

export type ChannelRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ChannelRowQuery = { __typename: 'Query', channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean } };

export type ChannelRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter: Types.ChannelFilterInput;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ChannelRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }> };

export type ChannelSetAsDefaultMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ChannelSetAsDefaultMutation = { __typename: 'Mutation', channelSetDefault: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } };

export const ChannelDetailFragmentDoc = gql`
    fragment ChannelDetail on ChannelObject {
  id
  code
  name
  host
  defaultLocale
  defaultCountry
  defaultWarehouse {
    id
    name
  }
  defaultTaxCategory {
    id
    code
    name
  }
  currency
  enabled
  color
  createdAt
  updatedAt
  warehouses {
    id
    name
  }
  regions {
    id
    name
    code
  }
  locales {
    locale
    name
  }
  translations {
    id
    locale
    name
    meta {
      title
      description
      keywords
    }
    contentRef
  }
}
    `;
export const ChannelItemFragmentDoc = gql`
    fragment ChannelItem on ChannelObject {
  id
  code
  name
  color
}
    `;
export const ChannelRowFragmentDoc = gql`
    fragment ChannelRow on ChannelObject {
  id
  code
  name
  currency
  enabled
  defaultLocale
  updatedAt
  createdAt
  isDefault
}
    `;
export const CreateChannelDocument = gql`
    mutation CreateChannel($data: CreateChannelInput!) {
  createChannel(data: $data) {
    ...ChannelItem
  }
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChannelGQL extends Apollo.Mutation<CreateChannelMutation, CreateChannelMutationVariables> {
    document = CreateChannelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($data: UpdateChannelInput!) {
  updateChannel(data: $data) {
    ...ChannelItem
  }
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChannelGQL extends Apollo.Mutation<UpdateChannelMutation, UpdateChannelMutationVariables> {
    document = UpdateChannelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelsDocument = gql`
    query Channels {
  channels {
    ...ChannelDetail
  }
}
    ${ChannelDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelsGQL extends Apollo.Query<ChannelsQuery, ChannelsQueryVariables> {
    document = ChannelsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelSearchDocument = gql`
    query ChannelSearch($searchText: String, $results: Int!) {
  channels(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    ...ChannelItem
  }
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelSearchGQL extends Apollo.Query<ChannelSearchQuery, ChannelSearchQueryVariables> {
    document = ChannelSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelByIdDocument = gql`
    query ChannelById($id: Int!) {
  channel(id: $id) {
    ...ChannelItem
  }
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelByIdGQL extends Apollo.Query<ChannelByIdQuery, ChannelByIdQueryVariables> {
    document = ChannelByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DefaultChannelDocument = gql`
    query DefaultChannel {
  defaultChannel {
    ...ChannelDetail
  }
}
    ${ChannelDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DefaultChannelGQL extends Apollo.Query<DefaultChannelQuery, DefaultChannelQueryVariables> {
    document = DefaultChannelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelDetailDocument = gql`
    query ChannelDetail($id: Int!) {
  channel(id: $id) {
    ...ChannelDetail
  }
}
    ${ChannelDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelDetailGQL extends Apollo.Query<ChannelDetailQuery, ChannelDetailQueryVariables> {
    document = ChannelDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelDetailByCodeDocument = gql`
    query ChannelDetailByCode($code: String!) {
  channelByCode(code: $code) {
    ...ChannelDetail
  }
}
    ${ChannelDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelDetailByCodeGQL extends Apollo.Query<ChannelDetailByCodeQuery, ChannelDetailByCodeQueryVariables> {
    document = ChannelDetailByCodeDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelItemDocument = gql`
    query ChannelItem($id: Int!) {
  channel(id: $id) {
    ...ChannelItem
  }
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelItemGQL extends Apollo.Query<ChannelItemQuery, ChannelItemQueryVariables> {
    document = ChannelItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelItemsDocument = gql`
    query ChannelItems($page: PageInput, $filter: ChannelFilterInput, $sort: SortInput) {
  items: channels(page: $page, filter: $filter, sort: $sort) {
    ...ChannelItem
  }
  totalItemsCount: channelsCount(filter: $filter)
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelItemsGQL extends Apollo.Query<ChannelItemsQuery, ChannelItemsQueryVariables> {
    document = ChannelItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelRowDocument = gql`
    query ChannelRow($id: Int!) {
  channel(id: $id) {
    ...ChannelRow
  }
}
    ${ChannelRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelRowGQL extends Apollo.Query<ChannelRowQuery, ChannelRowQueryVariables> {
    document = ChannelRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelRowsDocument = gql`
    query ChannelRows($page: PageInput, $filter: ChannelFilterInput!, $sort: SortInput) {
  items: channels(page: $page, filter: $filter, sort: $sort) {
    ...ChannelRow
  }
  totalItemsCount: channelsCount(filter: $filter)
}
    ${ChannelRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelRowsGQL extends Apollo.Query<ChannelRowsQuery, ChannelRowsQueryVariables> {
    document = ChannelRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChannelSetAsDefaultDocument = gql`
    mutation ChannelSetAsDefault($id: Int!) {
  channelSetDefault(id: $id) {
    ...ChannelItem
  }
}
    ${ChannelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChannelSetAsDefaultGQL extends Apollo.Mutation<ChannelSetAsDefaultMutation, ChannelSetAsDefaultMutationVariables> {
    document = ChannelSetAsDefaultDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }