import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  IUserPaymentMethodItemObject,
  IUserPaymentMethodRowObject,
  UserPaymentMethodsService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserPaymentMethodRowCollectionProvider
  implements IDataCollectionProvider<IUserPaymentMethodRowObject, any, any, number>
{
  constructor(private userPaymentMethodService: UserPaymentMethodsService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IUserPaymentMethodRowObject>> {
    return this.userPaymentMethodService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IUserPaymentMethodRowObject): number {
    return model.id;
  }

  toString(model: IUserPaymentMethodRowObject): string {
    return model.description ?? String(model.id);
  }
}

@Injectable({ providedIn: 'root' })
export class UserPaymentMethodItemCollectionProvider
  implements
    IDataCollectionProvider<IUserPaymentMethodItemObject, any, any, number>,
    IDataProvider<IUserPaymentMethodItemObject, number>
{
  constructor(private userPaymentMethodService: UserPaymentMethodsService) {}

  fetchSingle(id: number): IUserPaymentMethodItemObject | Observable<IUserPaymentMethodItemObject> {
    return this.userPaymentMethodService.fetchItem(id);
  }

  toString(model: IUserPaymentMethodItemObject): string {
    return model.description ?? String(model.id);
  }

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IUserPaymentMethodItemObject>> {
    return this.userPaymentMethodService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IUserPaymentMethodItemObject): number {
    return model.id;
  }
}
