import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type StockCategoryItemFragment = { __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any };

export type StockCategoryRowFragment = { __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any };

export type StockCategoryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type StockCategoryItemQuery = { __typename: 'Query', stockCategory: { __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any } };

export type StockCategoryItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.StockCategoryFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type StockCategoryItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any }> };

export type StockCategoryRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type StockCategoryRowQuery = { __typename: 'Query', stockCategory: { __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any } };

export type StockCategoryRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.StockCategoryFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type StockCategoryRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any }> };

export type StockCategoryDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type StockCategoryDetailQuery = { __typename: 'Query', stockCategory: { __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any } };

export type StockCategoriesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.StockCategoryFilterInput>;
}>;


export type StockCategoriesQuery = { __typename: 'Query', stockCategories: Array<{ __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any }> };

export type StockCategorySearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type StockCategorySearchQuery = { __typename: 'Query', stockCategories: Array<{ __typename: 'StockCategoryObject', id: number, code: string, name: string, description?: string | null | undefined, updatedAt: any, createdAt: any }> };

export type CreateMutationVariables = Types.Exact<{
  data: Types.CreateStockCategoryInput;
}>;


export type CreateMutation = { __typename: 'Mutation', createStockCategory: { __typename: 'StockCategoryObject', id: number } };

export type UpdateMutationVariables = Types.Exact<{
  data: Types.UpdateStockCategoryInput;
}>;


export type UpdateMutation = { __typename: 'Mutation', updateStockCategory: { __typename: 'StockCategoryObject', id: number } };

export const StockCategoryItemFragmentDoc = gql`
    fragment StockCategoryItem on StockCategoryObject {
  id
  code
  name
  description
  updatedAt
  createdAt
}
    `;
export const StockCategoryRowFragmentDoc = gql`
    fragment StockCategoryRow on StockCategoryObject {
  id
  code
  name
  description
  updatedAt
  createdAt
}
    `;
export const StockCategoryItemDocument = gql`
    query StockCategoryItem($id: Float!) {
  stockCategory(id: $id) {
    ...StockCategoryItem
  }
}
    ${StockCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategoryItemGQL extends Apollo.Query<StockCategoryItemQuery, StockCategoryItemQueryVariables> {
    document = StockCategoryItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockCategoryItemsDocument = gql`
    query StockCategoryItems($page: PageInput, $filter: StockCategoryFilterInput, $sort: SortInput) {
  items: stockCategories(page: $page, filter: $filter, sort: $sort) {
    ...StockCategoryItem
  }
  totalItemsCount: stockCategoriesCount(filter: $filter)
}
    ${StockCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategoryItemsGQL extends Apollo.Query<StockCategoryItemsQuery, StockCategoryItemsQueryVariables> {
    document = StockCategoryItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockCategoryRowDocument = gql`
    query StockCategoryRow($id: Float!) {
  stockCategory(id: $id) {
    ...StockCategoryRow
  }
}
    ${StockCategoryRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategoryRowGQL extends Apollo.Query<StockCategoryRowQuery, StockCategoryRowQueryVariables> {
    document = StockCategoryRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockCategoryRowsDocument = gql`
    query StockCategoryRows($filter: StockCategoryFilterInput, $page: PageInput, $sort: SortInput) {
  items: stockCategories(page: $page, filter: $filter, sort: $sort) {
    ...StockCategoryRow
  }
  totalItemsCount: stockCategoriesCount(filter: $filter)
}
    ${StockCategoryRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategoryRowsGQL extends Apollo.Query<StockCategoryRowsQuery, StockCategoryRowsQueryVariables> {
    document = StockCategoryRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockCategoryDetailDocument = gql`
    query StockCategoryDetail($id: Float!) {
  stockCategory(id: $id) {
    ...StockCategoryItem
  }
}
    ${StockCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategoryDetailGQL extends Apollo.Query<StockCategoryDetailQuery, StockCategoryDetailQueryVariables> {
    document = StockCategoryDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockCategoriesDocument = gql`
    query StockCategories($filter: StockCategoryFilterInput) {
  stockCategories(filter: $filter) {
    ...StockCategoryItem
  }
}
    ${StockCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategoriesGQL extends Apollo.Query<StockCategoriesQuery, StockCategoriesQueryVariables> {
    document = StockCategoriesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockCategorySearchDocument = gql`
    query StockCategorySearch($searchText: String) {
  stockCategories(filter: {search: $searchText}) {
    ...StockCategoryItem
  }
}
    ${StockCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockCategorySearchGQL extends Apollo.Query<StockCategorySearchQuery, StockCategorySearchQueryVariables> {
    document = StockCategorySearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDocument = gql`
    mutation Create($data: CreateStockCategoryInput!) {
  createStockCategory(input: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGQL extends Apollo.Mutation<CreateMutation, CreateMutationVariables> {
    document = CreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocument = gql`
    mutation Update($data: UpdateStockCategoryInput!) {
  updateStockCategory(input: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGQL extends Apollo.Mutation<UpdateMutation, UpdateMutationVariables> {
    document = UpdateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }