import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { CurrencyItem, CurrencyService } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CurrencyAutocompleteDataSource extends ModelAutocompleteDatasource<CurrencyItem> {
  constructor(private currencyService: CurrencyService) {
    super((i: number, item: CurrencyItem) => item.id);
  }

  connect(searchText: Observable<string | null>): Observable<CurrencyItem[]> {
    return searchText.pipe(
      switchMap((text) => (text ? this.currencyService.search(text) : this.currencyService.list())),
    );
  }

  disconnect(): void {}

  displayFn = (currency: CurrencyItem) => {
    if (typeof currency !== 'object' || currency === null) return '';

    return `${currency.name} (${currency.id})`;
  };
}
