import { Inject, Injectable, InjectionToken } from '@angular/core';
import { STORAGE_VERSION } from '@x/common/storage/storage-version';
import { StorageProviderInterface } from './storage';
import { STORAGE_PREFIX } from './storage-prefix';
import { StorageService } from './storage.service';

export const SESSION_STORAGE_PROVIDER = new InjectionToken<StorageProviderInterface>(
  'SESSION_STORAGE_PROVIDER',
);

@Injectable({ providedIn: 'root' })
export class SessionStorage extends StorageService {
  constructor(
    @Inject(SESSION_STORAGE_PROVIDER)
    _storage: StorageProviderInterface,
    @Inject(STORAGE_PREFIX)
    _prefix: string,
    @Inject(STORAGE_VERSION)
    _version: number,
  ) {
    super(_storage, _prefix, _version);
  }
}
