import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { VoucherProgramFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { IVoucherProgramRowObject, VoucherProgramService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class VoucherProgramRowCollectionProvider
  implements
    IDataCollectionProvider<IVoucherProgramRowObject, VoucherProgramFilterInput, any, number>
{
  constructor(private voucherProgramService: VoucherProgramService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IVoucherProgramRowObject>> {
    return this.voucherProgramService.fetchRows({
      filter,
      page,
      sort,
    });
  }

  toId(model: IVoucherProgramRowObject): number {
    return model.id;
  }

  toString(model: IVoucherProgramRowObject): string {
    return `Voucher ID${model.id}`;
  }
}
