import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { AuthRoleService } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';
import {
  IAuthRoleItemObject,
  IAuthRoleRowObject,
} from '../../domain-client/services/types/auth-role';

@Injectable({ providedIn: 'root' })
export class AuthRoleItemCollectionProvider
  implements IDataCollectionProvider<IAuthRoleItemObject>
{
  constructor(private authRoleService: AuthRoleService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAuthRoleItemObject>> {
    return this.authRoleService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IAuthRoleItemObject> {
    return this.authRoleService.fetchItem(Number(id));
  }

  toId(model: IAuthRoleItemObject): string | number {
    return model.id;
  }

  toString(model: IAuthRoleItemObject): string {
    return `${model.name}`;
  }
}

@Injectable({ providedIn: 'root' })
export class AuthRoleRowCollectionProvider implements IDataCollectionProvider<IAuthRoleRowObject> {
  constructor(private authRoleService: AuthRoleService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAuthRoleRowObject>> {
    return this.authRoleService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IAuthRoleRowObject> {
    return this.authRoleService.fetchRow(Number(id));
  }

  toId(model: IAuthRoleRowObject): string | number {
    return model.id;
  }

  toString(model: IAuthRoleRowObject): string {
    return `${model.name}`;
  }
}
