import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  AssignCartAdjustmentInput,
  CheckoutCartInput,
  CreateCartInput,
  EnquireCartInput,
  RemoveCartItemInput,
  UpdateCartAddressInput,
  UpdateCartCloneAddressInput,
  UpdateCartCouponCodeInput,
  UpdateCartCustomerInput,
  UpdateCartGiftOptionsInput,
  UpdateCartInstructionsInput,
  UpdateCartItemInput,
  UpdateCartReferrerCodeInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  AssignCartAdjustmentGQL,
  CartAssignAddressGQL,
  CartAssignCloneAddressGQL,
  CartAssignCouponCodeGQL,
  CartAssignCustomerGQL,
  CartAssignReferrerCodeGQL,
  CartCheckoutGQL,
  CartCreateGQL,
  CartEnquireGQL,
  CartRemoveItemGQL,
  CartUpdateGiftOptionsGQL,
  CartUpdateItemGQL,
  CartsGQL,
  ProcessCartGQL,
  RecreateCartGQL,
  UpdateCartInstructionsGQL,
} from './gql/cart.gql.generated';
import { CartIndexObject } from './types/order';

@Injectable()
export class CartService {
  constructor(
    private cartsGQL: CartsGQL,
    private cartCreateGQL: CartCreateGQL,
    private cartUpdateItemGQL: CartUpdateItemGQL,
    private cartRemoveItemGQL: CartRemoveItemGQL,
    private cartAssignAddressGQL: CartAssignAddressGQL,
    private cartAssignCloneAddressGQL: CartAssignCloneAddressGQL,
    private cartAssignCustomerGQL: CartAssignCustomerGQL,
    private cartAssignCouponCodeGQL: CartAssignCouponCodeGQL,
    private cartAssignReferrerCodeGQL: CartAssignReferrerCodeGQL,
    private cartUpdateGiftOptionsGQL: CartUpdateGiftOptionsGQL,
    private cartCheckoutGQL: CartCheckoutGQL,
    private cartEnquireGQL: CartEnquireGQL,
    private updateCartInstructionsGQL: UpdateCartInstructionsGQL,
    private assignCartAdjustmentGQL: AssignCartAdjustmentGQL,
    private processCartGql: ProcessCartGQL,
    private recreateCartGQL: RecreateCartGQL,
  ) {}

  create(data: CreateCartInput) {
    return this.cartCreateGQL.mutate({ data }).pipe(mapFetchResultData((r) => r.createCart));
  }

  fetchAll(): Observable<CartIndexObject[]> {
    return this.cartsGQL.fetch().pipe(mapFetchResultData((r) => r.orders));
  }

  updateItem(data: UpdateCartItemInput) {
    return this.cartUpdateItemGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateCartItem));
  }

  removeItem(data: RemoveCartItemInput) {
    return this.cartRemoveItemGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.removeCartItem));
  }

  assignAddress(data: UpdateCartAddressInput) {
    return this.cartAssignAddressGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignCartAddress));
  }

  assignCloneAddress(data: UpdateCartCloneAddressInput) {
    return this.cartAssignCloneAddressGQL
      .mutate({ data })
      .pipe(mapFetchResultData((d) => d.assignCartCloneAddress));
  }

  assignCustomer(input: UpdateCartCustomerInput) {
    return this.cartAssignCustomerGQL
      .mutate({ data: { ...input } })
      .pipe(mapFetchResultData((r) => r.assignCartCustomer));
  }

  assignCouponCode(data: UpdateCartCouponCodeInput) {
    return this.cartAssignCouponCodeGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignCartCouponCode));
  }

  assignReferrerCode(data: UpdateCartReferrerCodeInput) {
    return this.cartAssignReferrerCodeGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignCartReferrerCode));
  }

  updateGiftOptions(data: UpdateCartGiftOptionsInput) {
    return this.cartUpdateGiftOptionsGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateCartGiftOptions));
  }

  checkout(data: CheckoutCartInput) {
    return this.cartCheckoutGQL.mutate({ data }).pipe(mapFetchResultData((r) => r.checkoutCart));
  }

  enquire(data: EnquireCartInput) {
    return this.cartEnquireGQL.mutate({ data }).pipe(mapFetchResultData((r) => r.enquireCart));
  }

  process(orderId: number) {
    return this.processCartGql.mutate({ orderId }).pipe(mapFetchResultData((r) => r.processCart));
  }

  updateInstructions(data: UpdateCartInstructionsInput) {
    return this.updateCartInstructionsGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateCartInstructions));
  }

  assignAdjustment(data: AssignCartAdjustmentInput) {
    return this.assignCartAdjustmentGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignCartAdjustment));
  }

  recreate(orderId: number, skipAgreements?: boolean) {
    return this.recreateCartGQL
      .mutate({ orderId, skipAgreements })
      .pipe(mapFetchResultData((r) => r.recreateCart));
  }
}
