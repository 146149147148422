import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { UserItemFragmentDoc, OrderSalesStatsFragmentDoc, OrderStateCountsFragmentDoc, UserDetailFragmentDoc } from './user.gql.generated';
import { ProductItemFragmentDoc } from './product.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductWishlistItemRowFragment = { __typename: 'ProductWishlistItemObject', id: number, createdAt: any, updatedAt: any, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any }, product: { __typename: 'ProductObject', id: number, name: string, code: string } };

export type ProductWishlistItemRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductWishlistItemFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductWishlistItemRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductWishlistItemObject', id: number, createdAt: any, updatedAt: any, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any }, product: { __typename: 'ProductObject', id: number, name: string, code: string } }> };

export type AddProductWishlistItemMutationVariables = Types.Exact<{
  input: Types.ProductWishlistItemInput;
}>;


export type AddProductWishlistItemMutation = { __typename: 'Mutation', addProductWishlistItem: { __typename: 'ProductWishlistItemObject', id: number, createdAt: any, updatedAt: any, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any }, product: { __typename: 'ProductObject', id: number, name: string, code: string } } };

export type RemoveProductWishlistItemMutationVariables = Types.Exact<{
  input: Types.ProductWishlistItemInput;
}>;


export type RemoveProductWishlistItemMutation = { __typename: 'Mutation', removeProductWishlistItem?: { __typename: 'ProductWishlistItemObject', id: number, createdAt: any, updatedAt: any, user: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any }, product: { __typename: 'ProductObject', id: number, name: string, code: string } } | null | undefined };

export const ProductWishlistItemRowFragmentDoc = gql`
    fragment ProductWishlistItemRow on ProductWishlistItemObject {
  id
  createdAt
  updatedAt
  user {
    ...UserItem
  }
  product {
    ...ProductItem
  }
}
    ${UserItemFragmentDoc}
${ProductItemFragmentDoc}`;
export const ProductWishlistItemRowsDocument = gql`
    query ProductWishlistItemRows($filter: ProductWishlistItemFilterInput, $page: PageInput, $sort: SortInput) {
  items: productWishlistItems(filter: $filter, page: $page, sort: $sort) {
    ...ProductWishlistItemRow
  }
  totalItemsCount: productWishlistItemsCount(filter: $filter)
}
    ${ProductWishlistItemRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductWishlistItemRowsGQL extends Apollo.Query<ProductWishlistItemRowsQuery, ProductWishlistItemRowsQueryVariables> {
    document = ProductWishlistItemRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddProductWishlistItemDocument = gql`
    mutation AddProductWishlistItem($input: ProductWishlistItemInput!) {
  addProductWishlistItem(input: $input) {
    ...ProductWishlistItemRow
  }
}
    ${ProductWishlistItemRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProductWishlistItemGQL extends Apollo.Mutation<AddProductWishlistItemMutation, AddProductWishlistItemMutationVariables> {
    document = AddProductWishlistItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveProductWishlistItemDocument = gql`
    mutation RemoveProductWishlistItem($input: ProductWishlistItemInput!) {
  removeProductWishlistItem(input: $input) {
    ...ProductWishlistItemRow
  }
}
    ${ProductWishlistItemRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProductWishlistItemGQL extends Apollo.Mutation<RemoveProductWishlistItemMutation, RemoveProductWishlistItemMutationVariables> {
    document = RemoveProductWishlistItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }