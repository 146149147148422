import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateShippingScheduleInput, UpdateShippingScheduleInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateShippingScheduleGQL,
  ShippingScheduleByIdGQL,
  ShippingScheduleRowsGQL,
  ShippingScheduleRowsQueryVariables,
  UpdateShippingScheduleGQL,
} from './gql/shipping-schedule.gql.generated';
import { IShippingScheduleItemObject, IShippingScheduleRowObject } from './types/shipping-schedule';

@Injectable()
export class ShippingScheduleService {
  constructor(
    private createGQL: CreateShippingScheduleGQL,
    private updateGQL: UpdateShippingScheduleGQL,
    private rowsGQL: ShippingScheduleRowsGQL,
    private byIdGQL: ShippingScheduleByIdGQL,
  ) {}

  fetchById(id: number): Observable<IShippingScheduleItemObject> {
    return this.byIdGQL.fetch({ id }).pipe(map((r) => r.data.shippingSchedule));
  }

  fetchSearch(searchText?: string | null, results = 10): Observable<IShippingScheduleRowObject[]> {
    const query: ShippingScheduleRowsQueryVariables = {
      filter: { search: searchText },
      page: {},
      sort: { column: 'name' },
    };

    return this.fetchRows(query).pipe(map((r) => r.items));
  }

  fetchRows(
    query: ShippingScheduleRowsQueryVariables,
  ): Observable<{ items: IShippingScheduleRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  create(input: CreateShippingScheduleInput) {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShippingSchedule));
  }

  update(input: UpdateShippingScheduleInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShippingSchedule));
  }
}
