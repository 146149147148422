import { Injectable } from '@angular/core';
import { SubscriptionVariantInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SubscriptionVariantCreateGQL,
  SubscriptionVariantDetailGQL,
  SubscriptionVariantItemGQL,
  SubscriptionVariantItemsGQL,
  SubscriptionVariantItemsQueryVariables,
  SubscriptionVariantRowsGQL,
  SubscriptionVariantRowsQueryVariables,
  SubscriptionVariantRunsGQL,
  SubscriptionVariantSearchGQL,
  SubscriptionVariantUpdateGQL,
} from './gql/subscription-variant.gql.generated';
import {
  ISubscriptionVariantDetailObject,
  ISubscriptionVariantItemObject,
  ISubscriptionVariantRowObject,
  ISubscriptionVariantRunRowObject,
} from './types/subscription-variant';

@Injectable()
export class SubscriptionVariantService {
  constructor(
    private readonly rowsGQL: SubscriptionVariantRowsGQL,
    private readonly itemGQL: SubscriptionVariantItemGQL,
    private readonly detailGQL: SubscriptionVariantDetailGQL,
    private readonly subscriptionVariantItemsGQL: SubscriptionVariantItemsGQL,
    private readonly searchGQL: SubscriptionVariantSearchGQL,
    private readonly updateGQL: SubscriptionVariantUpdateGQL,
    private readonly createGQL: SubscriptionVariantCreateGQL,
    private readonly runsGQL: SubscriptionVariantRunsGQL,
  ) {}

  fetchRows(
    query: SubscriptionVariantRowsQueryVariables,
  ): Observable<{ items: ISubscriptionVariantRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<ISubscriptionVariantItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionVariant));
  }

  fetchItems(
    query: SubscriptionVariantItemsQueryVariables,
  ): Observable<{ items: ISubscriptionVariantItemObject[]; totalItemsCount: number }> {
    return this.subscriptionVariantItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchDetail(id: number): Observable<ISubscriptionVariantDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionVariant));
  }

  fetchItemSearch(
    search?: string | null,
    limit = 10,
  ): Observable<ISubscriptionVariantItemObject[]> {
    return this.searchGQL
      .fetch({
        filter: { search },
        page: { index: 0, size: limit },
        sort: { column: 'name', order: 'asc' },
      })
      .pipe(map((r) => r.data.subscriptionVariants));
  }

  update(data: SubscriptionVariantInput) {
    return this.updateGQL.mutate({ data }).pipe(map((r) => r.data?.updateSubscriptionVariant));
  }

  create(data: SubscriptionVariantInput) {
    return this.createGQL.mutate({ data }).pipe(map((r) => r.data?.createSubscriptionVariant));
  }

  fetchItemRuns(id: number): Observable<ISubscriptionVariantRunRowObject> {
    return this.runsGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionVariant));
  }
}
