import { NestedTreeControl } from '@angular/cdk/tree';
import { TaxonNode } from './taxon-node';

export class TaxonTreeControl extends NestedTreeControl<TaxonNode, number> {
  constructor() {
    super((node) => node.children, { trackBy: (n) => n.taxon.id });
  }

  expandAscendants(dataNode: TaxonNode) {
    if (dataNode.parent) {
      this.expand(dataNode.parent);
      this.expandAscendants(dataNode.parent);
    }
  }
}
