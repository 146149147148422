import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShippingCategoryItemFragment = { __typename: 'ShippingCategoryObject', id: number, name: string, code: string, description?: string | null | undefined, createdAt: any, updatedAt: any };

export type CreateShippingCategoryMutationVariables = Types.Exact<{
  input: Types.CreateShippingCategoryInput;
}>;


export type CreateShippingCategoryMutation = { __typename: 'Mutation', createShippingCategory: { __typename: 'ShippingCategoryObject', id: number, name: string, code: string, description?: string | null | undefined, createdAt: any, updatedAt: any } };

export type UpdateShippingCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateShippingCategoryInput;
}>;


export type UpdateShippingCategoryMutation = { __typename: 'Mutation', updateShippingCategory: { __typename: 'ShippingCategoryObject', id: number, name: string, code: string, description?: string | null | undefined, createdAt: any, updatedAt: any } };

export type ShippingCategoryItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShippingCategoryItemQuery = { __typename: 'Query', shippingCategory: { __typename: 'ShippingCategoryObject', id: number, name: string, code: string, description?: string | null | undefined, createdAt: any, updatedAt: any } };

export type ShippingCategoryItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ShippingCategoryFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ShippingCategoryItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ShippingCategoryObject', id: number, name: string, code: string, description?: string | null | undefined, createdAt: any, updatedAt: any }> };

export const ShippingCategoryItemFragmentDoc = gql`
    fragment ShippingCategoryItem on ShippingCategoryObject {
  id
  name
  code
  description
  createdAt
  updatedAt
}
    `;
export const CreateShippingCategoryDocument = gql`
    mutation CreateShippingCategory($input: CreateShippingCategoryInput!) {
  createShippingCategory(input: $input) {
    ...ShippingCategoryItem
  }
}
    ${ShippingCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShippingCategoryGQL extends Apollo.Mutation<CreateShippingCategoryMutation, CreateShippingCategoryMutationVariables> {
    document = CreateShippingCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShippingCategoryDocument = gql`
    mutation UpdateShippingCategory($input: UpdateShippingCategoryInput!) {
  updateShippingCategory(input: $input) {
    ...ShippingCategoryItem
  }
}
    ${ShippingCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShippingCategoryGQL extends Apollo.Mutation<UpdateShippingCategoryMutation, UpdateShippingCategoryMutationVariables> {
    document = UpdateShippingCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingCategoryItemDocument = gql`
    query ShippingCategoryItem($id: Int!) {
  shippingCategory(id: $id) {
    ...ShippingCategoryItem
  }
}
    ${ShippingCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingCategoryItemGQL extends Apollo.Query<ShippingCategoryItemQuery, ShippingCategoryItemQueryVariables> {
    document = ShippingCategoryItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingCategoryItemsDocument = gql`
    query ShippingCategoryItems($page: PageInput, $filter: ShippingCategoryFilterInput, $sort: SortInput) {
  items: shippingCategories(page: $page, filter: $filter, sort: $sort) {
    ...ShippingCategoryItem
  }
  totalItemsCount: shippingCategoriesCount(filter: $filter)
}
    ${ShippingCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingCategoryItemsGQL extends Apollo.Query<ShippingCategoryItemsQuery, ShippingCategoryItemsQueryVariables> {
    document = ShippingCategoryItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }