import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { AuthRoleService } from '@x/ecommerce/domain-client';
import { map, Observable, shareReplay } from 'rxjs';
import { IAuthPermissionObject } from '../../domain-client/services/types/auth-role';

@Injectable({ providedIn: 'root' })
export class AuthPermissionItemCollectionProvider
  implements IDataCollectionProvider<IAuthPermissionObject>
{
  permissions$ = this.authRoleService.fetchPermissions().pipe(shareReplay());

  constructor(private authRoleService: AuthRoleService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IAuthPermissionObject>> {
    return this.permissions$.pipe(
      map((permissions) => {
        const items = searchText
          ? permissions.filter(
              (p) =>
                p.permission.toLowerCase().includes(searchText.toLowerCase()) ||
                p.description?.toLowerCase().includes(searchText.toLowerCase()),
            )
          : permissions;
        return { items, totalItemsCount: permissions.length };
      }),
    );
  }

  fetchSingle(id: string | number): Observable<IAuthPermissionObject | undefined> {
    return this.permissions$.pipe(map((permission) => permission.find((p) => p.permission === id)));
  }

  toId(model: IAuthPermissionObject): string | number {
    return model.permission;
  }

  toString(model: IAuthPermissionObject): string {
    return `${model.description}`;
  }
}
