import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import {
  ITaxCategoryItemObject,
  ITaxCategoryRowObject,
  TaxCategoryService,
} from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaxCategoryRowCollectionProvider
  implements IDataCollectionProvider<ITaxCategoryRowObject>
{
  constructor(private taxonService: TaxCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ITaxCategoryRowObject>> {
    return this.taxonService.fetchRows({
      page,
      sort,
      filter: { search: searchText, ...filter },
    });
  }

  fetchSingle(id: string | number): ITaxCategoryRowObject | Observable<ITaxCategoryRowObject> {
    return this.taxonService.fetchRow(Number(id));
  }

  toId(model: ITaxCategoryRowObject): string | number {
    return model.id;
  }

  toString(model: ITaxCategoryRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class TaxCategoryItemCollectionProvider
  implements IDataCollectionProvider<ITaxCategoryItemObject>
{
  constructor(private taxonService: TaxCategoryService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ITaxCategoryItemObject>> {
    return this.taxonService.fetchRows({
      page,
      sort,
      filter: { search: searchText, ...filter },
    });
  }

  fetchSingle(id: string | number): ITaxCategoryItemObject | Observable<ITaxCategoryItemObject> {
    return this.taxonService.fetchRow(Number(id));
  }

  toId(model: ITaxCategoryItemObject): string | number {
    return model.id;
  }

  toString(model: ITaxCategoryItemObject): string {
    return model.name;
  }
}
