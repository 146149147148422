import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateStockItemComponentInput, UpdateStockItemComponentInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  CreateStockItemComponentGQL,
  DeleteStockItemComponentGQL,
  StockItemComponentItemGQL,
  UpdateStockItemComponentGQL,
} from './gql/stock-item-component.gql.generated';
import { IStockItemComponentItemObject } from './types/stock-item-component';

@Injectable()
export class StockItemComponentService {
  constructor(
    private readonly stockItemComponentGQL: StockItemComponentItemGQL,
    private readonly createStockItemComponentGQL: CreateStockItemComponentGQL,
    private readonly updateStockItemComponentGQL: UpdateStockItemComponentGQL,
    private readonly deleteStockItemComponentGQL: DeleteStockItemComponentGQL,
  ) {}

  fetchItem(id: number): Observable<IStockItemComponentItemObject> {
    return this.stockItemComponentGQL
      .fetch({
        id,
      })
      .pipe(mapFetchResultData((r) => r.stockItemComponent));
  }

  create(input: CreateStockItemComponentInput): Observable<{ id: number }> {
    return this.createStockItemComponentGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createStockItemComponent));
  }

  update(input: UpdateStockItemComponentInput): Observable<{ id: number }> {
    return this.updateStockItemComponentGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.updateStockItemComponent));
  }

  delete(id: number): Observable<number> {
    return this.deleteStockItemComponentGQL
      .mutate({ id })
      .pipe(mapFetchResultData((r) => r.deleteStockItemComponent));
  }
}
