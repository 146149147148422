import { Injectable } from '@angular/core';
import { LocaleItem, LocaleService } from '@x/common/locale';
import { ChannelService, IChannelDetailObject } from '@x/ecommerce/domain-client';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export interface ICurrencyItem {
  id: string;
  label: string;
}

@Injectable({ providedIn: 'root' })
export class ChannelContext {
  private _availableLocales$: Observable<string[]>;
  private _availableCurrencies$: Observable<string[]>;

  private _defaultChannel$: Observable<IChannelDetailObject> = this.channelService
    .fetchDefaultChannel()
    .pipe(shareReplay());

  constructor(private localeService: LocaleService, private channelService: ChannelService) {
    this._availableLocales$ = this.getAllChannels().pipe(
      map((channels) => {
        let locales: any = {};

        channels.forEach((channel) => {
          channel.locales.forEach((locale) => (locales[locale.locale] = true));
        });

        return Object.keys(locales);
      }),
      shareReplay(),
    );

    this._availableCurrencies$ = this.getAllChannels().pipe(
      map((channels) => {
        let currencies: any = {};

        channels.forEach((channel) => {
          currencies[channel.currency] = true;
        });

        return Object.keys(currencies);
      }),
      shareReplay(),
    );
  }

  /**
   * Get the default configured channel
   */
  async getDefaultChannel(): Promise<IChannelDetailObject> {
    return firstValueFrom(this._defaultChannel$);
  }

  getAllChannels(): Observable<IChannelDetailObject[]> {
    return this.channelService.fetchAll();
  }

  getCurrenciesInAllChannels(): Observable<string[]> {
    return this._availableCurrencies$.pipe(shareReplay());
  }

  getCurrencyOptionsInAllChannels(): Observable<ICurrencyItem[]> {
    return this._availableCurrencies$.pipe(
      map((value) => {
        return value.map((currency) => {
          return { label: currency, id: currency };
        });
      }),
    );
  }

  getLocalesInAllChannels(): Observable<string[]> {
    return this._availableLocales$.pipe(shareReplay());
  }

  getLocaleOptionsInAllChannels(): Observable<LocaleItem[]> {
    return combineLatest([this._availableLocales$, this.localeService.list()]).pipe(
      map(([availLocales, allLocales]) => {
        return allLocales.filter((item) => {
          return availLocales.includes(item.id);
        });
      }),
    );
  }

  getLocaleOption(id: string): Observable<LocaleItem> {
    return this.localeService.list().pipe(
      map((allLocales) => {
        const locale = allLocales.find((item) => item.id === id);
        if (!locale) throw new Error('Unknown locale id ' + id);
        return locale;
      }),
    );
  }

  getLocalesInChannel(id: number): Observable<LocaleItem[]> {
    return this.channelService.fetchDetail(id).pipe(
      map((channel) => channel.locales),
      map((locales) => {
        return locales.map((locale) => {
          return {
            id: locale.locale,
            name: locale.name || '',
          };
        });
      }),
    );
  }
}
