import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { OrderTagInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  AddOrderTagsGQL,
  OrderTagItemGQL,
  OrderTagItemsGQL,
  OrderTagItemsQueryVariables,
  RemoveOrderTagsGQL,
} from './gql/order-tag.gql.generated';
import { DomainDataCollection } from './types/collection';
import { IOrderTagItem } from './types/order-tag';

@Injectable()
export class OrderTagService {
  constructor(
    private addOrderTagsGql: AddOrderTagsGQL,
    private removeOrderTagsGql: RemoveOrderTagsGQL,
    private orderTagItemsGql: OrderTagItemsGQL,
    private orderTagItemGql: OrderTagItemGQL,
  ) {}

  fetchItem(id: number): Observable<IOrderTagItem> {
    return this.orderTagItemGql.fetch({ id }).pipe(mapFetchResultData((r) => r.orderTag));
  }

  fetchItems(query: OrderTagItemsQueryVariables): Observable<DomainDataCollection<IOrderTagItem>> {
    return this.orderTagItemsGql.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  addOrderTags(input: OrderTagInput) {
    return this.addOrderTagsGql.mutate({ input }).pipe(mapFetchResultData((r) => r.addOrderTags));
  }
  removeOrderTags(input: OrderTagInput) {
    return this.removeOrderTagsGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.removeOrderTags));
  }
}
