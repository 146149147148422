import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AgreementDeclarationItemFragment = { __typename: 'AgreementDeclarationObject', id: number, agreed: boolean, agreedAt?: any | null | undefined, agreement: { __typename: 'AgreementObject', name: string, translations: Array<{ __typename: 'AgreementTranslationObject', name: string, label?: string | null | undefined, termsUrl?: string | null | undefined }> } };

export type AgreementDeclarationItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.AgreementDeclarationFilter>;
}>;


export type AgreementDeclarationItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AgreementDeclarationObject', id: number, agreed: boolean, agreedAt?: any | null | undefined, agreement: { __typename: 'AgreementObject', name: string, translations: Array<{ __typename: 'AgreementTranslationObject', name: string, label?: string | null | undefined, termsUrl?: string | null | undefined }> } }> };

export const AgreementDeclarationItemFragmentDoc = gql`
    fragment AgreementDeclarationItem on AgreementDeclarationObject {
  id
  agreed
  agreedAt
  agreement {
    name
    translations {
      name
      label
      termsUrl
    }
  }
}
    `;
export const AgreementDeclarationItemsDocument = gql`
    query AgreementDeclarationItems($page: PageInput, $sort: SortInput, $filter: AgreementDeclarationFilter) {
  items: agreementDeclarations(page: $page, filter: $filter, sort: $sort) {
    ...AgreementDeclarationItem
  }
  totalItemsCount: agreementDeclarationsCount(filter: $filter)
}
    ${AgreementDeclarationItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AgreementDeclarationItemsGQL extends Apollo.Query<AgreementDeclarationItemsQuery, AgreementDeclarationItemsQueryVariables> {
    document = AgreementDeclarationItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }