import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PaymentMethodItemFragment = { __typename: 'PaymentMethodObject', id: number, name: string, code: string };

export type PaymentMethodFragment = { __typename: 'PaymentMethodObject', id: number, name: string, code: string, provider: string, channels: Array<{ __typename: 'ChannelObject', code: string, enabled: boolean }> };

export type PaymentMethodRowFragment = { __typename: 'PaymentMethodObject', id: number, code: string, name: string, provider: string, createdAt: any, updatedAt: any, canReuse: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }> };

export type PaymentMethodDetailFragment = { __typename: 'PaymentMethodObject', id: number, code: string, name: string, provider: string, providerConfig?: any | null | undefined, canReuse: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }> };

export type CreatePaymentMethodMutationVariables = Types.Exact<{
  input: Types.CreatePaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = { __typename: 'Mutation', createPaymentMethod: { __typename: 'PaymentMethodObject', id: number, code: string, name: string } };

export type UpdatePaymentMethodMutationVariables = Types.Exact<{
  input: Types.UpdatePaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = { __typename: 'Mutation', updatePaymentMethod: { __typename: 'PaymentMethodObject', id: number, code: string, name: string } };

export type UpdateChannelEnabledMutationVariables = Types.Exact<{
  input: Types.PaymentMethodChannelEnabledInput;
}>;


export type UpdateChannelEnabledMutation = { __typename: 'Mutation', updatePaymentMethodChannelEnabled: { __typename: 'PaymentMethodObject', id: number, code: string, name: string } };

export type PaymentMethodSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type PaymentMethodSearchQuery = { __typename: 'Query', paymentMethods: Array<{ __typename: 'PaymentMethodObject', id: number, code: string, name: string }> };

export type PaymentMethodItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PaymentMethodItemQuery = { __typename: 'Query', paymentMethod: { __typename: 'PaymentMethodObject', id: number, name: string, code: string } };

export type PaymentMethodItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.PaymentMethodFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type PaymentMethodItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'PaymentMethodObject', id: number, name: string, code: string }> };

export type PaymentMethodRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PaymentMethodRowQuery = { __typename: 'Query', paymentMethod: { __typename: 'PaymentMethodObject', id: number, code: string, name: string, provider: string, createdAt: any, updatedAt: any, canReuse: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }> } };

export type PaymentMethodRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.PaymentMethodFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type PaymentMethodRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'PaymentMethodObject', id: number, code: string, name: string, provider: string, createdAt: any, updatedAt: any, canReuse: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }> }> };

export type PaymentMethodByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PaymentMethodByIdQuery = { __typename: 'Query', paymentMethod: { __typename: 'PaymentMethodObject', id: number, code: string, name: string } };

export type PaymentMethodDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type PaymentMethodDetailQuery = { __typename: 'Query', paymentMethod: { __typename: 'PaymentMethodObject', id: number, code: string, name: string, provider: string, providerConfig?: any | null | undefined, canReuse: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }> } };

export const PaymentMethodItemFragmentDoc = gql`
    fragment PaymentMethodItem on PaymentMethodObject {
  id
  name
  code
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment paymentMethod on PaymentMethodObject {
  id
  name
  code
  provider
  channels {
    code
    enabled
  }
}
    `;
export const PaymentMethodRowFragmentDoc = gql`
    fragment PaymentMethodRow on PaymentMethodObject {
  id
  code
  name
  provider
  channels {
    id
    code
    name
  }
  createdAt
  updatedAt
  canReuse
}
    `;
export const PaymentMethodDetailFragmentDoc = gql`
    fragment PaymentMethodDetail on PaymentMethodObject {
  id
  code
  name
  provider
  providerConfig
  channels {
    id
    code
    name
  }
  canReuse
}
    `;
export const CreatePaymentMethodDocument = gql`
    mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePaymentMethodGQL extends Apollo.Mutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables> {
    document = CreatePaymentMethodDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePaymentMethodDocument = gql`
    mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
  updatePaymentMethod(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePaymentMethodGQL extends Apollo.Mutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables> {
    document = UpdatePaymentMethodDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChannelEnabledDocument = gql`
    mutation UpdateChannelEnabled($input: PaymentMethodChannelEnabledInput!) {
  updatePaymentMethodChannelEnabled(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChannelEnabledGQL extends Apollo.Mutation<UpdateChannelEnabledMutation, UpdateChannelEnabledMutationVariables> {
    document = UpdateChannelEnabledDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodSearchDocument = gql`
    query PaymentMethodSearch($searchText: String, $results: Int!) {
  paymentMethods(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodSearchGQL extends Apollo.Query<PaymentMethodSearchQuery, PaymentMethodSearchQueryVariables> {
    document = PaymentMethodSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodItemDocument = gql`
    query PaymentMethodItem($id: Int!) {
  paymentMethod(id: $id) {
    ...PaymentMethodItem
  }
}
    ${PaymentMethodItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodItemGQL extends Apollo.Query<PaymentMethodItemQuery, PaymentMethodItemQueryVariables> {
    document = PaymentMethodItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodItemsDocument = gql`
    query PaymentMethodItems($page: PageInput, $filter: PaymentMethodFilterInput, $sort: SortInput) {
  items: paymentMethods(page: $page, filter: $filter, sort: $sort) {
    ...PaymentMethodItem
  }
  totalItemsCount: paymentMethodsCount(filter: $filter)
}
    ${PaymentMethodItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodItemsGQL extends Apollo.Query<PaymentMethodItemsQuery, PaymentMethodItemsQueryVariables> {
    document = PaymentMethodItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodRowDocument = gql`
    query PaymentMethodRow($id: Int!) {
  paymentMethod(id: $id) {
    ...PaymentMethodRow
  }
}
    ${PaymentMethodRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodRowGQL extends Apollo.Query<PaymentMethodRowQuery, PaymentMethodRowQueryVariables> {
    document = PaymentMethodRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodRowsDocument = gql`
    query PaymentMethodRows($page: PageInput, $filter: PaymentMethodFilterInput, $sort: SortInput) {
  items: paymentMethods(page: $page, filter: $filter, sort: $sort) {
    ...PaymentMethodRow
  }
  totalItemsCount: paymentMethodsCount(filter: $filter)
}
    ${PaymentMethodRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodRowsGQL extends Apollo.Query<PaymentMethodRowsQuery, PaymentMethodRowsQueryVariables> {
    document = PaymentMethodRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodByIdDocument = gql`
    query PaymentMethodById($id: Int!) {
  paymentMethod(id: $id) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodByIdGQL extends Apollo.Query<PaymentMethodByIdQuery, PaymentMethodByIdQueryVariables> {
    document = PaymentMethodByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentMethodDetailDocument = gql`
    query PaymentMethodDetail($id: Int!) {
  paymentMethod(id: $id) {
    ...PaymentMethodDetail
  }
}
    ${PaymentMethodDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentMethodDetailGQL extends Apollo.Query<PaymentMethodDetailQuery, PaymentMethodDetailQueryVariables> {
    document = PaymentMethodDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }