import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TaxCategoryRowFragment = { __typename: 'TaxCategoryObject', id: number, name: string, code: string, createdAt: any, updatedAt: any };

export type TaxCategoryItemFragment = { __typename: 'TaxCategoryObject', id: number, name: string };

export type TaxCategoryRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type TaxCategoryRowQuery = { __typename: 'Query', taxCategory: { __typename: 'TaxCategoryObject', id: number, name: string, code: string, createdAt: any, updatedAt: any } };

export type TaxCategoryRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.TaxCategoryFilterInput>;
}>;


export type TaxCategoryRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'TaxCategoryObject', id: number, name: string, code: string, createdAt: any, updatedAt: any }> };

export type TaxCategoryItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.TaxCategoryFilterInput>;
}>;


export type TaxCategoryItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'TaxCategoryObject', id: number, name: string }> };

export type TaxCategorySearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type TaxCategorySearchQuery = { __typename: 'Query', taxCategories: Array<{ __typename: 'TaxCategoryObject', id: number, name: string }> };

export type TaxCategoryListGqlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TaxCategoryListGqlQuery = { __typename: 'Query', taxCategories: Array<{ __typename: 'TaxCategoryObject', id: number, name: string }> };

export type TaxCategoryByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type TaxCategoryByIdQuery = { __typename: 'Query', taxCategory: { __typename: 'TaxCategoryObject', id: number, name: string } };

export type CreateTaxCategoryMutationVariables = Types.Exact<{
  input: Types.CreateTaxCategoryInput;
}>;


export type CreateTaxCategoryMutation = { __typename: 'Mutation', createTaxCategory: { __typename: 'TaxCategoryObject', id: number, name: string } };

export type UpdateTaxCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateTaxCategoryInput;
}>;


export type UpdateTaxCategoryMutation = { __typename: 'Mutation', updateTaxCategory: { __typename: 'TaxCategoryObject', id: number, name: string } };

export const TaxCategoryRowFragmentDoc = gql`
    fragment TaxCategoryRow on TaxCategoryObject {
  id
  name
  code
  createdAt
  updatedAt
}
    `;
export const TaxCategoryItemFragmentDoc = gql`
    fragment TaxCategoryItem on TaxCategoryObject {
  id
  name
}
    `;
export const TaxCategoryRowDocument = gql`
    query TaxCategoryRow($id: Int!) {
  taxCategory(id: $id) {
    ...TaxCategoryRow
  }
}
    ${TaxCategoryRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxCategoryRowGQL extends Apollo.Query<TaxCategoryRowQuery, TaxCategoryRowQueryVariables> {
    document = TaxCategoryRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxCategoryRowsDocument = gql`
    query TaxCategoryRows($page: PageInput, $sort: SortInput, $filter: TaxCategoryFilterInput) {
  items: taxCategories(page: $page, filter: $filter, sort: $sort) {
    ...TaxCategoryRow
  }
  totalItemsCount: taxCategoriesCount(filter: $filter)
}
    ${TaxCategoryRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxCategoryRowsGQL extends Apollo.Query<TaxCategoryRowsQuery, TaxCategoryRowsQueryVariables> {
    document = TaxCategoryRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxCategoryItemsDocument = gql`
    query TaxCategoryItems($page: PageInput, $sort: SortInput, $filter: TaxCategoryFilterInput) {
  items: taxCategories(page: $page, filter: $filter, sort: $sort) {
    ...TaxCategoryItem
  }
  totalItemsCount: taxCategoriesCount(filter: $filter)
}
    ${TaxCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxCategoryItemsGQL extends Apollo.Query<TaxCategoryItemsQuery, TaxCategoryItemsQueryVariables> {
    document = TaxCategoryItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxCategorySearchDocument = gql`
    query TaxCategorySearch($searchText: String, $results: Int!) {
  taxCategories(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    ...TaxCategoryItem
  }
}
    ${TaxCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxCategorySearchGQL extends Apollo.Query<TaxCategorySearchQuery, TaxCategorySearchQueryVariables> {
    document = TaxCategorySearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxCategoryListGqlDocument = gql`
    query TaxCategoryListGQL {
  taxCategories {
    ...TaxCategoryItem
  }
}
    ${TaxCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxCategoryListGqlGQL extends Apollo.Query<TaxCategoryListGqlQuery, TaxCategoryListGqlQueryVariables> {
    document = TaxCategoryListGqlDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxCategoryByIdDocument = gql`
    query TaxCategoryById($id: Int!) {
  taxCategory(id: $id) {
    ...TaxCategoryItem
  }
}
    ${TaxCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxCategoryByIdGQL extends Apollo.Query<TaxCategoryByIdQuery, TaxCategoryByIdQueryVariables> {
    document = TaxCategoryByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTaxCategoryDocument = gql`
    mutation CreateTaxCategory($input: CreateTaxCategoryInput!) {
  createTaxCategory(input: $input) {
    ...TaxCategoryItem
  }
}
    ${TaxCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTaxCategoryGQL extends Apollo.Mutation<CreateTaxCategoryMutation, CreateTaxCategoryMutationVariables> {
    document = CreateTaxCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaxCategoryDocument = gql`
    mutation UpdateTaxCategory($input: UpdateTaxCategoryInput!) {
  updateTaxCategory(input: $input) {
    ...TaxCategoryItem
  }
}
    ${TaxCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaxCategoryGQL extends Apollo.Mutation<UpdateTaxCategoryMutation, UpdateTaxCategoryMutationVariables> {
    document = UpdateTaxCategoryDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }