import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateParcelOptionInput, UpdateParcelOptionInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateParcelOptionGQL,
  ParcelOptionDetailGQL,
  ParcelOptionRowGQL,
  ParcelOptionRowsGQL,
  ParcelOptionRowsQueryVariables,
  ParcelOptionsGQL,
  UpdateParcelOptionGQL,
} from './gql/parcel-option.gql.generated';
import {
  IParcelOptionRowObject,
  ParcelOptionDetailObject,
  ParcelOptionRowObject,
} from './types/parcel-option';

@Injectable()
export class ParcelOptionService {
  constructor(
    private allGQL: ParcelOptionsGQL,
    private createGQL: CreateParcelOptionGQL,
    private updateGQL: UpdateParcelOptionGQL,
    private rowGql: ParcelOptionRowGQL,
    private rowsGQL: ParcelOptionRowsGQL,
    private detailGQL: ParcelOptionDetailGQL,
  ) {}

  fetchDetail(id: number): Observable<ParcelOptionDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((result) => result.data.parcelOption));
  }

  fetchAll(): Observable<ParcelOptionDetailObject[]> {
    return this.allGQL.fetch().pipe(mapFetchResultData((d) => d.parcelOptions));
  }

  fetchRow(id: number): Observable<IParcelOptionRowObject> {
    return this.rowGql.fetch({ id }).pipe(map((r) => r.data.parcelOption));
  }

  fetchRows(
    query: ParcelOptionRowsQueryVariables,
  ): Observable<{ items: ParcelOptionRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  create(input: CreateParcelOptionInput) {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createParcelOption));
  }

  update(input: UpdateParcelOptionInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateParcelOption));
  }

  updateMany() {
    //TODO
  }
}
