import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type TaxRateRowFragment = { __typename: 'TaxRateObject', id: number, name: string, code: string, amount: number, inclusive: boolean, priority: number, country?: string | null | undefined, province?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, name: string, code: string, color: string }, category: { __typename: 'TaxCategoryObject', id: number, name: string } };

export type TaxRateItemFragment = { __typename: 'TaxRateObject', id: number, name: string };

export type TaxRateRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type TaxRateRowQuery = { __typename: 'Query', taxRate: { __typename: 'TaxRateObject', id: number, name: string, code: string, amount: number, inclusive: boolean, priority: number, country?: string | null | undefined, province?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, name: string, code: string, color: string }, category: { __typename: 'TaxCategoryObject', id: number, name: string } } };

export type TaxRateRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.TaxRateFilterInput>;
}>;


export type TaxRateRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'TaxRateObject', id: number, name: string, code: string, amount: number, inclusive: boolean, priority: number, country?: string | null | undefined, province?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, name: string, code: string, color: string }, category: { __typename: 'TaxCategoryObject', id: number, name: string } }> };

export type TaxRateSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type TaxRateSearchQuery = { __typename: 'Query', taxRates: Array<{ __typename: 'TaxRateObject', id: number, name: string }> };

export type TaxRateListGqlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TaxRateListGqlQuery = { __typename: 'Query', taxRates: Array<{ __typename: 'TaxRateObject', id: number, name: string }> };

export type TaxRateByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type TaxRateByIdQuery = { __typename: 'Query', taxRate: { __typename: 'TaxRateObject', id: number, name: string, code: string, amount: number, inclusive: boolean, priority: number, country?: string | null | undefined, province?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, name: string, code: string, color: string }, category: { __typename: 'TaxCategoryObject', id: number, name: string } } };

export type CreateTaxRateMutationVariables = Types.Exact<{
  input: Types.CreateTaxRateInput;
}>;


export type CreateTaxRateMutation = { __typename: 'Mutation', createTaxRate: { __typename: 'TaxRateObject', id: number, name: string, code: string, amount: number, inclusive: boolean, priority: number, country?: string | null | undefined, province?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, name: string, code: string, color: string }, category: { __typename: 'TaxCategoryObject', id: number, name: string } } };

export type UpdateTaxRateMutationVariables = Types.Exact<{
  input: Types.UpdateTaxRateInput;
}>;


export type UpdateTaxRateMutation = { __typename: 'Mutation', updateTaxRate: { __typename: 'TaxRateObject', id: number, name: string, code: string, amount: number, inclusive: boolean, priority: number, country?: string | null | undefined, province?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, name: string, code: string, color: string }, category: { __typename: 'TaxCategoryObject', id: number, name: string } } };

export const TaxRateRowFragmentDoc = gql`
    fragment TaxRateRow on TaxRateObject {
  id
  name
  code
  amount
  inclusive
  priority
  country
  province
  channel {
    id
    name
    code
    color
  }
  category {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const TaxRateItemFragmentDoc = gql`
    fragment TaxRateItem on TaxRateObject {
  id
  name
}
    `;
export const TaxRateRowDocument = gql`
    query TaxRateRow($id: Int!) {
  taxRate(id: $id) {
    ...TaxRateRow
  }
}
    ${TaxRateRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxRateRowGQL extends Apollo.Query<TaxRateRowQuery, TaxRateRowQueryVariables> {
    document = TaxRateRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxRateRowsDocument = gql`
    query TaxRateRows($page: PageInput, $sort: SortInput, $filter: TaxRateFilterInput) {
  items: taxRates(page: $page, filter: $filter, sort: $sort) {
    ...TaxRateRow
  }
  totalItemsCount: taxRatesCount(filter: $filter)
}
    ${TaxRateRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxRateRowsGQL extends Apollo.Query<TaxRateRowsQuery, TaxRateRowsQueryVariables> {
    document = TaxRateRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxRateSearchDocument = gql`
    query TaxRateSearch($searchText: String, $results: Int!) {
  taxRates(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    ...TaxRateItem
  }
}
    ${TaxRateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxRateSearchGQL extends Apollo.Query<TaxRateSearchQuery, TaxRateSearchQueryVariables> {
    document = TaxRateSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxRateListGqlDocument = gql`
    query TaxRateListGQL {
  taxRates {
    ...TaxRateItem
  }
}
    ${TaxRateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxRateListGqlGQL extends Apollo.Query<TaxRateListGqlQuery, TaxRateListGqlQueryVariables> {
    document = TaxRateListGqlDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxRateByIdDocument = gql`
    query TaxRateById($id: Int!) {
  taxRate(id: $id) {
    ...TaxRateRow
  }
}
    ${TaxRateRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxRateByIdGQL extends Apollo.Query<TaxRateByIdQuery, TaxRateByIdQueryVariables> {
    document = TaxRateByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTaxRateDocument = gql`
    mutation CreateTaxRate($input: CreateTaxRateInput!) {
  createTaxRate(input: $input) {
    ...TaxRateRow
  }
}
    ${TaxRateRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTaxRateGQL extends Apollo.Mutation<CreateTaxRateMutation, CreateTaxRateMutationVariables> {
    document = CreateTaxRateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaxRateDocument = gql`
    mutation UpdateTaxRate($input: UpdateTaxRateInput!) {
  updateTaxRate(input: $input) {
    ...TaxRateRow
  }
}
    ${TaxRateRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaxRateGQL extends Apollo.Mutation<UpdateTaxRateMutation, UpdateTaxRateMutationVariables> {
    document = UpdateTaxRateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }