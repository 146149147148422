import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { OrderFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { OrderTemplateService } from '../../domain-client/services/order-template.service';
import { IOrderTemplateItemObject } from '../../domain-client/services/types/order-template';

@Injectable({ providedIn: 'root' })
export class OrderTemplateItemCollectionProvider
  implements
    IDataCollectionProvider<IOrderTemplateItemObject, OrderFilterInput>,
    IDataProvider<IOrderTemplateItemObject>
{
  constructor(private orderTemplateService: OrderTemplateService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IOrderTemplateItemObject>> {
    return this.orderTemplateService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IOrderTemplateItemObject> {
    return this.orderTemplateService.fetchItem(Number(id));
  }

  toId(model: IOrderTemplateItemObject): string | number {
    return model.id;
  }

  toString(model: IOrderTemplateItemObject): string {
    return `${model.name}`;
  }
}
