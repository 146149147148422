import { InjectLogger, Logger } from '@x/common/log';
import { LocalStorage } from '@x/common/storage';
import { GridDatasourceData, GridPageModel, GridSortModel } from './grid.datasource';

export interface GridStateModel<M extends Record<string, any> = any, F = any, A = any> {
  data?: GridDatasourceData<M>;
  filter?: F;
  args?: A;
  page?: GridPageModel;
  sort?: GridSortModel;
  selections?: Array<string | number>;
}

export interface GridStateStorage<M extends Record<string, any> = any, F = any, A = any> {
  store(state: GridStateModel<M, F, A>): void;
  load(): GridStateModel<M, F, A> | undefined | null;
}

export class GridStateLocalStorage<M extends Record<string, any> = any, F = any, A = any>
  implements GridStateStorage<M, F, A>
{
  @InjectLogger()
  log: Logger;

  constructor(private readonly key: string, private readonly storage: LocalStorage) {}

  store(state: GridStateModel<M, F, A>): void {
    this.storage.setItem(this.key, state);
  }

  load(): GridStateModel<M, F, A> | null | undefined {
    return this.storage.getItem(this.key);
  }
}
