import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductVariantItemFragment = { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } };

export type OrderDetailProductVariantItemFragment = { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string, hidden: boolean }, stockItem?: { __typename: 'StockItemObject', sku: string } | null | undefined, channel?: { __typename: 'ProductVariantChannelObject', id: number, price?: number | null | undefined, available: boolean, availableRegions: Array<{ __typename: 'GeoRegionObject', id: number }> } | null | undefined };

export type ProductVariantItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductVariantItemQuery = { __typename: 'Query', productVariant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } };

export type ProductVariantItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ProductVariantFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ProductVariantItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } }> };

export type OrderDetailProductVariantItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  channelCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type OrderDetailProductVariantItemQuery = { __typename: 'Query', productVariant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string, hidden: boolean }, stockItem?: { __typename: 'StockItemObject', sku: string } | null | undefined, channel?: { __typename: 'ProductVariantChannelObject', id: number, price?: number | null | undefined, available: boolean, availableRegions: Array<{ __typename: 'GeoRegionObject', id: number }> } | null | undefined } };

export type OrderDetailProductVariantItemsQueryVariables = Types.Exact<{
  channelCode: Types.Scalars['String']['input'];
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ProductVariantFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type OrderDetailProductVariantItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string, hidden: boolean }, stockItem?: { __typename: 'StockItemObject', sku: string } | null | undefined, channel?: { __typename: 'ProductVariantChannelObject', id: number, price?: number | null | undefined, available: boolean, availableRegions: Array<{ __typename: 'GeoRegionObject', id: number }> } | null | undefined }> };

export type ProductVariantSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ProductVariantSearchQuery = { __typename: 'Query', productVariants: Array<{ __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } }> };

export type ProductVariantByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ProductVariantByIdQuery = { __typename: 'Query', productVariant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } };

export const ProductVariantItemFragmentDoc = gql`
    fragment ProductVariantItem on ProductVariantObject {
  id
  name
  code
  product {
    id
    code
    name
  }
}
    `;
export const OrderDetailProductVariantItemFragmentDoc = gql`
    fragment OrderDetailProductVariantItem on ProductVariantObject {
  id
  name
  code
  product {
    id
    code
    name
    hidden
  }
  stockItem {
    sku
  }
  channel(channelCode: $channelCode) {
    id
    price
    available
    availableRegions {
      id
    }
  }
}
    `;
export const ProductVariantItemDocument = gql`
    query ProductVariantItem($id: Int!) {
  productVariant(id: $id) {
    ...ProductVariantItem
  }
}
    ${ProductVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductVariantItemGQL extends Apollo.Query<ProductVariantItemQuery, ProductVariantItemQueryVariables> {
    document = ProductVariantItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductVariantItemsDocument = gql`
    query ProductVariantItems($page: PageInput, $filter: ProductVariantFilterInput, $sort: SortInput) {
  items: productVariants(page: $page, filter: $filter, sort: $sort) {
    ...ProductVariantItem
  }
  totalItemsCount: productVariantsCount(filter: $filter)
}
    ${ProductVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductVariantItemsGQL extends Apollo.Query<ProductVariantItemsQuery, ProductVariantItemsQueryVariables> {
    document = ProductVariantItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderDetailProductVariantItemDocument = gql`
    query OrderDetailProductVariantItem($id: Int!, $channelCode: String) {
  productVariant(id: $id) {
    ...OrderDetailProductVariantItem
  }
}
    ${OrderDetailProductVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderDetailProductVariantItemGQL extends Apollo.Query<OrderDetailProductVariantItemQuery, OrderDetailProductVariantItemQueryVariables> {
    document = OrderDetailProductVariantItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderDetailProductVariantItemsDocument = gql`
    query OrderDetailProductVariantItems($channelCode: String!, $page: PageInput, $filter: ProductVariantFilterInput, $sort: SortInput) {
  items: productVariants(page: $page, filter: $filter, sort: $sort) {
    ...OrderDetailProductVariantItem
  }
  totalItemsCount: productVariantsCount(filter: $filter)
}
    ${OrderDetailProductVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderDetailProductVariantItemsGQL extends Apollo.Query<OrderDetailProductVariantItemsQuery, OrderDetailProductVariantItemsQueryVariables> {
    document = OrderDetailProductVariantItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductVariantSearchDocument = gql`
    query ProductVariantSearch($searchText: String) {
  productVariants(filter: {search: $searchText}) {
    ...ProductVariantItem
  }
}
    ${ProductVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductVariantSearchGQL extends Apollo.Query<ProductVariantSearchQuery, ProductVariantSearchQueryVariables> {
    document = ProductVariantSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductVariantByIdDocument = gql`
    query ProductVariantById($id: Int!) {
  productVariant(id: $id) {
    ...ProductVariantItem
  }
}
    ${ProductVariantItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductVariantByIdGQL extends Apollo.Query<ProductVariantByIdQuery, ProductVariantByIdQueryVariables> {
    document = ProductVariantByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }