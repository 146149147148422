import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ProductVariantItemFragmentDoc } from './product-variant.gql.generated';
import { ChannelRowFragmentDoc } from './channel.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrderTemplateItemItemFragment = { __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } };

export type OrderTemplateItemFragment = { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> };

export type OrderTemplateItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type OrderTemplateItemQuery = { __typename: 'Query', orderTemplate: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> } };

export type OrderTemplateItemsQueryVariables = Types.Exact<{
  sort?: Types.InputMaybe<Types.SortInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.OrderTemplateFilterInput>;
}>;


export type OrderTemplateItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> }> };

export type CreateOrderTemplateMutationVariables = Types.Exact<{
  input: Types.CreateOrderTemplateInput;
}>;


export type CreateOrderTemplateMutation = { __typename: 'Mutation', createOrderTemplate: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> } };

export type SetOrderTemplateItemMutationVariables = Types.Exact<{
  input: Types.OrderTemplateItemInput;
}>;


export type SetOrderTemplateItemMutation = { __typename: 'Mutation', setOrderTemplateItem: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> } };

export const OrderTemplateItemItemFragmentDoc = gql`
    fragment OrderTemplateItemItem on OrderTemplateItemObject {
  id
  variant {
    ...ProductVariantItem
  }
  quantity
  unitPrice
  createdAt
  updatedAt
  resolvedUnitPrice
}
    ${ProductVariantItemFragmentDoc}`;
export const OrderTemplateItemFragmentDoc = gql`
    fragment OrderTemplateItem on OrderTemplateObject {
  id
  name
  locale
  createdAt
  updatedAt
  channel {
    ...ChannelRow
  }
  items {
    ...OrderTemplateItemItem
  }
}
    ${ChannelRowFragmentDoc}
${OrderTemplateItemItemFragmentDoc}`;
export const OrderTemplateItemDocument = gql`
    query OrderTemplateItem($id: Int!) {
  orderTemplate(id: $id) {
    ...OrderTemplateItem
  }
}
    ${OrderTemplateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderTemplateItemGQL extends Apollo.Query<OrderTemplateItemQuery, OrderTemplateItemQueryVariables> {
    document = OrderTemplateItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderTemplateItemsDocument = gql`
    query OrderTemplateItems($sort: SortInput, $page: PageInput, $filter: OrderTemplateFilterInput) {
  items: orderTemplates(sort: $sort, page: $page, filter: $filter) {
    ...OrderTemplateItem
  }
  totalItemsCount: orderTemplatesCount(filter: $filter)
}
    ${OrderTemplateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderTemplateItemsGQL extends Apollo.Query<OrderTemplateItemsQuery, OrderTemplateItemsQueryVariables> {
    document = OrderTemplateItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrderTemplateDocument = gql`
    mutation CreateOrderTemplate($input: CreateOrderTemplateInput!) {
  createOrderTemplate(input: $input) {
    ...OrderTemplateItem
  }
}
    ${OrderTemplateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderTemplateGQL extends Apollo.Mutation<CreateOrderTemplateMutation, CreateOrderTemplateMutationVariables> {
    document = CreateOrderTemplateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetOrderTemplateItemDocument = gql`
    mutation SetOrderTemplateItem($input: OrderTemplateItemInput!) {
  setOrderTemplateItem(input: $input) {
    ...OrderTemplateItem
  }
}
    ${OrderTemplateItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetOrderTemplateItemGQL extends Apollo.Mutation<SetOrderTemplateItemMutation, SetOrderTemplateItemMutationVariables> {
    document = SetOrderTemplateItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }