import { GridDatasource, GridDatasourceQuery } from './grid.datasource';

export class GridQueryHistoryDatasource<F extends Record<string, any> = {}> {
  get queryHistory() {
    return this._queryHistory;
  }

  protected _queryHistory: GridDatasourceQuery<F>[] = [];
  protected _queryHistoryCursor: number = 0;

  constructor(private _datasource: GridDatasource) {}

  reset() {
    this._queryHistory = [];
    this._queryHistoryCursor = 0;
  }

  canUndo() {
    return this._queryHistoryCursor > 0;
  }

  canRedo() {
    return this._queryHistoryCursor < this._queryHistory.length - 1;
  }

  undo() {
    const prev = this._queryHistory[this._queryHistoryCursor - 1];
    if (prev) {
      this._queryHistoryCursor--;
      this._datasource.setQuery(prev);
    }
  }

  redo() {
    const next = this._queryHistory[this._queryHistoryCursor + 1];
    if (next) {
      this._queryHistoryCursor++;
      this._datasource.setQuery(next);
    }
  }

  push(query: GridDatasourceQuery<F>) {
    const current = this._queryHistory[this._queryHistoryCursor];

    if (current && this._datasource.queryIsEqual(current, query)) {
      return;
    }

    if (this._queryHistoryCursor < this._queryHistory.length - 1) {
      this._queryHistory = this._queryHistory.slice(0, this._queryHistoryCursor + 1);
    }

    this._queryHistory.push(query);
    this._queryHistoryCursor = this._queryHistory.length - 1;
    if (this._queryHistory.length > 10) {
      this._queryHistory.shift();
      this._queryHistoryCursor--;
    }
  }
}
