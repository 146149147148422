import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AgreementDetailFragment = { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number }>, translations: Array<{ __typename: 'AgreementTranslationObject', id: number, name: string, label?: string | null | undefined, locale: string, termsUrl?: string | null | undefined, updatedAt: any, createdAt: any }> };

export type AgreementRowFragment = { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined };

export type UpdateAgreementMutationVariables = Types.Exact<{
  input: Types.UpdateAgreementInput;
}>;


export type UpdateAgreementMutation = { __typename: 'Mutation', updateAgreement: { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number }>, translations: Array<{ __typename: 'AgreementTranslationObject', id: number, name: string, label?: string | null | undefined, locale: string, termsUrl?: string | null | undefined, updatedAt: any, createdAt: any }> } };

export type CreateAgreementMutationVariables = Types.Exact<{
  input: Types.CreateAgreementInput;
}>;


export type CreateAgreementMutation = { __typename: 'Mutation', createAgreement: { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number }>, translations: Array<{ __typename: 'AgreementTranslationObject', id: number, name: string, label?: string | null | undefined, locale: string, termsUrl?: string | null | undefined, updatedAt: any, createdAt: any }> } };

export type ArchiveAgreementMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ArchiveAgreementMutation = { __typename: 'Mutation', archiveAgreement: { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number }>, translations: Array<{ __typename: 'AgreementTranslationObject', id: number, name: string, label?: string | null | undefined, locale: string, termsUrl?: string | null | undefined, updatedAt: any, createdAt: any }> } };

export type AgreementDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AgreementDetailQuery = { __typename: 'Query', agreement: { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number }>, translations: Array<{ __typename: 'AgreementTranslationObject', id: number, name: string, label?: string | null | undefined, locale: string, termsUrl?: string | null | undefined, updatedAt: any, createdAt: any }> } };

export type AgreementRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AgreementRowQuery = { __typename: 'Query', agreement: { __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined } };

export type AgreementRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter: Types.AgreementFilter;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type AgreementRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'AgreementObject', id: number, name: string, updatedAt: any, createdAt: any, archivedAt?: any | null | undefined }> };

export const AgreementDetailFragmentDoc = gql`
    fragment AgreementDetail on AgreementObject {
  id
  name
  updatedAt
  createdAt
  archivedAt
  channels {
    id
  }
  translations {
    id
    name
    label
    locale
    termsUrl
    updatedAt
    createdAt
  }
}
    `;
export const AgreementRowFragmentDoc = gql`
    fragment AgreementRow on AgreementObject {
  id
  name
  updatedAt
  createdAt
  archivedAt
}
    `;
export const UpdateAgreementDocument = gql`
    mutation UpdateAgreement($input: UpdateAgreementInput!) {
  updateAgreement(input: $input) {
    ...AgreementDetail
  }
}
    ${AgreementDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAgreementGQL extends Apollo.Mutation<UpdateAgreementMutation, UpdateAgreementMutationVariables> {
    document = UpdateAgreementDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAgreementDocument = gql`
    mutation CreateAgreement($input: CreateAgreementInput!) {
  createAgreement(input: $input) {
    ...AgreementDetail
  }
}
    ${AgreementDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAgreementGQL extends Apollo.Mutation<CreateAgreementMutation, CreateAgreementMutationVariables> {
    document = CreateAgreementDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArchiveAgreementDocument = gql`
    mutation ArchiveAgreement($id: Int!) {
  archiveAgreement(id: $id) {
    ...AgreementDetail
  }
}
    ${AgreementDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ArchiveAgreementGQL extends Apollo.Mutation<ArchiveAgreementMutation, ArchiveAgreementMutationVariables> {
    document = ArchiveAgreementDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AgreementDetailDocument = gql`
    query AgreementDetail($id: Int!) {
  agreement(id: $id) {
    ...AgreementDetail
  }
}
    ${AgreementDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AgreementDetailGQL extends Apollo.Query<AgreementDetailQuery, AgreementDetailQueryVariables> {
    document = AgreementDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AgreementRowDocument = gql`
    query AgreementRow($id: Int!) {
  agreement(id: $id) {
    ...AgreementRow
  }
}
    ${AgreementRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AgreementRowGQL extends Apollo.Query<AgreementRowQuery, AgreementRowQueryVariables> {
    document = AgreementRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AgreementRowsDocument = gql`
    query AgreementRows($page: PageInput, $filter: AgreementFilter!, $sort: SortInput) {
  items: agreements(page: $page, filter: $filter, sort: $sort) {
    ...AgreementRow
  }
  totalItemsCount: agreementsCount(filter: $filter)
}
    ${AgreementRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AgreementRowsGQL extends Apollo.Query<AgreementRowsQuery, AgreementRowsQueryVariables> {
    document = AgreementRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }