import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { OrderItemFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { IOrderItemRowObject, OrderItemService } from '../../domain-client';

@Injectable({ providedIn: 'root' })
export class OrderItemRowCollectionProvider
  implements IDataCollectionProvider<IOrderItemRowObject, OrderItemFilterInput, any, number>
{
  constructor(private orderService: OrderItemService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IOrderItemRowObject>> {
    return this.orderService.fetchRows({
      filter,
      page,
      sort,
    });
  }

  toId(model: IOrderItemRowObject): number {
    return model.id;
  }

  toString(model: IOrderItemRowObject): string {
    return `${model.id}`;
  }
}
