import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { GeoRegionObject, GeoRegionService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class GeoRegionIdTransformer implements ModelTransformer<GeoRegionObject, number> {
  private _cache = new Map<number, GeoRegionObject>();

  constructor(private service: GeoRegionService) {}

  modelToValue(model: GeoRegionObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<GeoRegionObject> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as GeoRegionObject);
    }

    return firstValueFrom(this.service.fetchDetail(id).pipe(tap((r) => this._cache.set(id, r))));
  }
}
