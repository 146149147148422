import { Injectable } from '@angular/core';
import { GridDatasource, GridDatasourceData, GridDatasourceQuery } from '@x/dashboard/grid';
import { ShippingMethodRowObject, ShippingMethodService } from '@x/ecommerce/domain-client';
import { ShippingMethodFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

export interface ShippingMethodQueryArgs {}

@Injectable()
export class ShippingMethodIndexDatasource extends GridDatasource<
  ShippingMethodRowObject,
  ShippingMethodFilterInput,
  ShippingMethodQueryArgs
> {
  constructor(private shippingMethodsService: ShippingMethodService) {
    super({
      defaultDisplayColumns: [
        'name',
        'provider',
        'costCalculator',
        'labelGenerator',
        'channels',
        'timestamps',
      ],
      defaultFilter: {
        search: null,
        createdAt: null,
        updatedAt: null,
        enabledChannelIds: null,
        providers: null,
      },
      defaultSort: {
        column: 'name',
        order: 'asc',
      },
      defaultArgs: {},
      pageSizeOptions: [20, 100, 1000],
    });
  }

  fetch({
    filter,
    sort,
    page,
    args,
  }: Readonly<GridDatasourceQuery<ShippingMethodFilterInput, ShippingMethodQueryArgs>>): Observable<
    GridDatasourceData<ShippingMethodRowObject>
  > {
    return this.shippingMethodsService.fetchRows({ ...args, filter, page, sort });
  }
}
