import { Injectable } from '@angular/core';
import { SubscriptionMembershipBenefitInput } from '@x/schemas/ecommerce';
import { map } from 'rxjs/operators';
import {
  SubscriptionMembershipBenefitArchiveGQL,
  SubscriptionMembershipBenefitCreateGQL,
} from './gql/subscription-membership-benefit.gql.generated';

@Injectable()
export class SubscriptionMembershipBenefitService {
  constructor(
    private readonly archiveGQL: SubscriptionMembershipBenefitArchiveGQL,
    private readonly createGQL: SubscriptionMembershipBenefitCreateGQL,
  ) {}
  archive(id: number) {
    return this.archiveGQL
      .mutate({ id })
      .pipe(map((r) => r.data?.archiveSubscriptionMembershipBenefit));
  }

  create(data: SubscriptionMembershipBenefitInput) {
    return this.createGQL
      .mutate({ data })
      .pipe(map((r) => r.data?.createSubscriptionMembershipBenefit));
  }
}
