import { Injectable } from '@angular/core';
import { StockAllocationIntervalFilterInput } from '@x/schemas/ecommerce';
import { map } from 'rxjs/operators';
import { StockAllocationIntervalGQL } from './gql/stock-allocation.gql.generated';

@Injectable()
export class StockAllocationService {
  constructor(private stockAllocationIntervalGQL: StockAllocationIntervalGQL) {}

  fetchIntervals(filter: StockAllocationIntervalFilterInput) {
    return this.stockAllocationIntervalGQL
      .fetch({ filter })
      .pipe(map((r) => r.data.stockAllocationIntervals));
  }
}
