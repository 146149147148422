import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { InjectionToken } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslatedError } from '@x/common/error';
import {
  BehaviorSubject,
  forkJoin,
  isObservable,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { GridDatasourceUnresolvedIdException } from '../exceptions/grid-datasource-unresolved-id.exception';
import { GridQueryHistoryDatasource } from './grid-query-history.datasource';
import { GridScrollStateModel, GridScrollStateStorage } from './grid-scroll-storage';
import { GridStateStorage } from './grid-state-storage';

export interface GridSortModel {
  column: string;
  order: 'asc' | 'desc';
}

export interface GridPageModel {
  index: number;
  size: number;
}

export interface GridDatasourceData<T> {
  items: T[];
  totalItemsCount: number;
}

export interface GridDatasourceQuery<F = any, A = any> {
  sort: GridSortModel;
  page: GridPageModel;
  filter: F;
  args: A;
}

export interface GridDatasourceOptions<F = any, A = any> {
  defaultFilter: F;
  defaultSort: GridSortModel;
  defaultPage?: GridPageModel;
  defaultArgs?: A;
  defaultDisplayColumns?: string[];
  pageSizeOptions?: number[];
  fetchDebounceTime?: number;
  argsControl?: AbstractControl;
  filterControl?: AbstractControl;
}

export type GridFetchFn<
  TGridModel extends Record<string, any> = any,
  TGridFilter = any,
  TGridArgs = any,
> = (
  query: Readonly<GridDatasourceQuery<TGridFilter, TGridArgs>>,
) => Observable<GridDatasourceData<TGridModel>> | GridDatasourceData<TGridModel>;

export class MutationSuccessResult<T> {
  constructor(public id: string | number, public result: T) {}
}

export class MutationErrorResult {
  constructor(public id: string | number, public error: any) {}
}

const DEFAULT_PAGE_SIZE_OPTIONS = [20, 50, 100];

export const GRID_DATASOURCE = new InjectionToken<GridDatasource<any, any>>('GridDatasource');

export type SelectionState = 'all' | 'none' | 'some';

export class GridDatasource<
  TGridModel extends Record<string, any> = any,
  TGridFilter extends Record<string, any> = any,
  TGridArgs = any,
> extends DataSource<TGridModel> {
  fetch(
    query: Readonly<GridDatasourceQuery<TGridFilter, TGridArgs>>,
  ): Observable<GridDatasourceData<TGridModel>> | GridDatasourceData<TGridModel> {
    if (this._fetchFn) {
      return this._fetchFn(query);
    }

    return {
      items: [],
      totalItemsCount: 0,
    };
  }

  get filter(): TGridFilter {
    return this._filter;
  }
  set filter(filter: TGridFilter) {
    this.setFilter(filter);
  }

  get args(): TGridArgs {
    return this._args;
  }
  set args(args: TGridArgs) {
    this.setArgs(args);
  }

  get pageIndex(): number {
    return this._page.index;
  }
  set pageIndex(index: number) {
    this.setPage(index);
  }

  get pageSize(): number {
    return this._page.size;
  }
  set pageSize(size: number) {
    this.setPage(this.pageIndex, size);
  }

  get sortColumn(): string {
    return this._sort.column;
  }
  set sortColumn(column: string) {
    this.setSort(column);
  }

  get sortOrder(): 'asc' | 'desc' {
    return this._sort.order;
  }
  set sortOrder(order: 'asc' | 'desc') {
    this.setSort(this._sort.column, order);
  }

  get displayColumns() {
    return this._displayColumns;
  }
  set displayColumns(columns: string[]) {
    this._displayColumns = columns;
    this._displayChange$.next();
  }

  get pageSizeOptions() {
    return this._pageSizeOptions;
  }
  set pageSizeOptions(options: number[]) {
    this._pageSizeOptions = options;
    this._displayChange$.next();
  }

  get loading() {
    return this._loading;
  }

  get items(): TGridModel[] {
    return this._data$.getValue().items;
  }

  get error(): TranslatedError | undefined | null {
    return this._fetchError;
  }

  get totalItemsCount(): number {
    return this._data$.getValue().totalItemsCount;
  }

  get selectionCount() {
    return this._selectionModel.selected.length ?? 0;
  }

  get itemLoadingCount() {
    return this._loadingModel.selected.length ?? 0;
  }

  get selected() {
    return this._selectionModel.selected;
  }

  selectionState: SelectionState = 'none';
  itemLoadingState: SelectionState = 'none';
  queryHistory: GridQueryHistoryDatasource<TGridFilter> =
    new GridQueryHistoryDatasource<TGridFilter>(this);

  protected _filter: TGridFilter;
  protected _args: TGridArgs;
  protected _sort: GridSortModel = { column: '_', order: 'asc' };
  protected _page: GridPageModel = { size: DEFAULT_PAGE_SIZE_OPTIONS[0], index: 0 };

  protected _displayColumns: string[] = [];

  protected _loading: boolean = false;

  protected _pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;

  protected _defaultPage: GridPageModel;
  protected _defaultSort: GridSortModel;
  protected _defaultFilter: TGridFilter;
  protected _defaultArgs: TGridArgs;
  protected _defaultDisplayColumns: string[] = [];

  protected _data$ = new BehaviorSubject<GridDatasourceData<TGridModel>>({
    items: [],
    totalItemsCount: 0,
  });

  protected _queryChange$ = new Subject<boolean>();
  protected _displayChange$ = new Subject<void>();

  protected _subscriptions: Subscription[] = [];
  protected _observables: Observable<any>[] = [];

  protected _debounceTime: number;

  protected _selectionModel = new SelectionModel<string | number>(true);
  protected _loadingModel = new SelectionModel<string | number>(true);
  protected _filterControl: AbstractControl;
  protected _argsControl: AbstractControl;

  protected _fetchError?: TranslatedError | null;

  protected _connected = false;

  protected _fetchFn?: GridFetchFn<TGridModel, TGridFilter, TGridArgs>;
  protected _storage?: GridStateStorage<TGridModel, TGridFilter, TGridArgs>;
  protected _isStateRestored = false;
  protected _scrollState: GridScrollStateModel;

  constructor(
    {
      defaultPage,
      defaultSort,
      defaultFilter,
      defaultArgs,
      defaultDisplayColumns,
      pageSizeOptions,
      fetchDebounceTime,
      filterControl,
      argsControl,
    }: GridDatasourceOptions<TGridFilter, TGridArgs>,
    fetchFn?: GridFetchFn<TGridModel, TGridFilter, TGridArgs>,
    storage?: GridStateStorage<TGridModel, TGridFilter, TGridArgs>,
    private scrollStorage?: GridScrollStateStorage,
  ) {
    super();

    this._page = this._defaultPage = defaultPage ?? {
      index: 0,
      size: DEFAULT_PAGE_SIZE_OPTIONS[0],
    };
    this._sort = this._defaultSort = defaultSort ?? { column: null, order: null };
    this._filter = this._defaultFilter = defaultFilter;
    this._args = this._defaultArgs = <any>defaultArgs;
    this._displayColumns = this._defaultDisplayColumns = defaultDisplayColumns ?? [];
    this._pageSizeOptions = pageSizeOptions ?? DEFAULT_PAGE_SIZE_OPTIONS;
    this._debounceTime = fetchDebounceTime ?? 300;
    this._storage = storage;

    this.restoreState();
    this._scrollState = scrollStorage?.load() ?? { top: 0, left: 0 };

    if (fetchFn) {
      this._fetchFn = fetchFn;
    }

    this.bindObservable(this.observeFetch());
    this.bindObservable(this.observeSelectionChanges());

    if (filterControl) this.bindFilterControl(filterControl);
    if (argsControl) this.bindArgsControl(argsControl);
  }

  /**
   * @inheritdoc
   */
  connect(collectionViewer: CollectionViewer): Observable<TGridModel[]> {
    if (!this._connected) {
      this.subscribeAll();
    }

    this._connected = true;

    return this._data$.pipe(map((r) => r.items));
  }

  /**
   * @inheritdoc
   */
  disconnect(collectionViewer: CollectionViewer): void {
    if (this._connected) {
      this.unsubscribeAll();
    }
    this._connected = false;
  }

  getId(obj: TGridModel): string | number {
    if ('id' in obj && (typeof obj.id === 'number' || typeof obj.id === 'string')) {
      return obj.id;
    }
    throw new GridDatasourceUnresolvedIdException(
      'GridDatasource: Could not resolve ID of object. ' +
        "Objects should have a unique string|number property 'id', " +
        'or override the getId method in the GridDatasource to use a different property.',
      obj,
    );
  }

  setFilter(filter: TGridFilter) {
    this._filter = filter;
    this._page.index = 0;
    if (this._filterControl && this._filterControl.value !== filter) {
      this._filterControl.patchValue(filter, { emitEvent: false });
    }
    this._queryChange$.next(true);
  }

  patchFilter(filter: Partial<TGridFilter>) {
    this.setFilter({
      ...this._filter,
      ...filter,
    });
  }

  setArgs(args: TGridArgs) {
    this._args = args;
    this._queryChange$.next(true);
  }

  setPage(page: GridPageModel): void;
  setPage(index: number, size?: number): void;
  setPage(index: number | GridPageModel, size?: number): void {
    if (typeof index === 'number') {
      this._page = {
        index,
        size: size ?? this._page.size,
      };
    } else {
      this._page = { ...index };
    }
    this._queryChange$.next(false);
  }

  setSort(sort: GridSortModel): void;
  setSort(column: string | null, order?: 'asc' | 'desc' | null): void;
  setSort(column: string | null | GridSortModel, order?: 'asc' | 'desc' | null): void {
    if (typeof column === 'string' || column === null) {
      this._sort = {
        column: column ?? this._defaultSort.column,
        order: order ?? this._defaultSort.order,
      };
    } else {
      this._sort = { ...column };
    }
    this._page.index = 0;
    this._queryChange$.next(false);
  }

  toggleSortOrder() {
    this.setSort(this.sortColumn, this.sortOrder === 'asc' ? 'desc' : 'asc');
  }

  setLoading(isLoading = true) {
    this._loading = isLoading;
    this._displayChange$.next();
  }

  startLoading() {
    this.setLoading(true);
  }

  stopLoading() {
    this.setLoading(false);
  }

  isAnyItemLoading() {
    return this._loadingModel.selected.length > 0;
  }

  isItemLoading(itemOrId: string | number | TGridModel) {
    return this._loadingModel.isSelected(this.coerceItemId(itemOrId));
  }

  setItemLoading(
    itemOrId: string | number | TGridModel | Array<string | number | TGridModel>,
    loading = true,
  ) {
    const items = (Array.isArray(itemOrId) ? itemOrId : [itemOrId]).map((itemOrId) =>
      this.coerceItemId(itemOrId),
    );

    loading ? this._loadingModel.select(...items) : this._loadingModel.deselect(...items);
  }

  startItemLoading(itemOrId: string | number | TGridModel | Array<string | number | TGridModel>) {
    this.setItemLoading(itemOrId);
  }

  stopItemLoading(itemOrId: string | number | TGridModel | Array<string | number | TGridModel>) {
    this.setItemLoading(itemOrId, false);
  }

  reset() {
    this._page = { ...this._defaultPage };
    this._sort = { ...this._defaultSort };
    this._filter = { ...this._defaultFilter };
    this._args = { ...this._defaultArgs };
    this._displayColumns = [...this._defaultDisplayColumns];
    this.queryHistory.reset();
    this._displayChange$.next();
    this._queryChange$.next(true);
  }

  refresh() {
    this._queryChange$.next(true);
  }

  getQuery(): GridDatasourceQuery<TGridFilter, TGridArgs> {
    return {
      page: this._page,
      sort: this._sort,
      filter: this._filter,
      args: this._args,
    };
  }

  setQuery(query: GridDatasourceQuery<TGridFilter, TGridArgs>) {
    this._page = query.page;
    this._filter = query.filter;
    this._sort = query.sort;
    this._args = query.args;
    this._queryChange$.next(true);
  }

  patchQuery(query: Partial<GridDatasourceQuery<TGridFilter, TGridArgs>>) {
    if (query.page) this._page = query.page;
    if (query.filter) this._filter = query.filter;
    if (query.sort) this._sort = query.sort;
    if (query.args) this._args = query.args;
    this._queryChange$.next(true);
  }

  stateChanges(): Observable<void> {
    return merge(
      this._displayChange$,
      this._queryChange$,
      this._data$.pipe(skip(1)),
      this._selectionModel.changed,
      this._loadingModel.changed,
    ).pipe(
      map((a) => {
        return;
      }),
    );
  }

  data(): Observable<GridDatasourceData<TGridModel>> {
    return this._data$.asObservable();
  }

  isSelected(item: TGridModel): boolean;
  isSelected(id: string | number): boolean;
  isSelected(itemOrId: TGridModel | string | number): boolean;
  isSelected(itemOrId: TGridModel | string | number): boolean {
    return this._selectionModel.isSelected(this.coerceItemId(itemOrId));
  }

  isSelectionEmpty() {
    return this._selectionModel.isEmpty();
  }

  select(item: TGridModel): void;
  select(id: number | string): void;
  select(ids: Array<number | string>): void;
  select(itemOrId: TGridModel | number | string): void;
  select(itemOrId: TGridModel | number | string | Array<number | string>): void {
    Array.isArray(itemOrId)
      ? itemOrId.forEach((id) => this._selectionModel.select(this.coerceItemId(id)))
      : this._selectionModel.select(this.coerceItemId(itemOrId));
  }

  deselect(item: TGridModel): void;
  deselect(id: number | string): void;
  deselect(ids: Array<number | string>): void;
  deselect(itemOrId: TGridModel | number | string): void;
  deselect(itemOrId: TGridModel | number | string | Array<number | string>): void {
    Array.isArray(itemOrId)
      ? itemOrId.forEach((id) => this._selectionModel.deselect(this.coerceItemId(id)))
      : this._selectionModel.deselect(this.coerceItemId(itemOrId));
  }

  toggleSelect(item: TGridModel): void;
  toggleSelect(id: number | string): void;
  toggleSelect(itemOrId: TGridModel | number | string): void {
    if (this.isSelected(itemOrId)) {
      this.deselect(itemOrId);
    } else {
      this.select(itemOrId);
    }
  }

  selectAll() {
    let ids: Array<number | string> = this.items.map((r) => this.getId(r));
    this._selectionModel.select(...ids);
  }

  clearSelection() {
    this._selectionModel.clear();
  }

  queryIsEqual(a: GridDatasourceQuery, b: GridDatasourceQuery): boolean {
    if (
      a.page.index != b.page.index ||
      a.page.size !== b.page.size ||
      a.sort.column !== b.sort.column ||
      a.sort.order !== b.sort.order
    ) {
      return false;
    }

    if (JSON.stringify(a.filter) !== JSON.stringify(b.filter)) {
      return false;
    }

    if (JSON.stringify(a.args) !== JSON.stringify(b.args)) {
      return false;
    }

    return true;
  }

  bindArgsControl(control: AbstractControl) {
    if (this._argsControl) {
      throw new Error('GridDatasource: Args Control is already bound.');
    }

    this._argsControl = control;
    control.setValue(this._args, { emitEvent: false });

    const changes$ = control.valueChanges.pipe(
      tap((value) => {
        this._args = value;
        this._queryChange$.next(true);
      }),
    );
    this.bindObservable(changes$);
  }

  bindFilterControl(control: AbstractControl) {
    if (this._filterControl) {
      throw new Error('GridDatasource: Filter Control is already bound.');
    }

    this._filterControl = control;

    control.patchValue(this._filter, { emitEvent: false });

    const changes$ = control.valueChanges.pipe(
      tap((value) => {
        this._filter = value;
        this._page.index = 0;
        this._queryChange$.next(true);
      }),
    );
    this.bindObservable(changes$);
  }

  observeMutation<T = any>(
    id: number | string,
    mutation: (id: string | number) => Observable<T>,
    { refresh, deselect }: { refresh?: boolean; deselect?: boolean } = {
      refresh: true,
      deselect: true,
    },
  ): Observable<MutationSuccessResult<T> | MutationErrorResult> {
    return of(id).pipe(
      tap((id) => this.startItemLoading(id)),
      switchMap((id) => {
        return mutation(id).pipe(
          tap(
            () => {
              if (deselect) {
                this.deselect(id);
              }
              this.stopItemLoading(id);
            },
            () => {
              this.stopItemLoading(id);
            },
            () => {
              if (refresh) {
                this.refresh();
              }
            },
          ),
          map((r) => new MutationSuccessResult(id, r)),
          catchError((e) => {
            // TODO: report error
            console.warn('GridDatasource: caught error', id);
            return of(new MutationErrorResult(id, e));
          }),
        );
      }),
    );
  }

  observeBulkMutation<T = any>(
    ids: Array<number | string>,
    mutation: (id: string | number) => Observable<T>,
    { refresh, deselect }: { refresh?: boolean; deselect?: boolean } = {
      refresh: true,
      deselect: true,
    },
  ): Observable<Array<MutationErrorResult | MutationSuccessResult<T>>> {
    const mutations$ = forkJoin(
      ids.map((id) => {
        return mutation(id).pipe(
          tap(
            (r) => {
              if (deselect) {
                this.deselect(id);
              }
              this.stopItemLoading(id);
            },
            (e) => {
              this.stopItemLoading(id);
            },
            () => {},
          ),
          map((r) => new MutationSuccessResult(id, r)),
          catchError((e) => {
            // TODO: report error
            console.warn('GridDatasource: caught error', id);
            return of(new MutationErrorResult(id, e));
          }),
        );
      }),
    );

    return of(ids).pipe(
      tap((ids) => this.startItemLoading(ids)),
      switchMap((ids) => mutations$),
      tap((r) => {
        if (refresh ?? true) {
          this.refresh();
        }
      }),
    );
  }

  setScrollState(state: GridScrollStateModel) {
    this._scrollState = state;
    if (this.scrollStorage) {
      this.scrollStorage.store(state);
    }
  }

  getScrollState(): GridScrollStateModel {
    return this._scrollState;
  }

  protected bindObservable(observable: Observable<any>) {
    this._observables.push(observable);
    if (this._connected) {
      this._subscriptions.push(observable.subscribe());
    }
  }

  private observeSelectionChanges() {
    return merge(this._data$, this._selectionModel.changed).pipe(
      tap(() => {
        this.updateSelectionState();
      }),
    );
  }

  private observeFetch() {
    // required to not debounce the first emit
    const initialQuery$ = this._queryChange$.pipe(first(), startWith(true));
    const debouncedQuery$ = this._queryChange$.pipe(skip(1), debounceTime(this._debounceTime));

    const result$ = merge(initialQuery$, debouncedQuery$).pipe(
      map(
        (refresh) =>
          [this.getQuery(), refresh] as [GridDatasourceQuery<TGridFilter, TGridArgs>, boolean],
      ),
      distinctUntilChanged(([a, prevRefresh], [b, refresh]) => {
        if (refresh) {
          return false;
        }

        const isEqual = this.queryIsEqual(a, b);
        console.log('queryIsEqual', isEqual);
        return isEqual;
      }),
      // skip(this._isStateRestored ? 1 : 0),
      switchMap(
        ([query, refresh]): Observable<
          [GridDatasourceData<TGridModel> | null, GridDatasourceQuery<TGridFilter, TGridArgs>]
        > => {
          let fetch = this.fetch(query);

          if (isObservable(fetch)) {
            this._fetchError = null;
            this.startLoading();

            return fetch.pipe(
              catchError((error) => {
                this._fetchError = error;
                return of(null);
              }),
              map((result) => {
                return [result, query];
              }),
            );
          } else {
            return of([fetch, query]);
          }
        },
      ),
      tap(
        ([data, query]) => {
          if (data) {
            this.queryHistory.push(query);
            this._data$.next(data);
            this.storeState();
          } else {
            this._data$.next({ totalItemsCount: 0, items: [] });
          }

          this.stopLoading();
        },
        (e) => {
          this._fetchError = e;
          this.stopLoading();
        },
      ),
    );

    return result$;
  }

  private updateSelectionState() {
    if (this._selectionModel.selected.length === 0) {
      this.selectionState = 'none';
      return;
    }

    for (const item of this.items) {
      if (!this.isSelected(item)) {
        this.selectionState = 'some';
        return;
      }
    }
    this.selectionState = 'all';
  }

  private coerceItemId(itemOrId: TGridModel | string | number): string | number {
    if (typeof itemOrId === 'string' || typeof itemOrId === 'number') {
      return itemOrId;
    } else {
      return this.getId(itemOrId);
    }
  }

  private subscribeAll() {
    this._subscriptions = this._observables.map((o) => o.subscribe());
  }

  private unsubscribeAll() {
    this._subscriptions.forEach((s) => (s.closed ? s.unsubscribe() : null));
    this._subscriptions = [];
  }

  private storeState() {
    if (this._storage) {
      this._storage.store({
        data: this._data$.getValue(),
        args: this._args,
        filter: this._filter,
        page: this._page,
        selections: this._selectionModel.selected,
        sort: this._sort,
      });
    }
  }

  private restoreState() {
    if (this._storage) {
      const state = this._storage.load();
      if (!state) return;
      if (state.data) {
        this._data$.next(state.data);
        this._isStateRestored = true;
      }
      if (state.args) this._args = state.args;
      if (state.filter) this._filter = state.filter;
      if (state.page) this._page = state.page;
      if (state.selections) this._selectionModel.select(...state.selections);
      if (state.sort) this._sort = state.sort;
    }
  }
}
