import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { ITaxRateRowObject, TaxRateService } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaxRateRowCollectionProvider implements IDataCollectionProvider<ITaxRateRowObject> {
  constructor(private taxRateService: TaxRateService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ITaxRateRowObject>> {
    return this.taxRateService.fetchRows({
      page,
      sort,
      filter: { search: searchText, ...filter },
    });
  }

  fetchSingle(id: string | number): ITaxRateRowObject | Observable<ITaxRateRowObject> {
    return this.taxRateService.fetchRow(Number(id));
  }

  toId(model: ITaxRateRowObject): string | number {
    return model.id;
  }

  toString(model: ITaxRateRowObject): string {
    return model.name;
  }
}
