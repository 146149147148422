import { Injectable } from '@angular/core';
import { CreateCollectionPointInput, UpdateCollectionPointInput } from '@x/schemas/ecommerce';
import { map } from 'rxjs/operators';
import {
  CollectionPointCreateGQL,
  CollectionPointItemGQL,
  CollectionPointItemsGQL,
  CollectionPointItemsQueryVariables,
  CollectionPointRowGQL,
  CollectionPointRowsGQL,
  CollectionPointUpdateGQL,
} from './gql/collection-point.gql.generated';

@Injectable()
export class CollectionPointService {
  constructor(
    private readonly collectionPointItemGql: CollectionPointItemGQL,
    private readonly collectionPointItemsGql: CollectionPointItemsGQL,
    private readonly updateGQL: CollectionPointUpdateGQL,
    private readonly createGQL: CollectionPointCreateGQL,
    private readonly collectionPointRowGql: CollectionPointRowGQL,
    private readonly collectionPointRowsGql: CollectionPointRowsGQL,
  ) {}

  fetchItem(id: number) {
    return this.collectionPointItemGql.fetch({ id }).pipe(map((r) => r.data.collectionPoint));
  }

  fetchItems(query: CollectionPointItemsQueryVariables) {
    return this.collectionPointItemsGql.fetch(query).pipe(map((r) => r.data));
  }

  fetchRow(id: number) {
    return this.collectionPointRowGql.fetch({ id }).pipe(map((r) => r.data.collectionPoint));
  }

  fetchRows(query: CollectionPointItemsQueryVariables) {
    return this.collectionPointRowsGql.fetch(query).pipe(map((r) => r.data));
  }

  update(data: UpdateCollectionPointInput) {
    return this.updateGQL.mutate({ data }).pipe(map((r) => r.data?.updateCollectionPoint));
  }

  create(data: CreateCollectionPointInput) {
    return this.createGQL.mutate({ data }).pipe(map((r) => r.data?.createCollectionPoint));
  }
}
