import { Injectable } from '@angular/core';
import { ModelTransformer } from '@x/dashboard/form';
import { IStockCategoryObject, StockCategoryService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class StockCategoryIdTransformer implements ModelTransformer<IStockCategoryObject, number> {
  private _cache = new Map<number, IStockCategoryObject>();

  constructor(private service: StockCategoryService) {}

  modelToValue(model: IStockCategoryObject): number {
    return model.id;
  }

  valueToModel(id: number): Promise<IStockCategoryObject> {
    if (this._cache.has(id)) {
      return Promise.resolve(this._cache.get(id) as IStockCategoryObject);
    }

    return firstValueFrom(this.service.fetchById(id).pipe(tap((r) => this._cache.set(id, r))));
  }
}
