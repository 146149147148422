import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { ReportProductInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { ReportProductsGQL } from './gql/report.gql.generated';
import { IReportObject } from './types/report';

@Injectable()
export class ReportService {
  constructor(private reportProductsGql: ReportProductsGQL) {}

  reportProducts(input: ReportProductInput): Observable<IReportObject> {
    return this.reportProductsGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.reportProducts));
  }
}
