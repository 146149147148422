import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ChannelItemFragmentDoc, ChannelDetailFragmentDoc } from './channel.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type WalletTransactionFragment = { __typename: 'WalletTransactionObject', id: number, amount: number, description?: string | null | undefined, balance: number, state: Types.WalletTransactionState, createdAt: any, updatedAt: any, finalizedAt?: any | null | undefined, order?: { __typename: 'OrderObject', id: number, number?: string | null | undefined, state: Types.OrderState, currency: string, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } } | null | undefined, wallet: { __typename: 'WalletObject', id: number, currency: string } };

export type WalletTransactionQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type WalletTransactionQuery = { __typename: 'Query', walletTransaction: { __typename: 'WalletTransactionObject', id: number, amount: number, description?: string | null | undefined, balance: number, state: Types.WalletTransactionState, createdAt: any, updatedAt: any, finalizedAt?: any | null | undefined, order?: { __typename: 'OrderObject', id: number, number?: string | null | undefined, state: Types.OrderState, currency: string, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } } | null | undefined, wallet: { __typename: 'WalletObject', id: number, currency: string } } };

export type WalletTransactionsQueryVariables = Types.Exact<{
  sort?: Types.InputMaybe<Types.SortInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  filter: Types.WalletTransactionFilterInput;
}>;


export type WalletTransactionsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'WalletTransactionObject', id: number, amount: number, description?: string | null | undefined, balance: number, state: Types.WalletTransactionState, createdAt: any, updatedAt: any, finalizedAt?: any | null | undefined, order?: { __typename: 'OrderObject', id: number, number?: string | null | undefined, state: Types.OrderState, currency: string, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } } | null | undefined, wallet: { __typename: 'WalletObject', id: number, currency: string } }> };

export const WalletTransactionFragmentDoc = gql`
    fragment walletTransaction on WalletTransactionObject {
  id
  amount
  description
  balance
  state
  createdAt
  updatedAt
  finalizedAt
  order {
    id
    number
    state
    currency
    channel {
      ...ChannelItem
    }
  }
  wallet {
    id
    currency
  }
}
    ${ChannelItemFragmentDoc}`;
export const WalletTransactionDocument = gql`
    query WalletTransaction($id: Int!) {
  walletTransaction(id: $id) {
    ...walletTransaction
  }
}
    ${WalletTransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WalletTransactionGQL extends Apollo.Query<WalletTransactionQuery, WalletTransactionQueryVariables> {
    document = WalletTransactionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WalletTransactionsDocument = gql`
    query WalletTransactions($sort: SortInput, $page: PageInput, $filter: WalletTransactionFilterInput!) {
  items: walletTransactions(sort: $sort, page: $page, filter: $filter) {
    ...walletTransaction
  }
  totalItemsCount: walletTransactionsCount(filter: $filter)
}
    ${WalletTransactionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WalletTransactionsGQL extends Apollo.Query<WalletTransactionsQuery, WalletTransactionsQueryVariables> {
    document = WalletTransactionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }