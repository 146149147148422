import { Injectable } from '@angular/core';
import {
  SubscriptionEntryNotificationType,
  SubscriptionVariantSmsType,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SendEntryNotificationGQL,
  SubscriptionEntryDetailGQL,
  SubscriptionEntryItemGQL,
  SubscriptionEntryRowsGQL,
  SubscriptionEntryRowsQueryVariables,
  SubscriptionEntrySearchGQL,
} from './gql/subscription-entry.gql.generated';
import { SendSubscriptionSmsGQL } from './gql/subscription-run.gql.generated';
import {
  ISubscriptionEntryDetailObject,
  ISubscriptionEntryItemObject,
  ISubscriptionEntryRowObject,
} from './types/subscription-entry';

@Injectable()
export class SubscriptionEntryService {
  constructor(
    private readonly rowsGQL: SubscriptionEntryRowsGQL,
    private readonly itemGQL: SubscriptionEntryItemGQL,
    private readonly detailGQL: SubscriptionEntryDetailGQL,
    private readonly searchGQL: SubscriptionEntrySearchGQL,
    private readonly subscriptionEntrySmsesGQL: SendSubscriptionSmsGQL,
    private readonly SendEntryNotificationGQL: SendEntryNotificationGQL,
  ) {}

  fetchRows(
    query: SubscriptionEntryRowsQueryVariables,
  ): Observable<{ items: ISubscriptionEntryRowObject[]; totalItemsCount: number }> {
    return this.rowsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchItem(id: number): Observable<ISubscriptionEntryItemObject> {
    return this.itemGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionEntry));
  }

  fetchDetail(id: number): Observable<ISubscriptionEntryDetailObject> {
    return this.detailGQL.fetch({ id }).pipe(map((r) => r.data.subscriptionEntry));
  }

  sendNotification(entryId: number, type: SubscriptionEntryNotificationType) {
    return this.SendEntryNotificationGQL.mutate({ entryId, type }).pipe(
      map((r) => r.data?.sendEntryNotification),
    );
  }

  sendSmses(entryIds: number[], smsType: SubscriptionVariantSmsType) {
    return this.subscriptionEntrySmsesGQL
      .mutate({ entryIds, smsType })
      .pipe(map((r) => r.data?.sendSubscriptionEntrySms));
  }
}
