import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { SubscriberTagInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  AddSubscriberTagsGQL,
  RemoveSubscriberTagsGQL,
  SubscriberTagItemGQL,
  SubscriberTagItemsGQL,
  SubscriberTagItemsQueryVariables,
} from './gql/subscriber-tag.gql.generated';
import { DomainDataCollection } from './types/collection';
import { ISubscriberTagItem } from './types/subscriber-tag';

@Injectable()
export class SubscriberTagService {
  constructor(
    private addSubscriberTagsGql: AddSubscriberTagsGQL,
    private removeSubscriberTagsGql: RemoveSubscriberTagsGQL,
    private subscriberTagItemsGql: SubscriberTagItemsGQL,
    private subscriberTagItemGql: SubscriberTagItemGQL,
  ) {}

  fetchItem(id: number): Observable<ISubscriberTagItem> {
    return this.subscriberTagItemGql.fetch({ id }).pipe(mapFetchResultData((r) => r.subscriberTag));
  }

  fetchItems(
    query: SubscriberTagItemsQueryVariables,
  ): Observable<DomainDataCollection<ISubscriberTagItem>> {
    return this.subscriberTagItemsGql.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  addSubscriberTags(input: SubscriberTagInput) {
    return this.addSubscriberTagsGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.addSubscriberTags));
  }
  removeSubscriberTags(input: SubscriberTagInput) {
    return this.removeSubscriberTagsGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.removeSubscriberTags));
  }
}
