import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { SubscriptionVariantItemFragmentDoc } from './subscription-variant.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubscriptionRunDetailFragment = { __typename: 'SubscriptionRunObject', id: number, name: string, runStart: any, runEnd: any, jobId?: string | null | undefined, jobAction?: string | null | undefined, jobStartAt?: any | null | undefined, entryCount: number, orderCount: number, paidOrderCount: number, pendingOrderCount: number, cancelledOrderCount: number, completedOrderCount: number, subscriberCount: number, createdAt: any, updatedAt: any, variant: { __typename: 'SubscriptionVariantObject', id: number, name: string }, salesTotals: Array<{ __typename: 'SubscriptionRunMoneyObject', currency?: string | null | undefined, total: number }> };

export type SubscriptionRunRowFragment = { __typename: 'SubscriptionRunObject', id: number, name: string, jobId?: string | null | undefined, jobAction?: string | null | undefined, jobStartAt?: any | null | undefined, runStart: any, runEnd: any, entryCount: number, orderCount: number, paidOrderCount: number, pendingOrderCount: number, cancelledOrderCount: number, createdAt: any, updatedAt: any, variant: { __typename: 'SubscriptionVariantObject', id: number, name: string }, salesTotals: Array<{ __typename: 'SubscriptionRunMoneyObject', currency?: string | null | undefined, total: number }> };

export type SubscriptionRunItemFragment = { __typename: 'SubscriptionRunObject', id: number, name: string };

export type SubscriptionRunItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionRunItemQuery = { __typename: 'Query', subscriptionRun: { __typename: 'SubscriptionRunObject', id: number, name: string } };

export type SubscriptionRunRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionRunRowQuery = { __typename: 'Query', subscriptionRun: { __typename: 'SubscriptionRunObject', id: number, name: string, jobId?: string | null | undefined, jobAction?: string | null | undefined, jobStartAt?: any | null | undefined, runStart: any, runEnd: any, entryCount: number, orderCount: number, paidOrderCount: number, pendingOrderCount: number, cancelledOrderCount: number, createdAt: any, updatedAt: any, variant: { __typename: 'SubscriptionVariantObject', id: number, name: string }, salesTotals: Array<{ __typename: 'SubscriptionRunMoneyObject', currency?: string | null | undefined, total: number }> } };

export type SubscriptionRunRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
  filter?: Types.InputMaybe<Types.SubscriptionRunFilterInput>;
}>;


export type SubscriptionRunRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'SubscriptionRunObject', id: number, name: string, jobId?: string | null | undefined, jobAction?: string | null | undefined, jobStartAt?: any | null | undefined, runStart: any, runEnd: any, entryCount: number, orderCount: number, paidOrderCount: number, pendingOrderCount: number, cancelledOrderCount: number, createdAt: any, updatedAt: any, variant: { __typename: 'SubscriptionVariantObject', id: number, name: string }, salesTotals: Array<{ __typename: 'SubscriptionRunMoneyObject', currency?: string | null | undefined, total: number }> }> };

export type SubscriptionRunDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type SubscriptionRunDetailQuery = { __typename: 'Query', subscriptionRun: { __typename: 'SubscriptionRunObject', id: number, name: string, runStart: any, runEnd: any, jobId?: string | null | undefined, jobAction?: string | null | undefined, jobStartAt?: any | null | undefined, entryCount: number, orderCount: number, paidOrderCount: number, pendingOrderCount: number, cancelledOrderCount: number, completedOrderCount: number, subscriberCount: number, createdAt: any, updatedAt: any, variant: { __typename: 'SubscriptionVariantObject', id: number, name: string }, salesTotals: Array<{ __typename: 'SubscriptionRunMoneyObject', currency?: string | null | undefined, total: number }> } };

export type CreateSubscriptionRunMutationVariables = Types.Exact<{
  variantId: Types.Scalars['Int']['input'];
  input: Types.SubscriptionRunInput;
}>;


export type CreateSubscriptionRunMutation = { __typename: 'Mutation', createSubscriptionRun: { __typename: 'SubscriptionRunObject', id: number, name: string } };

export type UpdateSubscriptionRunMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.SubscriptionRunInput;
}>;


export type UpdateSubscriptionRunMutation = { __typename: 'Mutation', updateSubscriptionRun: { __typename: 'SubscriptionRunObject', id: number, name: string } };

export type QueueSubscriptionRunProcessEntriesMutationVariables = Types.Exact<{
  runId: Types.Scalars['Int']['input'];
}>;


export type QueueSubscriptionRunProcessEntriesMutation = { __typename: 'Mutation', queueSubscriptionRunProcessEntries: { __typename: 'SubscriptionRunObject', id: number, name: string } };

export type QueueSubscriptionRunProcessPaymentsMutationVariables = Types.Exact<{
  runId: Types.Scalars['Int']['input'];
}>;


export type QueueSubscriptionRunProcessPaymentsMutation = { __typename: 'Mutation', queueSubscriptionRunProcessPayments: { __typename: 'SubscriptionRunObject', id: number, name: string } };

export type UnlockSubscriptionRunProcessingMutationVariables = Types.Exact<{
  runId: Types.Scalars['Int']['input'];
}>;


export type UnlockSubscriptionRunProcessingMutation = { __typename: 'Mutation', unlockSubscriptionRunProcessing: { __typename: 'SubscriptionRunObject', id: number, name: string } };

export type GenerateSubscriptionEntriesMutationVariables = Types.Exact<{
  runId: Types.Scalars['Int']['input'];
}>;


export type GenerateSubscriptionEntriesMutation = { __typename: 'Mutation', generateSubscriptionEntries: { __typename: 'SubscriptionRunGenerateEntriesResult', subscriberCount: number, entriesCreated: number } };

export type ProcessSubscriptionEntriesMutationVariables = Types.Exact<{
  entryIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type ProcessSubscriptionEntriesMutation = { __typename: 'Mutation', processSubscriptionEntries: { __typename: 'SubscriptionRunProcessEntriesResult', ordersCreated: number, entryCount: number } };

export type ProcessSubscriptionEntriesPaymentMutationVariables = Types.Exact<{
  entryIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type ProcessSubscriptionEntriesPaymentMutation = { __typename: 'Mutation', processSubscriptionEntriesPayment: { __typename: 'SubscriptionRunProcessPaymentsResult', paymentsCompleted: number, paymentsFailed: number, entriesProcessed: number, entryCount: number } };

export type SendSubscriptionSmsMutationVariables = Types.Exact<{
  entryIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  smsType: Types.SubscriptionVariantSmsType;
}>;


export type SendSubscriptionSmsMutation = { __typename: 'Mutation', sendSubscriptionEntrySms: Array<{ __typename: 'NotificationLogObject', id: string }> };

export const SubscriptionRunDetailFragmentDoc = gql`
    fragment SubscriptionRunDetail on SubscriptionRunObject {
  id
  name
  runStart
  runEnd
  jobId
  jobAction
  jobStartAt
  entryCount
  orderCount
  paidOrderCount
  pendingOrderCount
  cancelledOrderCount
  completedOrderCount
  subscriberCount
  createdAt
  updatedAt
  variant {
    ...SubscriptionVariantItem
  }
  salesTotals {
    currency
    total
  }
}
    ${SubscriptionVariantItemFragmentDoc}`;
export const SubscriptionRunRowFragmentDoc = gql`
    fragment SubscriptionRunRow on SubscriptionRunObject {
  id
  name
  jobId
  jobAction
  jobStartAt
  runStart
  runEnd
  entryCount
  orderCount
  paidOrderCount
  pendingOrderCount
  cancelledOrderCount
  createdAt
  updatedAt
  variant {
    ...SubscriptionVariantItem
  }
  salesTotals {
    currency
    total
  }
}
    ${SubscriptionVariantItemFragmentDoc}`;
export const SubscriptionRunItemFragmentDoc = gql`
    fragment SubscriptionRunItem on SubscriptionRunObject {
  id
  name
}
    `;
export const SubscriptionRunItemDocument = gql`
    query SubscriptionRunItem($id: Int!) {
  subscriptionRun(id: $id) {
    ...SubscriptionRunItem
  }
}
    ${SubscriptionRunItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionRunItemGQL extends Apollo.Query<SubscriptionRunItemQuery, SubscriptionRunItemQueryVariables> {
    document = SubscriptionRunItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionRunRowDocument = gql`
    query SubscriptionRunRow($id: Int!) {
  subscriptionRun(id: $id) {
    ...SubscriptionRunRow
  }
}
    ${SubscriptionRunRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionRunRowGQL extends Apollo.Query<SubscriptionRunRowQuery, SubscriptionRunRowQueryVariables> {
    document = SubscriptionRunRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionRunRowsDocument = gql`
    query SubscriptionRunRows($page: PageInput, $sort: SortInput, $filter: SubscriptionRunFilterInput) {
  items: subscriptionRuns(page: $page, sort: $sort, filter: $filter) {
    ...SubscriptionRunRow
  }
  totalItemsCount: subscriptionRunsCount(filter: $filter)
}
    ${SubscriptionRunRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionRunRowsGQL extends Apollo.Query<SubscriptionRunRowsQuery, SubscriptionRunRowsQueryVariables> {
    document = SubscriptionRunRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionRunDetailDocument = gql`
    query SubscriptionRunDetail($id: Int!) {
  subscriptionRun(id: $id) {
    ...SubscriptionRunDetail
  }
}
    ${SubscriptionRunDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionRunDetailGQL extends Apollo.Query<SubscriptionRunDetailQuery, SubscriptionRunDetailQueryVariables> {
    document = SubscriptionRunDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubscriptionRunDocument = gql`
    mutation CreateSubscriptionRun($variantId: Int!, $input: SubscriptionRunInput!) {
  createSubscriptionRun(variantId: $variantId, input: $input) {
    ...SubscriptionRunItem
  }
}
    ${SubscriptionRunItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubscriptionRunGQL extends Apollo.Mutation<CreateSubscriptionRunMutation, CreateSubscriptionRunMutationVariables> {
    document = CreateSubscriptionRunDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubscriptionRunDocument = gql`
    mutation UpdateSubscriptionRun($id: Int!, $input: SubscriptionRunInput!) {
  updateSubscriptionRun(runId: $id, input: $input) {
    ...SubscriptionRunItem
  }
}
    ${SubscriptionRunItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubscriptionRunGQL extends Apollo.Mutation<UpdateSubscriptionRunMutation, UpdateSubscriptionRunMutationVariables> {
    document = UpdateSubscriptionRunDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueueSubscriptionRunProcessEntriesDocument = gql`
    mutation QueueSubscriptionRunProcessEntries($runId: Int!) {
  queueSubscriptionRunProcessEntries(runId: $runId) {
    ...SubscriptionRunItem
  }
}
    ${SubscriptionRunItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueueSubscriptionRunProcessEntriesGQL extends Apollo.Mutation<QueueSubscriptionRunProcessEntriesMutation, QueueSubscriptionRunProcessEntriesMutationVariables> {
    document = QueueSubscriptionRunProcessEntriesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueueSubscriptionRunProcessPaymentsDocument = gql`
    mutation QueueSubscriptionRunProcessPayments($runId: Int!) {
  queueSubscriptionRunProcessPayments(runId: $runId) {
    ...SubscriptionRunItem
  }
}
    ${SubscriptionRunItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QueueSubscriptionRunProcessPaymentsGQL extends Apollo.Mutation<QueueSubscriptionRunProcessPaymentsMutation, QueueSubscriptionRunProcessPaymentsMutationVariables> {
    document = QueueSubscriptionRunProcessPaymentsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnlockSubscriptionRunProcessingDocument = gql`
    mutation UnlockSubscriptionRunProcessing($runId: Int!) {
  unlockSubscriptionRunProcessing(runId: $runId) {
    ...SubscriptionRunItem
  }
}
    ${SubscriptionRunItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UnlockSubscriptionRunProcessingGQL extends Apollo.Mutation<UnlockSubscriptionRunProcessingMutation, UnlockSubscriptionRunProcessingMutationVariables> {
    document = UnlockSubscriptionRunProcessingDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateSubscriptionEntriesDocument = gql`
    mutation GenerateSubscriptionEntries($runId: Int!) {
  generateSubscriptionEntries(runId: $runId) {
    subscriberCount
    entriesCreated
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateSubscriptionEntriesGQL extends Apollo.Mutation<GenerateSubscriptionEntriesMutation, GenerateSubscriptionEntriesMutationVariables> {
    document = GenerateSubscriptionEntriesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProcessSubscriptionEntriesDocument = gql`
    mutation ProcessSubscriptionEntries($entryIds: [Int!]!) {
  processSubscriptionEntries(entryIds: $entryIds) {
    ordersCreated
    entryCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProcessSubscriptionEntriesGQL extends Apollo.Mutation<ProcessSubscriptionEntriesMutation, ProcessSubscriptionEntriesMutationVariables> {
    document = ProcessSubscriptionEntriesDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProcessSubscriptionEntriesPaymentDocument = gql`
    mutation ProcessSubscriptionEntriesPayment($entryIds: [Int!]!) {
  processSubscriptionEntriesPayment(entryIds: $entryIds) {
    paymentsCompleted
    paymentsFailed
    entriesProcessed
    entryCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProcessSubscriptionEntriesPaymentGQL extends Apollo.Mutation<ProcessSubscriptionEntriesPaymentMutation, ProcessSubscriptionEntriesPaymentMutationVariables> {
    document = ProcessSubscriptionEntriesPaymentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendSubscriptionSmsDocument = gql`
    mutation SendSubscriptionSms($entryIds: [Int!]!, $smsType: SubscriptionVariantSmsType!) {
  sendSubscriptionEntrySms(entryIds: $entryIds, smsType: $smsType) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendSubscriptionSmsGQL extends Apollo.Mutation<SendSubscriptionSmsMutation, SendSubscriptionSmsMutationVariables> {
    document = SendSubscriptionSmsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }