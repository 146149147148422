import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ParcelOptionItemFragment = { __typename: 'ParcelOptionObject', id: number, name: string, code: string };

export type ParcelOptionRowFragment = { __typename: 'ParcelOptionObject', id: number, code: string, name: string, weight?: number | null | undefined, createdAt: any, updatedAt: any, dimensions?: { __typename: 'DimensionsObject', length: number, width: number, height: number } | null | undefined };

export type ParcelOptionDetailFragment = { __typename: 'ParcelOptionObject', id: number, code: string, name: string, weight?: number | null | undefined, createdAt: any, updatedAt: any, dimensions?: { __typename: 'DimensionsObject', length: number, width: number, height: number } | null | undefined };

export type CreateParcelOptionMutationVariables = Types.Exact<{
  input: Types.CreateParcelOptionInput;
}>;


export type CreateParcelOptionMutation = { __typename: 'Mutation', createParcelOption: { __typename: 'ParcelOptionObject', id: number, code: string, name: string } };

export type UpdateParcelOptionMutationVariables = Types.Exact<{
  input: Types.UpdateParcelOptionInput;
}>;


export type UpdateParcelOptionMutation = { __typename: 'Mutation', updateParcelOption: { __typename: 'ParcelOptionObject', id: number, code: string, name: string } };

export type ParcelOptionItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ParcelOptionItemQuery = { __typename: 'Query', parcelOption: { __typename: 'ParcelOptionObject', id: number, name: string, code: string } };

export type ParcelOptionItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ParcelOptionFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ParcelOptionItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ParcelOptionObject', id: number, name: string, code: string }> };

export type ParcelOptionSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type ParcelOptionSearchQuery = { __typename: 'Query', parcelOptions: Array<{ __typename: 'ParcelOptionObject', id: number, code: string, name: string }> };

export type ParcelOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ParcelOptionsQuery = { __typename: 'Query', parcelOptions: Array<{ __typename: 'ParcelOptionObject', id: number, code: string, name: string, weight?: number | null | undefined, createdAt: any, updatedAt: any, dimensions?: { __typename: 'DimensionsObject', length: number, width: number, height: number } | null | undefined }> };

export type ParcelOptionRowQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ParcelOptionRowQuery = { __typename: 'Query', parcelOption: { __typename: 'ParcelOptionObject', id: number, code: string, name: string, weight?: number | null | undefined, createdAt: any, updatedAt: any, dimensions?: { __typename: 'DimensionsObject', length: number, width: number, height: number } | null | undefined } };

export type ParcelOptionRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ParcelOptionFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ParcelOptionRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ParcelOptionObject', id: number, code: string, name: string, weight?: number | null | undefined, createdAt: any, updatedAt: any, dimensions?: { __typename: 'DimensionsObject', length: number, width: number, height: number } | null | undefined }> };

export type ParcelOptionDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ParcelOptionDetailQuery = { __typename: 'Query', parcelOption: { __typename: 'ParcelOptionObject', id: number, code: string, name: string, weight?: number | null | undefined, createdAt: any, updatedAt: any, dimensions?: { __typename: 'DimensionsObject', length: number, width: number, height: number } | null | undefined } };

export const ParcelOptionItemFragmentDoc = gql`
    fragment ParcelOptionItem on ParcelOptionObject {
  id
  name
  code
}
    `;
export const ParcelOptionRowFragmentDoc = gql`
    fragment ParcelOptionRow on ParcelOptionObject {
  id
  code
  name
  dimensions {
    length
    width
    height
  }
  weight
  createdAt
  updatedAt
}
    `;
export const ParcelOptionDetailFragmentDoc = gql`
    fragment ParcelOptionDetail on ParcelOptionObject {
  id
  code
  name
  dimensions {
    length
    width
    height
  }
  weight
  createdAt
  updatedAt
}
    `;
export const CreateParcelOptionDocument = gql`
    mutation CreateParcelOption($input: CreateParcelOptionInput!) {
  createParcelOption(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateParcelOptionGQL extends Apollo.Mutation<CreateParcelOptionMutation, CreateParcelOptionMutationVariables> {
    document = CreateParcelOptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateParcelOptionDocument = gql`
    mutation UpdateParcelOption($input: UpdateParcelOptionInput!) {
  updateParcelOption(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateParcelOptionGQL extends Apollo.Mutation<UpdateParcelOptionMutation, UpdateParcelOptionMutationVariables> {
    document = UpdateParcelOptionDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionItemDocument = gql`
    query ParcelOptionItem($id: Int!) {
  parcelOption(id: $id) {
    ...ParcelOptionItem
  }
}
    ${ParcelOptionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionItemGQL extends Apollo.Query<ParcelOptionItemQuery, ParcelOptionItemQueryVariables> {
    document = ParcelOptionItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionItemsDocument = gql`
    query ParcelOptionItems($page: PageInput, $filter: ParcelOptionFilterInput, $sort: SortInput) {
  items: parcelOptions(page: $page, filter: $filter, sort: $sort) {
    ...ParcelOptionItem
  }
  totalItemsCount: parcelOptionsCount(filter: $filter)
}
    ${ParcelOptionItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionItemsGQL extends Apollo.Query<ParcelOptionItemsQuery, ParcelOptionItemsQueryVariables> {
    document = ParcelOptionItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionSearchDocument = gql`
    query ParcelOptionSearch($searchText: String, $results: Int!) {
  parcelOptions(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionSearchGQL extends Apollo.Query<ParcelOptionSearchQuery, ParcelOptionSearchQueryVariables> {
    document = ParcelOptionSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionsDocument = gql`
    query ParcelOptions {
  parcelOptions {
    ...ParcelOptionDetail
  }
}
    ${ParcelOptionDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionsGQL extends Apollo.Query<ParcelOptionsQuery, ParcelOptionsQueryVariables> {
    document = ParcelOptionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionRowDocument = gql`
    query ParcelOptionRow($id: Int!) {
  parcelOption(id: $id) {
    ...ParcelOptionRow
  }
}
    ${ParcelOptionRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionRowGQL extends Apollo.Query<ParcelOptionRowQuery, ParcelOptionRowQueryVariables> {
    document = ParcelOptionRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionRowsDocument = gql`
    query ParcelOptionRows($page: PageInput, $filter: ParcelOptionFilterInput, $sort: SortInput) {
  items: parcelOptions(page: $page, filter: $filter, sort: $sort) {
    ...ParcelOptionRow
  }
  totalItemsCount: parcelOptionsCount(filter: $filter)
}
    ${ParcelOptionRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionRowsGQL extends Apollo.Query<ParcelOptionRowsQuery, ParcelOptionRowsQueryVariables> {
    document = ParcelOptionRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParcelOptionDetailDocument = gql`
    query ParcelOptionDetail($id: Int!) {
  parcelOption(id: $id) {
    ...ParcelOptionDetail
  }
}
    ${ParcelOptionDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ParcelOptionDetailGQL extends Apollo.Query<ParcelOptionDetailQuery, ParcelOptionDetailQueryVariables> {
    document = ParcelOptionDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }