import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AgreementDeclarationItemsGQL,
  AgreementDeclarationItemsQueryVariables,
} from './gql/agreement-declaration.gql.generated';
import { IAgreementDeclarationItemObject } from './types/agreement-declaration';

@Injectable()
export class AgreementDeclarationService {
  constructor(private itemsGQL: AgreementDeclarationItemsGQL) {}

  fetchItems(
    query: AgreementDeclarationItemsQueryVariables,
  ): Observable<{ items: IAgreementDeclarationItemObject[]; totalItemsCount: number }> {
    return this.itemsGQL.fetch(query).pipe(map((r) => r.data));
  }
}
