import { Injectable } from '@angular/core';
import { ModelAutocompleteDatasource } from '@x/dashboard/form';
import { CountryService, ICountryItem } from '@x/geocode/client';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CountryAutocompleteDatasource extends ModelAutocompleteDatasource<ICountryItem> {
  constructor(private countryService: CountryService) {
    super((i: number, item: ICountryItem) => item.code);
  }

  connect(
    searchText: Observable<string | null>,
    searchArguments?: any,
  ): Observable<ICountryItem[]> {
    return searchText.pipe(
      switchMap((text) => {
        return this.countryService.fetchCountrySearch(text);
      }),
    );
  }

  disconnect(): void {}

  displayFn = (item: ICountryItem | null | undefined) => {
    return item ? item.name : '';
  };
}
