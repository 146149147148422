import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GlobalVariableQueryVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
}>;


export type GlobalVariableQuery = { __typename: 'Query', globalVariable: { __typename: 'VariableObject', id: number, key: string, value?: any | null | undefined, createdAt: any, updatedAt: any } };

export type GlobalVariableArrayQueryVariables = Types.Exact<{
  prefix: Types.Scalars['String']['input'];
}>;


export type GlobalVariableArrayQuery = { __typename: 'Query', globalVariables: Array<{ __typename: 'VariableObject', id: number, key: string, value?: any | null | undefined, createdAt: any, updatedAt: any }> };

export type SetGlobalVariableMutationVariables = Types.Exact<{
  input: Types.SetGlobalVariableInput;
}>;


export type SetGlobalVariableMutation = { __typename: 'Mutation', setGlobalVariable: { __typename: 'VariableObject', id: number, key: string, value?: any | null | undefined, createdAt: any, updatedAt: any } };

export type DeleteGlobalVariableMutationVariables = Types.Exact<{
  input: Types.DeleteGlobalVariableInput;
}>;


export type DeleteGlobalVariableMutation = { __typename: 'Mutation', deleteGlobalVariable: { __typename: 'VariableDeleteObject', key: string } };

export type UserVariableQueryVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
  userId: Types.Scalars['Int']['input'];
}>;


export type UserVariableQuery = { __typename: 'Query', userVariable: { __typename: 'VariableObject', id: number, key: string, value?: any | null | undefined, userId?: number | null | undefined, createdAt: any, updatedAt: any } };

export type UserVariableArrayQueryVariables = Types.Exact<{
  prefix: Types.Scalars['String']['input'];
  userId: Types.Scalars['Int']['input'];
}>;


export type UserVariableArrayQuery = { __typename: 'Query', userVariables: Array<{ __typename: 'VariableObject', id: number, key: string, value?: any | null | undefined, userId?: number | null | undefined, createdAt: any, updatedAt: any }> };

export type SetUserVariableMutationVariables = Types.Exact<{
  input: Types.SetUserVariableInput;
}>;


export type SetUserVariableMutation = { __typename: 'Mutation', setUserVariable: { __typename: 'VariableObject', id: number, key: string, value?: any | null | undefined, createdAt: any, updatedAt: any } };

export type DeleteUserVariableMutationVariables = Types.Exact<{
  input: Types.DeleteUserVariableInput;
}>;


export type DeleteUserVariableMutation = { __typename: 'Mutation', deleteUserVariable: { __typename: 'VariableDeleteObject', key: string } };

export const GlobalVariableDocument = gql`
    query GlobalVariable($key: String!) {
  globalVariable(key: $key) {
    id
    key
    value
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GlobalVariableGQL extends Apollo.Query<GlobalVariableQuery, GlobalVariableQueryVariables> {
    document = GlobalVariableDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GlobalVariableArrayDocument = gql`
    query GlobalVariableArray($prefix: String!) {
  globalVariables(prefix: $prefix) {
    id
    key
    value
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GlobalVariableArrayGQL extends Apollo.Query<GlobalVariableArrayQuery, GlobalVariableArrayQueryVariables> {
    document = GlobalVariableArrayDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetGlobalVariableDocument = gql`
    mutation SetGlobalVariable($input: SetGlobalVariableInput!) {
  setGlobalVariable(input: $input) {
    id
    key
    value
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGlobalVariableGQL extends Apollo.Mutation<SetGlobalVariableMutation, SetGlobalVariableMutationVariables> {
    document = SetGlobalVariableDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGlobalVariableDocument = gql`
    mutation DeleteGlobalVariable($input: DeleteGlobalVariableInput!) {
  deleteGlobalVariable(input: $input) {
    key
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGlobalVariableGQL extends Apollo.Mutation<DeleteGlobalVariableMutation, DeleteGlobalVariableMutationVariables> {
    document = DeleteGlobalVariableDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserVariableDocument = gql`
    query UserVariable($key: String!, $userId: Int!) {
  userVariable(key: $key, userId: $userId) {
    id
    key
    value
    userId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserVariableGQL extends Apollo.Query<UserVariableQuery, UserVariableQueryVariables> {
    document = UserVariableDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserVariableArrayDocument = gql`
    query UserVariableArray($prefix: String!, $userId: Int!) {
  userVariables(prefix: $prefix, userId: $userId) {
    id
    key
    value
    userId
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserVariableArrayGQL extends Apollo.Query<UserVariableArrayQuery, UserVariableArrayQueryVariables> {
    document = UserVariableArrayDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetUserVariableDocument = gql`
    mutation SetUserVariable($input: SetUserVariableInput!) {
  setUserVariable(input: $input) {
    id
    key
    value
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetUserVariableGQL extends Apollo.Mutation<SetUserVariableMutation, SetUserVariableMutationVariables> {
    document = SetUserVariableDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserVariableDocument = gql`
    mutation DeleteUserVariable($input: DeleteUserVariableInput!) {
  deleteUserVariable(input: $input) {
    key
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserVariableGQL extends Apollo.Mutation<DeleteUserVariableMutation, DeleteUserVariableMutationVariables> {
    document = DeleteUserVariableDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }