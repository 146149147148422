import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LocaleItem } from '@x/common/locale';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChannelContext } from './channel-context.service';

export interface LocaleContext {
  selected: string;
  default: string;
  options: LocaleItem[];
}

@Injectable({ providedIn: 'root' })
export class LocaleContextService {
  private selectedLocale$: BehaviorSubject<string>;

  constructor(
    @Inject(LOCALE_ID)
    private appLocale: string,
    private channelService: ChannelContext,
  ) {
    this.selectedLocale$ = new BehaviorSubject<string>(appLocale);
  }

  get selectedLocale() {
    return this.selectedLocale$.getValue();
  }
  set selectedLocale(locale: string) {
    this.selectedLocale$.next(locale);
  }

  getContext(): Observable<LocaleContext> {
    return combineLatest([
      this.selectedLocale$,
      this.channelService.getLocaleOptionsInAllChannels(),
    ]).pipe(
      map(([selected, options]) => {
        return {
          selected,
          options,
          default: this.appLocale,
        };
      }),
    );
  }
}
