import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  IOrderDetailObject,
  IOrderItemObject,
  IOrderRowObject,
  OrderMapItem,
  OrderService,
} from '@x/ecommerce/domain-client';
import { OrderFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrderItemCollectionProvider
  implements
    IDataCollectionProvider<IOrderItemObject, OrderFilterInput>,
    IDataProvider<IOrderItemObject>
{
  constructor(private orderService: OrderService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IOrderItemObject>> {
    return this.orderService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IOrderItemObject> {
    return this.orderService.fetchItem(Number(id));
  }

  toId(model: IOrderItemObject): string | number {
    return model.id;
  }

  toString(model: IOrderItemObject): string {
    return `${model.number ? '#' : 'ID'}${model.number ?? model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class OrderRowCollectionProvider
  implements IDataCollectionProvider<IOrderRowObject, OrderFilterInput, any, number>
{
  constructor(private orderService: OrderService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IOrderRowObject>> {
    return this.orderService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: IOrderRowObject): number {
    return model.id;
  }

  toString(model: IOrderRowObject): string {
    return `${model.number ? '#' : 'ID'}${model.number ?? model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class OrderDetailProvider implements IDataProvider<IOrderDetailObject, number> {
  constructor(private orderService: OrderService) {}

  fetchSingle(id: number): IOrderDetailObject | Observable<IOrderDetailObject> {
    return this.orderService.fetchById(id);
  }

  toId(model: IOrderDetailObject): number {
    return model.id;
  }

  toString(model: IOrderDetailObject): string {
    return `${model.number ? '#' : 'ID'}${model.number ?? model.id}`;
  }
}

@Injectable({ providedIn: 'root' })
export class OrderMapItemCollectionProvider
  implements IDataCollectionProvider<OrderMapItem, OrderFilterInput, any, number>
{
  constructor(private orderService: OrderService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<OrderMapItem>> {
    return this.orderService.fetchMapItems({
      page: { index: 0, size: 5000 },
      filter: { search: searchText, ...filter },
      sort,
    });
  }

  toId(model: IOrderRowObject): number {
    return model.id;
  }

  toString(model: IOrderRowObject): string {
    return `${model.number ? '#' : 'ID'}${model.number ?? model.id}`;
  }
}
